import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TemplateFiltersContext } from 'contexts/filters'

import useTracker from 'services/analytics/hooks/use_tracker'
import { isAnyArray, isPresent } from 'services/helpers/values'

import { addNotification } from 'views/notifications/slice'
import { createFilterTemplate } from 'features/shipments/store/filter_templates_slice'

import useTags from 'features/shipments/hooks/use_tags'
import useAppDispatch from 'services/hooks/use_app_dispatch'

import type { Category } from 'services/hooks/use_category'
import type { Any } from 'services/helpers/types'

export interface UseTemplatesValues {
  categories: Category[]
  onApply: () => void
}

export interface FilterTemplate {
  name: string
  filters: { name: string; value: Any; category: string }[]
  tags: { value: string }[]
}

const useTemplates = ({ categories, onApply }: UseTemplatesValues) => {
  const [name, setName] = useState('')
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { track } = useTracker()
  const { templateFilters, setTemplateFilters } = useContext(TemplateFiltersContext)
  const hasAppliedTemplateFilters = isPresent(templateFilters) && isAnyArray(templateFilters)
  const [shouldTriggerOnApply, setShouldTriggerOnApply] = useState(false)
  const applyTemplateFilters = useCallback(() => {
    categories.forEach(({ resetFilters }) => resetFilters())

    // Because the template filters model is fragile and prone to change,
    // we use defensive programing here by adding safe navigation operators
    // so that if a category or filter name changes, the application won't throw error
    templateFilters?.forEach(({ name: filterName, value, category: categoryName }) => {
      const category = categories.find(({ name: n }) => categoryName === n)
      const filter = category?.filters?.find(({ name: n }) => filterName === n)

      filter?.setValue(value)
    })
  }, [templateFilters, categories])

  useEffect(() => {
    if (shouldTriggerOnApply) {
      setShouldTriggerOnApply(false)
      onApply()
      setTemplateFilters(null)
    }
  }, [shouldTriggerOnApply, onApply, setTemplateFilters])

  useEffect(() => {
    if (hasAppliedTemplateFilters) {
      applyTemplateFilters()
      setShouldTriggerOnApply(true)
    }
  }, [applyTemplateFilters, hasAppliedTemplateFilters])

  const filters = useMemo(
    () =>
      categories
        .map(({ filters: f, name: categoryName }) =>
          f
            .filter(({ isPresent: p }) => p)
            .map(({ name: n, value }) => ({
              name: n,
              value,
              category: categoryName,
            }))
        )
        .flat(),
    [categories]
  )
  const { tags } = useTags({ categories })
  const filterTemplate: FilterTemplate = {
    name,
    filters,
    tags: tags.map(({ value }) => ({ value })),
  }
  const create = () =>
    dispatch(createFilterTemplate(filterTemplate))
      .unwrap()
      .then(() => {
        track('Shipment list / save filter template', {
          name,
          categories: categories.map(({ name: categoryName }) => categoryName),
          status: 'success',
        })
        dispatch(
          addNotification({
            type: 'success',
            title: t('shipments.filterTemplates.creation.request'),
            text: t('shipments.filterTemplates.creation.success'),
          })
        )
      })
      .catch(() => {
        track('Shipment list / save filter', {
          name,
          categories: categories.map(({ name: categoryName }) => categoryName),
          status: 'error',
        })
        dispatch(
          addNotification({
            type: 'alert',
            title: t('shipments.filterTemplates.creation.request'),
            text: t('shipments.filterTemplates.creation.fail'),
          })
        )
      })

  return {
    name,
    setName,
    create,
  }
}

export default useTemplates
