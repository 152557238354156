import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { Controller, useFormContext } from 'react-hook-form'

import Form from 'components/form'
import Grid from 'components/grid'
import InputCheckbox from 'components/input_checkbox'
import NotifMessage from 'components/notif_message'
import Input from 'components/input'
import Select from 'components/select'

import {
  TEST_ID_BOOKING_FORM_ASK_RATE_CONFIRMATION,
  ARIA_LABEL_BOOKING_FORM_RATE_PROPOSAL_CURRENCY,
  TEST_ID_BOOKING_FORM_RATE_PROPOSAL_AMOUNT,
} from 'tests/e2e/test_ids'

import useStaticLocales from 'views/locales/hooks/use_static_locales'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import { WITH_BOOKING_OLD_WORKFLOW } from 'constants/organization_features'

import { BookingFieldNames } from 'views/booking/slices/types'
import { BookingFormInput } from 'views/booking/components/form/types'
import { BOOKING_FIELD_NAMES } from 'constants/bookings'

interface RateConfirmationProps {
  isFieldDisabled: (fieldName: BookingFieldNames) => boolean
}

const RateConfirmation: FC<RateConfirmationProps> = ({ isFieldDisabled }) => {
  const { t } = useTranslation()
  const { features } = useOrganizationCan()
  const { control } = useFormContext<BookingFormInput>()
  const { fromStaticToSelectOptions } = useStaticLocales()

  const orgaWithLegacyWorkflow = features(WITH_BOOKING_OLD_WORKFLOW)

  return (
    <Form.Group title={t('bookings.rateConfirmation.title')}>
      <Grid columns={2} columnsTablet={1}>
        <Grid.Row>
          <Grid.Column>
            <Controller
              name='rate.withRateConfirmation'
              control={control}
              render={({ field }) => (
                <InputCheckbox
                  text={t('bookings.rateConfirmation.askFor')}
                  checked={field.value}
                  onChange={field.onChange}
                  disabled={isFieldDisabled(BOOKING_FIELD_NAMES.RATE_CONFIRMATION)}
                  testId={TEST_ID_BOOKING_FORM_ASK_RATE_CONFIRMATION}
                />
              )}
            />
            <NotifMessage
              type='tip'
              text={t(
                `bookings.tooltips.${
                  orgaWithLegacyWorkflow ? 'rateConfirmationLegacy' : 'rateConfirmation'
                }`
              )}
            />
          </Grid.Column>

          <Grid.Column>
            <Controller
              name='rate.rateconfirmation.amount'
              control={control}
              render={({ field }) => (
                <Input
                  label={t('bookings.rateConfirmation.forwarderRateProposal')}
                  type='number'
                  min={0}
                  step='0.01'
                  placeholder={t('bookings.merchandise.amount')}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  disabled={isFieldDisabled(BOOKING_FIELD_NAMES.RATE_AMOUNT)}
                  testId={TEST_ID_BOOKING_FORM_RATE_PROPOSAL_AMOUNT}
                />
              )}
            />

            <Controller
              name='rate.rateconfirmation.currencyCode'
              control={control}
              render={({ field }) => (
                <Select
                  placeholder={t('bookings.merchandise.currency')}
                  options={fromStaticToSelectOptions('currencies')}
                  value={field.value}
                  onChange={({ value }) => field.onChange(value)}
                  isDisabled={isFieldDisabled(BOOKING_FIELD_NAMES.RATE_CURRENCY)}
                  ariaLabel={ARIA_LABEL_BOOKING_FORM_RATE_PROPOSAL_CURRENCY}
                />
              )}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form.Group>
  )
}

export default RateConfirmation
