import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit'

import { AxiosResponse } from 'axios'

import { Capacity } from 'types/companies'

import { setSsoConfiguration } from 'views/iam/slices/ssoSlice'

import { InternalClient, SharedClient, TrustedRouteClient } from 'services/api/clients'
// onError callback uses signOut request
// eslint-disable-next-line import/no-cycle
import onError from 'services/api/error'
import { removeTokens, saveTokens } from 'utils/authentication'

import { PERSISTED_STATE_KEY } from 'services/store/middlewares/persistState'

import type { RootState } from 'services/store/store'
import type { Settings, User } from 'views/iam/types'

const iAmAdapter = createEntityAdapter<User>()
const initialState = iAmAdapter.getInitialState()

type ApiResponseUser = Omit<User, 'id'>
const formatUser = ({ data }: AxiosResponse<ApiResponseUser>): User => ({
  id: data.profile.id,
  ...data,
})
const formatBlank = () => ({})

export const signIn = createAsyncThunk<
  User,
  { email: string; password: string },
  { state: RootState }
>('iam/signIn', async ({ email, password }, thunkAPI) =>
  InternalClient.post('/users/sign_in', { email, password })
    .then((response) => {
      saveTokens(response.headers)
      return response
    })
    .then(formatUser)
    .catch(onError(thunkAPI))
)

type SignOutApiResponse = {
  bypassLogoutPage?: boolean
  customDomain?: string
  isSso?: boolean
  ssoClientId?: string
  ssoLoginUrl?: string
  ssoLogoutHint?: string
  ssoLogoutUrl?: string
}

export const signOut = createAsyncThunk<Record<string, never>, void, { state: RootState }>(
  'iam/signOut',
  async (_arg, { dispatch }) =>
    InternalClient.get<SignOutApiResponse>('/users/sign_out')
      .then((response) => {
        const {
          ssoLoginUrl,
          ssoClientId,
          ssoLogoutHint,
          ssoLogoutUrl,
          isSso,
          customDomain,
          bypassLogoutPage,
        } = response.data

        dispatch(
          setSsoConfiguration({
            loginUrl: ssoLoginUrl,
            logoutRedirectUrl: ssoLogoutUrl,
            logoutHint: ssoLogoutHint,
            isSSOUser: isSso,
            clientId: ssoClientId,
            customDomain,
            bypassLogoutPage,
          })
        )
        removeTokens()
        return response
      })
      .then(formatBlank)
      .finally(() => {
        dispatch(removeUser())
        localStorage.removeItem(PERSISTED_STATE_KEY)
      })
)

type SignInApiResponse = {
  ssoContext: {
    ssoLogoutUrl?: string
    ssoLoginUrl?: string
    ssoClientId?: string
    ssoLogoutHint?: string
    isSso?: boolean
    bypassLogoutPage?: boolean
  }
  organization?: {
    customDomain?: string
  }
}

export const ssoSignIn = createAsyncThunk<User, { ssoToken: string }, { state: RootState }>(
  'iam/ssoSignIn',
  async ({ ssoToken }, thunkAPI) =>
    InternalClient.post<SignInApiResponse & ApiResponseUser>('/users/sso_sign_in', { ssoToken })
      .then((response) => {
        saveTokens(response.headers)
        const { ssoContext, organization } = response.data
        thunkAPI.dispatch(
          setSsoConfiguration({
            loginUrl: ssoContext.ssoLoginUrl,
            logoutRedirectUrl: ssoContext.ssoLogoutUrl,
            logoutHint: ssoContext.ssoLogoutHint,
            isSSOUser: ssoContext.isSso,
            clientId: ssoContext.ssoClientId,
            customDomain: organization?.customDomain,
            logoutInitiatedByUser: false,
            bypassLogoutPage: ssoContext.bypassLogoutPage,
          })
        )
        return response
      })
      .then(formatUser)
      .catch(onError(thunkAPI))
)

export const signInWithSsoJwt = createAsyncThunk<
  User,
  { organization: string; jwt: string },
  { state: RootState }
>('iam/signInWithSsoJwt', async ({ organization, jwt }) =>
  InternalClient.get(`/sso/jwt/${organization}`, {
    headers: { Authorization: `Token ${jwt}` },
  })
    .then((response) => {
      saveTokens(response.headers)
      return response
    })
    .then(formatUser)
)

export const passwordRecoveryRequest = createAsyncThunk<
  { message: string },
  { email: string },
  { state: RootState }
>('iam/passwordRecoveryRequest', async ({ email }, thunkAPI) =>
  InternalClient.post('/passwords', { email })
    .then((r) => r.data)
    .catch(onError(thunkAPI))
)

export const changePasswordFromRecovery = createAsyncThunk<
  User,
  { password: string; passwordConfirmation: string; token: string },
  { state: RootState }
>('iam/changePasswordFromRecovery', async ({ password, passwordConfirmation, token }, thunkAPI) =>
  InternalClient.patch('/passwords', {
    password,
    password_confirmation: passwordConfirmation,
    pwd_token: token,
  })
    .then((r) => r.data)
    .catch(onError(thunkAPI))
)

export const changePasswordFromInvitation = createAsyncThunk<
  User,
  { password: string; passwordConfirmation: string; token: string },
  { state: RootState }
>('iam/changePasswordFromInvitation', async ({ password, passwordConfirmation, token }, thunkAPI) =>
  InternalClient.patch('/users/invitation', {
    password,
    password_confirmation: passwordConfirmation,
    invitation_token: token,
  })
    .then((r) => r.data)
    .catch(onError(thunkAPI))
)

// TODO: REFACTOR:
// It is cumbersome to manipulate user data because of the localstorage sync
// Any update should update localstorage and store
export const updateSettings = createAsyncThunk<
  User,
  { settings: Settings; id: number },
  { state: RootState }
>('iam/updateSettings', async ({ id, settings }, thunkAPI) =>
  InternalClient.patch('/user_index_settings_update', {
    id,
    settings,
  })
    .then((r) => r.data)
    .then((response) => {
      const { dispatch, getState } = thunkAPI
      const user = selectCurrentUser(getState())
      const isCurrentUserUpdate = user.id === id
      if (isCurrentUserUpdate) {
        const { settings: responseSettings } = response
        const newUser = { ...user, profile: { ...user.profile, settings: responseSettings } }
        dispatch(updateUser({ id: user.id, changes: newUser }))
      }
      return response
    })
    .catch(onError(thunkAPI))
)

export const updateUserRequest = createAsyncThunk<
  User,
  { lastName: string; firstName: string; email: string },
  { state: RootState }
>('iam/updateUserRequest', async ({ lastName, firstName, email }, thunkAPI) => {
  const { getState, dispatch } = thunkAPI
  const user = selectCurrentUser(getState())
  return InternalClient.patch('/users', {
    user: {
      lastName,
      firstName,
      email,
    },
  })
    .then((r) => r.data)
    .then((response) => {
      const newUser = { ...user, profile: { ...user.profile, ...response } }
      dispatch(updateUser({ id: user.id, changes: newUser }))
      return response
    })
    .catch(onError(thunkAPI))
})

export const changePasswordRequest = createAsyncThunk<
  User,
  { password: string; newPassword: string; confirmNewPassword: string },
  { state: RootState }
>('iam/changePasswordRequest', async ({ password, newPassword, confirmNewPassword }, thunkAPI) =>
  InternalClient.patch('/users/change_password', {
    user: {
      oldPassword: password,
      password: newPassword,
      passwordConfirmation: confirmNewPassword,
    },
  })
    .then((r) => r.data)
    .catch(onError(thunkAPI))
)

export const refreshAccessToken = createAsyncThunk<void, void, { state: RootState }>(
  'iam/refreshAccessToken',
  async (_arg, thunkAPI) => {
    const { dispatch, getState } = thunkAPI
    const user = selectCurrentUser(getState())

    return InternalClient.get('/users/profile')
      .then(formatUser)
      .then((newUser) => {
        if (user.id) {
          dispatch(updateUser({ id: user.id, changes: newUser }))
        } else {
          dispatch(addUser(newUser))
        }
      })
      .catch(() => {})
  }
)
export const trustedRouteProfile = createAsyncThunk<void, void, { state: RootState }>(
  'iam/trustedRouteProfile',
  async (_arg, thunkAPI) => {
    const { dispatch, getState } = thunkAPI
    const user = selectCurrentUser(getState())

    return TrustedRouteClient.get('/user/profile', {
      headers: { Authorization: `Bearer ${user.accessToken}` },
    })
      .then((r) => {
        dispatch(
          updateUser({
            id: user.id,
            changes: {
              trustedRouteSettings: r.data,
            },
          })
        )
      })
      .catch(onError(thunkAPI))
  }
)

export const fetchSharedUser = createAsyncThunk<User, { token: string }, { state: RootState }>(
  'iam/fetchSharedUser',
  async ({ token }, thunkAPI) =>
    SharedClient.get(`/users/${token}`)
      .then((r) => r.data)
      .then((user) => ({ id: token, ...nullUser, ...user }))
      .catch(onError(thunkAPI))
)

const iAmSlice = createSlice({
  name: 'IAm',
  initialState,
  reducers: {
    updateUser: iAmAdapter.updateOne,
    addUser: iAmAdapter.addOne,
    removeUser: iAmAdapter.removeAll,
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, iAmAdapter.addOne)
    builder.addCase(ssoSignIn.fulfilled, iAmAdapter.addOne)
    builder.addCase(signInWithSsoJwt.fulfilled, iAmAdapter.addOne)
    builder.addCase(fetchSharedUser.fulfilled, iAmAdapter.addOne)
  },
})

export const { addUser, updateUser, removeUser } = iAmSlice.actions

const { selectAll } = iAmAdapter.getSelectors((state: RootState) => state.iam)

export const nullCompany: { customReferences: string[]; capacities: Capacity[] } = {
  customReferences: [],
  capacities: [],
}
const nullUser = {
  company: nullCompany,
  organization: { theme: {}, features: {}, displaySettings: {} },
  roles: [],
  permissions: [],
  profile: { settings: {} },
  availableAnalyticsCategories: [],
}
export const selectCurrentUser = createSelector(selectAll, (users) => users[0] || nullUser)
export const selectCurrentUserOrganization = createSelector(
  selectCurrentUser,
  (user) => user.organization
)
export const selectCurrentUserOrganizationLogo = createSelector(
  selectCurrentUser,
  (user) => user.organization.logo
)

export const selectCurrentUserLocale = createSelector(
  selectCurrentUser,
  (user) => user.profile.settings?.locale
)

export default iAmSlice.reducer
