import { SelectValue } from 'components/select'
import {
  MERCHANDISE_FCL,
  MERCHANDISE_TYPE_CONTAINER,
  MERCHANDISE_TYPE_PACKAGE,
  MERCHANDISE_TYPE_PACKAGE_TOTAL,
  MERCHANDISE_TYPE_TOTAL,
} from 'constants/bookings'
import { TRANSPORT_TYPE_SEA } from 'constants/shipments'
import { isPresent } from 'services/helpers/values'
import {
  BookingFormMerchandise,
  BookingFormMerchandiseContainerContent,
  BookingFormMerchandisePackage,
  BookingFormMerchandiseTotalContent,
  BookingFormMerchandiseTotalPackage,
  CommonFormMerchandiseDetails,
} from 'views/booking/components/form/merchandise/types'
import { UNKNOWN_CARRIER_ID } from 'views/booking/components/form/transport_details'
import {
  BookingFormContext,
  BookingFormInput,
  BookingFormReferenceData,
  CommentFormData,
  IncotermsFormData,
  MiscellaneousFormData,
  PartiesFormData,
  RateFormData,
  RoutingFormData,
  TransportDetails,
} from 'views/booking/components/form/types'
import {
  BookingTemplate,
  TemplateBookingMerchandiseContainer,
  TemplateBookingMerchandisePackage,
  TemplateBookingMerchandiseTotal,
  TemplateBookingMerchandiseTotalPackage,
} from 'views/booking/components/templates/types'
import {
  Booking,
  BookingAddress,
  BookingMerchandiseContainer,
  BookingMerchandisePackage,
  BookingMerchandiseTotal,
  BookingMerchandiseTotalPackage,
  CommonMerchandiseDetails,
} from 'views/booking/slices/types'
import i18n from 'views/locales/i18n'

const buildBookingMerchandiseFromCommonMerchandiseDetails = (
  merchandiseDetails: CommonMerchandiseDetails | Partial<CommonMerchandiseDetails>,
  context: BookingFormContext
): CommonFormMerchandiseDetails => ({
  ...merchandiseDetails,
  productDescription: merchandiseDetails.productDescription,
  controlledTemperatures: {
    min: merchandiseDetails.controlledTemperatures?.min,
    max: merchandiseDetails.controlledTemperatures?.max,
  },
  commercialValue: {
    ...merchandiseDetails.commercialValue,
    amount: merchandiseDetails.commercialValue?.amount,
    currencyCode: merchandiseDetails.commercialValue?.currencyCode
      ? {
          label: merchandiseDetails.commercialValue.currencyCode,
          value: merchandiseDetails.commercialValue.currencyCode,
        }
      : undefined,
  },
  hazardousGoods: {
    ...merchandiseDetails.hazardousGoods,
    unNumber: merchandiseDetails.hazardousGoods?.unNumber,
    hazardousClass: context.hazardousClasses.find(
      (c) => c.value?.toString() === merchandiseDetails.hazardousGoods?.hazardousClass?.toString()
    ),
    packingGroup: context.packingGroups.find(
      (pg) => pg.value?.toString() === merchandiseDetails.hazardousGoods?.packingGroup?.toString()
    ),
    weight: merchandiseDetails.hazardousGoods?.weight?.value,
  },
})

const formatBookingMerchandiseContainer = (
  merchandise: BookingMerchandiseContainer | TemplateBookingMerchandiseContainer,
  context: BookingFormContext
): BookingFormMerchandiseContainerContent[] =>
  merchandise.content?.map((container) => ({
    quantity: container.quantity,
    containerType: context.containerTypes.find(
      (type) => type.value?.toString() === container.containerType?.toString()
    ),
    products:
      container.products?.map((product) => ({
        ...buildBookingMerchandiseFromCommonMerchandiseDetails(product, context),
        volume: product.volume?.value,
        weight: product.weight?.value,
        packageNumber: product.packageNumber || undefined,
      })) || [],
  })) || []

const formatBookingMerchandisePackage = (
  merchandise: BookingMerchandisePackage | TemplateBookingMerchandisePackage,
  context: BookingFormContext
): BookingFormMerchandisePackage => ({
  packages:
    merchandise.content?.map((p) => ({
      ...buildBookingMerchandiseFromCommonMerchandiseDetails(p, context),
      packageType: context.packageTypes.find(
        (type) => type.value?.toString() === p.packageType?.toString()
      ),
      weight: p.weight?.value,
      width: p.width?.value,
      height: p.height?.value,
      length: p.length?.value,
    })) || [],
  totalWeight: merchandise.totalWeight?.value,
  totalVolume: merchandise.totalVolume?.value,
})

const formatBookingMerchandiseTotalPackage = (
  merchandise: BookingMerchandiseTotalPackage | TemplateBookingMerchandiseTotalPackage,
  context: BookingFormContext
): BookingFormMerchandiseTotalPackage => ({
  volume: merchandise.volume?.value,
  weight: merchandise.weight?.value,
  packageNumber: merchandise.packageNumber || undefined,
  merchandiseDetails: merchandise.content?.map((merchandiseDetails) => ({
    ...buildBookingMerchandiseFromCommonMerchandiseDetails(merchandiseDetails, context),
  })) || [{}],
})

const formatBookingMerchandiseTotal = (
  merchandise: BookingMerchandiseTotal | TemplateBookingMerchandiseTotal,
  context: BookingFormContext
): BookingFormMerchandiseTotalContent => ({
  containers: merchandise.content?.containers?.map((container) => ({
    quantity: container.quantity,
    containerType: context.containerTypes.find(
      (type) => type.value?.toString() === container.containerType?.toString()
    ),
  })) || [{}],
  products: merchandise.content?.products?.map((product) => ({
    ...buildBookingMerchandiseFromCommonMerchandiseDetails(product, context),
    packageNumber: product.packageNumber || undefined,
    volume: product.volume?.value,
    weight: product.weight?.value,
  })) || [{}],
})

export const formatBookingMerchandiseToMerchandiseFormData = (
  context: BookingFormContext,
  booking?: Partial<Booking> | BookingTemplate
): BookingFormMerchandise => ({
  shippingMode: booking?.merchandise?.shippingMode || MERCHANDISE_FCL,
  merchandiseType: booking?.merchandise?.merchandiseType || MERCHANDISE_TYPE_TOTAL,
  content: {
    container:
      booking?.merchandise?.merchandiseType === MERCHANDISE_TYPE_CONTAINER
        ? formatBookingMerchandiseContainer(booking?.merchandise, context)
        : [{ products: [{}] }],
    package:
      booking?.merchandise?.merchandiseType === MERCHANDISE_TYPE_PACKAGE
        ? formatBookingMerchandisePackage(booking?.merchandise, context)
        : { packages: [{}] },
    packageTotal:
      booking?.merchandise?.merchandiseType === MERCHANDISE_TYPE_PACKAGE_TOTAL
        ? formatBookingMerchandiseTotalPackage(booking?.merchandise, context)
        : { merchandiseDetails: [{}] },
    total:
      booking?.merchandise?.merchandiseType === MERCHANDISE_TYPE_TOTAL
        ? formatBookingMerchandiseTotal(booking?.merchandise, context)
        : { containers: [{}], products: [{}] },
  },
})

const formatParties = (
  context: BookingFormContext,
  booking?: Partial<Booking>
): PartiesFormData => ({
  consignee: booking?.consignee
    ? {
        label: booking.consignee.name,
        value: booking.consignee.id,
      }
    : undefined,
  consignor: booking?.consignor
    ? {
        label: booking.consignor.name,
        value: booking.consignor.id,
      }
    : undefined,
  shipper: booking?.shipper
    ? {
        label: booking.shipper.name,
        value: booking.shipper.id,
      }
    : context.defaultShipper,
  forwarder: booking?.forwarder
    ? {
        label: booking.forwarder.name,
        value: booking.forwarder.id,
      }
    : context.defaultForwarder,
})

const formatReferences = (booking?: Partial<Booking>): BookingFormReferenceData => ({
  clientReference: booking?.clientReference || undefined,
  clientBookingNumber: booking?.clientBookingNumber || undefined,
  customReferences: booking?.customReferences,
  forwarderReference: booking?.forwarderReference || undefined,
})

const formatIncoterms = (booking?: Partial<Booking>): IncotermsFormData => ({
  incoterms: booking?.incoterms
    ? {
        label: booking?.incoterms,
        value: booking?.incoterms,
      }
    : undefined,
  incotermsLocation: booking?.incotermsLocation || undefined,
})

const formatMiscellaneous = (booking?: Partial<Booking>): MiscellaneousFormData => ({
  cutOffDate: booking?.vesselCutOffDate || undefined,
  customFields: booking?.customFields,
  vgmCutOffDate: booking?.vgmCutOffDate || undefined,
})

const formatRate = (booking?: Partial<Booking>): RateFormData => ({
  withRateConfirmation: booking?.rateConfirmation || false,
  rateconfirmation: booking?.proposal
    ? {
        amount: booking.proposal.rate || undefined,
        currencyCode: booking.proposal.currencyCode
          ? {
              label: booking.proposal.currencyCode,
              value: booking.proposal.currencyCode,
            }
          : undefined,
      }
    : undefined,
})

const formatTransportDetails = (
  context: BookingFormContext,
  booking?: Partial<Booking>
): TransportDetails => {
  const existingVessels =
    booking?.vessels
      ?.filter((vessel) => vessel.id)
      .map((vessel) => ({
        label: vessel.name,
        value: vessel.id,
      })) || []

  const unknownVessels =
    booking?.vessels
      ?.filter((vessel) => !vessel.id)
      .map((vessel) => ({
        label: `${i18n.t('bookings.select.options.unknownVessel')} (${vessel.name})`,
        value: undefined,
      })) || []

  let carrier: SelectValue | undefined

  if (booking?.carrier?.id !== UNKNOWN_CARRIER_ID && booking?.carrier?.name) {
    carrier = {
      label: booking.carrier.name,
      value: booking.carrier.id,
    }
  } else if (booking?.carrier?.name) {
    carrier = {
      label: `${i18n.t('bookings.select.options.unknownCarrier')} (${booking.carrier.name})`,
      value: booking.carrier.id,
    }
  }

  return {
    flightNumbers: booking?.flightNumbers,
    bookingNumber: booking?.bookingNumber || undefined,
    carrier,
    masterBl: booking?.masterBl || undefined,
    shipmentAttributes:
      booking?.shipmentAttributes?.map((shipmentAttribute) => ({
        weight: shipmentAttribute.weight?.value,
        shipmentReference: shipmentAttribute.shipmentReference,
        masterAwb: shipmentAttribute.masterAwb,
        containerNumber: shipmentAttribute.containerNumber,
        containerType: context.containerTypes.find(
          (type) => type.label === shipmentAttribute.containerType
        ),
      })) || [],
    vessels: existingVessels.concat(unknownVessels),
    voyageNumbers: booking?.voyageNumbers,
  }
}

const formatComments = (booking?: Partial<Booking>): CommentFormData => ({
  shipperComment: booking?.comments || undefined,
  forwarderComment: booking?.forwarderComment || undefined,
})

const formatBookingAddress = (address: BookingAddress) => ({
  label: `${address.name}, ${address.countryCode} ${
    isPresent(address.locode) ? `(${address.locode})` : ''
  }`,
  value: address.id,
})

const getDefaultPtd = (
  context: BookingFormContext,
  booking?: Partial<Booking>
): string | undefined => {
  if (context.isStatusPastProposalExchange || context.isForwarder)
    return booking?.proposal?.ptd || booking?.revisedPtd || undefined

  return booking?.shipperPtd || undefined
}

const getDefaultPta = (
  context: BookingFormContext,
  booking?: Partial<Booking>
): string | undefined => {
  if (context.isStatusPastProposalExchange || context.isForwarder)
    return booking?.proposal?.pta || booking?.revisedPta || undefined

  return booking?.shipperPta || undefined
}

const getDefaultPoldPtd = (
  context: BookingFormContext,
  booking?: Partial<Booking>
): string | undefined => {
  if (booking?.transportPlan?.withPreCarriage || !context.withBookingOldWorkflow)
    return booking?.polPtd || undefined

  return getDefaultPtd(context, booking)
}

const getDefaultPodPta = (
  context: BookingFormContext,
  booking?: Partial<Booking>
): string | undefined => {
  if (booking?.transportPlan?.withOnCarriage || !context.withBookingOldWorkflow)
    return booking?.podPta || undefined

  return getDefaultPta(context, booking)
}

const formatRouting = (
  context: BookingFormContext,
  booking?: Partial<Booking>
): RoutingFormData => ({
  withPreCarriage: booking?.transportPlan?.withPreCarriage || false,
  preCarriage: booking?.transportPlan?.withPreCarriage
    ? {
        location: booking?.preCarriageAddress
          ? formatBookingAddress(booking?.preCarriageAddress)
          : undefined,
        ptd: getDefaultPtd(context, booking),
      }
    : undefined,
  pol: booking?.transportPlan?.withPol
    ? {
        location: booking?.pol ? formatBookingAddress(booking?.pol) : undefined,
        ptd: getDefaultPoldPtd(context, booking),
        pta: booking?.polPta || undefined,
      }
    : undefined,
  transshipments:
    booking?.transshipments?.map((transshipment) => ({
      ...transshipment,
      location: formatBookingAddress(transshipment.address),
    })) || [],
  pod: booking?.transportPlan?.withPod
    ? {
        location: booking?.pod ? formatBookingAddress(booking?.pod) : undefined,
        ptd: booking?.podPtd || undefined,
        pta: getDefaultPodPta(context, booking),
      }
    : undefined,
  withPostCarriage: booking?.transportPlan?.withOnCarriage || false,
  postCarriage: booking?.transportPlan?.withOnCarriage
    ? {
        location: booking?.onCarriageAddress
          ? formatBookingAddress(booking?.onCarriageAddress)
          : undefined,
        pta: getDefaultPta(context, booking),
      }
    : undefined,
})

const formatBookingToFormInput = (
  context: BookingFormContext,
  booking?: Partial<Booking>
): BookingFormInput => ({
  references: formatReferences(booking),
  parties: formatParties(context, booking),
  incoterms: formatIncoterms(booking),
  transportType: booking?.transportType || TRANSPORT_TYPE_SEA,
  miscellaneous: formatMiscellaneous(booking),
  rate: formatRate(booking),
  transportDetails: formatTransportDetails(context, booking),
  merchandise: formatBookingMerchandiseToMerchandiseFormData(context, booking),
  routing: formatRouting(context, booking),
  keyContacts:
    booking?.assignedUsers?.map((user) => user.email) || context.defaultKeyContacts || [],
  comments: formatComments(booking),
})

export default formatBookingToFormInput
