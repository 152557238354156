import {
  addDays,
  addMonths,
  addYears,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  getHours,
  getMinutes,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  isEqual,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfWeek,
  toDate,
} from 'date-fns'

import { UTCDate } from '@date-fns/utc'

import { isPresent } from 'services/helpers/values'

import i18n from 'views/locales/i18n'

import { LOCALES_DATE_FNS_MAPPING, SupportedLanguage } from 'constants/locales'

export type UnsafeDate = Date | number | string | null | undefined

class DateHelper {
  private readonly dateWithTimezone: Date

  private readonly date: Date

  constructor(unsafeDate?: UnsafeDate) {
    this.dateWithTimezone = DateHelper.toSafeDate(unsafeDate, true)
    this.date = new UTCDate(this.dateWithTimezone)
  }

  diff(unsafeDateTo: UnsafeDate, measure: 'days' | 'minutes' | 'hours' = 'days'): number {
    const dateTo = DateHelper.toSafeDate(unsafeDateTo)

    switch (measure) {
      case 'minutes':
        return differenceInMinutes(this.date, dateTo)
      case 'hours':
        return differenceInHours(this.date, dateTo)
      case 'days':
      default:
        return differenceInDays(this.date, dateTo)
    }
  }

  toSlash(): string {
    return this.format('dd/MM/yyyy')
  }

  toDateOnly(): string {
    return this.format('yyyy-MM-dd')
  }

  toSlashAndHour(): string {
    return `${this.format('dd/MM/yy')} - ${this.format('H:mm')}`
  }

  toLocale({ hours } = { hours: false }): string {
    if (!hours || !this.hasHours()) {
      return this.format('PP')
    }

    return this.format('PP - HH:mm')
  }

  format(formatStr: string, locale?: SupportedLanguage): string {
    return format(this.date, formatStr, {
      locale: locale
        ? LOCALES_DATE_FNS_MAPPING[locale]
        : LOCALES_DATE_FNS_MAPPING[i18n.language as SupportedLanguage],
    })
  }

  isBetweenDates(unsafeDateA: UnsafeDate, unsafeDateB?: UnsafeDate): boolean {
    const dateA = DateHelper.toSafeDate(unsafeDateA)
    if (isPresent(unsafeDateB)) {
      const dateB = DateHelper.toSafeDate(unsafeDateB)
      return this.isAfterOrEqual(dateA) && this.isBeforeOrEqual(dateB)
    }
    return this.isAfterOrEqual(dateA)
  }

  isBeforeOrEqual(unsafeDate: UnsafeDate): boolean {
    const date = DateHelper.toSafeDate(unsafeDate)
    return this.isBefore(date) || isEqual(this.date, date)
  }

  isAfterOrEqual(unsafeDate: UnsafeDate): boolean {
    const date = DateHelper.toSafeDate(unsafeDate)
    return this.isAfter(date) || isEqual(this.date, date)
  }

  isBefore(unsafeDate: UnsafeDate): boolean {
    const date = DateHelper.toSafeDate(unsafeDate)
    return isBefore(this.date, date)
  }

  isAfter(unsafeDate: UnsafeDate): boolean {
    const date = DateHelper.toSafeDate(unsafeDate)
    return isAfter(this.date, date)
  }

  toDate(): Date {
    return this.date
  }

  toISOString(): string {
    return this.date.toISOString()
  }

  rangeToday(): [string, string] {
    return [startOfDay(this.date).toISOString(), endOfDay(this.date).toISOString()]
  }

  rangeThisWeek(): [string, string] {
    return [startOfWeek(this.date).toISOString(), endOfWeek(this.date).toISOString()]
  }

  rangeThisMonth(): [string, string] {
    return [startOfMonth(this.date).toISOString(), endOfMonth(this.date).toISOString()]
  }

  hasHours(): boolean {
    return getHours(this.date) !== 0 || getMinutes(this.date) !== 0
  }

  addDays(days: number): Date {
    return addDays(this.date, days)
  }

  addMonths(months: number): Date {
    return addMonths(this.date, months)
  }

  addYears(years: number): Date {
    return addYears(this.date, years)
  }

  getMonth(): number {
    return getMonth(this.date)
  }

  getYear(): number {
    return getYear(this.date)
  }

  getDay(): number {
    return getDay(this.date)
  }

  getHour(): number {
    return getHours(this.date)
  }

  getMinute(): number {
    return getMinutes(this.date)
  }

  private static toSafeDate(unsafeDate: UnsafeDate, preserveTimeZone = false): Date {
    let safeDate: Date
    if (!unsafeDate) {
      safeDate = new Date()
    } else if (typeof unsafeDate === 'string') {
      safeDate = parseISO(unsafeDate)
    } else {
      safeDate = toDate(unsafeDate)
    }

    return preserveTimeZone ? safeDate : new UTCDate(safeDate)
  }
}

export default DateHelper
