import styled, { css } from 'styled-components'

import { StepStatus } from 'types/shipments'

import { text14Regular24, textSmallDescription, textItalicDescription } from 'styles/utils/texts'
import { borderRadiusRound } from 'styles/utils/border_radius'
import StyledIcon from 'components/icon/style'
import { tablet } from 'styles/utils/breakpoints'
import { STEP_STATUS_CURRENT, STEP_STATUS_FUTURE } from 'constants/shipments'

const verticalBreak = tablet.down

// Step
export const StyledStepHead = styled.div<{ $condensed: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 170px;
  left: 15px;
  position: relative;
  transform: translateX(-50%);
  margin-top: 5px;

  ${(props) =>
    !props.$condensed &&
    css`
      @media ${verticalBreak} {
        align-items: flex-start;
        text-align: left;
        transform: translateY(-25%);
        position: absolute;
        top: 0;
        left: 40px;
      }
    `}
`

export const StyledStepInner = styled.div``

export const StyledStepIcon = styled.div`
  width: 30px;
  height: 30px;
  ${borderRadiusRound}
  margin-right: 22px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;

  ${StyledIcon} {
    position: relative;
    z-index: 80;
    width: 14px;
    fill: ${({ theme }) => theme.white};
  }
`

export const StyledStepTitle = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
`

export const StyledStepName = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${text14Regular24}

  @media ${verticalBreak} {
    width: 100%;
  }
`
export const StyledStepFlag = styled.div`
  margin-left: 10px;
`

export const StyledStepTime = styled.div`
  ${textSmallDescription}
  color: ${({ theme }) => theme.textLight};
`

export const StyledStepProgress = styled.div`
  width: 4px;
  position: absolute;
  top: 13px;
  left: 15px;
  width: calc(100% - 5px);
  z-index: -1;

  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 4px;
    top: 0;
    left: 0;
  }

  &::before {
    box-sizing: content-box;
    border-top: 4px dotted ${({ theme }) => theme.mediumGray};
  }

  &::after {
    background-color: ${({ theme }) => theme.primary};
    transform-origin: 0 0;
  }

  ${StyledIcon} {
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -13px;
    margin-top: -13px;
    width: 25px;
    height: 25px;
    z-index: 80;
    fill: ${({ theme }) => theme.primary};

    @media ${verticalBreak} {
      width: 20px;
      height: 20px;
      margin-left: -10px;
      margin-top: -10px;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
`

export const StyledStep = styled.div<{
  $condensed: boolean
  $status: StepStatus
  $inTransit: boolean
}>`
  color: ${({ theme }) => theme.textDark};
  position: relative;
  flex: none;
  width: calc(100% / 3);

  &:last-child {
    margin-left: calc(-100% / 3);

    ${StyledStepInner} {
      transform: translateX(100%);
    }
    ${StyledStepProgress} {
      display: none;
    }
  }

  /* Condensed */
  ${(props) =>
    props.$condensed &&
    css`
      ${StyledStepIcon} {
        width: 10px;
        height: 10px;
      }
      ${StyledStepProgress} {
        top: 4px;
        left: 10px;
        width: calc(100% - 2px);

        ${StyledIcon} {
          width: 8px;
          height: 8px;
          margin-left: -4px;
          margin-top: -4px;
          top: 1px;
        }

        &::before,
        &::after {
          border-width: 2px;
          height: 2px;
        }

        &::before {
          border-top-style: dashed;
        }
      }
    `}

  /* Not Condensed */
  ${(props) =>
    !props.$condensed &&
    css`
      height: 84px;

      @media ${verticalBreak} {
        height: 80px;
        width: 100%;

        &:last-child {
          margin-left: 0;
          margin-bottom: -50px;

          ${StyledStepInner} {
            transform: none;
          }
        }

        ${StyledStepProgress} {
          left: 14px;
          top: 30px;
          height: calc(100% - 30px);
          width: 4px;

          &::before {
            border-top: 0;
            border-left: 4px dotted ${({ theme }) => theme.mediumGray};
          }

          &::after,
          &::before {
            height: 100%;
            width: 4px;
          }
        }
      }
    `}

  /* Inactive */
  ${(props) =>
    props.$status === 'inactive' &&
    css`
      z-index: 0;

      &:last-child {
        ${StyledStepProgress} {
          display: block;
          position: absolute;
          left: auto;
        }
      }
      ${StyledStepIcon} {
        background-color: ${props.theme.mediumGray25};
      }
      ${StyledStepProgress} {
        z-index: 20;
        &::after {
          background-color: ${props.theme.mediumGray25};
        }
      }
    `}

  ${(props) =>
    props.$condensed &&
    props.$status === 'inactive' &&
    css`
      &:last-child {
        ${StyledStepProgress} {
          right: calc(100% - 9px);
        }
      }
    `}

  ${(props) =>
    !props.$condensed &&
    props.$status === 'inactive' &&
    css`
      &:last-child {
        ${StyledStepProgress} {
          right: calc(100% - 26px);

          @media ${verticalBreak} {
            right: auto;
            bottom: 100%;
            top: auto;
            left: 14px !important;
          }
        }
      }
    `}



  /* Past */
  ${(props) =>
    props.$status === 'past' &&
    css`
      ${StyledStepIcon} {
        background-color: ${props.theme.primary};
      }
      ${StyledStepProgress} {
        z-index: 20;
        &::after {
          transform: scaleX(1);
        }
      }
    `}

  ${(props) =>
    !props.$condensed &&
    props.$status === 'past' &&
    css`
      ${StyledStepProgress} {
        &::after {
          @media ${verticalBreak} {
            transform: scaleY(1);
          }
        }
      }
    `}

  /* Current */
  ${(props) =>
    props.$status === STEP_STATUS_CURRENT &&
    css`
      ${StyledStepIcon} {
        background-color: ${props.theme.primary};
      }
      ${StyledStepProgress} {
        &::after {
          transform: scaleX(0);
        }
      }
    `}

  ${(props) =>
    !props.$condensed &&
    props.$status === STEP_STATUS_CURRENT &&
    css`
      ${StyledStepProgress} {
        &::after {
          @media ${verticalBreak} {
            transform: scaleY(0);
          }
        }
      }
    `}


  /* Current in transit */
  ${(props) =>
    props.$status === STEP_STATUS_CURRENT &&
    props.$inTransit &&
    css`
      ${StyledStepProgress} {
        &::before {
          width: calc(50% - 10px);
          left: calc(50% + 10px);
        }
        &::after {
          transform: scaleX(1);
          width: calc(50% - 10px);
        }
        ${StyledIcon} {
          display: block;
        }
      }
    `}


  ${(props) =>
    !props.$condensed &&
    props.$status === STEP_STATUS_CURRENT &&
    props.$inTransit &&
    css`
      ${StyledStepProgress} {
        @media ${verticalBreak} {
          &::before {
            transform: scaleY(1);
            width: 100%;
            height: calc(50% - 11px);
            top: calc(50% + 11px);
            left: 0;
          }
          &::after {
            transform: scaleY(1);
            width: 100%;
            height: calc(50% - 11px);
          }
        }
      }
    `}


  /* Future */
  ${(props) =>
    props.$status === STEP_STATUS_FUTURE &&
    css`
      ${StyledStepProgress} {
        &::after {
          transform: scaleX(0);
        }
      }
    `}

  ${(props) =>
    props.$status === STEP_STATUS_FUTURE &&
    !props.$condensed &&
    css`
      ${StyledStepIcon} {
        background-color: ${props.theme.textLight};
      }
      ${StyledStepProgress} {
        &::after {
          @media ${verticalBreak} {
            transform: scaleY(0);
          }
        }
      }
    `}


  ${(props) =>
    props.$status === STEP_STATUS_FUTURE &&
    props.$condensed &&
    css`
      ${StyledStepIcon} {
        background-color: ${props.theme.white};
        border: 2px solid ${props.theme.primary};
        z-index: 40;
      }
    `}
`

// Timeline

export const StyledTimeline = styled.div<{ $condensed: boolean }>`
  display: flex;
  flex-grow: 1;
  min-width: 0;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;

  ${(props) =>
    props.$condensed &&
    css`
      padding-right: 10px;
    `}

  ${(props) =>
    !props.$condensed &&
    css`
      // TODO: improve summary 4 steps display: the first 3 steps occupy the whole parent, and the last one overflows.
      // To center the 4 steps in the card, a right padding is added so that the 4th overflowed step goes back in parent boundaries
      // 4 steps should be set as display flex justify-content space-between, and what is in between should be styled apart
      padding: 11px 120px 11px 80px;

      @media ${verticalBreak} {
        display: block;
        height: auto;
        width: 100%;
        padding: 15px;
      }
    `}
`

export const StyledShipmentTimelineHeader = styled.div`
  display: flex;
  margin: 11px 0 0;
`

export const StyledShipmentTimelineInfo = styled.span`
  ${textItalicDescription};
  color: ${({ theme }) => theme.textLight};
  width: 100%;
  padding: 0 15px;
`
