import {
  MERCHANDISE_FCL,
  MERCHANDISE_LCL,
  MERCHANDISE_TYPE_CONTAINER,
  MERCHANDISE_TYPE_PACKAGE,
  MERCHANDISE_TYPE_PACKAGE_TOTAL,
  MERCHANDISE_TYPE_TOTAL,
} from 'constants/bookings'
import { TRANSPORT_TYPE_AIR, TRANSPORT_TYPE_SEA } from 'constants/shipments'
import { isPresent, stringToInt } from 'services/helpers/values'
import { CommonFormMerchandiseDetails } from 'views/booking/components/form/merchandise/types'
import {
  AirTransportDetails,
  BookingFormInput,
  SeaTransportDetails,
} from 'views/booking/components/form/types'
import {
  CreateBookingTemplateData,
  TemplateBookingMerchandise,
  TemplateBookingMerchandiseContainer,
  TemplateBookingMerchandisePackage,
  TemplateBookingMerchandiseTotal,
  TemplateBookingMerchandiseTotalPackage,
} from 'views/booking/components/templates/types'
import { CommonMerchandiseDetails } from 'views/booking/slices/types'

const buildCommonMerchandiseDetails = (
  merchandise: CommonFormMerchandiseDetails
): CommonMerchandiseDetails => ({
  ...merchandise,
  productDescription: merchandise.productDescription || '',
  commercialValue:
    merchandise.commercialValue?.amount && merchandise.commercialValue.currencyCode
      ? {
          amount: merchandise.commercialValue.amount,
          currencyCode: merchandise.commercialValue.currencyCode.label,
        }
      : undefined,
  hazardousGoods: {
    ...merchandise.hazardousGoods,
    hazardousClass:
      stringToInt(merchandise.hazardousGoods?.hazardousClass?.value as string) ?? undefined,
    packingGroup:
      stringToInt(merchandise.hazardousGoods?.packingGroup?.value as string) ?? undefined,
    weight: merchandise.hazardousGoods?.weight
      ? {
          value: merchandise.hazardousGoods.weight,
          unit: 'kg',
        }
      : undefined,
  },
})

const buildMerchandiseTotalPackage = (
  bookingFormInput: BookingFormInput
): TemplateBookingMerchandiseTotalPackage => ({
  content:
    bookingFormInput.merchandise.content.packageTotal?.merchandiseDetails?.map((m) =>
      buildCommonMerchandiseDetails(m)
    ) || [],
  volume: bookingFormInput.merchandise.content.packageTotal?.volume
    ? {
        value: bookingFormInput.merchandise.content.packageTotal.volume,
        unit: 'cbm',
      }
    : undefined,
  weight: bookingFormInput.merchandise.content.packageTotal?.weight
    ? {
        value: bookingFormInput.merchandise.content.packageTotal.weight,
        unit: 'kg',
      }
    : undefined,
  packageNumber: bookingFormInput.merchandise.content.packageTotal?.packageNumber,
  shippingMode: MERCHANDISE_LCL,
  merchandiseType: MERCHANDISE_TYPE_PACKAGE_TOTAL,
})

const buildMerchandiseTotal = (
  bookingFormInput: BookingFormInput
): TemplateBookingMerchandiseTotal => ({
  content: {
    containers:
      bookingFormInput.merchandise.content.total?.containers.map((container) => ({
        ...container,
        containerType: stringToInt(container.containerType?.value as string) ?? undefined,
      })) || [],
    products:
      bookingFormInput.merchandise.content.total?.products?.map((product) => ({
        ...buildCommonMerchandiseDetails(product),
        volume: product.volume ? { value: product.volume, unit: 'cbm' } : undefined,
        weight: product.weight ? { value: product.weight, unit: 'kg' } : undefined,
        packageNumber: product.packageNumber!,
      })) || [],
  },
  shippingMode: MERCHANDISE_FCL,
  merchandiseType: MERCHANDISE_TYPE_TOTAL,
})

const buildMerchandisePackage = (
  bookingFormInput: BookingFormInput
): TemplateBookingMerchandisePackage => ({
  content:
    bookingFormInput.merchandise.content.package?.packages.map((p) => ({
      ...buildCommonMerchandiseDetails(p),
      quantity: p.quantity,
      packageType: stringToInt(p.packageType?.value as string) ?? undefined,
      weight: p.weight
        ? {
            value: p.weight,
            unit: 'kg',
          }
        : undefined,
      length: p.length
        ? {
            value: p.length,
            unit: 'cm',
          }
        : undefined,
      width: p.width
        ? {
            value: p.width,
            unit: 'cm',
          }
        : undefined,
      height: p.height
        ? {
            value: p.height,
            unit: 'cm',
          }
        : undefined,
    })) || [],
  shippingMode: MERCHANDISE_LCL,
  merchandiseType: MERCHANDISE_TYPE_PACKAGE,
})

const buildMerchandiseContainer = (
  bookingFormInput: BookingFormInput
): TemplateBookingMerchandiseContainer => ({
  content:
    bookingFormInput.merchandise.content.container?.map((container) => ({
      containerType: stringToInt(container.containerType?.value as string) ?? undefined,
      quantity: container.quantity,
      products:
        container.products?.map((product) => ({
          ...buildCommonMerchandiseDetails(product),
          packageNumber: product.packageNumber!,
          volume: product.volume
            ? {
                value: product.volume,
                unit: 'cbm',
              }
            : undefined,
          weight: product.weight
            ? {
                value: product.weight,
                unit: 'kg',
              }
            : undefined,
        })) || [],
    })) || [],
  shippingMode: MERCHANDISE_FCL,
  merchandiseType: MERCHANDISE_TYPE_CONTAINER,
})

const buildMerchandise = (bookingFormInput: BookingFormInput): TemplateBookingMerchandise => {
  if (bookingFormInput.merchandise.merchandiseType === MERCHANDISE_TYPE_PACKAGE_TOTAL) {
    return buildMerchandiseTotalPackage(bookingFormInput)
  }

  if (bookingFormInput.merchandise.merchandiseType === MERCHANDISE_TYPE_TOTAL) {
    return buildMerchandiseTotal(bookingFormInput)
  }

  if (bookingFormInput.merchandise.merchandiseType === MERCHANDISE_TYPE_PACKAGE) {
    return buildMerchandisePackage(bookingFormInput)
  }

  return buildMerchandiseContainer(bookingFormInput)
}

const generateCreateTemplatePayload = (
  bookingFormInput: BookingFormInput,
  templateName: string
): CreateBookingTemplateData => {
  const transportDetails =
    bookingFormInput.transportType === TRANSPORT_TYPE_SEA
      ? (bookingFormInput.transportDetails as SeaTransportDetails)
      : (bookingFormInput.transportDetails as AirTransportDetails)

  const carrierId = transportDetails.carrier
    ? stringToInt(transportDetails.carrier.value as string)
    : undefined

  return {
    templateName,
    shipperId: stringToInt(bookingFormInput.parties.shipper?.value as string) || undefined,
    forwarderId: stringToInt(bookingFormInput.parties.forwarder?.value as string) || undefined,
    consignorId: stringToInt(bookingFormInput.parties.consignor?.value as string) || undefined,
    consigneeId: stringToInt(bookingFormInput.parties.consignee?.value as string) || undefined,
    clientReference: bookingFormInput.references.clientReference,
    clientBookingNumber: bookingFormInput.references.clientBookingNumber,
    comments: bookingFormInput.comments.shipperComment,
    incoterms: bookingFormInput.incoterms.incoterms?.value as string,
    incotermsLocation: bookingFormInput.incoterms.incotermsLocation,
    merchandise: buildMerchandise(bookingFormInput),
    carrier: carrierId
      ? {
          id: carrierId,
          info: transportDetails.carrier?.createValue,
        }
      : undefined,
    bookingNumber:
      bookingFormInput.transportType === TRANSPORT_TYPE_SEA
        ? (transportDetails as SeaTransportDetails).bookingNumber
        : undefined,
    masterBl:
      bookingFormInput.transportType === TRANSPORT_TYPE_SEA
        ? (transportDetails as SeaTransportDetails).masterBl
        : undefined,
    vesselImos:
      bookingFormInput.transportType === TRANSPORT_TYPE_SEA
        ? (transportDetails as SeaTransportDetails).vessels
            ?.filter((v) => !isPresent(v.createValue))
            .map((v) => v.value as string) || []
        : [],
    vesselInfo:
      bookingFormInput.transportType === TRANSPORT_TYPE_SEA
        ? (transportDetails as SeaTransportDetails).vessels
            ?.filter((v) => isPresent(v.createValue))
            .map((v) => v.createValue as string) || []
        : [],
    voyageNumbers:
      bookingFormInput.transportType === TRANSPORT_TYPE_SEA
        ? (transportDetails as SeaTransportDetails).voyageNumbers
        : undefined,
    flightNumbers:
      bookingFormInput.transportType === TRANSPORT_TYPE_AIR
        ? (transportDetails as AirTransportDetails).flightNumbers
        : undefined,
    transportType: bookingFormInput.transportType,
    preCarriageAddressId: bookingFormInput.routing.withPreCarriage
      ? stringToInt(bookingFormInput.routing.preCarriage?.location?.value as string) || undefined
      : undefined,
    polId: stringToInt(bookingFormInput.routing.pol?.location?.value as string) || undefined,
    transshipments:
      bookingFormInput.routing.transshipments
        ?.filter((ts) => isPresent(ts.location))
        .map((ts) => ({
          addressId: stringToInt(ts.location?.value as string) || undefined,
        })) || [],
    podId: stringToInt(bookingFormInput.routing.pod?.location?.value as string) || undefined,
    onCarriageAddressId: bookingFormInput.routing.withPostCarriage
      ? stringToInt(bookingFormInput.routing.postCarriage?.location?.value as string) || undefined
      : undefined,
    rateConfirmation: bookingFormInput.rate.withRateConfirmation,
    assignedUsers: bookingFormInput.keyContacts.map((kc) => ({ email: kc })),
    customReferences:
      bookingFormInput.references.customReferences
        ?.filter((cr) => isPresent(cr.key) && isPresent(cr.value))
        .map((cr) => ({
          key: cr.key!,
          value: cr.value!,
        })) || [],
  }
}

export default generateCreateTemplatePayload
