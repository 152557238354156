import React, { FC, SyntheticEvent } from 'react'

import Form from 'components/form'
import { WITH_FRONT_BOOKING_TRANSPORT_DETAILS } from 'constants/organization_features'
import References from 'views/booking/components/form/references'
import { StyledForm, StyledPageBookingDetailTabs } from 'views/booking/components/form/style'
import useBookingRole from 'views/booking/hooks/use_booking_role'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import Parties from 'views/booking/components/form/parties'
import Incoterms from 'views/booking/components/form/incoterms'
import Routing from 'views/booking/components/form/routing'
import Comments from 'views/booking/components/form/comments'
import BookingFormDocuments from 'views/booking/components/form/documents'
import { RoutingWarning, BookingFieldNames } from 'views/booking/slices/types'
import KeyContacts from 'views/booking/components/form/key_contacts'
import Miscellaneous from 'views/booking/components/form/miscellaneous'
import TransportDetails from 'views/booking/components/form/transport_details'
import RateConfirmation from 'views/booking/components/form/rate_confirmation'
import Merchandise from 'views/booking/components/form/merchandise'

interface BookingFormProps {
  isCreate: boolean
  routingWarning?: RoutingWarning
  isFieldDisabled: (fieldName: BookingFieldNames) => boolean
}

const BookingForm: FC<BookingFormProps> = ({ isCreate, routingWarning, isFieldDisabled }) => {
  const { hasRateConfirmationFeature } = useBookingRole()
  const { features } = useOrganizationCan()

  return (
    <StyledPageBookingDetailTabs>
      <StyledForm as={Form} onSubmit={(e: SyntheticEvent) => e.preventDefault()}>
        <References isFieldDisabled={isFieldDisabled} />
        <Parties isFieldDisabled={isFieldDisabled} />
        <Incoterms isFieldDisabled={isFieldDisabled} />
        <Routing isFieldDisabled={isFieldDisabled} routingWarning={routingWarning} />
        {features(WITH_FRONT_BOOKING_TRANSPORT_DETAILS) && (
          <TransportDetails isFieldDisabled={isFieldDisabled} isCreate={isCreate} />
        )}
        <Miscellaneous isFieldDisabled={isFieldDisabled} isCreate={isCreate} />
        <Merchandise isFieldDisabled={isFieldDisabled} />
        {hasRateConfirmationFeature && <RateConfirmation isFieldDisabled={isFieldDisabled} />}
        <KeyContacts isFieldDisabled={isFieldDisabled} />
        {isCreate && (
          <Form.Group title='Documents'>
            <BookingFormDocuments />
          </Form.Group>
        )}
        <Comments isFieldDisabled={isFieldDisabled} />
      </StyledForm>
    </StyledPageBookingDetailTabs>
  )
}

export default BookingForm
