import React, { FC, forwardRef } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'

import DateHelper from 'services/helpers/date_helper'
import { isPresent } from 'services/helpers/values'

import Select from 'components/select'
import {
  StyledDatePicker,
  StyledDatePickerContainer,
  StyledDatePickerLabel,
  StyledDateTimeSelectWrap,
  StyledLabelRequiredIcon,
  StyledSelectDatePicker,
} from 'components/input_datepicker/style'
import useDatepickerHook from 'components/input_datepicker/hooks/use_datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import { StyledInputError } from 'components/input/style'
import Icon from 'components/icon'
import { LOCALES, LOCALES_DATE_FNS_MAPPING } from 'constants/locales'
import i18n from 'views/locales/i18n'

LOCALES.forEach((locale) => {
  registerLocale(locale, LOCALES_DATE_FNS_MAPPING[locale])
})

type Ref = HTMLDivElement
interface DateTimeSelectProps {
  placeholderText?: string
  value?: string
  onClick?: () => void
  startDate?: Date
  endDate?: Date
  withTime?: boolean
  customId: string
  testId?: string
  disabled?: boolean
  hasError: boolean
}

const DateTimeSelect = forwardRef<Ref, DateTimeSelectProps>(
  (
    {
      value,
      onClick,
      startDate,
      endDate,
      customId,
      testId,
      withTime = false,
      placeholderText = '',
      disabled = false,
      hasError,
    }: DateTimeSelectProps,
    ref
  ) => {
    const label = [startDate, endDate]
      .filter(isPresent)
      .map((d) => new DateHelper(d).toLocale({ hours: isPresent(withTime) ? withTime! : false }))
      .join(' - ')
    return (
      <StyledDateTimeSelectWrap
        onClick={onClick}
        ref={ref}
        aria-hidden='true'
        id={customId}
        data-testid={testId}
        $disabled={disabled}
      >
        <StyledSelectDatePicker
          as={Select}
          value={value ? { value, label } : null}
          isClearable
          icon='calendar'
          $hasError={hasError}
          placeholder={placeholderText}
          isDisabled
          $disabled={disabled}
        />
      </StyledDateTimeSelectWrap>
    )
  }
)

export type TDates = Date | [Date | null, Date | null] | null

export interface InputDatepickerProps {
  range?: boolean
  showTimeSelect?: boolean
  onChange?: ({ name, value }: { name?: string; value: Array<Date> }) => void
  startDate?: string | null
  endDate?: string | null
  defaultStartDate?: Date
  defaultEndDate?: Date
  onToggleOpen?: (isOpen: boolean) => void
  placeholder?: string
  label?: string
  className?: string
  withPortal?: boolean
  name?: string
  required?: boolean
  filterDate?: (date: Date) => boolean
  testId?: string
  disabled?: boolean
  error?: string
}

const InputDatepicker: FC<InputDatepickerProps> = ({
  onChange,
  startDate,
  endDate,
  defaultStartDate,
  defaultEndDate,
  onToggleOpen,
  placeholder,
  label,
  className,
  filterDate,
  testId,
  showTimeSelect = false,
  range = false,
  withPortal = false,
  name = '',
  required = false,
  disabled = false,
  error,
}) => {
  const {
    startDate: uncontrolledStartDate,
    endDate: uncontrolledEndDate,
    onChangeHook,
  } = useDatepickerHook({
    range,
    defaultStartDate,
    defaultEndDate,
  })

  const internalStartDate = uncontrolledStartDate || startDate
  const internalEndDate = uncontrolledEndDate || endDate

  const onChangeDatepicker = (dates: TDates): void => {
    const formatted: Array<Date> = [dates]
      .flat()
      .filter((date): date is Date => isPresent(date))
      .map((d) => convertLocalToUTCDate(d))

    if (uncontrolledEndDate || uncontrolledStartDate) {
      onChangeHook(formatted)
    }

    if (onChange) onChange({ name, value: formatted })
  }

  // React datepicker uses local time so we mock UTC times as local for display
  // and on change : we change the date back to UTC.
  const convertUTCToLocalDate = (date: Date) => {
    if (!date) {
      return date
    }

    const utcDate = new Date(date)

    return new Date(
      utcDate.getUTCFullYear(),
      utcDate.getUTCMonth(),
      utcDate.getUTCDate(),
      utcDate.getUTCHours(),
      utcDate.getUTCMinutes()
    )
  }

  const convertLocalToUTCDate = (date: Date) => {
    if (!date) {
      return date
    }

    const localDate = new Date(date)

    return new Date(
      Date.UTC(
        localDate.getFullYear(),
        localDate.getMonth(),
        localDate.getDate(),
        localDate.getHours(),
        localDate.getMinutes()
      )
    )
  }

  return (
    <StyledDatePickerContainer className={className}>
      {label && (
        <StyledDatePickerLabel htmlFor={name}>
          {label} {required && <StyledLabelRequiredIcon>*</StyledLabelRequiredIcon>}
        </StyledDatePickerLabel>
      )}
      <StyledDatePicker>
        <DatePicker
          name={name}
          selected={convertUTCToLocalDate(internalStartDate)}
          onChange={onChangeDatepicker}
          isClearable={!!internalStartDate && !disabled}
          locale={i18n.language}
          showMonthDropdown
          showYearDropdown
          dropdownMode='select'
          onCalendarOpen={() => onToggleOpen && onToggleOpen(true)}
          onCalendarClose={() => onToggleOpen && onToggleOpen(false)}
          showTimeSelect={!range && showTimeSelect}
          startDate={convertUTCToLocalDate(internalStartDate)}
          endDate={range ? convertUTCToLocalDate(internalEndDate) : null}
          selectsRange={range}
          placeholderText={placeholder}
          withPortal={withPortal}
          autoComplete='off'
          portalId='datepicker-modal-root'
          customInput={
            <DateTimeSelect
              customId={name!}
              testId={testId}
              startDate={internalStartDate}
              endDate={internalEndDate}
              withTime={showTimeSelect}
              disabled={disabled}
              placeholderText={placeholder}
              hasError={isPresent(error)}
            />
          }
          filterDate={filterDate}
          disabledKeyboardNavigation
          disabled={disabled}
        />
      </StyledDatePicker>
      {error && (
        <StyledInputError>
          <Icon name='warning' /> {error}
        </StyledInputError>
      )}
    </StyledDatePickerContainer>
  )
}

export default InputDatepicker
