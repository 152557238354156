import { TrustedRoute } from 'views/trusted_routes/types/trusted_route'
import { compactArray } from 'services/helpers/values'
import {
  SUBSCRIPTION_FULFILLED,
  SUBSCRIPTION_PENDING,
  SUBSCRIPTION_REJECTED,
  TRUSTED_ROUTES_POOLING_FULFILLED,
  TRUSTED_ROUTES_POOLING_RUNNING,
  TRUSTED_ROUTES_POOLING_TIMEOUT,
  TrustedRoutesStatus,
} from 'views/trusted_routes/types/status'

export const allLocodes = (trustedRoute: TrustedRoute): string[] => [
  trustedRoute.legs[0].departure.port.locode,
  ...trustedRoute.legs.map((leg) => leg.arrival.port.locode),
]

export const allVesselIMOS = (trustedRoute: TrustedRoute): number[] =>
  compactArray(trustedRoute.legs.map((leg) => leg.vesselImo))

export const isProcessing = (status: TrustedRoutesStatus): boolean =>
  status === SUBSCRIPTION_PENDING ||
  status === SUBSCRIPTION_FULFILLED ||
  status === TRUSTED_ROUTES_POOLING_RUNNING

export const isLoopCompleted = (status: TrustedRoutesStatus): boolean =>
  status === TRUSTED_ROUTES_POOLING_FULFILLED || status === TRUSTED_ROUTES_POOLING_TIMEOUT

export const isLoopCompletedSuccessfully = (status: TrustedRoutesStatus): boolean =>
  status === TRUSTED_ROUTES_POOLING_FULFILLED

export const haveLoopFailed = (status: TrustedRoutesStatus): boolean =>
  status === SUBSCRIPTION_REJECTED || status === TRUSTED_ROUTES_POOLING_TIMEOUT
