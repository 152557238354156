import { isPresent } from 'services/helpers/values'
import DateHelper from 'services/helpers/date_helper'
import { TDateRange } from 'services/hooks/use_filter'

const getQueryParamsFormatters = () => {
  const formatDateRange = (dateRange: TDateRange) => {
    if (!isPresent(dateRange.start) || !isPresent(dateRange.end))
      return { start: dateRange.start, end: dateRange.end }

    const startDate = new DateHelper(dateRange.start)
    const endDate = new DateHelper(dateRange.end)

    return {
      start: startDate.hasHours() ? dateRange.start : startDate.toDateOnly(),
      end: endDate.hasHours() ? dateRange.end : endDate.toDateOnly(),
    }
  }

  return {
    formatDateRange,
  }
}

export default getQueryParamsFormatters
