import { createContext } from 'react'

import { SHIPMENT_VIEW_LARGE } from 'constants/shipments'

import { ShipmentViewType } from 'features/shipments/types/shipment'

interface ShipmentViewContextValues {
  view: ShipmentViewType
  setView: (param: ShipmentViewType) => void
}

const ShipmentViewContext = createContext<ShipmentViewContextValues>({
  view: SHIPMENT_VIEW_LARGE,
  setView: () => {},
})

export default ShipmentViewContext
