import React, { useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'

import ShipmentsTrackingMap from 'features/shipments/components/shipments_tracking_map'
import Page from 'components/page'
import { SHIPMENT_VIEW_CONDENSED } from 'constants/shipments'
import {
  StyledPageShipmentListSelect,
  StyledShipmentsListHeader,
  StyledShipmentsListTitle,
  StyledShipmentsListWrapper,
} from 'features/shipments/components/shipments_tracking_map/style'
import ShipmentViewContext from 'features/shipments/contexts/shipment_view_context'
import Select from 'components/select'
import CustomKpis from 'features/custom_kpis/components/custom_kpi_list'
import useCurrentUser from 'views/iam/hooks/use_current_user'
import { isPresent } from 'services/helpers/values'
import { fetchShipments, FetchShipmentsParams } from 'features/shipments/store/shipment_slice'
import {
  FILTER_BY_FAVORITE,
  SORT_BY_CREATED_AT,
  SORT_BY_LAST_ETA_CHANGE,
} from 'features/shipments/constants/shipment'
import { addNotification } from 'views/notifications/slice'
import usePagination from 'services/api/hooks/use_pagination'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import useTracker from 'services/analytics/hooks/use_tracker'
import { ShipmentViewType } from 'features/shipments/types/shipment'
import { DASHBOARD_SOURCE } from 'constants/shipment_source'
import { StyledShipments, StyledShipmentsListContainer } from 'pages/dashboard/style'
import ShipmentsList from 'features/shipments/components/shipments_list'

const PER = 30

const DashboardPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { track } = useTracker()
  const user = useCurrentUser()

  const [view, setView] = useState<ShipmentViewType>(SHIPMENT_VIEW_CONDENSED)
  const { page } = usePagination()
  const contextValue = useMemo(() => ({ view, setView }), [view, setView])

  const sortByOptions: {
    value: typeof SORT_BY_CREATED_AT | typeof SORT_BY_LAST_ETA_CHANGE
    label: string
    type?: typeof FILTER_BY_FAVORITE
  }[] = useMemo(
    () => [
      {
        value: SORT_BY_CREATED_AT,
        label: t('shipments.show.lastCreated'),
      },
      {
        value: SORT_BY_LAST_ETA_CHANGE,
        label: t('shipments.show.lastEtaChange'),
      },
      {
        value: SORT_BY_CREATED_AT,
        type: FILTER_BY_FAVORITE,
        label: t('shipments.show.favorite'),
      },
    ],

    // param t from useTranslation is causing unnecessary triggers of this hook
    // so we removed it from dependency array
    // eslint-disable-next-line
    []
  )

  const [sortByIndex, setSortByIndex] = useState(0)
  const currentSortBy = sortByOptions[sortByIndex]

  useEffect(() => {
    const params: FetchShipmentsParams = {
      sortBy: currentSortBy.value,
      per: PER,
      source: DASHBOARD_SOURCE,
      page,
      direction: 'desc',
    }
    if (isPresent(currentSortBy.type) && currentSortBy.type === FILTER_BY_FAVORITE) {
      params.favoriteUserId = user.id
    }
    dispatch(fetchShipments(params))
      .unwrap()
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      })

    // eslint-disable-next-line
  }, [currentSortBy])

  return (
    <Page plain>
      <ShipmentsTrackingMap />
      <StyledShipments>
        <StyledShipmentsListContainer>
          <ShipmentViewContext.Provider value={contextValue}>
            <StyledShipmentsListHeader>
              <StyledShipmentsListTitle>{t('dashboard.shipments.title')}</StyledShipmentsListTitle>

              <StyledPageShipmentListSelect
                as={Select}
                noPadding
                label={`${t('actions.show')} :`}
                variant='text-control'
                options={sortByOptions}
                onChange={({ value }) => {
                  const index = sortByOptions.findIndex(
                    (s) => s.value === value.value && s.type === value.type
                  )
                  setSortByIndex(index)
                  const option = sortByOptions[index]
                  track('Dashboard / List', {
                    detail: 'show',
                    name: option.type || option.value,
                  })
                }}
                onMenuOpen={() => {
                  track('Dashboard / List', { detail: 'show' })
                }}
                isClearable={false}
                isSearchable={false}
                value={currentSortBy}
              />
            </StyledShipmentsListHeader>
            <StyledShipmentsListWrapper>
              <ShipmentsList source={DASHBOARD_SOURCE} />
            </StyledShipmentsListWrapper>
          </ShipmentViewContext.Provider>
        </StyledShipmentsListContainer>
        <CustomKpis />
      </StyledShipments>
    </Page>
  )
}

export default DashboardPage
