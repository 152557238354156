import React, { FC, useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Coordinates } from 'types/common'

import ShipmentViewContext from 'features/shipments/contexts/shipment_view_context'

import {
  StyledModalShipments,
  StyledModalShipmentsPrevNext,
  ModalShipmentsHeadingIcon,
  ModalShipmentsHeadingTitle,
  ModalShipmentsNavPrev,
  ModalShipmentsNavNext,
  ModalShipmentsNavArrow,
} from 'features/shipments/components/modal_shipments/style'
import Modal from 'components/modal'
import Icon from 'components/icon'
import ShipmentTransit from 'features/shipments/components/modal_shipments/shipment_transit'
import Placeholder from 'components/placeholder'
import ModalShipmentSkeleton from 'features/shipments/components/modal_shipments/skeleton'

import { isPresent } from 'services/helpers/values'
import useShallowSelector from 'services/hooks/use_shallow_selector'
import useTracker from 'services/analytics/hooks/use_tracker'

import {
  STEP_TYPE_DELIVERY,
  STEP_TYPE_PICKUP,
  STEP_TYPE_POD,
  STEP_TYPE_POL,
  SHIPMENT_VIEW_MODAL,
} from 'constants/shipments'
import { selectDashboardMapShipmentsByVehicleId } from 'features/shipments/store/vehicle_slice'
import ShipmentListItem from 'features/shipments/components/shipment_list_item'
import { ShipmentViewType } from 'features/shipments/types/shipment'

const nextStepMapping = {
  [STEP_TYPE_PICKUP]: STEP_TYPE_POL,
  [STEP_TYPE_POL]: STEP_TYPE_POD,
  [STEP_TYPE_POD]: STEP_TYPE_DELIVERY,
  [STEP_TYPE_DELIVERY]: null,
} as const

interface ModalShipmentsProps {
  coordinates: Coordinates
  vehicleId: string
}

const ModalShipments: FC<ModalShipmentsProps> = ({ coordinates, vehicleId }) => {
  const shipments = useShallowSelector(selectDashboardMapShipmentsByVehicleId({ id: vehicleId }))
  const [view, setView] = useState<ShipmentViewType>(SHIPMENT_VIEW_MODAL)
  const { t } = useTranslation()
  const { track } = useTracker()
  const onPrev = () => {
    let newIndex = index - 1
    if (newIndex < 0) newIndex = shipments.length - 1
    setIndex(newIndex)
    track('Dashboard / Map', { detail: 'previous' })
  }
  const onNext = () => {
    let newIndex = index + 1
    if (newIndex === shipments.length) newIndex = 0
    setIndex(newIndex)
    track('Dashboard / Map', { detail: 'next' })
  }
  const numShipments = shipments.length
  const isMultipleShipments = numShipments > 1
  const [index, setIndex] = useState(0)
  const currentShipment = useMemo(() => shipments[index], [shipments, index])

  useEffect(() => {
    setIndex(0)
  }, [shipments])

  const { transportType, stepType } = currentShipment.summary.current

  const nextStepType = nextStepMapping[stepType]
  const destination = nextStepType
    ? currentShipment.summary[nextStepType]?.location?.name
    : undefined

  return (
    <StyledModalShipments as={Modal} modalName='dashboardMap' $coordinates={coordinates}>
      {isMultipleShipments && <StyledModalShipmentsPrevNext />}
      <Modal.Header>
        <ModalShipmentsHeadingIcon as={Icon} name='shipment' />
        <ModalShipmentsHeadingTitle>
          {isMultipleShipments ? `${index + 1}/${numShipments}` : numShipments}{' '}
          {t('shipments.shipment', { count: numShipments })}
        </ModalShipmentsHeadingTitle>
      </Modal.Header>
      <Modal.Content>
        <Placeholder
          ready={isPresent(currentShipment)}
          customPlaceholder={<ModalShipmentSkeleton />}
        >
          <ShipmentViewContext.Provider value={{ view, setView }}>
            <ShipmentListItem shipment={currentShipment} />
            <ShipmentTransit transportType={transportType} destination={destination} />
          </ShipmentViewContext.Provider>
        </Placeholder>
      </Modal.Content>
      {isMultipleShipments && (
        <Modal.Footer>
          <ModalShipmentsNavPrev onClick={onPrev}>
            <ModalShipmentsNavArrow as={Icon} name='arrow_top' />
            {t('actions.prev')}
          </ModalShipmentsNavPrev>
          <ModalShipmentsNavNext onClick={onNext}>
            {t('actions.next')}
            <ModalShipmentsNavArrow as={Icon} name='arrow_bottom' />
          </ModalShipmentsNavNext>
        </Modal.Footer>
      )}
    </StyledModalShipments>
  )
}

export default ModalShipments
