import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

import Form from 'components/form'

import { BookingFieldNames } from 'views/booking/slices/types'
import {
  StyledAddMerchandiseItemWrapper,
  StyledHazardousGoodsItemInput,
  StyledHazardousGoodsWrapper,
  StyledRemoveMerchandiseItemWrapper,
} from 'views/booking/components/form/style'
import Button from 'components/button'
import Grid from 'components/grid'
import Input from 'components/input'
import { BOOKING_FIELD_NAMES, BOOKING_FIELD_VALIDATIONS } from 'constants/bookings'
import {
  TEST_ID_BOOKING_FORM_MINIMAL_TEMPERATURE,
  getTestIdForMerchandiseProduct,
  getTestIdForMerchandiseProductCurrencyCode,
  getTestIdForMerchandiseProductMaximalTemperature,
} from 'tests/e2e/test_ids'
import Textarea from 'components/textarea'
import Select from 'components/select'
import useStaticLocales from 'views/locales/hooks/use_static_locales'
import { BookingFormInput } from 'views/booking/components/form/types'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import { WITH_BOOKING_OLD_WORKFLOW } from 'constants/organization_features'
import { stringToFloat } from 'services/helpers/values'

interface MerchandisePackageTotalDetailsProps {
  isFieldDisabled: (fieldName: BookingFieldNames) => boolean
}

const MerchandisePackageTotalDetails: FC<MerchandisePackageTotalDetailsProps> = ({
  isFieldDisabled,
}) => {
  const { features } = useOrganizationCan()
  const { s } = useStaticLocales()
  const { t } = useTranslation()

  const withMultipleDetails = !features(WITH_BOOKING_OLD_WORKFLOW)

  const {
    control,
    formState: { errors },
  } = useFormContext<BookingFormInput>()

  const {
    fields: merchandises,
    remove: removeMerchandise,
    append: appendMerchandise,
  } = useFieldArray({
    control,
    name: 'merchandise.content.packageTotal.merchandiseDetails',
  })

  const fromStaticToSelectOptions = (path: string) =>
    Object.entries(s(path)).map(([key, value]) => ({ value: key, label: value }))

  return (
    <>
      {merchandises.map((merchandiseDetails, index: number) => (
        <Form.Insert
          key={merchandiseDetails.id}
          subtitle={`${t('bookings.merchandise.merchandiseDetails')} ${
            withMultipleDetails ? `#${index + 1}` : ''
          }`.trim()}
        >
          {index !== 0 && (
            <StyledRemoveMerchandiseItemWrapper>
              <Button
                variant='icon'
                icon='trash'
                disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                onClick={() => removeMerchandise(index)}
              />
            </StyledRemoveMerchandiseItemWrapper>
          )}
          <Grid columns={3}>
            <Grid.Row testId={getTestIdForMerchandiseProduct(index)}>
              <Grid.Column>
                <Controller
                  name={`merchandise.content.packageTotal.merchandiseDetails.${index}.productDescription`}
                  control={control}
                  render={({ field }) => (
                    <Textarea
                      label={t('bookings.merchandise.productDescription')}
                      placeholder={t('bookings.merchandise.yourDescription')}
                      name={`merchandiseDescription-${index}`}
                      rows={4}
                      compact
                      value={field.value}
                      onChange={({ target: { value } }) => field.onChange(value)}
                      disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                    />
                  )}
                />
              </Grid.Column>
              <Grid.Column>
                <Controller
                  name={`merchandise.content.packageTotal.merchandiseDetails.${index}.commercialValue.amount`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      type='number'
                      step='0.01'
                      min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.AMOUNT}
                      label={t('bookings.merchandise.commercialValue')}
                      placeholder={t('bookings.merchandise.amount')}
                      name={`merchandiseAmount-${index}`}
                      value={field.value}
                      error={
                        errors?.merchandise?.content?.packageTotal?.merchandiseDetails?.[index]
                          ?.commercialValue?.amount?.message
                      }
                      onChange={({ target: { value } }) => field.onChange(stringToFloat(value))}
                      disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                    />
                  )}
                />
                <Controller
                  name={`merchandise.content.packageTotal.merchandiseDetails.${index}.commercialValue.currencyCode`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder={t('bookings.merchandise.currency')}
                      options={fromStaticToSelectOptions('currencies')}
                      name={`merchandiseCurrencyCode-${index}`}
                      value={field.value}
                      error={
                        errors?.merchandise?.content?.packageTotal?.merchandiseDetails?.[index]
                          ?.commercialValue?.currencyCode?.message
                      }
                      isClearable
                      onChange={({ value }) => field.onChange(value)}
                      testId={getTestIdForMerchandiseProductCurrencyCode(index)}
                      isDisabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                    />
                  )}
                />
              </Grid.Column>
              <Grid.Column>
                <Controller
                  name={`merchandise.content.packageTotal.merchandiseDetails.${index}.controlledTemperatures.min`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      label={t('bookings.merchandise.controlledTemperatures.title')}
                      placeholder={t('bookings.merchandise.controlledTemperatures.minimal')}
                      type='number'
                      step='0.01'
                      testId={TEST_ID_BOOKING_FORM_MINIMAL_TEMPERATURE}
                      name={`merchandiseTemperatureMinimal-${index}`}
                      value={field.value}
                      error={
                        errors.merchandise?.content?.packageTotal?.merchandiseDetails?.[index]
                          ?.controlledTemperatures?.min?.message
                      }
                      disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                      onChange={({ target: { value } }) => field.onChange(stringToFloat(value))}
                    />
                  )}
                />
                <Controller
                  name={`merchandise.content.packageTotal.merchandiseDetails.${index}.controlledTemperatures.max`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder={t('bookings.merchandise.controlledTemperatures.maximal')}
                      type='number'
                      step='0.01'
                      testId={getTestIdForMerchandiseProductMaximalTemperature(index)}
                      name={`merchandiseTemperatureMaximal-${index}`}
                      value={field.value}
                      error={
                        errors.merchandise?.content?.packageTotal?.merchandiseDetails?.[index]
                          ?.controlledTemperatures?.max?.message
                      }
                      disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                      onChange={({ target: { value } }) => field.onChange(stringToFloat(value))}
                    />
                  )}
                />
              </Grid.Column>
              <Grid.Column>
                <Controller
                  name={`merchandise.content.packageTotal.merchandiseDetails.${index}.hazardousGoods.hazardousClass`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      label={t('bookings.merchandise.product.hazardousGoods.hazardousClass')}
                      placeholder={t('actions.select')}
                      options={fromStaticToSelectOptions('hazardousGoods')}
                      name={`merchandiseHazardousClass-${index}`}
                      value={field.value}
                      onChange={({ value }) => field.onChange(value)}
                      error={
                        errors.merchandise?.content?.packageTotal?.merchandiseDetails?.[index]
                          ?.hazardousGoods?.hazardousClass?.message
                      }
                      isDisabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                      isClearable
                    />
                  )}
                />
              </Grid.Column>
              <Grid.Column>
                <Controller
                  name={`merchandise.content.packageTotal.merchandiseDetails.${index}.hazardousGoods.packingGroup`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      label={t('bookings.merchandise.product.hazardousGoods.packingGroup')}
                      placeholder={t('actions.select')}
                      options={fromStaticToSelectOptions('packingGroups')}
                      name={`merchandiseHazardousPackingGroup-${index}`}
                      value={field.value}
                      error={
                        errors.merchandise?.content?.packageTotal?.merchandiseDetails?.[index]
                          ?.hazardousGoods?.packingGroup?.message
                      }
                      onChange={({ value }) => field.onChange(value)}
                      isDisabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                      isClearable
                    />
                  )}
                />
              </Grid.Column>
              <Grid.Column>
                <StyledHazardousGoodsWrapper>
                  <Controller
                    name={`merchandise.content.packageTotal.merchandiseDetails.${index}.hazardousGoods.unNumber`}
                    control={control}
                    render={({ field }) => (
                      <StyledHazardousGoodsItemInput
                        label={t('bookings.merchandise.product.hazardousGoods.unNumber')}
                        placeholder={t('bookings.merchandise.product.hazardousGoods.unNumber')}
                        name={`merchandiseHazardousUnNumber-${index}`}
                        value={field.value}
                        error={
                          errors.merchandise?.content?.packageTotal?.merchandiseDetails?.[index]
                            ?.hazardousGoods?.unNumber?.message
                        }
                        disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                        onChange={({ target: { value } }) => field.onChange(value)}
                      />
                    )}
                  />
                  <Controller
                    name={`merchandise.content.packageTotal.merchandiseDetails.${index}.hazardousGoods.weight`}
                    control={control}
                    render={({ field }) => (
                      <StyledHazardousGoodsItemInput
                        label={t('bookings.merchandise.product.hazardousGoods.weight')}
                        placeholder='kg'
                        type='number'
                        step='0.01'
                        min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT}
                        name={`merchandiseHazardousWeight-${index}`}
                        value={field.value}
                        disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                        error={
                          errors.merchandise?.content?.packageTotal?.merchandiseDetails?.[index]
                            ?.hazardousGoods?.weight?.message
                        }
                        onChange={({ target: { value } }) => field.onChange(stringToFloat(value))}
                      />
                    )}
                  />
                </StyledHazardousGoodsWrapper>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form.Insert>
      ))}
      {withMultipleDetails && (
        <StyledAddMerchandiseItemWrapper>
          <Button
            disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
            onClick={() => appendMerchandise({})}
            icon='plus_outline'
            variant='dashed'
            text={`${t('actions.addNew', { context: 'plural' })} ${t(
              'bookings.merchandise.merchandiseDetails'
            ).toLowerCase()}`}
          />
        </StyledAddMerchandiseItemWrapper>
      )}
    </>
  )
}

export default MerchandisePackageTotalDetails
