import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { Controller, useFormContext } from 'react-hook-form'

import Form from 'components/form'
import Grid from 'components/grid'

import { BookingFieldNames } from 'views/booking/slices/types'

import Input from 'components/input'

import { BOOKING_FIELD_NAMES, BOOKING_FIELD_VALIDATIONS } from 'constants/bookings'

import MerchandisePackageTotalDetails from 'views/booking/components/form/merchandise/merchandise_package_total_details'
import { BookingFormInput } from 'views/booking/components/form/types'
import { stringToFloat, stringToInt } from 'services/helpers/values'

interface MerchandisePackageTotalProps {
  isFieldDisabled: (fieldName: BookingFieldNames) => boolean
}

const MerchandisePackageTotal: FC<MerchandisePackageTotalProps> = ({
  isFieldDisabled,
}: MerchandisePackageTotalProps) => {
  const { t } = useTranslation()

  const {
    control,
    formState: { errors },
  } = useFormContext<BookingFormInput>()

  return (
    <Form.Group>
      <Form.Insert subtitle={t('bookings.merchandise.packageRequest')}>
        <Grid columns={3}>
          <Grid.Row compact>
            <Grid.Column>
              <Controller
                name='merchandise.content.packageTotal.volume'
                control={control}
                render={({ field }) => (
                  <Input
                    type='number'
                    label={t('bookings.merchandise.volume')}
                    placeholder='cbm'
                    step='0.01'
                    required
                    name={field.name}
                    min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.VOLUME}
                    value={field.value}
                    error={errors.merchandise?.content?.packageTotal?.volume?.message}
                    onChange={({ target: { value } }) => field.onChange(stringToFloat(value))}
                    disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_SIGNIFICANT_CONTENT)}
                  />
                )}
              />
            </Grid.Column>
            <Grid.Column>
              <Controller
                name='merchandise.content.packageTotal.weight'
                control={control}
                render={({ field }) => (
                  <Input
                    type='number'
                    label={t('bookings.merchandise.weight')}
                    placeholder='kg'
                    step='0.01'
                    required
                    name={field.name}
                    min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT}
                    value={field.value}
                    error={errors.merchandise?.content?.packageTotal?.weight?.message}
                    onChange={({ target: { value } }) => field.onChange(stringToFloat(value))}
                    disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_SIGNIFICANT_CONTENT)}
                  />
                )}
              />
            </Grid.Column>
            <Grid.Column>
              <Controller
                name='merchandise.content.packageTotal.packageNumber'
                control={control}
                render={({ field }) => (
                  <Input
                    label={t('bookings.merchandise.numberOfPackages')}
                    placeholder={t('bookings.merchandise.numberOfPackages')}
                    type='number'
                    step='1'
                    name={field.name}
                    min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.NUMBER_OF_PACKAGES}
                    value={field.value}
                    error={errors.merchandise?.content?.packageTotal?.packageNumber?.message}
                    onChange={({ target: { value } }) => field.onChange(stringToInt(value))}
                    disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                  />
                )}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form.Insert>
      <MerchandisePackageTotalDetails isFieldDisabled={isFieldDisabled} />
    </Form.Group>
  )
}

export default MerchandisePackageTotal
