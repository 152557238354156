import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Modal from 'components/modal'
import useModal from 'components/modal/hooks/use_modal'
import InputDatepicker from 'components/input_datepicker'
import Button from 'components/button'

import useExportForm from 'features/shipments/components/export_modal/hooks/use_export_form'
import { exportAvailable, exportShipments } from 'features/shipments/store/shipment_slice'
import { addNotification } from 'views/notifications/slice'

import useAppDispatch from 'services/hooks/use_app_dispatch'
import useTracker from 'services/analytics/hooks/use_tracker'
import { isPresent } from 'services/helpers/values'
import {
  TEST_ID_SHIPMENT_EXPORT_FROM_DATE,
  TEST_ID_SHIPMENT_EXPORT_TO_DATE,
} from 'tests/e2e/test_ids'
import DateHelper from 'services/helpers/date_helper'
import NotifMessage from 'components/notif_message'
import {
  StyledModalDatepickers,
  StyledModalDescription,
} from 'features/shipments/components/export_modal/style'

const ExportModal: FC = () => {
  const modalName = 'shipmentsExport'
  const [isExportAvailable, setIsExportAvailable] = useState<boolean>(true)
  const { setOpen } = useModal(modalName)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { opened } = useModal(modalName)

  useEffect(() => {
    if (opened)
      dispatch(exportAvailable())
        .unwrap()
        .then((r) => setIsExportAvailable(r.exportAvailable))
        .catch(() => {
          dispatch(
            addNotification({
              type: 'alert',
              title: t('errors.notification.title'),
              text: t('errors.notification.content'),
            })
          )
        })
  }, [opened, dispatch, t, isExportAvailable])

  const { track } = useTracker()

  const { fromFilter, toFilter, isValid } = useExportForm()

  const addDays = useCallback((date: Date, days: number) => {
    const newDate = new Date(date)
    newDate.setDate(newDate.getDate() + days)
    return newDate
  }, [])

  const isStartDateValid = useCallback(
    (date: Date) => {
      if (!isPresent(toFilter.value)) return true
      const start = addDays(new Date(toFilter.value), -365)
      const end = toFilter.value
      return new DateHelper(date).isBetweenDates(start, end)
    },
    [addDays, toFilter.value]
  )

  const isEndDateValid = useCallback(
    (date: Date) => {
      if (!isPresent(fromFilter.value)) return true
      const start = fromFilter.value
      const end = addDays(new Date(fromFilter.value), 365)
      return new DateHelper(date).isBetweenDates(start, end)
    },
    [addDays, fromFilter.value]
  )

  return (
    <Modal position='center' modalName={modalName}>
      <Modal.Header>{t('shipments.export.title')}</Modal.Header>
      {!isExportAvailable && (
        <NotifMessage
          type='alert'
          title={t('shipments.exportUnavailable.title')}
          text={t('shipments.exportUnavailable.text')}
        />
      )}
      <Modal.Content>
        <StyledModalDescription>{t('shipments.export.description')}</StyledModalDescription>
        <StyledModalDatepickers>
          <InputDatepicker
            label='From'
            withPortal
            name={fromFilter.name}
            onChange={fromFilter.onChange}
            startDate={fromFilter.value}
            filterDate={isStartDateValid}
            disabled={!isExportAvailable}
            testId={TEST_ID_SHIPMENT_EXPORT_FROM_DATE}
          />
          <InputDatepicker
            label='To'
            withPortal
            name={toFilter.name}
            onChange={toFilter.onChange}
            startDate={toFilter.value}
            filterDate={isEndDateValid}
            disabled={!isExportAvailable}
            testId={TEST_ID_SHIPMENT_EXPORT_TO_DATE}
          />
        </StyledModalDatepickers>
      </Modal.Content>
      <Modal.Footer>
        <Button
          variant='highlight'
          text={t('shipments.export.request')}
          disabled={!isValid || !isExportAvailable}
          onClick={() => {
            dispatch(exportShipments({ startDate: fromFilter.value!, endDate: toFilter.value! }))
              .unwrap()
              .then(() => {
                track('Shipment list / export', { status: 'success' })
                dispatch(
                  addNotification({
                    type: 'success',
                    title: t('shipments.export.request'),
                    text: t('shipments.export.success'),
                  })
                )
                setOpen(false)
              })
              .catch(() => {
                track('Shipment list / export', { status: 'error' })
                dispatch(
                  addNotification({
                    type: 'alert',
                    title: t('shipments.export.request'),
                    text: t('shipments.export.fail'),
                  })
                )
              })
          }}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default ExportModal
