import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  filterObject,
  isAnyArray,
  isPresent,
  readonlyArrayIncludes,
  toCamelCase,
} from 'services/helpers/values'

import useFilters from 'features/shipments/hooks/use_filters'
import useActiveFilters from 'features/shipments/hooks/use_active_filters'
import useCategories from 'features/shipments/hooks/use_categories'

import {
  TRANSPORT_TYPE_ICONS,
  TRANSPORT_TYPE_SEA,
  TRANSPORT_TYPE_RAIL,
  TRANSPORT_TYPE_AIR,
  TRANSPORT_TYPE_ROAD,
  TRANSPORT_TYPE_PARCEL,
  STEP_TYPE_POD,
  STEP_TYPE_PICKUP,
  STEP_TYPE_POL,
  STEP_TYPE_DELIVERY,
  VESSEL_ICON,
  TransportType,
} from 'constants/shipments'
import DateHelper from 'services/helpers/date_helper'
import {
  ARRIVAL_FORECAST_DELAYED,
  ARRIVAL_FORECAST_EARLY,
  ARRIVAL_FORECAST_ON_TIME,
  ArrivalForecast,
  CustomKpi,
  SHIPMENT_TIME_RANGES,
  TIME_RANGE_ALL_ACTIVE,
  TIME_RANGE_THIS_MONTH,
  TIME_RANGE_THIS_WEEK,
  TIME_RANGE_TODAY,
  TYPE_SHIPMENT,
} from 'features/custom_kpis/types/types'

const useKpi = () => {
  const { t } = useTranslation()
  const filters = useFilters()
  const [shouldGoToShipments, setShouldGoToShipments] = useState(false)
  const { arrayOfCategories } = useCategories({ filters })
  const { save: saveActiveFilters, goToShipments: goToShipmentsAndApplyFilters } = useActiveFilters(
    {
      categories: arrayOfCategories,
    }
  )

  useEffect(() => {
    if (shouldGoToShipments) {
      saveActiveFilters()
      goToShipmentsAndApplyFilters()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldGoToShipments])

  const translateTotal = (total: number): string => {
    if (total > 1_000_000) {
      return `${(total / 1_000_000).toFixed(1)}M`
    }
    if (total >= 10_000) {
      return `${(total / 1_000).toFixed(1)}K`
    }
    return total.toString()
  }
  const translate = (kpi: CustomKpi) => {
    const {
      id,
      total,
      kpiType,
      addressName,
      transportationDate,
      transportModes,
      arrivalForecasts,
    } = kpi

    const transportMode = {
      sea: transportModes.includes(TRANSPORT_TYPE_SEA) || false,
      rail: transportModes.includes(TRANSPORT_TYPE_RAIL) || false,
      air: transportModes.includes(TRANSPORT_TYPE_AIR) || false,
      road: transportModes.includes(TRANSPORT_TYPE_ROAD) || false,
      parcel: transportModes.includes(TRANSPORT_TYPE_PARCEL) || false,
    }
    const usedModes = Object.keys(filterObject(transportMode))
    const icon = TRANSPORT_TYPE_ICONS[usedModes[0] as TransportType] || VESSEL_ICON
    let transport = usedModes.map((mode) => t(`customKpis.form.transportModes.${mode}`)).join(', ')
    if (usedModes.length === 0 || usedModes.length === Object.keys(transportMode).length)
      transport = t('customKpis.all')
    const date = isPresent(transportationDate)
      ? t(`static.timeRanges.${toCamelCase(transportationDate)}`)
      : t('customKpis.all')
    const place = addressName || t('customKpis.all')
    const isShipment = kpiType === TYPE_SHIPMENT
    const formattedTotal = translateTotal(total)
    const arrivalForecast =
      arrivalForecasts
        .map((f) => t(`customKpis.form.arrivalForecast.${toCamelCase(f)}`))
        .join(', ') || t('customKpis.all')

    return {
      id,
      total: formattedTotal,
      kpiType,
      icon,
      transport,
      date,
      place,
      isShipment,
      arrivalForecast,
    }
  }

  const goToShipments = useCallback(
    ({ kpi }: { kpi: CustomKpi }) => {
      const { transportModes, stepType, addressName, arrivalForecasts, transportationDate } = kpi

      const transportModeFilters = {
        sea: filters.transportModeSeaFilter,
        road: filters.transportModeRoadFilter,
        air: filters.transportModeAirFilter,
        rail: filters.transportModeRailFilter,
        parcel: filters.transportModeParcelFilter,
      }

      transportModes.forEach((mode) => {
        transportModeFilters[mode].setValue(true)
      })

      if (isPresent(addressName)) {
        const routingFilter = {
          [STEP_TYPE_PICKUP]: filters.routingPickupFilter,
          [STEP_TYPE_POL]: filters.routingPolFilter,
          [STEP_TYPE_POD]: filters.routingPodFilter,
          [STEP_TYPE_DELIVERY]: filters.routingDeliveryFilter,
        }[stepType]
        routingFilter.setValue([{ value: addressName, label: addressName, type: 'location' }])
      }
      if (isAnyArray(arrivalForecasts)) {
        const arrivalForecastFilters = Object.entries({
          [ARRIVAL_FORECAST_EARLY]: filters.arrivalForecastEarlyFilter,
          [ARRIVAL_FORECAST_ON_TIME]: filters.arrivalForecastOnTimeFilter,
          [ARRIVAL_FORECAST_DELAYED]: filters.arrivalForecastDelayedFilter,
        })
          .filter(([key]) => arrivalForecasts.includes(key as ArrivalForecast))
          .map(([, filter]) => filter)
        arrivalForecastFilters.forEach((filter) => filter.setValue(true))
      }
      if (isPresent(transportationDate)) {
        const date = new DateHelper()
        // At this stage, kpi are only shipments. This hook will have to be redone
        const dateRange: string[] | null | undefined = readonlyArrayIncludes(
          SHIPMENT_TIME_RANGES,
          transportationDate
        )
          ? {
              [TIME_RANGE_ALL_ACTIVE]: null,
              [TIME_RANGE_TODAY]: date.rangeToday(),
              [TIME_RANGE_THIS_WEEK]: date.rangeThisWeek(),
              [TIME_RANGE_THIS_MONTH]: date.rangeThisMonth(),
            }[transportationDate]
          : undefined
        const transportationDateFilter = {
          [STEP_TYPE_PICKUP]: filters.transportationDatePickupFilter,
          [STEP_TYPE_POL]: filters.transportationDatePolFilter,
          [STEP_TYPE_POD]: filters.transportationDatePodFilter,
          [STEP_TYPE_DELIVERY]: filters.transportationDateDeliveryFilter,
        }[stepType]

        if (isPresent(dateRange)) {
          const transportationDateValue = {
            start: dateRange[0],
            end: dateRange[1],
          }
          transportationDateFilter.setValue(transportationDateValue)
        }
        if (transportationDate === TIME_RANGE_ALL_ACTIVE) {
          filters.statusActiveFilter.setValue(true)
        }
      }
      setShouldGoToShipments(true)
    },
    [filters]
  )

  return { translate, goToShipments }
}

export default useKpi
