import React, { FC, useContext } from 'react'

import { isPresent } from 'services/helpers/values'

import OngoingAlertIcon from 'components/ongoing-alert/ongoing-alert-icon'
import ShipmentContext from 'features/shipments/contexts/shipment_context'

const OngoingAlert: FC = () => {
  const { shipment } = useContext(ShipmentContext)
  const { ongoingAlert } = shipment

  return isPresent(ongoingAlert) ? <OngoingAlertIcon ongoingAlert={ongoingAlert} /> : null
}

export default OngoingAlert
