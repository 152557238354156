import { createSlice, createAsyncThunk, createEntityAdapter, Comparer } from '@reduxjs/toolkit'

import { InternalClient } from 'services/api/clients'
import onError from 'services/api/error'
import useThunkTeamsParam from 'services/api/hooks/use_thunk_teams_param'
import DateHelper from 'services/helpers/date_helper'

import { STATUS_PENDING, STATUS_FULFILLED, STATUS_REJECTED } from 'constants/api'

import {
  BookingTemplate,
  CreateBookingTemplateData,
  EditBookingTemplateData,
} from 'views/booking/components/templates/types'

import type { RootState } from 'services/store/store'

export const fetchBookingTemplates = createAsyncThunk(
  'bookings/fetchBookingTemplates',
  async (_, thunkAPI) =>
    InternalClient.get('/bookings/templates')
      .then((r) => r.data)
      .catch(onError(thunkAPI))
)

export const createBookingTemplate = createAsyncThunk(
  'bookings/createBookingTemplate',
  async (data: CreateBookingTemplateData, thunkAPI) =>
    InternalClient.post('/bookings/templates', {
      ...data,
      ...useThunkTeamsParam(thunkAPI, { legacyNaming: true }),
    })
      .then((r) => r.data)
      .catch(onError(thunkAPI))
)

export const updateBookingTemplate = createAsyncThunk(
  'bookings/updateBookingTemplate',
  async ({ id, changes }: { id: number; changes: EditBookingTemplateData }, thunkAPI) =>
    InternalClient.patch(`/bookings/templates/${id}`, {
      ...changes,
      ...useThunkTeamsParam(thunkAPI, { legacyNaming: true }),
    })
      .then((r) => r.data)
      .catch(onError(thunkAPI))
)

export const deleteBookingTemplate = createAsyncThunk(
  'bookings/deleteBookingTemplate',
  async (data: BookingTemplate, thunkAPI) =>
    InternalClient.delete(`/bookings/templates/${data.id}`)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
)

const sortByLastUpdatedDesc: Comparer<BookingTemplate> = (a, b) =>
  new DateHelper(a.createdAt).isBefore(b.createdAt) ? 1 : -1
const bookingTemplatesAdapter = createEntityAdapter({
  selectId: ({ id }: BookingTemplate) => id,
  sortComparer: sortByLastUpdatedDesc,
})

const initialState = bookingTemplatesAdapter.getInitialState({
  status: STATUS_PENDING,
})

const bookingTemplatesSlice = createSlice({
  name: 'bookingTemplates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBookingTemplates.fulfilled, (state, action: any) => {
      bookingTemplatesAdapter.setAll(state, action.payload)
      state.status = STATUS_FULFILLED
    })
    builder.addCase(fetchBookingTemplates.pending, (state) => {
      state.status = STATUS_PENDING
    })
    builder.addCase(fetchBookingTemplates.rejected, (state) => {
      state.status = STATUS_REJECTED
    })
    builder.addCase(createBookingTemplate.fulfilled, (state, action: any) => {
      bookingTemplatesAdapter.upsertOne(state, action.payload)
    })
    builder.addCase(updateBookingTemplate.fulfilled, (state, action: any) => {
      bookingTemplatesAdapter.upsertOne(state, action.payload)
    })
    builder.addCase(deleteBookingTemplate.fulfilled, (state, action: any) => {
      bookingTemplatesAdapter.removeOne(state, action.meta.arg.id)
    })
  },
})

export const { selectAll: selectBookingTemplates } =
  bookingTemplatesAdapter.getSelectors<RootState>((state: RootState) => state.bookingTemplates)

export const selectBookingTemplatesStatus = (state: RootState) => state.bookingTemplates.status

export default bookingTemplatesSlice.reducer
