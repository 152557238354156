import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { MilestoneType } from 'types/milestones'

import { TransportType } from 'types/shipments'

import {
  TIMELINE_CARRIER_HAULAGE_MILESTONES,
  TIMELINE_GENERAL_MILESTONES,
  TIMELINE_TYPE_MILESTONES,
} from 'constants/milestones'

import Modal from 'components/modal'
import useModal from 'components/modal/hooks/use_modal'
import Button from 'components/button'
import Select from 'components/select'
import InputDatepicker from 'components/input_datepicker'

import { StyledModalWrapper } from 'views/shipment/style'
import useNewMilestoneForm from 'views/shipment/hooks/use_new_milestone_form'
import useAvailableMilestonesByAddress from 'views/shipment/hooks/use_available_milestones_by_address'
import ShipmentContext from 'views/shipment/context'
import { addMilestone, fetchTimeline } from 'views/shipment/slice'
import { addNotification } from 'views/notifications/slice'

import useAppDispatch from 'services/hooks/use_app_dispatch'
import DateHelper from 'services/helpers/date_helper'

export interface NewMilestoneModalProps {
  locationId: number
  locationName: string
  locationTypes: TransportType[]
}

const NewMilestoneModal: FC<NewMilestoneModalProps> = ({
  locationId,
  locationName,
  locationTypes,
}) => {
  const modalName = 'newMilestoneModal'
  const { setOpen } = useModal(modalName)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { id } = useContext(ShipmentContext)

  const onCloseModal = () => setOpen(false)

  const milestonesToOptions = (value: number) => ({ value, label: t(`static.milestones.${value}`) })
  const { milestoneTypes: availableMilestoneTypes } = useAvailableMilestonesByAddress({
    id: id!,
    addressId: locationId,
  })
  const transportMilestones = locationTypes.map((type) => ({
    label: t(`shipments.newMilestoneModal.milestoneTypes.${type}`),
    options: TIMELINE_TYPE_MILESTONES[type]
      .filter((mtype: MilestoneType) => availableMilestoneTypes?.includes(mtype))
      .map((mtype: MilestoneType) => milestonesToOptions(mtype)),
  }))
  const transportMilestoneIds = transportMilestones
    .map(({ options }) => options.map(({ value }) => value))
    .flat()
  const carrierHaulageMilestones = {
    label: t('shipments.newMilestoneModal.milestoneTypes.carrierHaulage'),
    options: TIMELINE_CARRIER_HAULAGE_MILESTONES.filter(
      (mtype: MilestoneType) =>
        availableMilestoneTypes?.includes(mtype) && !transportMilestoneIds.includes(mtype)
    ).map((mtype: MilestoneType) => milestonesToOptions(mtype)),
  }
  const generalInformationMilestones = {
    label: t('shipments.newMilestoneModal.milestoneTypes.generalInformation'),
    options: TIMELINE_GENERAL_MILESTONES.filter((mtype: MilestoneType) =>
      availableMilestoneTypes?.includes(mtype)
    ).map((mtype: MilestoneType) => milestonesToOptions(mtype)),
  }
  const milestoneOptions = transportMilestones.concat(
    carrierHaulageMilestones,
    generalInformationMilestones
  )

  const { milestoneTypeFilter, estimatedTimeFilter, actualTimeFilter, queryParams, isValid } =
    useNewMilestoneForm()

  const onSubmit = () => {
    dispatch(
      addMilestone({
        id: id!,
        milestoneType: queryParams.milestoneType,
        estimatedTime: queryParams.estimatedTime,
        actualTime: queryParams.actualTime,
        addressId: locationId,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(
          addNotification({
            type: 'success',
            title: t('shipments.newMilestoneModal.creationAlert.title'),
            text: t('shipments.newMilestoneModal.creationAlert.successMessage'),
          })
        )
        dispatch(fetchTimeline({ id: id! }))
          .unwrap()
          .catch(() => {
            dispatch(
              addNotification({
                type: 'alert',
                title: t('errors.notification.title'),
                text: t('errors.notification.content'),
              })
            )
          })
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('shipments.newMilestoneModal.creationAlert.title'),
            text: t('shipments.newMilestoneModal.creationAlert.errorMessage'),
          })
        )
      })
  }

  return (
    <Modal position='center' onClose={onCloseModal} modalName={modalName}>
      <Modal.Header>{t('shipments.newMilestoneModal.title', { name: locationName })}</Modal.Header>
      <Modal.Content>
        <StyledModalWrapper>
          <Select
            label={t('shipments.newMilestoneModal.milestoneType')}
            name={milestoneTypeFilter.name}
            onChange={milestoneTypeFilter.onChange}
            options={milestoneOptions}
          />
          <InputDatepicker
            label={t('shipments.newMilestoneModal.estimatedDate')}
            name={estimatedTimeFilter.name}
            onChange={estimatedTimeFilter.onChange}
            startDate={estimatedTimeFilter.value}
            withPortal
            showTimeSelect
          />
          <InputDatepicker
            label={t('shipments.newMilestoneModal.actualDate')}
            name={actualTimeFilter.name}
            onChange={actualTimeFilter.onChange}
            startDate={actualTimeFilter.value}
            filterDate={(date: Date) => new DateHelper(date).isBeforeOrEqual(new Date())}
            withPortal
            showTimeSelect
          />
        </StyledModalWrapper>
      </Modal.Content>
      <Modal.Footer>
        <Button text={t('actions.cancel')} onClick={onCloseModal} rounded variant='clear' />
        <Button
          text={t('actions.create')}
          onClick={onSubmit}
          disabled={!isValid}
          rounded
          variant='highlight'
        />
      </Modal.Footer>
    </Modal>
  )
}

export default NewMilestoneModal
