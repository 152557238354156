import styled from 'styled-components'

import { desktopLarge, tablet } from 'styles/utils/breakpoints'
import { text16SemiBold } from 'styles/utils/texts'
import { TrustedRoutesSidebarWidth } from 'views/trusted_routes/components/filters/style'

export const TrustedRoutesMaxWidth = 1600
export const TrustedRoutesPagePadding = 100
export const TrustedRoutesGap = 20

const TrustedRoutePageContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: ${TrustedRoutesGap}px;
  padding-top: ${TrustedRoutesGap}px;
  padding-bottom: ${TrustedRoutesGap}px;
  @media ${tablet.up} {
    padding-left: ${TrustedRoutesPagePadding}px;
  }
  @media ${tablet.down} {
    padding-left: ${TrustedRoutesGap}px;
  }
  height: 100%;
`

const Content = styled.div`
  min-height: 0;
  display: flex;
  gap: ${TrustedRoutesGap}px;
  position: relative;
  flex-grow: 1;
`

const List = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: ${TrustedRoutesGap}px;
`

const Header = styled.div`
  max-width: ${TrustedRoutesMaxWidth}px;
  @media ${tablet.up} {
    margin-right: ${TrustedRoutesPagePadding}px;
  }
  @media ${tablet.down} {
    margin-right: ${TrustedRoutesGap}px;
  }
`

const NoResult = styled.div`
  color: ${(props) => props.theme.textDark};
  text-align: center;
  ${text16SemiBold};
`

const AllianceWarning = styled.div`
  padding-top: 10px;
  max-width: calc(${TrustedRoutesMaxWidth}px - ${TrustedRoutesSidebarWidth + TrustedRoutesGap}px);
  width: calc(
    100vw - ${TrustedRoutesPagePadding * 2 + TrustedRoutesSidebarWidth + TrustedRoutesGap}px
  );
  @media ${desktopLarge.down} {
    width: 100%;
    max-width: calc(100% - ${TrustedRoutesPagePadding}px);
  }
  @media ${tablet.down} {
    width: 100%;
    max-width: calc(100% - ${TrustedRoutesGap}px);
  }
  white-space: pre-line;
  color: ${(props) => props.theme.warn};

  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
`

const S = {
  TrustedRoutePageContainer,
  Content,
  List,
  NoResult,
  Header,
  AllianceWarning,
}

export default S
