import React, { FC } from 'react'

import useComputedShipmentOrganization from 'features/shipments/components/shipment_list_item/hooks/use_computed_shipment_organization'
import useReferences, {
  Reference,
} from 'features/shipments/components/shipment_list_item/hooks/use_references'

const Organization: FC = () => {
  const references: Reference[] = [useComputedShipmentOrganization()]

  const { References } = useReferences({ references })
  return <References />
}

export default Organization
