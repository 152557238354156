import React, { FC } from 'react'

import { StepStatus } from 'types/shipments'

import { STEP_STATUS_CURRENT, VehicleType } from 'constants/shipments'

import ShipmentTimelineStep from 'components/shipment_timeline/shipment_timeline_step'

import { SummaryStep } from 'features/shipments/types/shipment'

interface StepProps {
  step?: SummaryStep
  status: StepStatus
  inTransit?: boolean
  currentVehicleType: VehicleType | null
}

const Step: FC<StepProps> = ({ step, status, inTransit = false, currentVehicleType }) =>
  step ? (
    <ShipmentTimelineStep
      status={status}
      inTransit={status === STEP_STATUS_CURRENT && inTransit}
      locationName={step.location.name}
      locationCode={step.location.countryCode}
      facilityType={step.facilityType}
      currentVehicleType={currentVehicleType}
      estimatedTime={step.estimatedTime}
      actualTime={step.actualTime}
    />
  ) : (
    <ShipmentTimelineStep status={status} inTransit={false} />
  )

export default Step
