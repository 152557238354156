import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { useTheme } from 'styled-components'
import mapboxgl, { LngLatBoundsLike, Map as MapboxMap } from 'mapbox-gl'

import { isAnyObject, isPresent } from 'services/helpers/values'

import Map from 'components/map'
import useBounds from 'components/map/hooks/use_bounds'
import useVehicleClusters from 'components/map/hooks/use_vehicle_clusters'
import useMarkers from 'components/map/hooks/use_markers'
import usePath from 'components/map/hooks/use_path'

import { OCP_ORGANIZATION, CLASQUIN_ORGANIZATION } from 'constants/organizations'

import { selectCurrentUserOrganization } from 'views/iam/slices/iamSlice'
import { CoordinateModel } from 'components/map/models'
import useLineString from 'components/map/hooks/use_line_string'
import useGeometry from 'components/map/hooks/use_geometry'
import { MarkerIconType } from 'components/marker'
import { ShipmentColor } from 'features/shipments/types/shipment'

// FIX: https://github.com/mapbox/mapbox-gl-js/issues/10173#issuecomment-750489778
// Mapbox is being transpiled by Babel and functions are broken.
// This fix tells webpack not to transpile Mapbox.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY || ''

const useMap = ({
  bounds: boundCoordinates,
  onToggleFullscreen,
  onVehicleClick,
  onClusterClick,
  displayToggleFullscreen = true,
}: {
  bounds: Array<CoordinateModel>
  onToggleFullscreen?: (isFullscreen: boolean) => void
  onVehicleClick: ({
    x,
    y,
    label,
    type,
    color,
    id,
  }: {
    x: number
    y: number
    label?: string
    type: MarkerIconType
    color: ShipmentColor
    id: string
  }) => void
  onClusterClick: () => void
  displayToggleFullscreen?: boolean
}) => {
  const map = useRef<MapboxMap>()
  const isInit = isPresent(map.current)
  const mapComponentRef = useRef()
  const theme = useTheme()
  const [loaded, setLoaded] = useState(false)
  const userOrganization = useSelector(selectCurrentUserOrganization)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const { bounds, fitBounds, fitBoundsOptions } = useBounds({
    map: map.current,
    coordinates: boundCoordinates,
  })
  const { addPath, addPointer } = usePath({ map: map.current })
  const { buildMarker, addMarker } = useMarkers({ map: map.current })
  const { addGeometry } = useGeometry({ map: map.current })
  const { addLineString } = useLineString({ map: map.current })

  const clusterizeVehicles = useVehicleClusters({
    map: map.current,
    buildMarker,
    onVehicleClick,
    onClusterClick,
  })

  const resize = useCallback(() => map.current?.resize(), [])

  const setCenter = useCallback(
    (coordinate) => {
      map?.current?.setCenter(coordinate)
      map?.current?.zoomTo(2)
    },
    [map]
  )

  useEffect(() => {
    // Map is disabled without API key
    // This ensures application still runs even without it
    if (!mapboxgl.accessToken) return
    if ((isAnyObject(bounds) && isInit) || !mapComponentRef.current) return
    if (map.current) return

    const withoutMoroccoBoundariesMapStyle = 'mapbox://styles/techs-wakeo/ckig3ntiu57u319k0vrq163k7'
    const defaultMapStyle = 'mapbox://styles/techs-wakeo/cks66nnev63r117mvhad4lfzt'
    const style = [OCP_ORGANIZATION, CLASQUIN_ORGANIZATION].includes(userOrganization?.name)
      ? withoutMoroccoBoundariesMapStyle
      : defaultMapStyle

    map.current = new MapboxMap({
      container: mapComponentRef.current,
      style,
      attributionControl: false,
      bounds: bounds as LngLatBoundsLike,
      fitBoundsOptions,
    })
    // eslint-disable-next-line no-console
    map.current.on('error', (e) => console.warn('Mapbox client error', e))
    map.current.on('load', () => {
      setLoaded(true)
    })
  }, [bounds, fitBoundsOptions, isInit, userOrganization, theme])

  const toggleFullscreen = useCallback(() => {
    setIsFullscreen(!isFullscreen)
    if (onToggleFullscreen) onToggleFullscreen(!isFullscreen)
    resize()
  }, [isFullscreen, onToggleFullscreen, resize])

  useEffect(() => {
    if (loaded) {
      resize()
    }
  }, [loaded, isFullscreen, resize])

  return {
    Map,
    mapProps: {
      ref: mapComponentRef,
      toggleFullscreen: displayToggleFullscreen ? toggleFullscreen : undefined,
    },
    addMarker,
    addPath,
    addPointer,
    addGeometry,
    addLineString,
    setCenter,
    fitBounds,
    loaded,
    resize,
    clusterizeVehicles,
    isFullscreen,
  }
}

export default useMap
