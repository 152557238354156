import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

import Form from 'components/form'
import Grid from 'components/grid'

import { BookingFieldNames } from 'views/booking/slices/types'

import Select from 'components/select'
import Input from 'components/input'

import { BOOKING_FIELD_NAMES, BOOKING_FIELD_VALIDATIONS } from 'constants/bookings'

import {
  StyledAddMerchandisePackageWrapper,
  StyledHazardousGoodsItemInput,
  StyledHazardousGoodsWrapper,
  StyledRemoveMerchandiseItemWrapper,
} from 'views/booking/components/form/style'

import Button from 'components/button'
import useStaticLocales from 'views/locales/hooks/use_static_locales'

import {
  TEST_ID_BOOKING_FORM_MINIMAL_TEMPERATURE,
  getTestIdForMerchandisePackage,
  getTestIdForMerchandiseProduct,
  getTestIdForMerchandiseProductCurrencyCode,
  getTestIdForMerchandiseProductMaximalTemperature,
} from 'tests/e2e/test_ids'
import Textarea from 'components/textarea'
import { BookingFormInput } from 'views/booking/components/form/types'
import { stringToFloat, stringToInt } from 'services/helpers/values'

interface MerchandisePackageLegacyProps {
  isFieldDisabled: (fieldName: BookingFieldNames) => boolean
}

const MerchandisePackageLegacy: FC<MerchandisePackageLegacyProps> = ({
  isFieldDisabled,
}: MerchandisePackageLegacyProps) => {
  const { s } = useStaticLocales()
  const { t } = useTranslation()

  const fromStaticToSelectOptions = (path: string) =>
    Object.entries(s(path)).map(([key, value]) => ({ value: key, label: value }))

  const {
    control,
    formState: { errors },
  } = useFormContext<BookingFormInput>()

  const {
    fields: packages,
    append: appendPackage,
    remove: removePackage,
  } = useFieldArray({
    control,
    name: 'merchandise.content.package.packages',
  })

  return (
    <Form.Group>
      <Form.Insert subtitle={t('bookings.merchandise.packagesRequest')}>
        {packages.map((merchandisePackage, index: number) => (
          <Form.Insert
            key={merchandisePackage.id}
            subtitle={`${t('bookings.merchandise.packageRequest')} #${index + 1}`}
          >
            {index !== 0 && (
              <StyledRemoveMerchandiseItemWrapper>
                <Button
                  disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_SIGNIFICANT_CONTENT)}
                  variant='icon'
                  icon='trash'
                  onClick={() => removePackage(index)}
                />
              </StyledRemoveMerchandiseItemWrapper>
            )}
            <Grid columns={3}>
              <Grid.Row compact testId={getTestIdForMerchandisePackage(index)}>
                <Grid.Column>
                  <Controller
                    name={`merchandise.content.package.packages.${index}.packageType`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        label={t('bookings.merchandise.type')}
                        placeholder={t('bookings.merchandise.packageType')}
                        options={fromStaticToSelectOptions('packageTypes')}
                        name={`packageType-${index}`}
                        value={field.value}
                        error={
                          errors.merchandise?.content?.package?.packages?.[index]?.packageType
                            ?.message
                        }
                        onChange={({ value }) => field.onChange(value)}
                        required={
                          !isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_SIGNIFICANT_CONTENT)
                        }
                        isDisabled={isFieldDisabled(
                          BOOKING_FIELD_NAMES.MERCHANDISE_SIGNIFICANT_CONTENT
                        )}
                        inputId={`package-type-${index}`}
                      />
                    )}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Controller
                    name={`merchandise.content.package.packages.${index}.quantity`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        label={t('bookings.merchandise.quantity')}
                        type='number'
                        placeholder={t('bookings.merchandise.quantity')}
                        min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.QUANTITY}
                        name={`packageQuantity-${index}`}
                        value={field.value}
                        error={
                          errors.merchandise?.content?.package?.packages?.[index]?.quantity?.message
                        }
                        onChange={({ target: { value } }) => field.onChange(stringToInt(value))}
                        required={
                          !isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_SIGNIFICANT_CONTENT)
                        }
                        disabled={isFieldDisabled(
                          BOOKING_FIELD_NAMES.MERCHANDISE_SIGNIFICANT_CONTENT
                        )}
                        id={`merchandise-quantity-${index}`}
                      />
                    )}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Controller
                    name={`merchandise.content.package.packages.${index}.weight`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        label={t('bookings.merchandise.packageWeight')}
                        placeholder='kg'
                        step='0.01'
                        type='number'
                        min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT}
                        name={`packageWeight-${index}`}
                        value={field.value}
                        error={
                          errors.merchandise?.content?.package?.packages?.[index]?.weight?.message
                        }
                        onChange={({ target: { value } }) => field.onChange(stringToFloat(value))}
                        required={!isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                        disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                        id={`merchandise-weight-${index}`}
                      />
                    )}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Controller
                    name={`merchandise.content.package.packages.${index}.length`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        label={t('bookings.merchandise.packageLength')}
                        type='number'
                        step='0.01'
                        min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.LENGTH}
                        placeholder='cm'
                        name={`packageLength-${index}`}
                        value={field.value}
                        error={
                          errors.merchandise?.content?.package?.packages?.[index]?.length?.message
                        }
                        onChange={({ target: { value } }) => field.onChange(stringToFloat(value))}
                        required={!isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                        disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                        id={`merchandise-length-${index}`}
                      />
                    )}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Controller
                    name={`merchandise.content.package.packages.${index}.width`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        label={t('bookings.merchandise.packageWidth')}
                        type='number'
                        step='0.01'
                        min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WIDTH}
                        placeholder='cm'
                        name={`packageWidth-${index}`}
                        value={field.value}
                        error={
                          errors.merchandise?.content?.package?.packages?.[index]?.width?.message
                        }
                        onChange={({ target: { value } }) => field.onChange(stringToFloat(value))}
                        required={!isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                        disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                        id={`merchandise-width-${index}`}
                      />
                    )}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Controller
                    name={`merchandise.content.package.packages.${index}.height`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        label={t('bookings.merchandise.packageHeight')}
                        type='number'
                        step='0.01'
                        min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.HEIGHT}
                        placeholder='cm'
                        name={`packageHeight-${index}`}
                        value={field.value}
                        error={
                          errors.merchandise?.content?.package?.packages?.[index]?.height?.message
                        }
                        onChange={({ target: { value } }) => field.onChange(stringToFloat(value))}
                        required={!isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                        disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                        id={`merchandise-height-${index}`}
                      />
                    )}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form.Insert>
        ))}
        <StyledAddMerchandisePackageWrapper>
          <Button
            onClick={() => appendPackage({})}
            disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_SIGNIFICANT_CONTENT)}
            icon='plus_outline'
            variant='dashed'
            text={`${t('actions.addNew', { context: 'female' })} ${t(
              'bookings.merchandise.packageRequest'
            ).toLowerCase()}`}
          />
        </StyledAddMerchandisePackageWrapper>
      </Form.Insert>
      <Form.Insert subtitle={t('bookings.merchandise.merchandiseDetails')}>
        <Grid columns={3}>
          <Grid.Row testId={getTestIdForMerchandiseProduct(0)}>
            <Grid.Column>
              <Controller
                name='merchandise.content.package.packages.0.productDescription'
                control={control}
                render={({ field }) => (
                  <Textarea
                    label={t('bookings.merchandise.productDescription')}
                    placeholder={t('bookings.merchandise.yourDescription')}
                    name='merchandiseDescription'
                    rows={4}
                    compact
                    value={field.value}
                    error={
                      errors.merchandise?.content?.package?.packages?.[0]?.productDescription
                        ?.message
                    }
                    onChange={({ target: { value } }) => field.onChange(value)}
                    disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                  />
                )}
              />
            </Grid.Column>
            <Grid.Column>
              <Controller
                name='merchandise.content.package.packages.0.commercialValue.amount'
                control={control}
                render={({ field }) => (
                  <Input
                    type='number'
                    step='0.01'
                    min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.AMOUNT}
                    label={t('bookings.merchandise.totalCommercialValue')}
                    placeholder={t('bookings.merchandise.amount')}
                    name='merchandiseAmount'
                    value={field.value}
                    error={
                      errors.merchandise?.content?.package?.packages?.[0]?.commercialValue?.amount
                        ?.message
                    }
                    onChange={({ target: { value } }) => field.onChange(stringToFloat(value))}
                    disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                  />
                )}
              />
              <Controller
                name='merchandise.content.package.packages.0.commercialValue.currencyCode'
                control={control}
                render={({ field }) => (
                  <Select
                    placeholder={t('bookings.merchandise.currency')}
                    options={fromStaticToSelectOptions('currencies')}
                    name='merchandiseCurrencyCode'
                    isDisabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                    value={field.value}
                    error={
                      errors.merchandise?.content?.package?.packages?.[0]?.commercialValue
                        ?.currencyCode?.message
                    }
                    isClearable
                    onChange={({ value }) => field.onChange(value)}
                    testId={getTestIdForMerchandiseProductCurrencyCode(0)}
                  />
                )}
              />
            </Grid.Column>
            <Grid.Column>
              <Controller
                name='merchandise.content.package.packages.0.controlledTemperatures.min'
                control={control}
                render={({ field }) => (
                  <Input
                    label={t('bookings.merchandise.controlledTemperatures.title')}
                    placeholder={t('bookings.merchandise.controlledTemperatures.minimal')}
                    type='number'
                    step='0.01'
                    disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                    testId={TEST_ID_BOOKING_FORM_MINIMAL_TEMPERATURE}
                    name='merchandiseTemperatureMinimal'
                    value={field.value}
                    error={
                      errors.merchandise?.content?.package?.packages?.[0]?.controlledTemperatures
                        ?.min?.message
                    }
                    onChange={({ target: { value } }) => field.onChange(stringToFloat(value))}
                  />
                )}
              />
              <Controller
                name='merchandise.content.package.packages.0.controlledTemperatures.max'
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder={t('bookings.merchandise.controlledTemperatures.maximal')}
                    type='number'
                    step='0.01'
                    disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                    testId={getTestIdForMerchandiseProductMaximalTemperature(0)}
                    name='merchandiseTemperatureMaximal'
                    value={field.value}
                    error={
                      errors.merchandise?.content?.package?.packages?.[0]?.controlledTemperatures
                        ?.max?.message
                    }
                    onChange={({ target: { value } }) => field.onChange(stringToFloat(value))}
                  />
                )}
              />
            </Grid.Column>
            <Grid.Column>
              <Controller
                name='merchandise.content.package.packages.0.hazardousGoods.hazardousClass'
                control={control}
                render={({ field }) => (
                  <Select
                    label={t('bookings.merchandise.product.hazardousGoods.hazardousClass')}
                    placeholder={t('actions.select')}
                    isDisabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                    options={fromStaticToSelectOptions('hazardousGoods')}
                    name='merchandiseHazardousClass'
                    value={field.value}
                    error={
                      errors.merchandise?.content?.package?.packages?.[0]?.hazardousGoods
                        ?.hazardousClass?.message
                    }
                    onChange={({ value }) => field.onChange(value)}
                    isClearable
                  />
                )}
              />
            </Grid.Column>
            <Grid.Column>
              <Controller
                name='merchandise.content.package.packages.0.hazardousGoods.packingGroup'
                control={control}
                render={({ field }) => (
                  <Select
                    label={t('bookings.merchandise.product.hazardousGoods.packingGroup')}
                    placeholder={t('actions.select')}
                    isDisabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                    options={fromStaticToSelectOptions('packingGroups')}
                    name='merchandiseHazardousPackingGroup'
                    value={field.value}
                    error={
                      errors.merchandise?.content?.package?.packages?.[0]?.hazardousGoods
                        ?.packingGroup?.message
                    }
                    onChange={({ value }) => field.onChange(value)}
                    isClearable
                  />
                )}
              />
            </Grid.Column>
            <Grid.Column>
              <StyledHazardousGoodsWrapper>
                <Controller
                  name='merchandise.content.package.packages.0.hazardousGoods.unNumber'
                  control={control}
                  render={({ field }) => (
                    <StyledHazardousGoodsItemInput
                      label={t('bookings.merchandise.product.hazardousGoods.unNumber')}
                      placeholder={t('bookings.merchandise.product.hazardousGoods.unNumber')}
                      disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                      name='merchandiseHazardousUnNumber'
                      value={field.value}
                      error={
                        errors.merchandise?.content?.package?.packages?.[0]?.hazardousGoods
                          ?.unNumber?.message
                      }
                      onChange={({ target: { value } }) => field.onChange(value)}
                    />
                  )}
                />
                <Controller
                  name='merchandise.content.package.packages.0.hazardousGoods.weight'
                  control={control}
                  render={({ field }) => (
                    <StyledHazardousGoodsItemInput
                      label={t('bookings.merchandise.product.hazardousGoods.weight')}
                      placeholder='kg'
                      type='number'
                      step='0.01'
                      disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                      min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT}
                      name='merchandiseHazardousWeight'
                      value={field.value}
                      error={
                        errors.merchandise?.content?.package?.packages?.[0]?.hazardousGoods?.weight
                          ?.message
                      }
                      onChange={({ target: { value } }) => field.onChange(stringToFloat(value))}
                    />
                  )}
                />
              </StyledHazardousGoodsWrapper>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form.Insert>
    </Form.Group>
  )
}

export default MerchandisePackageLegacy
