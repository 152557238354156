import React, { useEffect, useState, ReactNode } from 'react'

import Icon from 'components/icon'
import {
  StyledAccordionItem,
  StyledAccordionItemHeading,
  StyledAccordionItemContent,
  StyledAccordionItemHeadingArrow,
  StyledAccordionItemHeadingMore,
  StyledHeading,
} from 'components/accordion_item/style'

interface AccordionItemProps {
  heading: ReactNode
  children: ReactNode
  defaultOpened?: boolean
  onChangeCallback?: (arg: boolean) => void
  indicator?: 'arrow' | 'more'
  small?: boolean
  testId?: string
  disabled?: boolean
}

const AccordionItem: React.FC<AccordionItemProps> = ({
  heading,
  children,
  onChangeCallback,
  testId,
  defaultOpened = false,
  indicator = 'arrow',
  small = false,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpened)

  useEffect(() => {
    onChangeCallback?.(isOpen)
  })

  return (
    <StyledAccordionItem data-testid={testId}>
      <StyledAccordionItemHeading
        onClick={() => {
          if (disabled) return
          setIsOpen(!isOpen)
        }}
      >
        <StyledHeading $small={small}>{heading}</StyledHeading>
        {disabled ? (
          <StyledAccordionItemHeadingArrow as='div' />
        ) : (
          <>
            {indicator === 'arrow' && !isOpen && (
              <StyledAccordionItemHeadingArrow as={Icon} name='arrow_bottom_outline' />
            )}
            {indicator === 'arrow' && isOpen && (
              <StyledAccordionItemHeadingArrow as={Icon} name='arrow_top' />
            )}
            {indicator === 'more' && !isOpen && (
              <StyledAccordionItemHeadingMore as={Icon} name='plus' />
            )}
            {indicator === 'more' && isOpen && (
              <StyledAccordionItemHeadingMore as={Icon} name='minus' />
            )}
          </>
        )}
      </StyledAccordionItemHeading>
      {isOpen && <StyledAccordionItemContent>{children}</StyledAccordionItemContent>}
    </StyledAccordionItem>
  )
}

export default AccordionItem
