import React, { FC } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'

interface LinkProps {
  to: string
  onClick: React.MouseEventHandler<HTMLAnchorElement>
  className?: string
  testId?: string
  target?: '_blank' | '_self' | '_parent' | '_top' | 'framename'
}

const Link: FC<LinkProps> = ({ to, onClick, children, className, testId, target }) =>
  typeof to === 'string' && to.startsWith('http') ? (
    <a
      href={to}
      onClick={onClick}
      rel='noreferrer'
      target={target}
      className={className}
      data-testid={testId}
    >
      {children}
    </a>
  ) : (
    <ReactRouterLink to={to} onClick={onClick} className={className} data-testid={testId}>
      {children}
    </ReactRouterLink>
  )
export default Link
