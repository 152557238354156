import React, { ChangeEvent, FocusEvent } from 'react'

import {
  StyledTextarea,
  StyledTextareaInput,
  StyledTextareaError,
  StyledTextareaLabel,
  StyledTextareaWrapper,
} from 'components/textarea/style'
import Icon from 'components/icon'

type Ref = HTMLTextAreaElement
interface TextareaProps {
  placeholder?: string
  label?: string
  name?: string
  defaultValue?: any
  value?: any
  error?: string
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void
  min?: number
  max?: number
  disabled?: boolean
  rows?: number
  compact?: boolean
  disableFocusStyle?: boolean
  testId?: string
}
const Textarea = React.forwardRef<Ref, TextareaProps>(
  (
    {
      placeholder,
      label,
      name,
      defaultValue,
      value,
      error,
      onChange,
      onBlur,
      min,
      max,
      disabled = false,
      rows,
      compact,
      testId,
      disableFocusStyle = false,
    }: TextareaProps,
    ref
  ) => (
    <StyledTextarea $hasError={!!error} $disabled={disabled}>
      {label && <StyledTextareaLabel htmlFor={name}>{label}</StyledTextareaLabel>}
      <StyledTextareaWrapper>
        <StyledTextareaInput
          data-testid={testId}
          placeholder={placeholder}
          $disableFocusStyle={disableFocusStyle}
          name={name}
          id={name}
          onChange={onChange}
          ref={ref}
          value={value}
          defaultValue={defaultValue}
          onBlur={onBlur}
          minLength={min}
          maxLength={max}
          disabled={disabled}
          rows={rows}
          $compact={compact}
        />
      </StyledTextareaWrapper>
      {error && (
        <StyledTextareaError>
          <Icon name='warning' /> {error}
        </StyledTextareaError>
      )}
    </StyledTextarea>
  )
)

export default Textarea
