/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext } from 'react'

export type DecomposedStep = 'preStopover' | 'stopover' | 'postStopover'

interface EditTimelineModalContextProps {
  currentEditedSegmentIndex: number
  currentEditedStepIndex: number
  currentEditedMilestoneIndex: number
  currentDecomposedStep?: DecomposedStep
  setCurrentEditedSegmentIndex: (index: number) => void
  setCurrentEditedStepIndex: (index: number) => void
  setCurrentEditedMilestoneIndex: (index: number) => void
  setCurrentDecomposedStep: (decomposedStep: DecomposedStep) => void
}

const EditTimelineModalContext = createContext<EditTimelineModalContextProps>({
  currentEditedSegmentIndex: -1,
  currentEditedStepIndex: -1,
  currentEditedMilestoneIndex: -1,
  currentDecomposedStep: undefined,
  setCurrentEditedSegmentIndex: (_index: number) => {},
  setCurrentEditedStepIndex: (_index: number) => {},
  setCurrentEditedMilestoneIndex: (_index: number) => {},
  setCurrentDecomposedStep: (_decomposedStep: DecomposedStep) => {},
})

export default EditTimelineModalContext
