import styled, { css } from 'styled-components'

import { easeQuartOut } from 'styles/utils/ease'
import { text14Regular, text14SemiBold } from 'styles/utils/texts'
import { desktop, desktopLarge, mobileSmall } from 'styles/utils/breakpoints'

import StyledFavorite from 'components/favorite/style'
import { PageContentMaxWidth } from 'components/page/style'

export const StyledShipmentListItemTimeline = styled.div``
export const StyledShipmentListItemMeta = styled.div`
  min-width: 0;
  flex-basis: 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
export const StyledShipmentListItemIcon = styled.div`
  fill: ${({ theme }) => theme.primary};
  width: 28px;
  height: 28px;
  flex: none;
`

export const StyledShipmentListItemTitle = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  min-width: 0;
`

export const StyledShipmentListItemRef = styled.div<{ $condensed: boolean }>`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 3px 0px 3px 0;
  ${text14Regular};
  color: ${({ theme }) => theme.textDark};
  transition: color 0.5s ${easeQuartOut};
  
  ${({ $condensed }) => {
    if (!$condensed) {
      return css`
        color: ${({ theme }) => theme.textLight};
        flex-grow: 1;
        max-width: 250px;
        flex-basis: 50%;
        padding-right: 15px;
      `
    }

    return null
  }}}
`

export const StyledShipmentListItemMainRef = styled(StyledShipmentListItemRef)`
  ${StyledShipmentListItemRef}
  ${text14SemiBold}
  color: ${({ theme }) => theme.textDark};
`

export const StyledShipmentListItemLeft = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  flex-grow: 1;
`

export const StyledShipmentListItemLeftContent = styled.div`
  display: flex;
  overflow: hidden;
  flex-grow: 1;
`

export const StyledShipmentListItemRight = styled.div`
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: 'flex-end' ${StyledFavorite} {
    margin-left: 13px;
  }
`

export const StyledShipmentListItemHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 10;
`

export const StyledShipmentListItem = styled.div`
  width: 100%;
  position: relative;
  display: block;

  &:hover {
    ${StyledShipmentListItemMainRef} {
      color: ${({ theme }) => theme.primary};
    }
  }

  margin-bottom: 20px;

  &:hover {
    ${StyledShipmentListItemTimeline} {
      box-shadow: 0px 1px 10px rgba(228, 228, 228, 0.7);
    }
  }

  ${StyledShipmentListItemLeft} {
    justify-content: space-between;

    @media ${mobileSmall.down} {
      margin-bottom: 10px;
    }
  }

  ${StyledShipmentListItemHead} {
    display: flex;
    align-items: center;
    padding: 9px 30px 11px 17px;
    border-radius: 24px 24px 0px 0px;
    background: ${({ theme }) => theme.white};
    box-shadow: 0px 1px 10px rgba(228, 228, 228, 0.7);

    @media ${mobileSmall.down} {
      flex-wrap: wrap;
    }
  }

  ${StyledShipmentListItemTimeline} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    gap: 40px;
    padding: 0 20px;
    border-radius: 0 0 24px 24px;
    background: ${({ theme }) => theme.white};
    transition: box-shadow 0.5s ${easeQuartOut};

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        52deg,
        ${({ theme }) => theme.gradients.dark.from} 6%,
        ${({ theme }) => theme.gradients.dark.to} 97%
      );
      opacity: 0.03;
    }

    @media ${desktop.up} {
      height: 104px;
    }
  }

  ${StyledShipmentListItemIcon} {
    margin-right: 10px;
  }

  @media ${desktopLarge.up} {
    max-width: ${PageContentMaxWidth};
  }
`

export const StyledCondensedData = styled.div`
  flex-basis: 60%;
  min-width: 0;
`
export const StyledCondensedRightBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 40%;
  min-width: 0;
  gap: 10px;
`
export const StyledCondensedRefs = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.mediumGray};
  gap: 10px;
`
// In order to display tooltip aligned with hovered content,
// we reduce block width to content width, instead of the whole column width
export const StyledRefValue = styled.span`
  display: inline-block;
  max-width: 100%;
  vertical-align: middle;
`
export const StyledCondensedTimeline = styled.div`
  flex-basis: 150px;
  min-width: 0;
`
export const StyledCondensedEta = styled.div`
  display: flex;
  justify-content: center;
  flex-basis: 100px;
  min-width: 0;
`
export const StyledCondensedMeta = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
  flex-basis: 100px;
  min-width: 0;
`
export const StyledCondensedFavorite = styled.div`
  flex-basis: 30px;
  min-width: 0;
`
export const StyledCondensedParties = styled.div``

export const StyledOngoingAlertAndDelay = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  // HACKEO as the last element from the timeline is using a translate transformation,
  // it actually overlap with this section, as a consequence hovertooltips are not working properly
  z-index: 2;
`
