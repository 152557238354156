import React, { memo, useContext, useMemo } from 'react'

import {
  StyledShipmentListItemRef,
  StyledShipmentListItemMainRef,
  StyledRefValue,
} from 'features/shipments/components/shipment_list_item/style'
import HoverTooltip from 'components/hover_tooltip'

import { SHIPMENT_VIEW_CONDENSED } from 'constants/shipments'

import { isPresent } from 'services/helpers/values'
import ShipmentViewContext from 'features/shipments/contexts/shipment_view_context'

export interface Reference {
  Component: typeof StyledShipmentListItemMainRef | typeof StyledShipmentListItemRef
  value?: string
  name: string
  show: boolean
  tooltipName: string
}

interface UseReferencesParams {
  references: Reference[]
}

const useReferences = ({ references }: UseReferencesParams) => {
  const condensedRefs = references.filter(({ show, value }) => show && isPresent(value))
  const { view } = useContext(ShipmentViewContext)
  const isCondensed = useMemo(() => view === SHIPMENT_VIEW_CONDENSED, [view])
  const References = () => (
    <>
      {isCondensed
        ? condensedRefs.map(({ Component, name, value, tooltipName }, i) => (
            <>
              <Component $condensed={isCondensed} key={`ref-${name}`}>
                <HoverTooltip delayed content={`${tooltipName} - ${value}`} ellipsis>
                  {value}
                </HoverTooltip>
              </Component>
              {i < condensedRefs.length - 1 ? ' / ' : null}
            </>
          ))
        : references
            .filter(({ show }) => show)
            .map(({ Component, value, show, name, tooltipName }) => (
              <Component $condensed={isCondensed} key={`ref-${name}`}>
                {show && (
                  <StyledRefValue>
                    <HoverTooltip delayed content={`${tooltipName} - ${value}`} ellipsis>
                      {value}
                    </HoverTooltip>
                  </StyledRefValue>
                )}
              </Component>
            ))}
    </>
  )
  return { References: memo(References) }
}

export default useReferences
