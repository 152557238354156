import styled from 'styled-components'

import { listItem13 } from 'styles/utils/texts'

const StyledShipmentListItemTime = styled.div`
  ${listItem13};
  color: ${({ theme }) => theme.textLight};
  white-space: nowrap;
`

export default StyledShipmentListItemTime
