import React, { useCallback } from 'react'

import { useTranslation } from 'react-i18next'

import { setFavorite, unsetFavorite } from 'features/shipments/store/shipment_slice'
import { addNotification } from 'views/notifications/slice'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import useTracker from 'services/analytics/hooks/use_tracker'

export type UseToggleFavoriteResponse = (e: React.MouseEvent<HTMLButtonElement>) => void

const useToggleFavorite = ({
  id,
  isActive,
}: {
  id: string
  isActive: boolean
}): UseToggleFavoriteResponse => {
  const dispatch = useAppDispatch()
  const { track } = useTracker()
  const { t } = useTranslation()

  return useCallback(
    (e) => {
      e.preventDefault()
      dispatch(isActive ? unsetFavorite({ id }) : setFavorite({ id }))
        .unwrap()
        .then(() => {
          track(`Shipment list / ${isActive ? 'unset' : 'set'} favourite`, { status: 'success' })
        })
        .catch(() => {
          track(`Shipment list / ${isActive ? 'unset' : 'set'} favourite`, { status: 'error' })
          dispatch(
            addNotification({
              type: 'alert',
              title: t('errors.notification.title'),
              text: t('errors.notification.content'),
            })
          )
        })
    },
    [dispatch, t, track, isActive, id]
  )
}

export default useToggleFavorite
