import { boolean, InferType, object, string } from 'yup'

import { SelectValue } from 'views/trusted_routes/slice'

export interface SearchBarQueryParams {
  departureLocode?: string
  departure?: string
  arrivalLocode?: string
  arrival?: string
  fromDate?: string
  fromAlternatives?: boolean
}

export const subscribeTrustedRoutesSchema = object({
  departure: object({ label: string().required(), value: string().required() }).required(),
  arrival: object({ label: string().required(), value: string().required() }).required(),
  fromDate: string().required(),
  withRates: boolean().required(),
})
export type SubscribeTrustedRoutesData = InferType<typeof subscribeTrustedRoutesSchema>

export interface SubscribeTrustedRoutesDataBeforeValidation {
  departure: SelectValue | null
  arrival: SelectValue | null
  fromDate: string | null
  withRates: boolean
}
