import React from 'react'

import DateHelper from 'services/helpers/date_helper'

import {
  StyledItem,
  StyledItemDate,
  StyledItemText,
  StyledItemTitle,
} from 'components/alert_list_item/style'

import { AlertListItemVariant } from 'components/alert_list_item/type'
import { Location } from 'features/shipments/types/shipment'

import { compactArray } from 'services/helpers/values'

const AlertListItem = ({
  declaredAt,
  location,
  title,
  details,
  variant,
}: {
  declaredAt: string
  location?: Location
  title: string
  details: string | null
  variant: AlertListItemVariant
}) => (
  <StyledItem $variant={variant}>
    <StyledItemDate>{new DateHelper(declaredAt).toLocale()}</StyledItemDate>
    <div>
      <StyledItemTitle>{title}</StyledItemTitle>
      {location && (
        <StyledItemText>
          {compactArray([location.name, location.country]).join(', ')}
        </StyledItemText>
      )}
      {details && <StyledItemText>{details}</StyledItemText>}
    </div>
  </StyledItem>
)

export default AlertListItem
