import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

import Form from 'components/form'

import { BookingFieldNames } from 'views/booking/slices/types'
import {
  StyledAddMerchandiseDetailsWrapper,
  StyledAddMerchandiseItemWrapper,
  StyledHazardousGoodsItemInput,
  StyledHazardousGoodsWrapper,
  StyledRemoveMerchandiseItemWrapper,
} from 'views/booking/components/form/style'
import Button from 'components/button'
import Grid from 'components/grid'
import Input from 'components/input'
import { BOOKING_FIELD_NAMES, BOOKING_FIELD_VALIDATIONS } from 'constants/bookings'
import {
  TEST_ID_BOOKING_FORM_MINIMAL_TEMPERATURE,
  getTestIdForMerchandiseProduct,
  getTestIdForMerchandiseProductCurrencyCode,
  getTestIdForMerchandiseProductMaximalTemperature,
} from 'tests/e2e/test_ids'
import Textarea from 'components/textarea'
import Select from 'components/select'
import useStaticLocales from 'views/locales/hooks/use_static_locales'
import { BookingFormInput } from 'views/booking/components/form/types'
import { stringToFloat, stringToInt } from 'services/helpers/values'

interface MerchandiseTotalDetailsProps {
  isFieldDisabled: (fieldName: BookingFieldNames) => boolean
  withMultipleDetails: boolean
}

const MerchandiseTotalDetails: FC<MerchandiseTotalDetailsProps> = ({
  isFieldDisabled,
  withMultipleDetails,
}) => {
  const { s } = useStaticLocales()
  const { t } = useTranslation()
  const {
    control,
    formState: { errors },
  } = useFormContext<BookingFormInput>()

  const {
    fields: products,
    remove: removeProduct,
    append: appendProduct,
  } = useFieldArray({
    control,
    name: 'merchandise.content.total.products',
  })

  const fromStaticToSelectOptions = (path: string) =>
    Object.entries(s(path)).map(([key, value]) => ({ value: key, label: value }))

  return (
    <>
      {products.map((product, index: number) => (
        <Form.Insert
          key={product.id}
          subtitle={`${t('bookings.merchandise.merchandiseDetails')} ${
            withMultipleDetails ? `#${index + 1}` : ''
          }`.trim()}
        >
          {index !== 0 && (
            <StyledRemoveMerchandiseItemWrapper>
              <Button
                variant='icon'
                icon='trash'
                disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                onClick={() => removeProduct(index)}
              />
            </StyledRemoveMerchandiseItemWrapper>
          )}
          <Grid columns={3}>
            <Grid.Row testId={getTestIdForMerchandiseProduct(index)}>
              <Grid.Column>
                <Controller
                  name={`merchandise.content.total.products.${index}.weight`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      type='number'
                      label={t('bookings.merchandise.weight')}
                      placeholder='kg'
                      min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT}
                      step='0.01'
                      name={`merchandiseWeight-${index}`}
                      value={field.value}
                      error={errors.merchandise?.content?.total?.products?.[index]?.weight?.message}
                      onChange={({ target: { value } }) => field.onChange(stringToFloat(value))}
                      disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                    />
                  )}
                />
              </Grid.Column>
              <Grid.Column>
                <Controller
                  name={`merchandise.content.total.products.${index}.volume`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      type='number'
                      label={t('bookings.merchandise.volume')}
                      placeholder='cbm'
                      step='0.01'
                      min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.VOLUME}
                      name={`merchandiseVolume-${index}`}
                      error={errors.merchandise?.content?.total?.products?.[index]?.volume?.message}
                      value={field.value}
                      onChange={({ target: { value } }) => field.onChange(stringToFloat(value))}
                      disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                    />
                  )}
                />
              </Grid.Column>
              <Grid.Column>
                <Controller
                  name={`merchandise.content.total.products.${index}.packageNumber`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      label={t('bookings.merchandise.numberOfPackages')}
                      placeholder={t('bookings.merchandise.numberOfPackages')}
                      type='number'
                      step='1'
                      min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.NUMBER_OF_PACKAGES}
                      name={`merchandisePackageNumber-${index}`}
                      error={
                        errors.merchandise?.content?.total?.products?.[index]?.packageNumber
                          ?.message
                      }
                      value={field.value}
                      onChange={({ target: { value } }) => field.onChange(stringToInt(value))}
                      disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                    />
                  )}
                />
              </Grid.Column>
              <Grid.Column>
                <Controller
                  name={`merchandise.content.total.products.${index}.productDescription`}
                  control={control}
                  render={({ field }) => (
                    <Textarea
                      label={t('bookings.merchandise.productDescription')}
                      placeholder={t('bookings.merchandise.yourDescription')}
                      name={`merchandiseDescription-${index}`}
                      rows={4}
                      compact
                      value={field.value}
                      error={
                        errors.merchandise?.content?.total?.products?.[index]?.productDescription
                          ?.message
                      }
                      onChange={({ target: { value } }) => field.onChange(value)}
                      disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                    />
                  )}
                />
              </Grid.Column>
              <Grid.Column>
                <Controller
                  name={`merchandise.content.total.products.${index}.commercialValue.amount`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      type='number'
                      step='0.01'
                      min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.AMOUNT}
                      label={t('bookings.merchandise.commercialValue')}
                      placeholder={t('bookings.merchandise.amount')}
                      name={`merchandiseAmount-${index}`}
                      value={field.value}
                      error={
                        errors.merchandise?.content?.total?.products?.[index]?.commercialValue
                          ?.amount?.message
                      }
                      onChange={({ target: { value } }) => field.onChange(stringToFloat(value))}
                      disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                    />
                  )}
                />
                <Controller
                  name={`merchandise.content.total.products.${index}.commercialValue.currencyCode`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder={t('bookings.merchandise.currency')}
                      options={fromStaticToSelectOptions('currencies')}
                      name={`merchandiseCurrencyCode-${index}`}
                      value={field.value}
                      error={
                        errors.merchandise?.content?.total?.products?.[index]?.commercialValue
                          ?.currencyCode?.message
                      }
                      isClearable
                      onChange={({ value }) => field.onChange(value)}
                      testId={getTestIdForMerchandiseProductCurrencyCode(index)}
                      isDisabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                    />
                  )}
                />
              </Grid.Column>
              <Grid.Column>
                <Controller
                  name={`merchandise.content.total.products.${index}.controlledTemperatures.min`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      label={t('bookings.merchandise.controlledTemperatures.title')}
                      placeholder={t('bookings.merchandise.controlledTemperatures.minimal')}
                      type='number'
                      step='0.01'
                      testId={TEST_ID_BOOKING_FORM_MINIMAL_TEMPERATURE}
                      name={`merchandiseTemperatureMinimal-${index}`}
                      value={field.value}
                      error={
                        errors.merchandise?.content?.total?.products?.[index]
                          ?.controlledTemperatures?.min?.message
                      }
                      disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                      onChange={({ target: { value } }) => field.onChange(stringToFloat(value))}
                    />
                  )}
                />
                <Controller
                  name={`merchandise.content.total.products.${index}.controlledTemperatures.max`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder={t('bookings.merchandise.controlledTemperatures.maximal')}
                      type='number'
                      step='0.01'
                      testId={getTestIdForMerchandiseProductMaximalTemperature(index)}
                      name={`merchandiseTemperatureMaximal-${index}`}
                      value={field.value}
                      error={
                        errors.merchandise?.content?.total?.products?.[index]
                          ?.controlledTemperatures?.max?.message
                      }
                      disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                      onChange={({ target: { value } }) => field.onChange(stringToFloat(value))}
                    />
                  )}
                />
              </Grid.Column>
              <Grid.Column>
                <Controller
                  name={`merchandise.content.total.products.${index}.hazardousGoods.hazardousClass`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      label={t('bookings.merchandise.product.hazardousGoods.hazardousClass')}
                      placeholder={t('actions.select')}
                      options={fromStaticToSelectOptions('hazardousGoods')}
                      name={`merchandiseHazardousClass-${index}`}
                      value={field.value}
                      error={
                        errors.merchandise?.content?.total?.products?.[index]?.hazardousGoods
                          ?.hazardousClass?.message
                      }
                      onChange={({ value }) => field.onChange(value)}
                      isDisabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                      isClearable
                    />
                  )}
                />
              </Grid.Column>
              <Grid.Column>
                <Controller
                  name={`merchandise.content.total.products.${index}.hazardousGoods.packingGroup`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      label={t('bookings.merchandise.product.hazardousGoods.packingGroup')}
                      placeholder={t('actions.select')}
                      options={fromStaticToSelectOptions('packingGroups')}
                      name={`merchandiseHazardousPackingGroup-${index}`}
                      value={field.value}
                      error={
                        errors.merchandise?.content?.total?.products?.[index]?.hazardousGoods
                          ?.packingGroup?.message
                      }
                      onChange={({ value }) => field.onChange(value)}
                      isDisabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                      isClearable
                    />
                  )}
                />
              </Grid.Column>
              <Grid.Column>
                <StyledHazardousGoodsWrapper>
                  <Controller
                    name={`merchandise.content.total.products.${index}.hazardousGoods.unNumber`}
                    control={control}
                    render={({ field }) => (
                      <StyledHazardousGoodsItemInput
                        label={t('bookings.merchandise.product.hazardousGoods.unNumber')}
                        placeholder={t('bookings.merchandise.product.hazardousGoods.unNumber')}
                        name={`merchandiseHazardousUnNumber-${index}`}
                        value={field.value}
                        error={
                          errors.merchandise?.content?.total?.products?.[index]?.hazardousGoods
                            ?.unNumber?.message
                        }
                        disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                        onChange={({ target: { value } }) => field.onChange(value)}
                      />
                    )}
                  />
                  <Controller
                    name={`merchandise.content.total.products.${index}.hazardousGoods.weight`}
                    control={control}
                    render={({ field }) => (
                      <StyledHazardousGoodsItemInput
                        label={t('bookings.merchandise.product.hazardousGoods.weight')}
                        placeholder='kg'
                        type='number'
                        step='0.01'
                        min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT}
                        name={`merchandiseHazardousWeight-${index}`}
                        value={field.value}
                        error={
                          errors.merchandise?.content?.total?.products?.[index]?.hazardousGoods
                            ?.weight?.message
                        }
                        disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                        onChange={({ target: { value } }) => field.onChange(stringToFloat(value))}
                      />
                    )}
                  />
                </StyledHazardousGoodsWrapper>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form.Insert>
      ))}
      {withMultipleDetails && (
        <StyledAddMerchandiseDetailsWrapper>
          <StyledAddMerchandiseItemWrapper>
            <Button
              disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
              onClick={() => appendProduct({})}
              icon='plus_outline'
              variant='dashed'
              text={`${t('actions.addNew', { context: 'plural' })} ${t(
                'bookings.merchandise.merchandiseDetails'
              ).toLowerCase()}`}
            />
          </StyledAddMerchandiseItemWrapper>
        </StyledAddMerchandiseDetailsWrapper>
      )}
    </>
  )
}

export default MerchandiseTotalDetails
