import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useFormContext, Controller } from 'react-hook-form'

import useAppDispatch from 'services/hooks/use_app_dispatch'
import { fetchAssignableUsers } from 'views/booking/slices/booking_slice'
import { addNotification } from 'views/notifications/slice'
import Grid from 'components/grid'
import Form from 'components/form'
import InputTags from 'components/input_tags'
import NotifMessage from 'components/notif_message'
import { BookingFieldNames } from 'views/booking/slices/types'
import { BookingFormInput } from 'views/booking/components/form/types'
import { BOOKING_FIELD_NAMES } from 'constants/bookings'

const KeyContacts = ({
  isFieldDisabled,
}: {
  isFieldDisabled: (fieldName: BookingFieldNames) => boolean
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { control, watch } = useFormContext<BookingFormInput>()

  const disabled = isFieldDisabled(BOOKING_FIELD_NAMES.KEY_CONTACTS)
  const forwarder = watch('parties.forwarder')

  const [assignableUsers, setAssignableUsers] = useState([])
  useEffect(() => {
    if (disabled) return
    dispatch(fetchAssignableUsers({ forwarderId: forwarder?.value }))
      .unwrap()
      .then((users) => {
        setAssignableUsers(users)
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      })
    // eslint-disable-next-line
  }, [forwarder, disabled])

  return (
    <Form.Group title={t('bookings.keyContacts.title')}>
      <Grid columns={1} columnsTablet={1}>
        <Grid.Row>
          <Grid.Column>
            <Controller
              name='keyContacts'
              control={control}
              render={({ field }) => (
                <InputTags
                  label={t('bookings.keyContacts.emailAddresses')}
                  name={field.name}
                  onChange={({ tags }) => {
                    field.onChange(tags)
                  }}
                  value={field.value}
                  options={assignableUsers.map(({ email }) => email)}
                  pattern='email'
                  disabled={disabled}
                />
              )}
            />

            <NotifMessage type='tip' text={t('bookings.tooltips.keyContacts')} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form.Group>
  )
}

export default KeyContacts
