import React from 'react'

import Icon from 'components/icon'
import StyledFavorite from 'components/favorite/style'

import { TEST_ID_FAVORITE_STAR_ICON } from 'tests/e2e/test_ids'

interface FavoriteProps {
  active?: boolean
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  big?: boolean
}

const Favorite: React.FC<FavoriteProps> = ({ active = false, onClick, big = false }) => (
  <StyledFavorite $active={active} onClick={onClick} $big={big}>
    <Icon name='star' testId={TEST_ID_FAVORITE_STAR_ICON} />
  </StyledFavorite>
)

export default Favorite
