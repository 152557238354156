import { useSelector } from 'react-redux'

import useStatus, { UseStatus } from 'services/api/hooks/use_status'

import {
  selectShipmentsFromSource,
  selectShipmentsStatus,
  selectShipmentsTotalCount,
} from 'features/shipments/store/shipment_slice'
import { SearchSource } from 'constants/shipment_source'
import { Shipment } from 'features/shipments/types/shipment'

const useShipments = (source: SearchSource): [Shipment[], UseStatus, number] => {
  const shipments = useSelector(selectShipmentsFromSource(source))
  const status = useStatus(useSelector(selectShipmentsStatus))
  const totalCount = useSelector(selectShipmentsTotalCount) || 0
  return [shipments, status, totalCount]
}

export default useShipments
