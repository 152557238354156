import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import useActiveValues from 'features/shipments/hooks/use_active_values'

import { removeAll, saveActiveFilters } from 'features/shipments/store/shipment_slice'
import { Category } from 'services/hooks/use_category'

const useActiveFilters = ({ categories }: { categories: Category[] }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { saveActiveValues } = useActiveValues({ categories })

  const activeFilters = categories.reduce((acc, c) => {
    Object.assign(acc, c.queryParams)
    return acc
  }, {})

  const save = () => {
    dispatch(removeAll())
    dispatch(saveActiveFilters({ activeFilters }))
    saveActiveValues()
  }
  const goToShipments = () => navigate('/shipments')

  return {
    save,
    goToShipments,
  }
}

export default useActiveFilters
