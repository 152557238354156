import React, { FC, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { Controller, useFormContext } from 'react-hook-form'

import { TRANSPORT_TYPE_AIR, TRANSPORT_TYPE_SEA } from 'constants/shipments'

import {
  BOOKING_FIELD_NAMES,
  MERCHANDISE_FCL,
  MERCHANDISE_LCL,
  MERCHANDISE_TYPE_CONTAINER,
  MERCHANDISE_TYPE_PACKAGE,
  MERCHANDISE_TYPE_PACKAGE_TOTAL,
  MERCHANDISE_TYPE_TOTAL,
} from 'constants/bookings'

import {
  TEST_ID_BOOKING_FORM_MERCHANDISE_TYPE_LCL,
  TEST_ID_BOOKING_FORM_SHIPPING_MODE_FCL,
  TEST_ID_BOOKING_FORM_SHIPPING_MODE_LCL,
} from 'tests/e2e/test_ids'

import {
  StyledCheckboxContainer,
  StyledMerchandiseTypeSwitchWrapper,
  StyledMerchandiseTypeSwitchInformationSpan,
} from 'views/booking/components/form/style'

import Form from 'components/form'
import SwitchInput from 'components/switch_input'
import CheckboxPicto from 'components/checkbox_picto'

import useOrganizationCan from 'views/iam/hooks/use_organization_can'

import { WITH_BOOKING_OLD_WORKFLOW } from 'constants/organization_features'

import MerchandiseTotal from 'views/booking/components/form/merchandise/merchandise_total'
import MerchandiseContainer from 'views/booking/components/form/merchandise/merchandise_container'
import { BookingFieldNames } from 'views/booking/slices/types'
import { BookingFormInput } from 'views/booking/components/form/types'
import MerchandisePackageTotal from 'views/booking/components/form/merchandise/merchandise_package_total'
import MerchandisePackage from 'views/booking/components/form/merchandise/merchandise_package'
import MerchandisePackageLegacy from 'views/booking/components/form/merchandise/merchandise_package_legacy'

interface MerchandiseProps {
  isFieldDisabled: (fieldName: BookingFieldNames) => boolean
}

const Merchandise: FC<MerchandiseProps> = ({ isFieldDisabled }) => {
  const { t } = useTranslation()
  const { features } = useOrganizationCan()
  const { control, watch, setValue } = useFormContext<BookingFormInput>()

  const currentTransportType = watch('transportType')
  const currentShippingMode = watch('merchandise.shippingMode')
  const currentMerchandiseType = watch('merchandise.merchandiseType')

  useEffect(() => {
    if (
      currentTransportType !== TRANSPORT_TYPE_AIR ||
      [MERCHANDISE_TYPE_PACKAGE, MERCHANDISE_TYPE_PACKAGE_TOTAL].includes(currentMerchandiseType)
    )
      return

    setValue('merchandise.shippingMode', MERCHANDISE_LCL)
    setValue('merchandise.merchandiseType', MERCHANDISE_TYPE_PACKAGE)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTransportType])

  return (
    <>
      <Form.Group title={t('bookings.merchandise.title')}>
        {currentTransportType === TRANSPORT_TYPE_SEA && (
          <StyledCheckboxContainer>
            <Controller
              name='merchandise.shippingMode'
              control={control}
              render={({ field }) => (
                <>
                  <CheckboxPicto
                    icon='container'
                    onChange={() => {
                      field.onChange(MERCHANDISE_FCL)
                      setValue('merchandise.merchandiseType', MERCHANDISE_TYPE_TOTAL)
                    }}
                    text='FCL'
                    type='radio'
                    name='merchandise'
                    checked={field.value === MERCHANDISE_FCL}
                    condensed
                    disabled={isFieldDisabled(BOOKING_FIELD_NAMES.SHIPPING_MODE)}
                    testId={TEST_ID_BOOKING_FORM_SHIPPING_MODE_FCL}
                  />
                  <CheckboxPicto
                    icon='shipment_box_outline'
                    onChange={() => {
                      field.onChange(MERCHANDISE_LCL)
                      setValue('merchandise.merchandiseType', MERCHANDISE_TYPE_PACKAGE_TOTAL)
                    }}
                    name='merchandise'
                    type='radio'
                    text='LCL'
                    checked={field.value === MERCHANDISE_LCL}
                    condensed
                    disabled={isFieldDisabled(BOOKING_FIELD_NAMES.SHIPPING_MODE)}
                    testId={TEST_ID_BOOKING_FORM_SHIPPING_MODE_LCL}
                  />
                </>
              )}
            />
          </StyledCheckboxContainer>
        )}
        {currentTransportType === TRANSPORT_TYPE_SEA &&
          currentShippingMode === MERCHANDISE_FCL &&
          !features(WITH_BOOKING_OLD_WORKFLOW) && (
            <StyledMerchandiseTypeSwitchWrapper>
              <StyledMerchandiseTypeSwitchInformationSpan>
                {t('bookings.merchandise.specifyMerchandiseDetails')}:
              </StyledMerchandiseTypeSwitchInformationSpan>
              <Controller
                name='merchandise.merchandiseType'
                control={control}
                render={({ field }) => (
                  <SwitchInput
                    leftLabel={t('bookings.merchandise.perContainer')}
                    onClick={() => {
                      const newMerchandiseType =
                        field.value === MERCHANDISE_TYPE_TOTAL
                          ? MERCHANDISE_TYPE_CONTAINER
                          : MERCHANDISE_TYPE_TOTAL

                      field.onChange(newMerchandiseType)
                    }}
                    rightLabel={t('bookings.merchandise.globally')}
                    rightOptionChecked={currentMerchandiseType === MERCHANDISE_TYPE_TOTAL}
                    disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_TYPE)}
                  />
                )}
              />
            </StyledMerchandiseTypeSwitchWrapper>
          )}
        {currentShippingMode === MERCHANDISE_LCL && (
          <StyledMerchandiseTypeSwitchWrapper>
            <StyledMerchandiseTypeSwitchInformationSpan>
              {t('bookings.merchandise.specifyMerchandiseDetails')}:
            </StyledMerchandiseTypeSwitchInformationSpan>
            <Controller
              name='merchandise.merchandiseType'
              control={control}
              render={({ field }) => (
                <SwitchInput
                  leftLabel={t('bookings.merchandise.perPackage')}
                  onClick={() => {
                    const newMerchandiseType =
                      field.value === MERCHANDISE_TYPE_PACKAGE_TOTAL
                        ? MERCHANDISE_TYPE_PACKAGE
                        : MERCHANDISE_TYPE_PACKAGE_TOTAL

                    field.onChange(newMerchandiseType)
                  }}
                  rightLabel={t('bookings.merchandise.globally')}
                  rightOptionChecked={currentMerchandiseType === MERCHANDISE_TYPE_PACKAGE_TOTAL}
                  testId={TEST_ID_BOOKING_FORM_MERCHANDISE_TYPE_LCL}
                  disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_TYPE)}
                />
              )}
            />
          </StyledMerchandiseTypeSwitchWrapper>
        )}
        {currentShippingMode === MERCHANDISE_FCL &&
          currentMerchandiseType === MERCHANDISE_TYPE_TOTAL && (
            <MerchandiseTotal isFieldDisabled={isFieldDisabled} />
          )}
        {currentShippingMode === MERCHANDISE_FCL &&
          currentMerchandiseType === MERCHANDISE_TYPE_CONTAINER && (
            <MerchandiseContainer isFieldDisabled={isFieldDisabled} />
          )}
        {currentShippingMode === MERCHANDISE_LCL &&
          currentMerchandiseType === MERCHANDISE_TYPE_PACKAGE && (
            <>
              {features(WITH_BOOKING_OLD_WORKFLOW) ? (
                <MerchandisePackageLegacy isFieldDisabled={isFieldDisabled} />
              ) : (
                <MerchandisePackage isFieldDisabled={isFieldDisabled} />
              )}
            </>
          )}
        {currentShippingMode === MERCHANDISE_LCL &&
          currentMerchandiseType === MERCHANDISE_TYPE_PACKAGE_TOTAL && (
            <MerchandisePackageTotal isFieldDisabled={isFieldDisabled} />
          )}
      </Form.Group>
    </>
  )
}

export default Merchandise
