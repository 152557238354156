import { useCallback, useEffect, useState } from 'react'

import useFilters from 'features/shipments/hooks/use_filters'
import useActiveFilters from 'features/shipments/hooks/use_active_filters'
import useCategories from 'features/shipments/hooks/use_categories'

const useBookingGoToShipments = () => {
  const filters = useFilters()
  const [shouldGoToShipments, setShouldGoToShipments] = useState(false)
  const { arrayOfCategories } = useCategories({ filters })
  const { save: saveActiveFilters, goToShipments: goToShipmentsAndApplyFilters } = useActiveFilters(
    {
      categories: arrayOfCategories,
    }
  )

  useEffect(() => {
    if (shouldGoToShipments) {
      saveActiveFilters()
      goToShipmentsAndApplyFilters()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldGoToShipments])

  const goToShipments = useCallback(
    (bookingToken, bookingReference) => {
      filters.bookingFilter.setValue({ label: bookingReference, value: bookingToken })
      setShouldGoToShipments(true)
    },
    [filters]
  )

  return { goToShipments }
}

export default useBookingGoToShipments
