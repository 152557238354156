import React from 'react'

import ContentLoader from 'react-content-loader'

import S from 'views/trusted_routes/components/alternative_routes/alternatives_fetcher/style'

const BlockAlternativesSkeleton: React.FC = () => (
  <S.OuterContainer>
    <S.Container>
      <ContentLoader
        speed={2}
        viewBox='0 0 600 45'
        backgroundColor='#f3f3f3'
        foregroundColor='#ecebeb'
      >
        <rect x='0' y='0' rx='12' ry='12' width='600' height='45' />
      </ContentLoader>
    </S.Container>
  </S.OuterContainer>
)

export default BlockAlternativesSkeleton
