import { createContext } from 'react'

import type { Shipment } from 'features/shipments/types/shipment'

interface ShipmentContextValue {
  shipment: Shipment
}
const ShipmentContext = createContext<ShipmentContextValue>(undefined!)

export default ShipmentContext
