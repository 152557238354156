import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FilterTabContext } from 'contexts/filters'

import { isEmptyArray, isInputEmpty } from 'services/helpers/values'
import iconsMapping from 'services/helpers/icons_mapping'
import useShipmentSelect from 'services/hooks/use_shipment_select'
import getRoutingHelpers from 'features/shipments/helpers/routing'
import useForm from 'services/hooks/use_form'
import useTracker from 'services/analytics/hooks/use_tracker'
import useTags, { Tag as TagType } from 'features/shipments/hooks/use_tags'
import useAppDispatch from 'services/hooks/use_app_dispatch'

import {
  TRANSPORT_TYPE_AIR,
  TRANSPORT_TYPE_PARCEL,
  TRANSPORT_TYPE_RAIL,
  TRANSPORT_TYPE_ROAD,
  TRANSPORT_TYPE_SEA,
} from 'constants/shipments'
import { FLAG_REASON_READ, ORDER_READ, ORGANIZATION_READ } from 'constants/permissions'

import MultiSelect from 'components/multiselect'
import { OptionProps, SelectValue } from 'components/select'
import Tag from 'components/tag'
import Input from 'components/input'
import InputCheckbox from 'components/input_checkbox'
import CheckboxPicto from 'components/checkbox_picto'
import InputDatepicker from 'components/input_datepicker'
import {
  StyledShipmentFilters,
  StyledShipmentFiltersCheckPictos,
  StyledShipmentFiltersDays,
  StyledShipmentFiltersTabs,
  StyledShipmentFiltersTag,
  StyledShipmentFiltersTags,
  StyledShipmentFilterTemplateInput,
} from 'features/shipments/components/shipment_filters/style'
import StyledCustomFilters from 'components/shipment_filters_custom/style'
import useTemplates from 'components/shipment_filters_custom/hooks/use_templates'
import FiltersSidebar from 'components/filters_sidebar'
import Tabs, { Tab } from 'components/tabs'
import useCategories from 'features/shipments/hooks/use_categories'
import useFilters from 'features/shipments/hooks/use_filters'
import useUserCan from 'views/iam/hooks/use_user_can'
import useStaticLocales from 'views/locales/hooks/use_static_locales'
import { addNotification } from 'views/notifications/slice'
import { fetchUsersOptions } from 'views/select_options/slice'
import SearchSelect from 'components/search_select'
import useActiveValues from 'features/shipments/hooks/use_active_values'
import useActiveFilters from 'features/shipments/hooks/use_active_filters'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import { WITH_PARCEL_TRACKING } from 'constants/organization_features'

const ShipmentFilters = () => {
  const { t } = useTranslation()
  const { s } = useStaticLocales()
  const can = useUserCan()
  const dispatch = useAppDispatch()
  const { track } = useTracker()
  const { features } = useOrganizationCan()

  const { currentTab, switchToTemplatesTab } = useContext(FilterTabContext)
  const filters = useFilters()
  const {
    organizationFilter,
    reportedReasonFilter,
    referenceFilter,
    etaDiffMinFilter,
    etaDiffMaxFilter,
    arrivalForecastEarlyFilter,
    arrivalForecastOnTimeFilter,
    arrivalForecastDelayedFilter,
    statusActiveFilter,
    statusPastFilter,
    statusExpiredFilter,
    transportModeSeaFilter,
    transportModeAirFilter,
    transportModeRailFilter,
    transportModeRoadFilter,
    transportModeParcelFilter,
    routingPickupFilter,
    routingPolFilter,
    routingPodFilter,
    routingDeliveryFilter,
    vesselFilter,
    ongoingAlertFilter,
    alertHistoryFilter,
    partiesForwarderFilter,
    partiesShipperFilter,
    partiesConsignorFilter,
    partiesConsigneeFilter,
    partiesShippingLineFilter,
    partiesAirlineFilter,
    partiesCarrierFilter,
    transportationDatePickupFilter,
    transportationDatePolFilter,
    transportationDatePodFilter,
    transportationDateDeliveryFilter,
    creationDateFilter,
    userVisibilityFilter,
  } = filters

  const { categories, arrayOfCategories } = useCategories({ filters })
  const {
    adminCategory,
    referenceCategory,
    etaDiffCategory,
    statusCategory,
    arrivalForecastCategory,
    transportModeCategory,
    routingCategory,
    partiesCategory,
    transportationDateCategory,
    vesselCategory,
    ongoingAlertCategory,
    alertHistoryCategory,
  } = categories

  const { apply, reset, isEdited, queryParams } = useForm({
    categories: arrayOfCategories,
    onApply: () => {
      saveActiveFilters()
      setShouldSetTags(true)
      track('Shipment list / filter', {
        categories: arrayOfCategories
          .filter((category) => category.isEdited)
          .map(({ name }) => name),
      })
    },
  })

  const [shouldApplyTemplate, setShouldApplyTemplate] = useState(false)
  const [filterOnOngoingAlertsOnly, setFilterOnOngoingAlertsOnly] = useState(true)

  useEffect(() => {
    if (shouldApplyTemplate) {
      setShouldApplyTemplate(false)
      apply()
    }
  }, [apply, shouldApplyTemplate])

  const {
    name: templateName,
    setName: setTemplateName,
    create: createTemplate,
  } = useTemplates({
    categories: arrayOfCategories,
    onApply: () => {
      setShouldApplyTemplate(true)
    },
  })

  const [activeTags, setActiveTags] = useState<TagType[]>([])

  const { tags } = useTags({ categories: arrayOfCategories })

  const [shouldSetTags, setShouldSetTags] = useState(false)

  const { setActiveValues, activeValuesComputed, activeValuesStored } = useActiveValues({
    categories: arrayOfCategories,
  })

  const { save: saveActiveFilters } = useActiveFilters({ categories: arrayOfCategories })

  const initAlertFilter = () => {
    if (activeValuesStored.find(({ name }) => name === alertHistoryFilter.name)?.value?.length) {
      setFilterOnOngoingAlertsOnly(false)
    }
  }

  // We come back on the page, or if we search from the search bar
  // Apply values
  useEffect(() => {
    if (activeValuesComputed !== activeValuesStored) {
      setActiveValues()
      setShouldSetTags(true)
      initAlertFilter()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeValuesStored])

  // Compute tags with active values just set
  useEffect(() => {
    if (shouldSetTags) {
      setActiveTags(tags)
      setShouldSetTags(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSetTags])

  const { toIndexedSelectValue, fetchOptions, fetchAllOptions, fetchedOptionsFormat } =
    useShipmentSelect({
      queryParams,
    })
  const {
    fetchRoutingOptions,
    fetchAllRoutingOptions,
    toIndexedSelectRoutingValue,
    fetchedRoutingOptionsFormat,
  } = getRoutingHelpers({ queryParams })

  const onApply = useCallback(() => apply(), [apply])

  const [reportedReasonOptions, setReportedReasonOptions] = useState<OptionProps[]>([])
  const [alertTypeOptions, setAlertTypeOptions] = useState<SelectValue[]>([])

  const onTabChange = () => switchToTemplatesTab()

  const canSeeOrganizationFilter = can(ORGANIZATION_READ)
  const canSeeReportedReasonFilter = can(FLAG_REASON_READ)
  const canSeeAdminFeatures = can(ORDER_READ)

  const canSeeAdminCategory =
    canSeeOrganizationFilter || canSeeReportedReasonFilter || canSeeAdminFeatures

  const onAlertFilterTypeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterOnOngoingAlertsOnly(e.target.checked)
    toggleAlertFilters()
  }

  const toggleAlertFilters = () => {
    if (filterOnOngoingAlertsOnly) {
      ongoingAlertFilter.reset()
    } else {
      alertHistoryFilter.reset()
    }
  }

  return (
    <FiltersSidebar
      onClear={() => {
        reset()
        apply()
        setFilterOnOngoingAlertsOnly(true)
      }}
      onApply={onApply}
      isClearDisabled={!isEdited && isEmptyArray(activeTags)}
      isApplyDisabled={!isEdited && isEmptyArray(activeTags)}
      header={
        <StyledShipmentFilters>
          <StyledShipmentFiltersTabs as={Tabs} defaultTabId={currentTab} onTabChange={onTabChange}>
            <Tab
              id='custom-tab'
              label={t('shipments.filters.custom')}
              key='shipment-filters-custom'
            >
              <StyledCustomFilters>
                <StyledShipmentFiltersTags>
                  {activeTags.map(({ value, onDelete }: TagType) => (
                    <StyledShipmentFiltersTag
                      as={Tag}
                      key={`filter-tag-${value}`}
                      text={`${value}`}
                      deletable
                      onDelete={() => {
                        onDelete()
                        apply()
                      }}
                    />
                  ))}
                </StyledShipmentFiltersTags>
                <StyledShipmentFilterTemplateInput>
                  <Input
                    type='text'
                    placeholder={t('shipments.filterTemplates.inputPlaceholder')}
                    name='filter_template_name'
                    value={templateName}
                    onChange={({ target: { value } }) => setTemplateName(value)}
                    button={{
                      text: t('actions.save'),
                      icon: 'save_outline',
                      onClick: () => createTemplate().then(() => switchToTemplatesTab()),
                      disabled: isInputEmpty(templateName) || activeTags.length <= 0,
                    }}
                  />
                </StyledShipmentFilterTemplateInput>
              </StyledCustomFilters>
            </Tab>
            <Tab
              id='templates-tab'
              label={t('shipments.filters.saved')}
              key='shipment-filters-templates'
            />
          </StyledShipmentFiltersTabs>
        </StyledShipmentFilters>
      }
    >
      {canSeeAdminCategory && (
        <FiltersSidebar.Section
          title={t('shipments.filters.admin')}
          defaultOpened={adminCategory.isEdited}
        >
          {canSeeOrganizationFilter && (
            <MultiSelect
              label={t('shipments.parties.owner')}
              name={organizationFilter.name}
              onChange={organizationFilter.onChange}
              isClearable
              isSearchable
              async
              fetch={fetchOptions('organization')}
              fetchOnFocus={fetchAllOptions('organization')}
              fetchedOptionsFormat={fetchedOptionsFormat}
              value={toIndexedSelectValue(organizationFilter)}
            />
          )}
          {canSeeReportedReasonFilter && (
            <MultiSelect
              label={t('shipments.reportedReason')}
              name={reportedReasonFilter.name}
              onChange={reportedReasonFilter.onChange}
              isClearable
              isSearchable
              onFocus={() => {
                setReportedReasonOptions([])
                dispatch(fetchAllOptions('flag_reason_ids')())
                  .unwrap()
                  .then((ids: number[]) => {
                    const options = Object.entries(s('flagReasons'))
                      .map(([value, label]) => ({
                        label,
                        value: parseInt(value, 10),
                      }))
                      .filter(({ value }) => ids.includes(value))
                    setReportedReasonOptions(options)
                  })
                  .catch(() =>
                    dispatch(
                      addNotification({
                        type: 'alert',
                        title: t('errors.notification.title'),
                        text: t('errors.notification.content'),
                      })
                    )
                  )
              }}
              options={reportedReasonOptions}
              value={toIndexedSelectValue(reportedReasonFilter)}
            />
          )}
          {canSeeAdminFeatures && (
            <>
              <InputDatepicker
                withPortal
                label={t('shipments.creationDate')}
                name={creationDateFilter.name}
                range
                onChange={creationDateFilter.onChange}
                startDate={creationDateFilter.value?.start}
                endDate={creationDateFilter.value?.end}
                onToggleOpen={(opened) => {
                  if (!opened && !creationDateFilter.isPresent) {
                    creationDateFilter.reset()
                  }
                }}
              />
              <SearchSelect
                label={t('shipments.userVisibility')}
                name={userVisibilityFilter.name}
                onChange={userVisibilityFilter.onChange}
                isClearable
                isSearchable
                async
                action={fetchUsersOptions}
                fetchOnFocus={() => fetchUsersOptions({ search: null })}
                value={toIndexedSelectValue(userVisibilityFilter)}
              />
            </>
          )}
        </FiltersSidebar.Section>
      )}
      <FiltersSidebar.Section
        title={t('shipments.filters.reference')}
        defaultOpened={referenceCategory.isEdited}
      >
        <Input
          type='text'
          placeholder='ie. SOCRTTY7789'
          name={referenceFilter.name}
          value={referenceFilter.value}
          onChange={referenceFilter.onChange}
          testId='test'
        />
      </FiltersSidebar.Section>
      <FiltersSidebar.Section
        title={t('shipments.filters.status')}
        defaultOpened={statusCategory.isEdited}
      >
        <InputCheckbox
          id={statusActiveFilter.name}
          name={statusActiveFilter.name}
          text={t('shipments.filters.active')}
          checked={statusActiveFilter.value}
          onChange={statusActiveFilter.onChange}
        />
        <InputCheckbox
          id={statusPastFilter.name}
          name={statusPastFilter.name}
          text={t('shipments.filters.past')}
          checked={statusPastFilter.value}
          onChange={statusPastFilter.onChange}
        />
        <InputCheckbox
          id={statusExpiredFilter.name}
          name={statusExpiredFilter.name}
          text={t('shipments.filters.expired')}
          checked={statusExpiredFilter.value}
          onChange={statusExpiredFilter.onChange}
        />
      </FiltersSidebar.Section>

      <FiltersSidebar.Section
        title={t('shipments.filters.transportMode')}
        defaultOpened={transportModeCategory.isEdited}
      >
        <StyledShipmentFiltersCheckPictos>
          <CheckboxPicto
            icon={iconsMapping(TRANSPORT_TYPE_RAIL, 'transportation')}
            id={transportModeRailFilter.name}
            name={transportModeRailFilter.name}
            text={t('shipments.filters.rail')}
            checked={!!transportModeRailFilter.value}
            onChange={transportModeRailFilter.onChange}
          />
          <CheckboxPicto
            icon={iconsMapping(TRANSPORT_TYPE_SEA, 'transportation')}
            id={transportModeSeaFilter.name}
            name={transportModeSeaFilter.name}
            text={t('shipments.filters.sea')}
            checked={!!transportModeSeaFilter.value}
            onChange={transportModeSeaFilter.onChange}
          />
          <CheckboxPicto
            icon={iconsMapping(TRANSPORT_TYPE_AIR, 'transportation')}
            id={transportModeAirFilter.name}
            name={transportModeAirFilter.name}
            text={t('shipments.filters.air')}
            checked={!!transportModeAirFilter.value}
            onChange={transportModeAirFilter.onChange}
          />
          <CheckboxPicto
            icon={iconsMapping(TRANSPORT_TYPE_ROAD, 'transportation')}
            id={transportModeRoadFilter.name}
            name={transportModeRoadFilter.name}
            text={t('shipments.filters.road')}
            checked={!!transportModeRoadFilter.value}
            onChange={transportModeRoadFilter.onChange}
          />
          {features(WITH_PARCEL_TRACKING) && (
            <CheckboxPicto
              icon={iconsMapping(TRANSPORT_TYPE_PARCEL, 'transportation')}
              id={transportModeParcelFilter.name}
              name={transportModeParcelFilter.name}
              text={t('shipments.filters.parcel')}
              checked={!!transportModeParcelFilter.value}
              onChange={transportModeParcelFilter.onChange}
            />
          )}
        </StyledShipmentFiltersCheckPictos>
      </FiltersSidebar.Section>

      <FiltersSidebar.Section
        title={t('shipments.filters.routing')}
        defaultOpened={routingCategory.isEdited}
      >
        <MultiSelect
          onChange={routingPickupFilter.onChange}
          isClearable
          isSearchable
          async
          label={t('shipments.routing.pickup')}
          name={routingPickupFilter.name}
          fetch={fetchRoutingOptions('pickup')}
          fetchOnFocus={fetchAllRoutingOptions('pickup')}
          fetchedOptionsFormat={fetchedRoutingOptionsFormat}
          value={toIndexedSelectRoutingValue(routingPickupFilter)}
          labelInfo={t('shipments.routing.routingDetails')}
          labelInfoPlacement='right'
        />
        <MultiSelect
          onChange={routingPolFilter.onChange}
          isClearable
          isSearchable
          async
          label={t('shipments.routing.pol')}
          name={routingPolFilter.name}
          fetch={fetchRoutingOptions('pol')}
          fetchOnFocus={fetchAllRoutingOptions('pol')}
          fetchedOptionsFormat={fetchedRoutingOptionsFormat}
          value={toIndexedSelectRoutingValue(routingPolFilter)}
          labelInfo={t('shipments.routing.routingDetails')}
          labelInfoPlacement='right'
        />
        <MultiSelect
          onChange={routingPodFilter.onChange}
          isClearable
          isSearchable
          async
          label={t('shipments.routing.pod')}
          name={routingPodFilter.name}
          fetch={fetchRoutingOptions('pod')}
          fetchOnFocus={fetchAllRoutingOptions('pod')}
          fetchedOptionsFormat={fetchedRoutingOptionsFormat}
          value={toIndexedSelectRoutingValue(routingPodFilter)}
          labelInfo={t('shipments.routing.routingDetails')}
          labelInfoPlacement='right'
        />
        <MultiSelect
          onChange={routingDeliveryFilter.onChange}
          isClearable
          isSearchable
          async
          label={t('shipments.routing.delivery')}
          name={routingDeliveryFilter.name}
          fetch={fetchRoutingOptions('delivery')}
          fetchOnFocus={fetchAllRoutingOptions('delivery')}
          fetchedOptionsFormat={fetchedRoutingOptionsFormat}
          value={toIndexedSelectRoutingValue(routingDeliveryFilter)}
          labelInfo={t('shipments.routing.routingDetails')}
          labelInfoPlacement='right'
        />
      </FiltersSidebar.Section>

      <FiltersSidebar.Section
        title={t('shipments.filters.arrivalForecast')}
        defaultOpened={arrivalForecastCategory.isEdited}
      >
        <InputCheckbox
          onChange={(e) => {
            etaDiffCategory.resetFilters()
            arrivalForecastEarlyFilter.onChange(e)
          }}
          id='early'
          name={arrivalForecastEarlyFilter.name}
          text={t('shipments.filters.early')}
          checked={arrivalForecastEarlyFilter.value}
        />
        <InputCheckbox
          onChange={(e) => {
            etaDiffCategory.resetFilters()
            arrivalForecastOnTimeFilter.onChange(e)
          }}
          id='past'
          name={arrivalForecastOnTimeFilter.name}
          text={t('shipments.filters.onTime')}
          checked={arrivalForecastOnTimeFilter.value}
        />
        <InputCheckbox
          onChange={(e) => {
            etaDiffCategory.resetFilters()
            arrivalForecastDelayedFilter.onChange(e)
          }}
          id='expired'
          name={arrivalForecastDelayedFilter.name}
          text={t('shipments.filters.delayed')}
          checked={arrivalForecastDelayedFilter.value}
        />
      </FiltersSidebar.Section>

      <FiltersSidebar.Section
        title={t('shipments.filters.etaDifference')}
        defaultOpened={etaDiffCategory.isEdited}
      >
        <StyledShipmentFiltersDays>
          <Input
            type='number'
            name={etaDiffMinFilter.name}
            label={t('shipments.filters.minEtaDiff')}
            value={etaDiffMinFilter.value}
            onChange={(e) => {
              arrivalForecastCategory.resetFilters()
              etaDiffMinFilter.onChange(e)
            }}
            max={etaDiffMaxFilter.value || undefined}
          />
          <Input
            type='number'
            name={etaDiffMaxFilter.name}
            label={t('shipments.filters.maxEtaDiff')}
            value={etaDiffMaxFilter.value}
            onChange={(e) => {
              arrivalForecastCategory.resetFilters()
              etaDiffMaxFilter.onChange(e)
            }}
            min={etaDiffMinFilter.value || undefined}
          />
        </StyledShipmentFiltersDays>
      </FiltersSidebar.Section>

      <FiltersSidebar.Section
        title={t('shipments.filters.parties')}
        defaultOpened={partiesCategory.isEdited}
      >
        <MultiSelect
          label={t('shipments.parties.forwarder')}
          name={partiesForwarderFilter.name}
          onChange={partiesForwarderFilter.onChange}
          isClearable
          isSearchable
          async
          fetch={fetchOptions('parties_forwarder')}
          fetchOnFocus={fetchAllOptions('parties_forwarder')}
          fetchedOptionsFormat={fetchedOptionsFormat}
          value={toIndexedSelectValue(partiesForwarderFilter)}
        />
        <MultiSelect
          label={t('shipments.parties.shipper')}
          name={partiesShipperFilter.name}
          onChange={partiesShipperFilter.onChange}
          isClearable
          isSearchable
          async
          fetch={fetchOptions('parties_shipper')}
          fetchOnFocus={fetchAllOptions('parties_shipper')}
          fetchedOptionsFormat={fetchedOptionsFormat}
          value={toIndexedSelectValue(partiesShipperFilter)}
        />
        <MultiSelect
          label={t('shipments.parties.consignee')}
          name={partiesConsigneeFilter.name}
          onChange={partiesConsigneeFilter.onChange}
          isClearable
          isSearchable
          async
          fetch={fetchOptions('parties_consignee')}
          fetchOnFocus={fetchAllOptions('parties_consignee')}
          fetchedOptionsFormat={fetchedOptionsFormat}
          value={toIndexedSelectValue(partiesConsigneeFilter)}
        />
        <MultiSelect
          label={t('shipments.parties.consignor')}
          name={partiesConsignorFilter.name}
          onChange={partiesConsignorFilter.onChange}
          isClearable
          isSearchable
          async
          fetch={fetchOptions('parties_consignor')}
          fetchOnFocus={fetchAllOptions('parties_consignor')}
          fetchedOptionsFormat={fetchedOptionsFormat}
          value={toIndexedSelectValue(partiesConsignorFilter)}
        />
        <MultiSelect
          label={t('shipments.parties.shippingLine')}
          name={partiesShippingLineFilter.name}
          onChange={partiesShippingLineFilter.onChange}
          isClearable
          isSearchable
          async
          fetch={fetchOptions('parties_shipping_line')}
          fetchOnFocus={fetchAllOptions('parties_shipping_line')}
          fetchedOptionsFormat={fetchedOptionsFormat}
          value={toIndexedSelectValue(partiesShippingLineFilter)}
        />
        <MultiSelect
          label={t('shipments.parties.airline')}
          name={partiesAirlineFilter.name}
          onChange={partiesAirlineFilter.onChange}
          isClearable
          isSearchable
          async
          fetch={fetchOptions('parties_airline')}
          fetchOnFocus={fetchAllOptions('parties_airline')}
          fetchedOptionsFormat={fetchedOptionsFormat}
          value={toIndexedSelectValue(partiesAirlineFilter)}
        />
        <MultiSelect
          label={t('shipments.parties.carrier')}
          name={partiesCarrierFilter.name}
          onChange={partiesCarrierFilter.onChange}
          isClearable
          isSearchable
          async
          fetch={fetchOptions('parties_carrier')}
          fetchOnFocus={fetchAllOptions('parties_carrier')}
          fetchedOptionsFormat={fetchedOptionsFormat}
          value={toIndexedSelectValue(partiesCarrierFilter)}
        />
      </FiltersSidebar.Section>

      <FiltersSidebar.Section
        title={t('shipments.filters.transportationDate')}
        defaultOpened={transportationDateCategory.isEdited}
      >
        <InputDatepicker
          withPortal
          label={t('shipments.routing.pickup')}
          name={transportationDatePickupFilter.name}
          range
          onChange={transportationDatePickupFilter.onChange}
          startDate={transportationDatePickupFilter.value?.start}
          endDate={transportationDatePickupFilter.value?.end}
          onToggleOpen={(opened) => {
            if (!opened && !transportationDatePickupFilter.isPresent) {
              transportationDatePickupFilter.reset()
            }
          }}
        />
        <InputDatepicker
          withPortal
          label={t('shipments.routing.pol')}
          name={transportationDatePolFilter.name}
          range
          onChange={transportationDatePolFilter.onChange}
          startDate={transportationDatePolFilter.value?.start}
          endDate={transportationDatePolFilter.value?.end}
          onToggleOpen={(opened) => {
            if (!opened && !transportationDatePolFilter.isPresent) {
              transportationDatePolFilter.reset()
            }
          }}
        />
        <InputDatepicker
          withPortal
          label={t('shipments.routing.pod')}
          name={transportationDatePodFilter.name}
          range
          onChange={transportationDatePodFilter.onChange}
          startDate={transportationDatePodFilter.value?.start}
          endDate={transportationDatePodFilter.value?.end}
          onToggleOpen={(opened) => {
            if (!opened && !transportationDatePodFilter.isPresent) {
              transportationDatePodFilter.reset()
            }
          }}
        />
        <InputDatepicker
          withPortal
          label={t('shipments.routing.delivery')}
          name={transportationDateDeliveryFilter.name}
          range
          onChange={transportationDateDeliveryFilter.onChange}
          startDate={transportationDateDeliveryFilter.value?.start}
          endDate={transportationDateDeliveryFilter.value?.end}
          onToggleOpen={(opened) => {
            if (!opened && !transportationDateDeliveryFilter.isPresent) {
              transportationDateDeliveryFilter.reset()
            }
          }}
        />
      </FiltersSidebar.Section>

      {features(WITH_PARCEL_TRACKING) && (
        <FiltersSidebar.Section
          title={t('shipments.filters.alert')}
          defaultOpened={
            (filterOnOngoingAlertsOnly ? ongoingAlertCategory : alertHistoryCategory).isEdited
          }
        >
          <MultiSelect
            name={(filterOnOngoingAlertsOnly ? ongoingAlertFilter : alertHistoryFilter).name}
            onChange={
              (filterOnOngoingAlertsOnly ? ongoingAlertFilter : alertHistoryFilter).onChange
            }
            isClearable
            isSearchable
            onFocus={() => {
              setAlertTypeOptions([])
              dispatch(
                fetchAllOptions(
                  filterOnOngoingAlertsOnly ? 'ongoing_alert_type' : 'alert_type_history'
                )()
              )
                .unwrap()
                .then((alertTypes: number[]) => {
                  const options = alertTypes.map((value) => ({
                    label: t(`static.alerts.${value}`),
                    value,
                  }))
                  setAlertTypeOptions(options)
                })
                .catch(() =>
                  dispatch(
                    addNotification({
                      type: 'alert',
                      title: t('errors.notification.title'),
                      text: t('errors.notification.content'),
                    })
                  )
                )
            }}
            options={alertTypeOptions}
            value={toIndexedSelectValue(
              filterOnOngoingAlertsOnly ? ongoingAlertFilter : alertHistoryFilter
            )}
          />

          <InputCheckbox
            id='ongoingAlertCheckbox'
            name='ongoingAlertCheckbox'
            text={t('shipments.filters.excludeResolvedAlerts')}
            checked={filterOnOngoingAlertsOnly}
            onChange={onAlertFilterTypeChanged}
          />
        </FiltersSidebar.Section>
      )}

      <FiltersSidebar.Section
        title={t('shipments.filters.vessel')}
        defaultOpened={vesselCategory.isEdited}
      >
        <MultiSelect
          name={vesselFilter.name}
          onChange={vesselFilter.onChange}
          isClearable
          isSearchable
          async
          fetch={fetchOptions('vessel')}
          fetchOnFocus={fetchAllOptions('vessel')}
          fetchedOptionsFormat={fetchedOptionsFormat}
          value={toIndexedSelectValue(vesselFilter)}
        />
      </FiltersSidebar.Section>
    </FiltersSidebar>
  )
}

export default ShipmentFilters
