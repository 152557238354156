import React, { FC } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

import { useTranslation } from 'react-i18next'

import { BOOKING_FIELD_NAMES, BOOKING_FIELD_VALIDATIONS } from 'constants/bookings'

import { BookingFormInput } from 'views/booking/components/form/types'

import { BookingFieldNames } from 'views/booking/slices/types'
import Form from 'components/form'
import useStaticLocales from 'views/locales/hooks/use_static_locales'
import Grid from 'components/grid'
import Button from 'components/button'
import {
  StyledAddMerchandiseItemWrapper,
  StyledHazardousGoodsItemInput,
  StyledHazardousGoodsWrapper,
  StyledRemoveMerchandiseItemWrapper,
} from 'views/booking/components/form/style'
import Input from 'components/input'
import {
  getTestIdForMerchandiseContainerProduct,
  getTestIdForMerchandiseProductCurrencyCode,
  getTestIdForMerchandiseProductMaximalTemperature,
} from 'tests/e2e/test_ids'
import Textarea from 'components/textarea'
import Select from 'components/select'
import { stringToFloat, stringToInt } from 'services/helpers/values'

interface MerchandiseProductProps {
  containerIndex: number
  isFieldDisabled: (fieldName: BookingFieldNames) => boolean
}

const MerchandiseProducts: FC<MerchandiseProductProps> = ({ isFieldDisabled, containerIndex }) => {
  const { s } = useStaticLocales()
  const { t } = useTranslation()

  const {
    control,
    formState: { errors },
  } = useFormContext<BookingFormInput>()

  const {
    fields: products,
    remove: removeProduct,
    append: appendProduct,
  } = useFieldArray({
    control,
    name: `merchandise.content.container.${containerIndex}.products`,
  })

  const fromStaticToSelectOptions = (path: string) =>
    Object.entries(s(path)).map(([key, value]) => ({ value: key, label: value }))

  return (
    <>
      <Grid.Row>
        {products.map((product, productIndex: number) => (
          <Form.Insert
            key={product.id}
            subtitle={`${t('bookings.merchandise.merchandiseDetails')} #${productIndex + 1}`}
          >
            {productIndex !== 0 && (
              <StyledRemoveMerchandiseItemWrapper>
                <Button
                  disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                  variant='icon'
                  icon='trash'
                  onClick={() => removeProduct(productIndex)}
                />
              </StyledRemoveMerchandiseItemWrapper>
            )}
            <Grid columns={3}>
              <Grid.Row
                testId={getTestIdForMerchandiseContainerProduct(containerIndex, productIndex)}
              >
                <Grid.Column>
                  <Controller
                    name={`merchandise.content.container.${containerIndex}.products.${productIndex}.weight`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        type='number'
                        label={t('bookings.merchandise.weight')}
                        placeholder='kg'
                        min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT}
                        step='0.01'
                        name={`container-${containerIndex}-merchandiseWeight-${productIndex}`}
                        value={field.value}
                        error={
                          errors.merchandise?.content?.container?.[containerIndex]?.products?.[
                            productIndex
                          ]?.weight?.message
                        }
                        onChange={({ target: { value } }) => field.onChange(stringToFloat(value))}
                        disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                      />
                    )}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Controller
                    name={`merchandise.content.container.${containerIndex}.products.${productIndex}.volume`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        type='number'
                        label={t('bookings.merchandise.volume')}
                        placeholder='cbm'
                        step='0.01'
                        min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.VOLUME}
                        name={`container-${containerIndex}-merchandiseVolume-${productIndex}`}
                        value={field.value}
                        error={
                          errors.merchandise?.content?.container?.[containerIndex]?.products?.[
                            productIndex
                          ]?.volume?.message
                        }
                        onChange={({ target: { value } }) => field.onChange(stringToFloat(value))}
                        disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                      />
                    )}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Controller
                    name={`merchandise.content.container.${containerIndex}.products.${productIndex}.packageNumber`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        label={t('bookings.merchandise.numberOfPackages')}
                        placeholder={t('bookings.merchandise.numberOfPackages')}
                        type='number'
                        step='1'
                        min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.NUMBER_OF_PACKAGES}
                        name={`container-${containerIndex}-merchandisePackageNumber-${productIndex}`}
                        value={field.value}
                        error={
                          errors.merchandise?.content?.container?.[containerIndex]?.products?.[
                            productIndex
                          ]?.packageNumber?.message
                        }
                        onChange={({ target: { value } }) => field.onChange(stringToInt(value))}
                        disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                      />
                    )}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Controller
                    name={`merchandise.content.container.${containerIndex}.products.${productIndex}.productDescription`}
                    control={control}
                    render={({ field }) => (
                      <Textarea
                        label={t('bookings.merchandise.productDescription')}
                        placeholder={t('bookings.merchandise.yourDescription')}
                        name={`container-${containerIndex}-merchandiseDescription-${productIndex}`}
                        value={field.value}
                        rows={4}
                        compact
                        onChange={({ target: { value } }) => field.onChange(value)}
                        disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                      />
                    )}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Controller
                    name={`merchandise.content.container.${containerIndex}.products.${productIndex}.commercialValue.amount`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        type='number'
                        step='0.01'
                        min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.AMOUNT}
                        label={t('bookings.merchandise.commercialValue')}
                        placeholder={t('bookings.merchandise.amount')}
                        name={`container-${containerIndex}-merchandiseAmount-${productIndex}`}
                        value={field.value}
                        error={
                          errors.merchandise?.content?.container?.[containerIndex]?.products?.[
                            productIndex
                          ]?.commercialValue?.amount?.message
                        }
                        onChange={({ target: { value } }) => field.onChange(stringToFloat(value))}
                        disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                      />
                    )}
                  />
                  <Controller
                    name={`merchandise.content.container.${containerIndex}.products.${productIndex}.commercialValue.currencyCode`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        placeholder={t('bookings.merchandise.currency')}
                        options={fromStaticToSelectOptions('currencies')}
                        testId={getTestIdForMerchandiseProductCurrencyCode(
                          productIndex,
                          containerIndex
                        )}
                        name={`container-${containerIndex}-merchandiseCurrencyCode-${productIndex}`}
                        isDisabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                        value={field.value}
                        error={
                          errors.merchandise?.content?.container?.[containerIndex]?.products?.[
                            productIndex
                          ]?.commercialValue?.currencyCode?.message
                        }
                        isClearable
                        onChange={({ value }) => field.onChange(value)}
                      />
                    )}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Controller
                    name={`merchandise.content.container.${containerIndex}.products.${productIndex}.controlledTemperatures.min`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        label={t('bookings.merchandise.controlledTemperatures.title')}
                        placeholder={t('bookings.merchandise.controlledTemperatures.minimal')}
                        type='number'
                        step='0.01'
                        disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                        name={`container-${containerIndex}-merchandiseTemperatureMinimal-${productIndex}`}
                        error={
                          errors.merchandise?.content?.container?.[containerIndex]?.products?.[
                            productIndex
                          ]?.controlledTemperatures?.min?.message
                        }
                        value={field.value}
                        onChange={({ target: { value } }) => field.onChange(stringToFloat(value))}
                      />
                    )}
                  />
                  <Controller
                    name={`merchandise.content.container.${containerIndex}.products.${productIndex}.controlledTemperatures.max`}
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder={t('bookings.merchandise.controlledTemperatures.maximal')}
                        type='number'
                        step='0.01'
                        testId={getTestIdForMerchandiseProductMaximalTemperature(
                          productIndex,
                          containerIndex
                        )}
                        disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                        error={
                          errors.merchandise?.content?.container?.[containerIndex]?.products?.[
                            productIndex
                          ]?.controlledTemperatures?.max?.message
                        }
                        name={`container-${containerIndex}-merchandiseTemperatureMaximal-${productIndex}`}
                        value={field.value}
                        onChange={({ target: { value } }) => field.onChange(stringToFloat(value))}
                      />
                    )}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Controller
                    name={`merchandise.content.container.${containerIndex}.products.${productIndex}.hazardousGoods.hazardousClass`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        label={t('bookings.merchandise.product.hazardousGoods.hazardousClass')}
                        placeholder={t('actions.select')}
                        options={fromStaticToSelectOptions('hazardousGoods')}
                        name={`container-${containerIndex}-merchandiseHazardousClass-${productIndex}`}
                        value={field.value}
                        error={
                          errors.merchandise?.content?.container?.[containerIndex]?.products?.[
                            productIndex
                          ]?.hazardousGoods?.hazardousClass?.message
                        }
                        isDisabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                        onChange={({ value }) => field.onChange(value)}
                        isClearable
                      />
                    )}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Controller
                    name={`merchandise.content.container.${containerIndex}.products.${productIndex}.hazardousGoods.packingGroup`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        label={t('bookings.merchandise.product.hazardousGoods.packingGroup')}
                        placeholder={t('actions.select')}
                        isDisabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                        options={fromStaticToSelectOptions('packingGroups')}
                        name={`container-${containerIndex}-merchandiseHazardousPackingGroup-${productIndex}`}
                        error={
                          errors.merchandise?.content?.container?.[containerIndex]?.products?.[
                            productIndex
                          ]?.hazardousGoods?.packingGroup?.message
                        }
                        value={field.value}
                        onChange={({ value }) => field.onChange(value)}
                        isClearable
                      />
                    )}
                  />
                </Grid.Column>
                <Grid.Column>
                  <StyledHazardousGoodsWrapper>
                    <Controller
                      name={`merchandise.content.container.${containerIndex}.products.${productIndex}.hazardousGoods.unNumber`}
                      control={control}
                      render={({ field }) => (
                        <StyledHazardousGoodsItemInput
                          label={t('bookings.merchandise.product.hazardousGoods.unNumber')}
                          placeholder={t('bookings.merchandise.product.hazardousGoods.unNumber')}
                          disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                          error={
                            errors.merchandise?.content?.container?.[containerIndex]?.products?.[
                              productIndex
                            ]?.hazardousGoods?.unNumber?.message
                          }
                          name={`container-${containerIndex}-merchandiseHazardousUnNumber-${productIndex}`}
                          value={field.value}
                          onChange={({ target: { value } }) => field.onChange(value)}
                        />
                      )}
                    />
                    <Controller
                      name={`merchandise.content.container.${containerIndex}.products.${productIndex}.hazardousGoods.weight`}
                      control={control}
                      render={({ field }) => (
                        <StyledHazardousGoodsItemInput
                          label={t('bookings.merchandise.product.hazardousGoods.weight')}
                          placeholder='kg'
                          type='number'
                          step='0.01'
                          disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
                          min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT}
                          name={`container-${containerIndex}-merchandiseHazardousWeight-${productIndex}`}
                          value={field.value}
                          error={
                            errors.merchandise?.content?.container?.[containerIndex]?.products?.[
                              productIndex
                            ]?.hazardousGoods?.weight?.message
                          }
                          onChange={({ target: { value } }) => field.onChange(stringToFloat(value))}
                        />
                      )}
                    />
                  </StyledHazardousGoodsWrapper>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form.Insert>
        ))}
      </Grid.Row>
      <Grid.Row>
        <Grid.Column size={3}>
          <StyledAddMerchandiseItemWrapper>
            <Button
              disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT)}
              onClick={() => appendProduct({})}
              icon='plus_outline'
              variant='dashed'
              text={`${t('actions.addNew', { context: 'plural' })} ${t(
                'bookings.merchandise.merchandiseDetails'
              ).toLowerCase()}`}
            />
          </StyledAddMerchandiseItemWrapper>
        </Grid.Column>
      </Grid.Row>
    </>
  )
}

export default MerchandiseProducts
