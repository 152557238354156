import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import ClipLoader from 'react-spinners/ClipLoader'
import { useTheme } from 'styled-components'

import useModal from 'components/modal/hooks/use_modal'
import Modal from 'components/modal'
import Button from 'components/button'

import DateHelper from 'services/helpers/date_helper'
import { isNull } from 'services/helpers/values'

import { TrustedRoute } from 'views/trusted_routes/types/trusted_route'
import S from 'views/trusted_routes/components/convert_to_booking/style'
import useConvertToBooking from 'views/trusted_routes/components/convert_to_booking/hooks/use_convert_to_booking'
import { addNotification } from 'views/notifications/slice'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { formatDate } from 'views/trusted_routes/components/convert_to_booking/helpers'

import { SupportedLanguage } from 'constants/locales'

const MINIMUM_DAYS_BEFORE_MANUAL_VALIDATION = 2

interface ConvertToBookingProps {
  trustedRoute: TrustedRoute
  onCancel: () => void
}

const ConvertToBooking: React.FC<ConvertToBookingProps> = ({ trustedRoute, onCancel }) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const { setOpen } = useModal('trustedRoutesConvertToBooking')
  const {
    state: { convertionPending, convertionRejected },
    triggerConvertion,
  } = useConvertToBooking(trustedRoute)

  const formatWarning = (cutOffDate: string | null): string => {
    if (isNull(cutOffDate)) return ''
    const dateHelper = new DateHelper(formatDate(cutOffDate))
    const dayBeforeCutOffDate: number = dateHelper.diff(new Date(), 'days')

    if (dayBeforeCutOffDate < 0) {
      return t('trustedRoutes.convertToBooking.cutOffDateExceeded', {
        cut_off_date: dateHelper.format('LL', i18n.language as SupportedLanguage),
      })
    }
    if (dayBeforeCutOffDate <= MINIMUM_DAYS_BEFORE_MANUAL_VALIDATION) {
      return t('trustedRoutes.convertToBooking.cutOffDateComingSoon', {
        cut_off_date: dateHelper.format('LL', i18n.language as SupportedLanguage),
      })
    }
    return ''
  }

  useEffect(() => {
    if (!convertionRejected) return
    setOpen(false)
    dispatch(
      addNotification({
        type: 'alert',
        title: t('errors.notification.title'),
        text: t('trustedRoutes.convertToBooking.convertionError'),
      })
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertionRejected])

  return (
    <Modal
      modalName='trustedRoutesConvertToBooking'
      width='small'
      position='center'
      onClose={() => {
        if (!convertionPending) onCancel()
      }}
    >
      <Modal.Header>
        {convertionPending
          ? t('trustedRoutes.convertToBooking.convertionTitle')
          : t('trustedRoutes.convertToBooking.cutOffDateDelayTitle')}
      </Modal.Header>
      <Modal.Content>
        {convertionPending ? (
          <S.LoaderWrapper>
            <span>{t('trustedRoutes.convertToBooking.convertionContent')}</span>
            <ClipLoader
              color={theme.primary}
              loading
              size={70}
              cssOverride={{ borderWidth: '5px' }}
            />
          </S.LoaderWrapper>
        ) : (
          formatWarning(trustedRoute.cutOffDate)
        )}
      </Modal.Content>
      {!convertionPending && (
        <Modal.Footer>
          <Button text={t('actions.cancel')} variant='clear' onClick={() => setOpen(false)} />
          <Button
            text={t('actions.book')}
            variant='highlight'
            onClick={() => triggerConvertion()}
          />
        </Modal.Footer>
      )}
    </Modal>
  )
}

export default ConvertToBooking
