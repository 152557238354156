import { useContext } from 'react'

import {
  StyledShipmentListItemMainRef,
  StyledShipmentListItemRef,
} from 'features/shipments/components/shipment_list_item/style'

import useCurrentUser from 'views/iam/hooks/use_current_user'

import useReferenceDisplay from 'views/iam/hooks/use_reference_display'

import { Reference } from 'features/shipments/components/shipment_list_item/hooks/use_references'
import ShipmentContext from 'features/shipments/contexts/shipment_context'

const useComputedUserReferences = (): Reference[] => {
  const { shipment } = useContext(ShipmentContext)
  const {
    profile: { settings },
  } = useCurrentUser()
  const { getReference, translation } = useReferenceDisplay({
    referenceDisplayIndex: settings.referenceDisplay,
  })
  const { getReference: getSecondaryReference, translation: secondaryTranslation } =
    useReferenceDisplay({
      referenceDisplayIndex: settings.secondaryReferenceDisplay,
    })
  const mainReference = getReference({
    clientReference: shipment.clientReference,
    forwarderReference: shipment.forwarderReference,
    shipmentReference: shipment.shipmentReference,
    wakeoReference: shipment.wakeoReference,
    clientBookingNumber: shipment.clientBookingNumber,
  })
  const secondaryReference = getSecondaryReference({
    clientReference: shipment.clientReference,
    forwarderReference: shipment.forwarderReference,
    shipmentReference: shipment.shipmentReference,
    wakeoReference: shipment.wakeoReference,
    clientBookingNumber: shipment.clientBookingNumber,
  })
  const defaultValue = 'n/a'
  const references: Reference[] = [
    {
      Component: StyledShipmentListItemMainRef,
      value: mainReference || defaultValue,
      name: 'mainReference',
      show: settings.shipmentIndexDisplayMainReference,
      tooltipName: translation,
    },
    {
      Component: StyledShipmentListItemRef,
      value: secondaryReference || defaultValue,
      name: 'secondaryReference',
      show: settings.shipmentIndexDisplaySecondaryReference,
      tooltipName: secondaryTranslation,
    },
  ]
  return references
}
export default useComputedUserReferences
