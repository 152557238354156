import React from 'react'

import { useTranslation } from 'react-i18next'
import { useFormContext, Controller } from 'react-hook-form'

import Grid from 'components/grid'
import Form from 'components/form'
import Textarea from 'components/textarea'

import { BookingFieldNames } from 'views/booking/slices/types'
import { BookingFormInput } from 'views/booking/components/form/types'
import { BOOKING_FIELD_NAMES } from 'constants/bookings'

const Comments = ({
  isFieldDisabled,
}: {
  isFieldDisabled: (fieldName: BookingFieldNames) => boolean
}) => {
  const { control } = useFormContext<BookingFormInput>()
  const { t } = useTranslation()

  return (
    <>
      <Form.Group title={t('bookings.shipperComment')}>
        <Grid columns={1} columnsTablet={1}>
          <Grid.Row>
            <Grid.Column>
              <Controller
                control={control}
                name='comments.shipperComment'
                render={({ field }) => (
                  <Textarea
                    placeholder={t('bookings.yourComment')}
                    name={field.name}
                    value={field.value}
                    disabled={isFieldDisabled(BOOKING_FIELD_NAMES.COMMENTS)}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form.Group>
      <Form.Group title={t('bookings.forwarderComment')}>
        <Grid columns={1} columnsTablet={1}>
          <Grid.Row>
            <Grid.Column>
              <Controller
                control={control}
                name='comments.forwarderComment'
                render={({ field }) => (
                  <Textarea
                    placeholder={t('bookings.yourComment')}
                    name={field.name}
                    value={field.value}
                    disabled={isFieldDisabled(BOOKING_FIELD_NAMES.FORWARDER_COMMENT)}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form.Group>
    </>
  )
}

export default Comments
