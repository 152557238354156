import { createAsyncThunk } from '@reduxjs/toolkit'

import useThunkTeamsParam from 'services/api/hooks/use_thunk_teams_param'
import useUrlParams from 'services/api/hooks/use_url_params'
import { InternalClient } from 'services/api/clients'

import onError from 'services/api/error'

import type { ShipmentResponse } from 'features/shipments/store/shipment_slice'

// eslint-disable-next-line import/prefer-default-export
export const fetchModalShipmentsByVehicleId = createAsyncThunk(
  'shipments/fetchModalShipmentsByVehicleId',
  async ({ vehicleId }: { vehicleId: string }, thunkAPI) => {
    const teams = useThunkTeamsParam(thunkAPI)
    const q = { dashboard_map_display_eq: true, dashboard_vehicle_id_eq: vehicleId }
    const url = useUrlParams('/shipments', {
      q,
      per: 500,
      ...teams,
    })

    return InternalClient.get(url)
      .then((r) => r.data)
      .then(({ shipments }) =>
        shipments.map(({ token, ...shipment }: ShipmentResponse) => ({ id: token, ...shipment }))
      )
      .catch(onError(thunkAPI))
  }
)
