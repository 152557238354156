import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

import S from 'views/trusted_routes/components/alternative_routes/alternatives_fetcher/style'

import {
  selectAlternatives,
  SORT_BY_SUSTAINABILITY,
  SORT_BY_TRANSIT_TIME,
} from 'views/trusted_routes/slice'
import { SearchBarQueryParams } from 'views/trusted_routes/components/search_bar/types'
import useQueryParams from 'services/hooks/use_query_params'
import {
  AlternativeNumericalValue,
  AlternativeType,
  ESTIMATED_TT,
  CO2,
  DISRUPTIONS,
} from 'views/trusted_routes/components/alternative_routes/types'
import useTracker from 'services/analytics/hooks/use_tracker'

const getQueryParamsForNumericalAlternative = (
  numericalValueAlternative: AlternativeNumericalValue
) =>
  Object.entries({
    departure: numericalValueAlternative.departureName,
    arrival: numericalValueAlternative.arrivalName,
    departureLocode: numericalValueAlternative.departure,
    arrivalLocode: numericalValueAlternative.arrival,
  })
    .map(([k, v]) => `${k}=${v}`)
    .join('&')

const AlternativesFetcher: React.FC = () => {
  const { t } = useTranslation()
  const { track } = useTracker()
  const alternatives = useSelector(selectAlternatives)
  const location = useLocation()
  const urlQueryParams = useQueryParams<SearchBarQueryParams>()

  const trackAlternativeClicked = (altType: AlternativeType, value: AlternativeNumericalValue) =>
    track('TrustedRoutes / SelectedAlternative', {
      alternativeCriterion: altType,
      polSource: urlQueryParams.departureLocode,
      podSource: urlQueryParams.arrivalLocode,
      polAlternative: value.departure,
      podAlternative: value.arrival,
      criterionValue: value.value,
    })

  return (
    <S.OuterContainer>
      <S.Container>
        {alternatives?.bestHistoricalEstimatedTimeAlternative ? (
          <S.OuterLabelContainer>
            <Link
              to={`${location.pathname}?${getQueryParamsForNumericalAlternative(
                alternatives.bestHistoricalEstimatedTimeAlternative
              )}&fromDate=${
                urlQueryParams.fromDate
              }&fromAlternatives=true&sortBy=${SORT_BY_TRANSIT_TIME}`}
              target='_blank'
              rel='noopener noreferrer'
              onClick={() =>
                trackAlternativeClicked(
                  ESTIMATED_TT,
                  alternatives.bestHistoricalEstimatedTimeAlternative!
                )
              }
            >
              {t('trustedRoutes.alternatives.betterEstimatedTime')}:
              <S.Link>
                {alternatives.bestHistoricalEstimatedTimeAlternative.departureName}→
                {alternatives.bestHistoricalEstimatedTimeAlternative.arrivalName} (
                {alternatives.bestHistoricalEstimatedTimeAlternative.value}{' '}
                {t('trustedRoutes.criteria.day')})
              </S.Link>
            </Link>
          </S.OuterLabelContainer>
        ) : (
          <S.OuterLabelContainer />
        )}
        {alternatives?.bestHistoricalCo2Alternative ? (
          <S.OuterLabelContainer>
            <Link
              to={`${location.pathname}?${getQueryParamsForNumericalAlternative(
                alternatives.bestHistoricalCo2Alternative
              )}&fromDate=${
                urlQueryParams.fromDate
              }&fromAlternatives=true&sortBy=${SORT_BY_SUSTAINABILITY}`}
              target='_blank'
              rel='noopener noreferrer'
              onClick={() =>
                trackAlternativeClicked(CO2, alternatives.bestHistoricalCo2Alternative!)
              }
            >
              {t('trustedRoutes.alternatives.lowerEmission')}:
              <S.Link>
                {alternatives.bestHistoricalCo2Alternative.departureName}→
                {alternatives.bestHistoricalCo2Alternative.arrivalName} (
                {Math.round(
                  (alternatives.bestHistoricalCo2Alternative.value + Number.EPSILON) * 100
                ) / 100}{' '}
                {t('trustedRoutes.criteria.co2')})
              </S.Link>
            </Link>
          </S.OuterLabelContainer>
        ) : (
          <S.OuterLabelContainer />
        )}
        {alternatives?.bestHistoricalDisruptionAlertsAlternative ? (
          <S.OuterLabelContainer>
            <Link
              to={`${location.pathname}?${getQueryParamsForNumericalAlternative(
                alternatives.bestHistoricalDisruptionAlertsAlternative
              )}&fromDate=${
                urlQueryParams.fromDate
              }&fromAlternatives=true&sortBy=${SORT_BY_SUSTAINABILITY}`}
              target='_blank'
              rel='noopener noreferrer'
              onClick={() =>
                trackAlternativeClicked(
                  DISRUPTIONS,
                  alternatives.bestHistoricalDisruptionAlertsAlternative!
                )
              }
            >
              {t('trustedRoutes.alternatives.fewerDisruptions')}:
              <S.Link>
                {alternatives.bestHistoricalDisruptionAlertsAlternative.departureName}→
                {alternatives.bestHistoricalDisruptionAlertsAlternative.arrivalName}
              </S.Link>
            </Link>
          </S.OuterLabelContainer>
        ) : (
          <S.OuterLabelContainer />
        )}
      </S.Container>
    </S.OuterContainer>
  )
}

export default AlternativesFetcher
