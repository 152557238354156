import styled, { css } from 'styled-components'

import { buttonSmall } from 'styles/utils/texts'
import { StyledMarker } from 'components/marker/style'

export const StyledSwitchInputSwitch = styled.div`
  display: flex;
  align-items: center;

  input {
    position: absolute;
    opacity: 0;
    left: -9999px;
  }

  input:disabled > label {
    background-color: ${({ theme }) => theme.primary};
  }
`

export const StyledLabel = styled.label<{
  $disabled: boolean
}>`
{
  position: relative;
  display: inline-block;
  width: 46px;
  height: 26px;
  background-color: ${({ theme, $disabled }) => ($disabled ? theme.disabled : theme.primary)};
  border-radius: 13px;
  cursor: pointer;
  border: 1px solid transparent;

  ${(props) =>
    props.$disabled &&
    css`
      pointer-events: none
      }
    `}

  &:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 0;
    width: 18px;
    height: 18px;
    background-color: white;
    border-radius: 50%;
    transform: translateX(4px);
    transition: transform 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  }
`

export const StyledSwitchInputOption = styled.button<{
  $active: boolean
  $left?: boolean
  $right?: boolean
}>`
  ${buttonSmall};
  color: ${(props) => (props.$active ? props.theme.textDark : props.theme.textLight)};
  transition: color 0.3s;

  ${(props) => {
    if (props.$left)
      return css`
        margin-right: 10px;
      `
    if (props.$right)
      return css`
        margin-left: 10px;
      `

    return null
  }};
  ${(props) =>
    props.$active
      ? css`
          ${StyledMarker} {
            background-color: ${props.theme.primary};
          }
        `
      : css`
          ${StyledMarker} {
            background-color: ${props.theme.disabled};
          }
        `}
`

export const StyledSwitchInput = styled.div<{
  $checked?: boolean
  $variant?: 'default' | 'outline'
  $disabled?: boolean
}>`
  display: flex;
  align-items: center;

  ${(props) => {
    if (props.$checked) {
      return css`
        ${StyledSwitchInputSwitch} label:after {
          transform: translateX(calc(46px - 5px - 18px));
        }
      `
    }

    return null
  }}

  ${({ theme, $variant, $checked, $disabled }) => {
    if ($variant === 'outline' && !$checked) {
      return css`
        ${StyledSwitchInputSwitch} label {
          background-color: white;
          border: 1px solid ${theme.mediumGray};

          &:after {
            background-color: ${$disabled ? theme.disabled : theme.primary};
          }
        }
      `
    }

    return null
  }}
`
