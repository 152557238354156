import styled, { css } from 'styled-components'

import { text14Regular, textSmallDescription, h3 } from 'styles/utils/texts'
import { borderRadiusSmall, borderRadiusXSmall } from 'styles/utils/border_radius'
import { easeQuartOut } from 'styles/utils/ease'
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-right.svg'

export const StyledReactSelectOptionSlotBefore = styled.div`
  margin-right: 10px;
`
export const StyledReactSelectOptionWrapper = styled.div`
  display: flex;
`
export const StyledReactSelectOptionLabel = styled.span``

export const StyledReactSelectOptionArrow = styled(ArrowIcon)`
  width: 7px;
  height: 12px;
  position: absolute;
  right: 20px;
  fill: ${({ theme }) => theme.mediumGray};
`

export const StyledSelectLabel = styled.label`
  color: ${({ theme }) => theme.textLight};
  ${textSmallDescription};
`
export const StyledSelectLabelRequiredIcon = styled.span`
  color: ${({ theme }) => theme.error};
`
export const StyledPicto = styled.div`
  width: 18px;
  height: 20px;
  position: absolute;
  top: 12px;
  left: 20px;
  z-index: 1;
  pointer-events: none;
  fill: ${({ theme }) => theme.mediumGray};
`

type SelectVariant = 'default' | 'text-control' | 'primary' | 'dropdown'

export const StyledReactSelect = styled.div<{
  $withIcon?: boolean
  $noPadding?: boolean
  $hideControls?: boolean
  $variant?: SelectVariant
  $hasError?: boolean
}>`
  .Select {
    &__placeholder {
      color: ${({ theme }) => theme.mediumGray};
      ${({ $withIcon }) =>
        $withIcon &&
        css`
          width: calc(100% - 35px);
        `}
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &__control {
      ${text14Regular}
      ${borderRadiusSmall}
      box-shadow: none;
      padding: ${({ $noPadding }) => ($noPadding ? '0px' : '4px 10px')};
      border: 1px solid ${({ theme }) => theme.bgInput30};
      color: ${({ theme }) => theme.textLight};
      background: ${({ theme }) => theme.bgInput30};
      transition: background 0.5s ${easeQuartOut}, border 0.5s ${easeQuartOut};
      cursor: pointer;

      &:hover {
        background: ${({ theme }) => theme.bgInput};
        border-color: ${({ theme }) => theme.bgInput};
      }
      &--is-disabled {
        background: ${({ theme }) => theme.disabled};
      }
      &--is-focused {
        background: ${({ theme }) => theme.white};
        border-color: ${({ theme }) => theme.primary};

        &:hover {
          border-color: ${({ theme }) => theme.primary};
        }
      }
    }

    &__multi-value {
      ${borderRadiusXSmall};
      background-color: ${({ theme }) => theme.bgInput75};
      &__label {
        font-size: 13px;
      }
      &__remove {
        transition: color 0.5s ${easeQuartOut};
        color: hsl(0, 0%, 80%);
        &:hover {
          ${borderRadiusXSmall};
          background-color: ${({ theme }) => theme.bgInput75};
          color: hsl(0, 0%, 60%);
        }
      }
    }

    &__value-container {
      ${({ $withIcon }) =>
        $withIcon &&
        css`
          padding-left: 35px;
        `}
    }

    &__indicator-separator {
      display: none;
    }

    &__menu {
      ${borderRadiusSmall}
      ${text14Regular}
      box-shadow: none;
      filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.05));
      //  We set z-index to 2 because we had an overlap issue where some parts of the UI went above the dropdown.
      z-index: 2;
    }

    &__single-value {
      ${text14Regular};
      color: ${({ theme }) => theme.textDark};

      &--is-disabled {
        color: ${({ theme }) => theme.textLight};
        pointer-events: none;
      }
    }

    &__option {
      ${text14Regular};
      color: ${({ theme }) => theme.textLight};
      background-color: ${({ theme }) => theme.white};
      border-bottom: 1px solid #efefef;
      padding: 13px 10px 13px 15px;
      margin: 0 5px;
      width: calc(100% - 10px);
      transition: color 0.5s ${easeQuartOut};
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;

      &:last-child {
        border-bottom: 0px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.white};
        color: ${({ theme }) => theme.textDark};
      }

      &--is-selected {
        color: ${({ theme }) => theme.textDark};
      }
      &--is-disabled {
        opacity: 0.4;
        color: ${({ theme }) => theme.textLight};
        pointer-events: none;
      }
    }
  }

  ${(props) =>
    props.$hasError &&
    css`
      ${borderRadiusSmall}
      border: 1px solid ${({ theme }) => theme.error};
    `}

  ${(props) =>
    props.$hideControls === true &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .Select__control {
        opacity: 0;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        white-space: nowrap;
        overflow: hidden;
      }
    `}

  ${(props) =>
    props.$variant === 'text-control' &&
    css`
      .Select__control {
        border: none;
        background: none;
        justify-content: flex-start;
        width: auto;
        display: inline-flex;
        cursor: pointer;
        flex-wrap: nowrap;
      }

      .Select__single-value {
        position: relative;
        transform: none;
        overflow: visible;
        ${text14Regular};
      }

      .Select__value-container {
        flex-grow: 0;
        flex-shrink: 0;
        overflow: visible;
        padding-left: 5px;
      }

      .Select__control:hover {
        border: none;
        background: none;
      }

      .Select__control--is-focused {
        border: none !important;
      }

      .Select__menu {
        width: auto;
      }

      .Select__menu-list {
        position: relative;
        width: fit-content;
      }

      .Select__option {
        cursor: pointer;
      }
    `}

  ${(props) =>
    props.$variant === 'primary' &&
    css`
      .Select {
        &__multi-value {
          background-color: ${({ theme }) => theme.primary25};
          &__label {
            font-size: 13px;
            color: ${({ theme }) => theme.primary};
          }
        }
      }
    `}
  ${(props) =>
    props.$variant === 'dropdown' &&
    css`
      .Select__control {
        border: none;
        background: none;
        justify-content: flex-start;
        width: auto;
        display: inline-flex;
        cursor: pointer;
      }

      .Select__single-value {
        position: relative;
        transform: none;
        overflow: visible;
        ${h3};
      }

      .Select__value-container {
        flex-grow: 0;
        flex-shrink: 0;
        overflow: visible;
        padding-left: 5px;
      }

      .Select__control:hover {
        border: none;
        background: none;
      }

      .Select__control--is-focused {
        border: none !important;
      }

      .Select__menu {
        width: auto;
      }

      .Select__menu-list {
        position: relative;
        width: fit-content;
      }

      .Select__option {
        cursor: pointer;
      }
    `}
`

export const StyledSelect = styled.div`
  position: relative;
`

export const StyledReactSelectSingleValue = styled.div`
  display: flex;
  align-items: center;
`
