import styled, { keyframes } from 'styled-components'

const OuterContainer = styled.div`
  display: flex;
  flex: 1 1 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const OuterLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
`

const Container = styled.div`
  display: flex;
  flex-basis: 600px;
  flex-direction: column;
  justify-content: center;
  gap: 10px 20px;
  font-size: small;
  color: gray;
`

const LoadingAnimation = keyframes`
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
`

const Loader = styled.div`
  display: flex;
  flex-grow: 1;
  background: linear-gradient(to right, white, ${({ theme }) => theme.primary} 200%);
  background-size: 200% 200%;
  height: 8px;
  animation: ${LoadingAnimation} 2s ease infinite;
`

const Link = styled.b`
  text-decoration-line: underline;
  padding-left: 5px;
  color: ${({ theme }) => theme.primary};
  &:hover {
    font-weight: bold;
    cursor: pointer;
  }
`

const S = {
  OuterContainer,
  OuterLabelContainer,
  Container,
  Loader,
  Link,
}

export default S
