import useCategory, { QueryParams } from 'services/hooks/use_category'
import { isPresent, toSnakeCase } from 'services/helpers/values'
import useFilters from 'views/audit_trail/hooks/use_filters'

const useCategories = (filters: ReturnType<typeof useFilters>) => {
  const { sectionFilter, segmentFilter, actorFilter, stepFilter, milestoneFilter } = filters

  const shipmentVersionCategory = useCategory({
    name: 'shipmentVersion',
    filters: [sectionFilter, segmentFilter, actorFilter, stepFilter, milestoneFilter],
    toQueryParams: (_filters, filtersHash) =>
      Object.entries(filtersHash)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_key, filter]) => isPresent(filter?.value?.value))
        .reduce(
          (acc, [key, filter]) => ({ ...acc, [`q[${toSnakeCase(key)}_eq]`]: filter.value?.value }),
          {}
        ) as QueryParams,
  })

  const categories = {
    shipmentVersionCategory,
  }

  const arrayOfCategories = Object.values(categories)

  return {
    categories,
    arrayOfCategories,
  }
}

export default useCategories
