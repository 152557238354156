import React from 'react'

import { useTranslation } from 'react-i18next'
import { useFormContext, Controller } from 'react-hook-form'

import { StyledInput } from 'views/booking/components/form/style'
import { fetchIncotermOptions } from 'views/select_options/slice'
import Grid from 'components/grid'
import Form from 'components/form'
import Input from 'components/input'
import SearchSelect from 'components/search_select'
import { BookingFieldNames } from 'views/booking/slices/types'

import { BookingFormInput } from 'views/booking/components/form/types'
import { BOOKING_FIELD_NAMES } from 'constants/bookings'

const Incoterms = ({
  isFieldDisabled,
}: {
  isFieldDisabled: (fieldName: BookingFieldNames) => boolean
}) => {
  const { t } = useTranslation()
  const {
    control,
    formState: { errors },
  } = useFormContext<BookingFormInput>()

  return (
    <Form.Group title={t('bookings.incoterms.title')}>
      <Grid columns={3}>
        <Grid.Row>
          <Grid.Column>
            <Controller
              name='incoterms.incoterms'
              control={control}
              render={({ field }) => (
                <StyledInput
                  as={SearchSelect}
                  label={t('bookings.incoterms.title')}
                  action={fetchIncotermOptions}
                  name={field.name}
                  error={errors.incoterms?.incoterms?.message}
                  onChange={({ value }) =>
                    field.onChange(value ? { label: value.label, value: value.value } : null)
                  }
                  value={field.value}
                  required={!isFieldDisabled(BOOKING_FIELD_NAMES.INCOTERMS_LOCATION)}
                  isDisabled={isFieldDisabled(BOOKING_FIELD_NAMES.INCOTERMS_LOCATION)}
                />
              )}
            />
          </Grid.Column>
          <Grid.Column>
            <Controller
              name='incoterms.incotermsLocation'
              control={control}
              render={({ field }) => (
                <StyledInput
                  as={Input}
                  label={t('bookings.incoterms.incotermsLocation')}
                  placeholder={t('bookings.location')}
                  name={field.name}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  disabled={isFieldDisabled(BOOKING_FIELD_NAMES.INCOTERMS_LOCATION)}
                />
              )}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form.Group>
  )
}

export default Incoterms
