import React, { FC, memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SHIPMENT_VIEW_LARGE } from 'constants/shipments'

import Page from 'components/page'
import ShipmentFilters from 'features/shipments/components/shipment_filters'

import ShipmentViewContext from 'features/shipments/contexts/shipment_view_context'
import ShipmentBar from 'features/shipments/components/shipment_bar'

import S from 'components/page/style'
import { INDEX_SOURCE } from 'constants/shipment_source'
import { ShipmentViewType } from 'features/shipments/types/shipment'
import {
  StyledShipments,
  StyledShipmentsBar,
  StyledShipmentsMainHeader,
} from 'pages/shipments/style'
import ShipmentsList from 'features/shipments/components/shipments_list'

const ShipmentsPage: FC = memo(() => {
  const [view, setView] = useState<ShipmentViewType>(SHIPMENT_VIEW_LARGE)
  const { t } = useTranslation()

  const shipmentViewContextValue = useMemo(() => ({ view, setView }), [view, setView])

  return (
    <S.ScrollbarPage sidebar={<ShipmentFilters />} as={Page}>
      <StyledShipments>
        <ShipmentViewContext.Provider value={shipmentViewContextValue}>
          <StyledShipmentsMainHeader>
            <S.PageTitle>{t('shipments.indexTitle')}</S.PageTitle>
            <StyledShipmentsBar>
              <ShipmentBar />
            </StyledShipmentsBar>
          </StyledShipmentsMainHeader>
          <ShipmentsList source={INDEX_SOURCE} />
        </ShipmentViewContext.Provider>
      </StyledShipments>
    </S.ScrollbarPage>
  )
})

export default ShipmentsPage
