import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

import Form from 'components/form'
import Grid from 'components/grid'
import Input from 'components/input'
import Select from 'components/select'

import { BOOKING_FIELD_NAMES, BOOKING_FIELD_VALIDATIONS } from 'constants/bookings'

import { BookingFieldNames } from 'views/booking/slices/types'

import useStaticLocales from 'views/locales/hooks/use_static_locales'

import {
  StyledAddMerchandiseItemWrapper,
  StyledRemoveMerchandiseItemWrapper,
} from 'views/booking/components/form/style'

import Button from 'components/button'

import { getTestIdForMerchandiseContainer } from 'tests/e2e/test_ids'
import { BookingFormInput } from 'views/booking/components/form/types'
import MerchandiseProducts from 'views/booking/components/form/merchandise/merchandise_product'
import { stringToInt } from 'services/helpers/values'

interface MerchandiseContainerProps {
  isFieldDisabled: (fieldName: BookingFieldNames) => boolean
}

const MerchandiseContainer: FC<MerchandiseContainerProps> = ({
  isFieldDisabled,
}: MerchandiseContainerProps) => {
  const { s } = useStaticLocales()
  const { t } = useTranslation()

  const {
    control,
    formState: { errors },
  } = useFormContext<BookingFormInput>()

  const {
    fields: containers,
    remove: removeContainer,
    append: appendContainer,
  } = useFieldArray({
    control,
    name: 'merchandise.content.container',
  })

  return (
    <Form.Group>
      {containers.map((container, index: number) => (
        <Form.Insert
          key={container.id}
          subtitle={`${t('bookings.merchandise.containerRequest')} #${index + 1}`}
        >
          {index !== 0 && (
            <StyledRemoveMerchandiseItemWrapper>
              <Button
                disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_SIGNIFICANT_CONTENT)}
                variant='icon'
                icon='trash'
                onClick={() => removeContainer(index)}
              />
            </StyledRemoveMerchandiseItemWrapper>
          )}
          <Grid columns={3}>
            <Grid.Row testId={getTestIdForMerchandiseContainer(index)}>
              <Grid.Column>
                <Controller
                  name={`merchandise.content.container.${index}.containerType`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      label={t('bookings.merchandise.type')}
                      placeholder={t('bookings.merchandise.containerType')}
                      options={Object.entries(s('containerTypes'))
                        .map(([key, value]) => ({
                          value: key,
                          label: value,
                        }))
                        .sort((a, b) => a.label.localeCompare(b.label))}
                      name={`containerType-${index}`}
                      value={field.value}
                      error={
                        errors.merchandise?.content?.container?.[index]?.containerType?.message
                      }
                      onChange={({ value }) => field.onChange(value)}
                      required={
                        !isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_SIGNIFICANT_CONTENT)
                      }
                      isDisabled={isFieldDisabled(
                        BOOKING_FIELD_NAMES.MERCHANDISE_SIGNIFICANT_CONTENT
                      )}
                      inputId={`merchandise-containerType-${index}`}
                    />
                  )}
                />
              </Grid.Column>
              <Grid.Column>
                <Controller
                  name={`merchandise.content.container.${index}.quantity`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      type='number'
                      label={t('bookings.merchandise.quantity')}
                      placeholder={t('bookings.merchandise.quantity')}
                      min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.QUANTITY}
                      name={`containerType-${index}`}
                      error={errors.merchandise?.content?.container?.[index]?.quantity?.message}
                      value={field.value}
                      onChange={({ target: { value } }) => field.onChange(stringToInt(value))}
                      required={
                        !isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_SIGNIFICANT_CONTENT)
                      }
                      disabled={isFieldDisabled(
                        BOOKING_FIELD_NAMES.MERCHANDISE_SIGNIFICANT_CONTENT
                      )}
                      id={`merchandise-quantity-${index}`}
                    />
                  )}
                />
              </Grid.Column>
            </Grid.Row>
            <MerchandiseProducts containerIndex={index} isFieldDisabled={isFieldDisabled} />
          </Grid>
        </Form.Insert>
      ))}
      <StyledAddMerchandiseItemWrapper>
        <Button
          disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_SIGNIFICANT_CONTENT)}
          onClick={() => appendContainer({ products: [{}] })}
          icon='plus_outline'
          variant='dashed'
          text={`${t('actions.addNew', { context: 'female' })} ${t(
            'bookings.merchandise.containerRequest'
          ).toLowerCase()}`}
        />
      </StyledAddMerchandiseItemWrapper>
    </Form.Group>
  )
}

export default MerchandiseContainer
