import React from 'react'

import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

import { isAnyArray } from 'services/helpers/values'
import CustomReferences from 'views/booking/components/form/custom_references'
import { StyledGridReferences, StyledInput } from 'views/booking/components/form/style'
import useCurrentCompany from 'views/iam/hooks/use_current_company'
import Grid from 'components/grid'
import Form from 'components/form'
import Input from 'components/input'
import { BookingFormInput } from 'views/booking/components/form/types'
import { BOOKING_FIELD_NAMES } from 'constants/bookings'
import { BookingFieldNames } from 'views/booking/slices/types'

const References = ({
  isFieldDisabled,
}: {
  isFieldDisabled: (fieldName: BookingFieldNames) => boolean
}) => {
  const {
    company: { customReferences },
  } = useCurrentCompany()
  const { t } = useTranslation()
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<BookingFormInput>()

  const shipperCompanyHasCustomReferences = isAnyArray(customReferences)
  const bookingHasCustomReferences = isAnyArray(watch('references.customReferences'))

  return (
    <Form.Group title={t('bookings.references.title')}>
      <StyledGridReferences as={Grid} columns={3}>
        <Grid.Row>
          <Grid.Column>
            <Controller
              name='references.clientReference'
              control={control}
              render={({ field }) => (
                <StyledInput
                  as={Input}
                  label={t('bookings.references.clientReference')}
                  placeholder={t('bookings.reference')}
                  error={errors.references?.clientReference?.message}
                  name={field.name}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  disabled={isFieldDisabled(BOOKING_FIELD_NAMES.CLIENT_REFERENCE)}
                  required
                />
              )}
            />
          </Grid.Column>
          <Grid.Column>
            <Controller
              name='references.clientBookingNumber'
              control={control}
              render={({ field }) => (
                <StyledInput
                  as={Input}
                  label={t('bookings.references.clientBookingNumber')}
                  placeholder={t('bookings.reference')}
                  name={field.name}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  disabled={isFieldDisabled(BOOKING_FIELD_NAMES.CLIENT_BOOKING_NUMBER)}
                />
              )}
            />
          </Grid.Column>
          <Grid.Column>
            <Controller
              name='references.forwarderReference'
              control={control}
              render={({ field }) => (
                <StyledInput
                  label={t('bookings.references.forwarderReference')}
                  placeholder={t('bookings.reference')}
                  name={field.name}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  disabled={isFieldDisabled(BOOKING_FIELD_NAMES.FORWARDER_REFERENCE)}
                />
              )}
            />
          </Grid.Column>
        </Grid.Row>

        {(shipperCompanyHasCustomReferences || bookingHasCustomReferences) && (
          <CustomReferences isFieldDisabled={isFieldDisabled} />
        )}
      </StyledGridReferences>
    </Form.Group>
  )
}

export default References
