import React from 'react'

import { useFormContext, useFieldArray, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Select, { OnChangeFnProps } from 'components/select'
import useCurrentCompany from 'views/iam/hooks/use_current_company'
import {
  StyledInputCustomRef,
  StyledSelectCustomRef,
  StyledCustomRefWrapper,
} from 'views/booking/components/form/style'
import { BookingFieldNames } from 'views/booking/slices/types'
import {
  getTestIdForCustomReferenceName,
  getTestIdForCustomReferenceReference,
} from 'tests/e2e/test_ids'
import { BookingFormInput } from 'views/booking/components/form/types'
import { BOOKING_FIELD_NAMES } from 'constants/bookings'
import BlockContainerItem from 'components/block_container/block_container_item'
import { capitalize } from 'services/helpers/values'
import BlockContainerAdd from 'components/block_container/block_container_add'

const CustomReferences = ({
  isFieldDisabled,
}: {
  isFieldDisabled: (fieldName: BookingFieldNames) => boolean
}) => {
  const { t } = useTranslation()
  const {
    company: { customReferences },
  } = useCurrentCompany()

  const {
    control,
    formState: { errors },
  } = useFormContext<BookingFormInput>()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'references.customReferences',
  })

  return (
    <StyledCustomRefWrapper>
      {fields.map((customReference, index) => (
        <BlockContainerItem
          title={capitalize(t('bookings.references.customReference', { count: 1 }))}
          key={customReference.key}
          index={index}
          columns={3}
          size={1}
          minLength={0}
          length={fields.length}
          disabled={isFieldDisabled(BOOKING_FIELD_NAMES.CUSTOM_REFERENCES)}
          removeBlock={() => remove(index)}
        >
          <Controller
            name={`references.customReferences.${index}.key`}
            control={control}
            render={({ field }) => (
              <StyledSelectCustomRef
                as={Select}
                value={{
                  label: field.value || '',
                  value: field.value,
                }}
                placeholder='Select'
                options={customReferences
                  .filter((r: string) => !fields.map((customRef) => customRef.key).includes(r))
                  .map((c: string) => ({
                    value: c,
                    label: c,
                  }))}
                error={errors.references?.customReferences?.[index]?.key?.message}
                onChange={({ value }: OnChangeFnProps) => field.onChange(value.value)}
                required
                isDisabled={isFieldDisabled(BOOKING_FIELD_NAMES.CUSTOM_REFERENCES)}
                testId={getTestIdForCustomReferenceName(index)}
              />
            )}
          />
          <Controller
            name={`references.customReferences.${index}.value`}
            control={control}
            render={({ field }) => (
              <StyledInputCustomRef
                placeholder={t('bookings.references.reference')}
                value={field.value}
                error={errors.references?.customReferences?.[index]?.value?.message}
                onChange={({ target: { value } }) => field.onChange(value)}
                disabled={isFieldDisabled(BOOKING_FIELD_NAMES.CUSTOM_REFERENCES)}
                testId={getTestIdForCustomReferenceReference(index)}
              />
            )}
          />
        </BlockContainerItem>
      ))}
      {fields.length < customReferences.length &&
        !isFieldDisabled(BOOKING_FIELD_NAMES.CUSTOM_REFERENCES) && (
          <BlockContainerAdd
            title={t('bookings.references.customReference', { count: 1 })}
            addBlock={() => append({})}
            btnSize={1}
            columns={3}
          />
        )}
    </StyledCustomRefWrapper>
  )
}

export default CustomReferences
