import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

import Form from 'components/form'
import Grid from 'components/grid'

import Select from 'components/select'
import Input from 'components/input'

import { BOOKING_FIELD_NAMES, BOOKING_FIELD_VALIDATIONS } from 'constants/bookings'

import {
  StyledAddMerchandiseItemWrapper,
  StyledRemoveContainerRequestButtonWrapper,
} from 'views/booking/components/form/style'

import Button from 'components/button'
import useStaticLocales from 'views/locales/hooks/use_static_locales'

import { getTestIdForMerchandiseContainer } from 'tests/e2e/test_ids'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import { WITH_BOOKING_OLD_WORKFLOW } from 'constants/organization_features'
import MerchandiseTotalDetails from 'views/booking/components/form/merchandise/merchandise_total_details'
import { BookingFormInput } from 'views/booking/components/form/types'
import { BookingFieldNames } from 'views/booking/slices/types'
import { stringToInt } from 'services/helpers/values'

interface MerchandiseTotalProps {
  isFieldDisabled: (fieldName: BookingFieldNames) => boolean
}

const MerchandiseTotal: FC<MerchandiseTotalProps> = ({
  isFieldDisabled,
}: MerchandiseTotalProps) => {
  const { s } = useStaticLocales()
  const { features } = useOrganizationCan()
  const { t } = useTranslation()
  const {
    control,
    formState: { errors },
  } = useFormContext<BookingFormInput>()
  const {
    fields: containers,
    append: appendContainer,
    remove: removeContainer,
  } = useFieldArray({
    control,
    name: 'merchandise.content.total.containers',
  })

  return (
    <Form.Group>
      <Form.Insert subtitle={t('bookings.merchandise.containersRequest')}>
        <Grid columns={3}>
          {containers.map((container, index: number) => (
            <Grid.Row key={container.id} compact testId={getTestIdForMerchandiseContainer(index)}>
              <Grid.Column>
                <Controller
                  name={`merchandise.content.total.containers.${index}.containerType`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      label={t('bookings.merchandise.type')}
                      placeholder={t('bookings.merchandise.containerType')}
                      options={Object.entries(s('containerTypes'))
                        .map(([key, value]) => ({
                          value: key,
                          label: value,
                        }))
                        .sort((a, b) => a.label.localeCompare(b.label))}
                      value={field.value}
                      error={
                        errors.merchandise?.content?.total?.containers?.[index]?.containerType
                          ?.message
                      }
                      onChange={({ value }) => field.onChange(value)}
                      required={
                        !isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_SIGNIFICANT_CONTENT)
                      }
                      isDisabled={isFieldDisabled(
                        BOOKING_FIELD_NAMES.MERCHANDISE_SIGNIFICANT_CONTENT
                      )}
                      inputId={`merchandise-containerType-${index}`}
                    />
                  )}
                />
              </Grid.Column>
              <Grid.Column>
                <Controller
                  name={`merchandise.content.total.containers.${index}.quantity`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      type='number'
                      label={t('bookings.merchandise.quantity')}
                      placeholder={t('bookings.merchandise.quantity')}
                      min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.QUANTITY}
                      error={
                        errors.merchandise?.content?.total?.containers?.[index]?.quantity?.message
                      }
                      value={field.value}
                      onChange={({ target: { value } }) => field.onChange(stringToInt(value))}
                      required={
                        !isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_SIGNIFICANT_CONTENT)
                      }
                      disabled={isFieldDisabled(
                        BOOKING_FIELD_NAMES.MERCHANDISE_SIGNIFICANT_CONTENT
                      )}
                      id={`merchandise-quantity-${index}`}
                    />
                  )}
                />
              </Grid.Column>
              <Grid.Column>
                {index !== 0 && (
                  <StyledRemoveContainerRequestButtonWrapper>
                    <Button
                      variant='icon'
                      icon='trash'
                      disabled={isFieldDisabled(
                        BOOKING_FIELD_NAMES.MERCHANDISE_SIGNIFICANT_CONTENT
                      )}
                      onClick={() => removeContainer(index)}
                    />
                  </StyledRemoveContainerRequestButtonWrapper>
                )}
              </Grid.Column>
            </Grid.Row>
          ))}
          <Grid.Row>
            <Grid.Column size={3}>
              <StyledAddMerchandiseItemWrapper>
                <Button
                  onClick={() => appendContainer({})}
                  disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE_SIGNIFICANT_CONTENT)}
                  icon='plus_outline'
                  variant='dashed'
                  text={`${t('actions.addNew', { context: 'male' })} ${t(
                    'bookings.merchandise.containerRequest'
                  ).toLowerCase()}`}
                />
              </StyledAddMerchandiseItemWrapper>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form.Insert>
      {features(WITH_BOOKING_OLD_WORKFLOW) ? (
        <MerchandiseTotalDetails withMultipleDetails={false} isFieldDisabled={isFieldDisabled} />
      ) : (
        <Form.Insert subtitle={t('bookings.merchandise.merchandiseDetails')}>
          <MerchandiseTotalDetails isFieldDisabled={isFieldDisabled} withMultipleDetails />
        </Form.Insert>
      )}
    </Form.Group>
  )
}

export default MerchandiseTotal
