import React, { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Page from 'components/page'

import { fetchBooking } from 'views/booking/slices/booking_slice'

import useBooking from 'views/booking/hooks/use_booking'
import { StyledBookingEdit } from 'views/booking/components/edit/style'

import useAppDispatch from 'services/hooks/use_app_dispatch'
import useOnce from 'services/hooks/use_once'
import {
  StyledBookingShowSkeleton,
  StyledPageBookingDetailBoxShadow,
  StyledPageBookingDetailButtonBack,
  StyledPageBookingDetailEditButtonBack,
  StyledPageBookingDetailsClientReference,
  StyledPageBookingDetailsHeader,
  StyledPageBookingDetailsMain,
  StyledPageBookingDetailsTitle,
  StyledPageBookingTag,
} from 'views/booking/style'
import { toCamelCase } from 'services/helpers/values'
import Tag from 'components/tag'
import useBookingDisplay from 'views/booking/hooks/use_booking_display'
import ErrorNotification from 'views/errors/error_notification'
import Placeholder from 'components/placeholder'
import BookingShowSkeleton from 'views/booking/components/show/skeleton'
import BookingEditForm from 'views/booking/components/edit/form'
import BookingContext from 'views/booking/contexts/booking_context'

export interface IApiError {
  code: number
  message: any
  name: string
  stack: string
}
export interface IBookingError {
  details: { name: string; message: string }[]
  errorType: 'syntax' | 'semantic' | 'not_authorized' | 'resource_not_found' | 'validation'
}
interface IUrlParams {
  id: string
}

const BookingEdit: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { id: token } = useParams<keyof IUrlParams>() as IUrlParams
  const [booking, status] = useBooking(token)
  const { statusColor } = useBookingDisplay(token)

  useOnce(() => {
    dispatch(fetchBooking(token))
  })

  return (
    <Page>
      <BookingContext.Provider value={{ token }}>
        <StyledBookingEdit>
          {/* Main */}
          <StyledPageBookingDetailsMain>
            <StyledPageBookingDetailsHeader>
              <StyledPageBookingDetailEditButtonBack
                as={StyledPageBookingDetailButtonBack}
                text={t('actions.backToList')}
                variant='transparent'
                icon='arrow_left'
                onClick={() => navigate('/bookings')}
              />

              {booking && status.ready && (
                <StyledPageBookingDetailsTitle>
                  <StyledPageBookingDetailsClientReference>
                    {t('actions.edit')} {booking.clientReference}
                  </StyledPageBookingDetailsClientReference>
                  <StyledPageBookingTag
                    as={Tag}
                    text={t(`bookings.status.${toCamelCase(booking.status)}`)}
                    color={statusColor}
                  />
                </StyledPageBookingDetailsTitle>
              )}
              {!status.ready && (
                <StyledPageBookingDetailsTitle>Loading...</StyledPageBookingDetailsTitle>
              )}
            </StyledPageBookingDetailsHeader>

            {booking && status.ready && <BookingEditForm booking={booking} />}

            {!status.ready && (
              <StyledPageBookingDetailBoxShadow>
                <Placeholder
                  ready={status.ready}
                  customPlaceholder={<StyledBookingShowSkeleton as={BookingShowSkeleton} />}
                />
              </StyledPageBookingDetailBoxShadow>
            )}
            {status.rejected && <ErrorNotification />}
          </StyledPageBookingDetailsMain>
        </StyledBookingEdit>
      </BookingContext.Provider>
    </Page>
  )
}

BookingEdit.propTypes = {}

BookingEdit.defaultProps = {}

export default BookingEdit
