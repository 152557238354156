import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useFormContext, Controller, useFieldArray } from 'react-hook-form'

import { Capacity } from 'types/companies'

import { SEARCH_TYPE_LOCATION_AIRPORT, SEARCH_TYPE_LOCATION_PORT } from 'constants/search_selects'

import { BOOKING_FIELD_NAMES, TRANSPORT_MODES } from 'constants/bookings'
import {
  LOCATION_TYPE_AIRPORT,
  LOCATION_TYPE_INLAND,
  LOCATION_TYPE_PORT,
  TRANSPORT_TYPE_AIR,
  TRANSPORT_TYPE_SEA,
} from 'constants/shipments'
import iconsMapping from 'services/helpers/icons_mapping'
import {
  getTestIdForTransshipment,
  getTestIdForTransshipmentPta,
  TEST_ID_BOOKING_FORM_ROUTING_ADD_TRANSSHIPMENT,
  TEST_ID_BOOKING_FORM_ROUTING_ORIGIN_LOCATION_SELECT,
  TEST_ID_BOOKING_FORM_ROUTING_DEPARTURE_SWITCH,
  TEST_ID_BOOKING_FORM_ROUTING_DESTINATION_LOCATION_SELECT,
  TEST_ID_BOOKING_FORM_ROUTING_DESTINATION_PTA,
  TEST_ID_BOOKING_FORM_ROUTING_DESTINATION_PTD,
  TEST_ID_BOOKING_FORM_ROUTING_DESTINATION_SWITCH,
  TEST_ID_BOOKING_FORM_ROUTING_ORIGIN_PTA,
  TEST_ID_BOOKING_FORM_ROUTING_ORIGIN_PTD,
  TEST_ID_BOOKING_FORM_TRANSPORT_TYPE_AIR,
  TEST_ID_BOOKING_FORM_TRANSPORT_TYPE_SEA,
  TEST_ID_BOOKING_FORM_ROUTING_POL,
  TEST_ID_BOOKING_FORM_ROUTING_ADD_POL,
  TEST_ID_BOOKING_FORM_ROUTING_ADD_POD,
  TEST_ID_BOOKING_FORM_ROUTING_POD,
  TEST_ID_BOOKING_FORM_ROUTING_POL_PTD,
  TEST_ID_BOOKING_FORM_ROUTING_POL_PTA,
  TEST_ID_BOOKING_FORM_ROUTING_POD_PTA,
  TEST_ID_BOOKING_FORM_ROUTING_POD_PTD,
  getTestIdForTransshipmentPtd,
} from 'tests/e2e/test_ids'

import {
  fetchCompanyAddressOptions,
  fetchLocationOptions,
  ISearch,
} from 'views/select_options/slice'
import {
  StyledCheckboxContainer,
  StyledLabelTransitStepType,
  StyledRoutingColumnHeader,
  StyledRoutingSwitchInputContainer,
  StyledRoutingTransitStepTypeWrapper,
  StyledSwitchInput,
} from 'views/booking/components/form/style'

import Form from 'components/form'
import Grid from 'components/grid'
import InputDatepicker from 'components/input_datepicker'
import CheckboxPicto from 'components/checkbox_picto'
import SearchSelect from 'components/search_select'

import { isPresent } from 'services/helpers/values'

import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import { WITH_FRONT_BOOKING_ROUTING_DETAILS } from 'constants/organization_features'
import SwitchInput from 'components/switch_input'
import { BookingFieldNames, RoutingWarning } from 'views/booking/slices/types'
import BlockContainerAdd from 'components/block_container/block_container_add'
import Button from 'components/button'
import Marker from 'components/marker'
import { BookingFormInput } from 'views/booking/components/form/types'
import NotifMessage from 'components/notif_message'

const Routing = ({
  isFieldDisabled,
  routingWarning,
}: {
  isFieldDisabled: (fieldName: BookingFieldNames) => boolean
  routingWarning?: RoutingWarning
}) => {
  const { t } = useTranslation()
  const { features } = useOrganizationCan()
  const {
    control,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<BookingFormInput>()
  const {
    fields: transshipments,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'routing.transshipments',
  })

  const [withPol, setWithPol] = useState<boolean>(
    isPresent(getValues('routing.pol.location.value'))
  )

  const [withPod, setWithPod] = useState<boolean>(
    isPresent(getValues('routing.pod.location.value'))
  )

  const filterLocations = (locations: { value: number; label: string }[]) =>
    locations.filter(
      (location) =>
        ![
          watch('routing.preCarriage.location.value'),
          watch('routing.pol.location.value'),
          watch('routing.pod.location.value'),
          watch('routing.postCarriage.location.value'),
          ...(watch('routing.transshipments')?.map(
            (transshipment) => transshipment.location?.value
          ) || []),
        ].includes(location.value)
    )

  const fetchLocationOptionsWithTooltip = (params: ISearch<any>) =>
    fetchLocationOptions({ withTooltip: true, ...params })

  const fetchCompanyAddressOptionsWithTooltip = (params: ISearch<any>, capacities: Capacity[]) =>
    fetchCompanyAddressOptions({ capacities, params: { ...params, withTooltip: true } })

  const transitAddressType =
    watch('transportType') === TRANSPORT_TYPE_SEA
      ? SEARCH_TYPE_LOCATION_PORT
      : SEARCH_TYPE_LOCATION_AIRPORT

  const withPreCarriage = watch('routing.withPreCarriage')
  const withPostCarriage = watch('routing.withPostCarriage')
  const transportType = watch('transportType')
  const currentPolValue = watch('routing.pol')
  const currentPodValue = watch('routing.pod')

  /** We cannot put this in a use effect because it would reset the fields even it is applied on demand from the code (template application for instance) */
  const onTransportTypeChanged = () => {
    setValue('routing.pol.location', null)
    setValue('routing.pod.location', null)
    setValue('routing.transshipments', [])
    setValue('transportDetails.carrier', null)
    setValue('transportDetails.shipmentAttributes', [])

    if (transportType === TRANSPORT_TYPE_AIR) {
      setValue('transportDetails.vessels', [])
      setValue('transportDetails.voyageNumbers', [])
    } else if (transportType === TRANSPORT_TYPE_SEA) {
      setValue('transportDetails.flightNumbers', [])
    }
  }

  useEffect(() => {
    setWithPol(isPresent(currentPolValue?.location))
  }, [currentPolValue])

  useEffect(() => {
    setWithPod(isPresent(currentPodValue?.location))
  }, [currentPodValue])

  return (
    <>
      <Form.Group title={t('bookings.routing.title')}>
        {routingWarning ? (
          <NotifMessage
            type='alert'
            text={
              <div>
                {routingWarning.message}
                <ul>
                  {routingWarning.addresses.map((address) => (
                    <li>- {address}</li>
                  ))}
                </ul>
              </div>
            }
          />
        ) : null}
        <StyledCheckboxContainer>
          <Controller
            control={control}
            name='transportType'
            render={({ field }) => (
              <>
                <CheckboxPicto
                  icon={iconsMapping(TRANSPORT_MODES.SEA, 'transportation')}
                  type='radio'
                  text={t('bookings.transportMode.sea')}
                  condensed
                  name={field.name}
                  onChange={() => {
                    field.onChange(TRANSPORT_TYPE_SEA)
                    onTransportTypeChanged()
                  }}
                  checked={field.value === TRANSPORT_TYPE_SEA}
                  disabled={isFieldDisabled(BOOKING_FIELD_NAMES.TRANSPORT_TYPE)}
                  testId={TEST_ID_BOOKING_FORM_TRANSPORT_TYPE_SEA}
                />
                <CheckboxPicto
                  icon={iconsMapping(TRANSPORT_MODES.AIR, 'transportation')}
                  type='radio'
                  text={t('bookings.transportMode.air')}
                  condensed
                  name={field.name}
                  onChange={() => {
                    field.onChange(TRANSPORT_TYPE_AIR)
                    onTransportTypeChanged()
                  }}
                  checked={field.value === TRANSPORT_TYPE_AIR}
                  disabled={isFieldDisabled(BOOKING_FIELD_NAMES.TRANSPORT_TYPE)}
                  testId={TEST_ID_BOOKING_FORM_TRANSPORT_TYPE_AIR}
                />
              </>
            )}
          />
        </StyledCheckboxContainer>
        <Grid columns={17} condensed>
          <Grid.Row>
            <Grid.Column size={4} centered>
              <StyledRoutingColumnHeader>
                {t('bookings.routing.columnHeaders.type')}
              </StyledRoutingColumnHeader>
            </Grid.Column>
            <Grid.Column size={4} centered>
              <StyledRoutingColumnHeader>
                {t('bookings.routing.columnHeaders.location')}
              </StyledRoutingColumnHeader>
            </Grid.Column>
            <Grid.Column size={4} centered>
              <StyledRoutingColumnHeader>
                {t('bookings.routing.columnHeaders.pta')}
              </StyledRoutingColumnHeader>
            </Grid.Column>
            <Grid.Column size={4} centered>
              <StyledRoutingColumnHeader>
                {t('bookings.routing.columnHeaders.ptd')}
              </StyledRoutingColumnHeader>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column centered size={4}>
              <Controller
                control={control}
                name='routing.withPreCarriage'
                render={({ field }) => (
                  <StyledSwitchInput
                    as={SwitchInput}
                    leftLabel={
                      <StyledRoutingSwitchInputContainer>
                        <Marker icon={LOCATION_TYPE_INLAND} />
                        <span>{t('bookings.routing.facility')}</span>
                      </StyledRoutingSwitchInputContainer>
                    }
                    rightLabel={
                      watch('transportType') === TRANSPORT_TYPE_SEA ? (
                        <StyledRoutingSwitchInputContainer>
                          <span>{t('bookings.routing.port')}</span>
                          <Marker icon={LOCATION_TYPE_PORT} />
                        </StyledRoutingSwitchInputContainer>
                      ) : (
                        <StyledRoutingSwitchInputContainer>
                          <span>{t('bookings.routing.airport')}</span>
                          <Marker icon={LOCATION_TYPE_AIRPORT} />
                        </StyledRoutingSwitchInputContainer>
                      )
                    }
                    onClick={(withoutPreCarriage) => {
                      if (withoutPreCarriage) {
                        setValue('routing.preCarriage', { location: null })
                        setWithPol(true)
                      } else {
                        setWithPol(isPresent(getValues('routing.pol.location.value')))
                      }

                      field.onChange(!withoutPreCarriage)
                    }}
                    rightOptionChecked={!field.value}
                    disabled={
                      isFieldDisabled(BOOKING_FIELD_NAMES.PRE_CARRIAGE) ||
                      isFieldDisabled(BOOKING_FIELD_NAMES.POL)
                    }
                    testId={TEST_ID_BOOKING_FORM_ROUTING_DEPARTURE_SWITCH}
                  />
                )}
              />
            </Grid.Column>

            {withPreCarriage ? (
              <>
                <Grid.Column size={4}>
                  <Controller
                    control={control}
                    name='routing.preCarriage.location'
                    key='routing.preCarriage.location'
                    render={({ field }) => (
                      <SearchSelect
                        action={(params: ISearch<any>) =>
                          fetchCompanyAddressOptionsWithTooltip(params, ['consignor'])
                        }
                        placeholder={t('bookings.select.actions.searchConsignor')}
                        fetchedOptionsFormat={filterLocations}
                        name={field.name}
                        error={errors.routing?.preCarriage?.location?.message}
                        onChange={({ value }) => field.onChange(value)}
                        value={field.value}
                        isDisabled={isFieldDisabled(BOOKING_FIELD_NAMES.PRE_CARRIAGE)}
                        testId={TEST_ID_BOOKING_FORM_ROUTING_ORIGIN_LOCATION_SELECT}
                      />
                    )}
                  />
                </Grid.Column>
                <Grid.Column size={4}>
                  <InputDatepicker disabled testId={TEST_ID_BOOKING_FORM_ROUTING_ORIGIN_PTA} />
                </Grid.Column>
                <Grid.Column size={4}>
                  <Controller
                    control={control}
                    name='routing.preCarriage.ptd'
                    key='routing.preCarriage.ptd'
                    render={({ field }) => (
                      <InputDatepicker
                        withPortal
                        name={field.name}
                        error={errors.routing?.preCarriage?.ptd?.message}
                        onChange={({ value }) => field.onChange(value[0] || null)}
                        startDate={field.value}
                        disabled={isFieldDisabled(BOOKING_FIELD_NAMES.PTD)}
                        testId={TEST_ID_BOOKING_FORM_ROUTING_ORIGIN_PTD}
                      />
                    )}
                  />
                </Grid.Column>
              </>
            ) : (
              <>
                <Grid.Column size={4}>
                  <Controller
                    control={control}
                    name='routing.pol.location'
                    key='routing.pol.location'
                    render={({ field }) => (
                      <SearchSelect
                        action={fetchLocationOptionsWithTooltip}
                        fetchedOptionsFormat={filterLocations}
                        type={transitAddressType}
                        name={field.name}
                        error={errors.routing?.pol?.location?.message}
                        onChange={({ value }) => field.onChange(value)}
                        value={field.value}
                        isDisabled={isFieldDisabled(BOOKING_FIELD_NAMES.POL)}
                        testId={TEST_ID_BOOKING_FORM_ROUTING_ORIGIN_LOCATION_SELECT}
                      />
                    )}
                  />
                </Grid.Column>
                <Grid.Column size={4}>
                  <InputDatepicker disabled testId={TEST_ID_BOOKING_FORM_ROUTING_ORIGIN_PTA} />
                </Grid.Column>
                <Grid.Column size={4}>
                  <Controller
                    control={control}
                    name='routing.pol.ptd'
                    key='routing.pol.ptd'
                    render={({ field }) => (
                      <InputDatepicker
                        withPortal
                        name={field.name}
                        error={errors.routing?.pol?.ptd?.message}
                        onChange={({ value }) => field.onChange(value[0] || null)}
                        startDate={field.value}
                        disabled={isFieldDisabled(BOOKING_FIELD_NAMES.POL_PTD)}
                        testId={TEST_ID_BOOKING_FORM_ROUTING_ORIGIN_PTD}
                      />
                    )}
                  />
                </Grid.Column>
              </>
            )}
          </Grid.Row>

          {features(WITH_FRONT_BOOKING_ROUTING_DETAILS) && (
            <>
              <Grid.Row>
                {withPreCarriage && !withPol && (
                  <Grid.Column size={17} centered>
                    <BlockContainerAdd
                      title={t(
                        `bookings.routing.${
                          watch('transportType') === TRANSPORT_TYPE_SEA ? 'pol' : 'polAir'
                        }`
                      )}
                      addBlock={() => setWithPol(true)}
                      btnSize={2}
                      columns={5}
                      testId={TEST_ID_BOOKING_FORM_ROUTING_ADD_POL}
                    />
                  </Grid.Column>
                )}

                {withPreCarriage && withPol && (
                  <>
                    <Grid.Column size={4} centered>
                      <StyledRoutingTransitStepTypeWrapper>
                        <StyledLabelTransitStepType>
                          {t(
                            `bookings.routing.${
                              watch('transportType') === TRANSPORT_TYPE_SEA ? 'pol' : 'polAir'
                            }`
                          )}
                        </StyledLabelTransitStepType>
                      </StyledRoutingTransitStepTypeWrapper>
                    </Grid.Column>
                    <Grid.Column size={4}>
                      <Controller
                        control={control}
                        name='routing.pol.location'
                        render={({ field }) => (
                          <SearchSelect
                            action={fetchLocationOptions}
                            fetchedOptionsFormat={filterLocations}
                            type={transitAddressType}
                            name={field.name}
                            error={errors.routing?.pol?.location?.message}
                            onChange={({ value }) => field.onChange(value)}
                            value={field.value}
                            required
                            isDisabled={isFieldDisabled(BOOKING_FIELD_NAMES.POL)}
                            testId={TEST_ID_BOOKING_FORM_ROUTING_POL}
                          />
                        )}
                      />
                    </Grid.Column>
                    <Grid.Column size={4}>
                      <Controller
                        control={control}
                        name='routing.pol.pta'
                        render={({ field }) => (
                          <InputDatepicker
                            withPortal
                            name={field.name}
                            error={errors.routing?.pol?.pta?.message}
                            onChange={({ value }) => field.onChange(value[0] || null)}
                            startDate={field.value}
                            disabled={isFieldDisabled(BOOKING_FIELD_NAMES.POL_PTA)}
                            testId={TEST_ID_BOOKING_FORM_ROUTING_POL_PTA}
                          />
                        )}
                      />
                    </Grid.Column>
                    <Grid.Column size={4} padded={false}>
                      <Controller
                        control={control}
                        name='routing.pol.ptd'
                        render={({ field }) => (
                          <InputDatepicker
                            withPortal
                            name={field.name}
                            error={errors.routing?.pol?.ptd?.message}
                            onChange={({ value }) => field.onChange(value[0] || null)}
                            startDate={field.value}
                            disabled={isFieldDisabled(BOOKING_FIELD_NAMES.POL_PTD)}
                            testId={TEST_ID_BOOKING_FORM_ROUTING_POL_PTD}
                          />
                        )}
                      />
                    </Grid.Column>
                    <Grid.Column size={1}>
                      <Button
                        variant='smallIcon'
                        icon='trash'
                        onClick={() => {
                          setWithPol(false)
                          setValue('routing.pol', null, { shouldDirty: true })
                        }}
                      />
                    </Grid.Column>
                  </>
                )}
              </Grid.Row>

              {transshipments.map((transshipment, index: number) => (
                <Grid.Row key={transshipment.id}>
                  <Grid.Column size={4} centered>
                    <StyledRoutingTransitStepTypeWrapper>
                      <StyledLabelTransitStepType>
                        {t('bookings.routing.transshipment', { count: 1 })}
                      </StyledLabelTransitStepType>
                    </StyledRoutingTransitStepTypeWrapper>
                  </Grid.Column>
                  <Grid.Column size={4}>
                    <Controller
                      control={control}
                      name={`routing.transshipments.${index}.location`}
                      key={transshipment.id}
                      render={({ field }) => (
                        <SearchSelect
                          action={fetchLocationOptions}
                          fetchedOptionsFormat={filterLocations}
                          type={transitAddressType}
                          name={field.name}
                          value={field.value}
                          error={errors.routing?.transshipments?.[index]?.location?.message}
                          onChange={({ value }) => field.onChange(value)}
                          required
                          testId={getTestIdForTransshipment(index)}
                        />
                      )}
                    />
                  </Grid.Column>
                  <Grid.Column size={4}>
                    <Controller
                      control={control}
                      name={`routing.transshipments.${index}.pta`}
                      key={`routing.transshipments.${transshipment.id}.pta`}
                      render={({ field }) => (
                        <InputDatepicker
                          withPortal
                          startDate={field.value}
                          error={errors.routing?.transshipments?.[index]?.pta?.message}
                          onChange={({ value }) => field.onChange(value[0] || null)}
                          testId={getTestIdForTransshipmentPta(index)}
                        />
                      )}
                    />
                  </Grid.Column>
                  <Grid.Column size={4} padded={false}>
                    <Controller
                      control={control}
                      name={`routing.transshipments.${index}.ptd`}
                      key={`routing.transshipments.${transshipment.id}.ptd`}
                      render={({ field }) => (
                        <InputDatepicker
                          withPortal
                          startDate={field.value}
                          error={errors.routing?.transshipments?.[index]?.ptd?.message}
                          onChange={({ value }) => field.onChange(value[0] || null)}
                          testId={getTestIdForTransshipmentPtd(index)}
                        />
                      )}
                    />
                  </Grid.Column>
                  <Grid.Column size={1}>
                    <Button variant='smallIcon' icon='trash' onClick={() => remove(index)} />
                  </Grid.Column>
                </Grid.Row>
              ))}

              <Grid.Row>
                <Grid.Column size={17} centered>
                  <BlockContainerAdd
                    title={t('bookings.routing.transshipment', { count: 1 })}
                    addBlock={() => append({})}
                    btnSize={2}
                    columns={5}
                    testId={TEST_ID_BOOKING_FORM_ROUTING_ADD_TRANSSHIPMENT}
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                {withPostCarriage && !withPod && (
                  <Grid.Column size={17} centered>
                    <BlockContainerAdd
                      title={t(
                        `bookings.routing.${
                          watch('transportType') === TRANSPORT_TYPE_SEA ? 'pod' : 'podAir'
                        }`
                      )}
                      addBlock={() => setWithPod(true)}
                      btnSize={2}
                      columns={5}
                      testId={TEST_ID_BOOKING_FORM_ROUTING_ADD_POD}
                    />
                  </Grid.Column>
                )}
                {withPostCarriage && withPod && (
                  <>
                    <Grid.Column size={4} centered>
                      <StyledRoutingTransitStepTypeWrapper>
                        <StyledLabelTransitStepType>
                          {t(
                            `bookings.routing.${
                              watch('transportType') === TRANSPORT_TYPE_SEA ? 'pod' : 'podAir'
                            }`
                          )}
                        </StyledLabelTransitStepType>
                      </StyledRoutingTransitStepTypeWrapper>
                    </Grid.Column>
                    <Grid.Column size={4}>
                      <Controller
                        control={control}
                        name='routing.pod.location'
                        key='routing.pod.location'
                        render={({ field }) => (
                          <SearchSelect
                            action={fetchLocationOptions}
                            fetchedOptionsFormat={filterLocations}
                            type={transitAddressType}
                            name={field.name}
                            value={field.value}
                            error={errors.routing?.pod?.location?.message}
                            onChange={({ value }) => field.onChange(value)}
                            required
                            isDisabled={isFieldDisabled(BOOKING_FIELD_NAMES.POD)}
                            testId={TEST_ID_BOOKING_FORM_ROUTING_POD}
                          />
                        )}
                      />
                    </Grid.Column>
                    <Grid.Column size={4}>
                      <Controller
                        control={control}
                        name='routing.pod.pta'
                        render={({ field }) => (
                          <InputDatepicker
                            withPortal
                            startDate={field.value}
                            error={errors.routing?.pod?.pta?.message}
                            onChange={({ value }) => field.onChange(value[0] || null)}
                            disabled={isFieldDisabled(BOOKING_FIELD_NAMES.POD_PTA)}
                            testId={TEST_ID_BOOKING_FORM_ROUTING_POD_PTA}
                          />
                        )}
                      />
                    </Grid.Column>
                    <Grid.Column size={4} padded={false}>
                      <Controller
                        control={control}
                        name='routing.pod.ptd'
                        render={({ field }) => (
                          <InputDatepicker
                            withPortal
                            startDate={field.value}
                            error={errors.routing?.pod?.ptd?.message}
                            onChange={({ value }) => field.onChange(value[0] || null)}
                            disabled={isFieldDisabled(BOOKING_FIELD_NAMES.POD_PTD)}
                            testId={TEST_ID_BOOKING_FORM_ROUTING_POD_PTD}
                          />
                        )}
                      />
                    </Grid.Column>
                    <Grid.Column size={1}>
                      <Button
                        variant='smallIcon'
                        icon='trash'
                        onClick={() => {
                          setWithPod(false)
                          setValue('routing.pod', null, { shouldDirty: true })
                        }}
                      />
                    </Grid.Column>
                  </>
                )}
              </Grid.Row>
            </>
          )}

          <Grid.Row>
            <Grid.Column centered size={4}>
              <Controller
                control={control}
                name='routing.withPostCarriage'
                render={({ field }) => (
                  <StyledSwitchInput
                    as={SwitchInput}
                    leftLabel={
                      <StyledRoutingSwitchInputContainer>
                        <Marker icon={LOCATION_TYPE_INLAND} />
                        <span>{t('bookings.routing.facility')}</span>
                      </StyledRoutingSwitchInputContainer>
                    }
                    rightLabel={
                      watch('transportType') === TRANSPORT_TYPE_SEA ? (
                        <StyledRoutingSwitchInputContainer>
                          <span>{t('bookings.routing.port')}</span>
                          <Marker icon={LOCATION_TYPE_PORT} />
                        </StyledRoutingSwitchInputContainer>
                      ) : (
                        <StyledRoutingSwitchInputContainer>
                          <span>{t('bookings.routing.airport')}</span>
                          <Marker icon={LOCATION_TYPE_AIRPORT} />
                        </StyledRoutingSwitchInputContainer>
                      )
                    }
                    onClick={(withoutPostCarriage) => {
                      field.onChange(!withoutPostCarriage)

                      if (withoutPostCarriage) {
                        setValue('routing.postCarriage', { location: null })
                        setWithPod(true)
                      } else {
                        setWithPod(isPresent(getValues('routing.pod.location.value')))
                      }
                    }}
                    rightOptionChecked={!field.value}
                    disabled={
                      isFieldDisabled(BOOKING_FIELD_NAMES.POD) ||
                      isFieldDisabled(BOOKING_FIELD_NAMES.ON_CARRIAGE)
                    }
                    testId={TEST_ID_BOOKING_FORM_ROUTING_DESTINATION_SWITCH}
                  />
                )}
              />
            </Grid.Column>
            {withPostCarriage ? (
              <>
                <Grid.Column size={4}>
                  <Controller
                    control={control}
                    name='routing.postCarriage.location'
                    key='routing.postCarriage.location'
                    render={({ field }) => (
                      <SearchSelect
                        action={(params: ISearch<any>) =>
                          fetchCompanyAddressOptionsWithTooltip(params, ['consignee'])
                        }
                        placeholder={t('bookings.select.actions.searchConsignee')}
                        fetchedOptionsFormat={filterLocations}
                        name={field.name}
                        error={errors.routing?.postCarriage?.location?.message}
                        onChange={({ value }) => field.onChange(value)}
                        value={field.value}
                        isDisabled={isFieldDisabled(BOOKING_FIELD_NAMES.ON_CARRIAGE)}
                        testId={TEST_ID_BOOKING_FORM_ROUTING_DESTINATION_LOCATION_SELECT}
                      />
                    )}
                  />
                </Grid.Column>
                <Grid.Column size={4}>
                  <Controller
                    control={control}
                    name='routing.postCarriage.pta'
                    key='routing.postCarriage.pta'
                    render={({ field }) => (
                      <InputDatepicker
                        withPortal
                        name={field.name}
                        error={errors.routing?.postCarriage?.pta?.message}
                        onChange={({ value }) => field.onChange(value[0] || null)}
                        startDate={field.value}
                        disabled={isFieldDisabled(BOOKING_FIELD_NAMES.PTA)}
                        testId={TEST_ID_BOOKING_FORM_ROUTING_DESTINATION_PTA}
                      />
                    )}
                  />
                </Grid.Column>
                <Grid.Column size={4}>
                  <InputDatepicker disabled testId={TEST_ID_BOOKING_FORM_ROUTING_DESTINATION_PTD} />
                </Grid.Column>
              </>
            ) : (
              <>
                <Grid.Column size={4}>
                  <Controller
                    control={control}
                    name='routing.pod.location'
                    key='routing.pod.location'
                    render={({ field }) => (
                      <SearchSelect
                        action={fetchLocationOptionsWithTooltip}
                        fetchedOptionsFormat={filterLocations}
                        name={field.name}
                        error={errors.routing?.pod?.location?.message}
                        onChange={({ value }) => field.onChange(value)}
                        type={transitAddressType}
                        value={field.value}
                        isDisabled={isFieldDisabled(BOOKING_FIELD_NAMES.POD)}
                        testId={TEST_ID_BOOKING_FORM_ROUTING_DESTINATION_LOCATION_SELECT}
                      />
                    )}
                  />
                </Grid.Column>
                <Grid.Column size={4}>
                  <Controller
                    control={control}
                    name='routing.pod.pta'
                    key='routing.pod.pta'
                    render={({ field }) => (
                      <InputDatepicker
                        withPortal
                        name={field.name}
                        error={errors.routing?.pod?.pta?.message}
                        onChange={({ value }) => field.onChange(value[0] || null)}
                        startDate={field.value}
                        disabled={isFieldDisabled(BOOKING_FIELD_NAMES.POD_PTA)}
                        testId={TEST_ID_BOOKING_FORM_ROUTING_DESTINATION_PTA}
                      />
                    )}
                  />
                </Grid.Column>
                <Grid.Column size={4}>
                  <InputDatepicker disabled testId={TEST_ID_BOOKING_FORM_ROUTING_DESTINATION_PTD} />
                </Grid.Column>
              </>
            )}
          </Grid.Row>
        </Grid>
      </Form.Group>
    </>
  )
}

export default Routing
