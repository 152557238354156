import {
  FacilityType,
  SHIPMENT_COLORS,
  SHIPMENT_VIEW_OPTIONS,
  SUMMARY_STEP_TYPES,
  TRANSPORT_TYPES,
  VehicleType,
} from 'constants/shipments'
import { SearchSource } from 'constants/shipment_source'
import {
  SORT_BY_CREATED_AT,
  ETA_DIFF_DELIVERY,
  ETA_DIFF_POD,
  TRANSPORTATION_DATE_POL_ETD,
  TRANSPORTATION_DATE_POD_ETA,
  TRANSPORTATION_DATE_DELIVERY_ETA,
  SORT_BY_LAST_ETA_CHANGE,
} from 'features/shipments/constants/shipment'

export type TransportType = typeof TRANSPORT_TYPES[number]
export type StepType = typeof SUMMARY_STEP_TYPES[number]
export type ColorType = typeof SHIPMENT_COLORS[number]
export interface Emissions {
  co2E?: string
}
export interface Location {
  id?: number
  name?: string
  countryCode?: string
  country?: string
  address?: string
  city?: string
  stateName?: string
}

export interface Parties {
  shipper?: string
  consignee?: string
  consignor?: string
  forwarder?: string
}
export interface SummaryStep {
  actualTime?: string
  estimatedTime?: string
  plannedTime?: string
  location: Location
  transportType: TransportType
  facilityType: FacilityType
}
export interface SummaryCurrentStep {
  inTransit?: boolean
  stepType: StepType
  transportType: TransportType
  vehicleType: VehicleType | null
}
export interface Summary {
  current: SummaryCurrentStep
  mainTransportMode: TransportType
  pickup?: SummaryStep
  pol?: SummaryStep
  pod?: SummaryStep
  delivery?: SummaryStep
}

export type Reference = string

export interface ShipmentAlertSummary {
  alertType: OngoingAlertType
  details: string
}

export interface Shipment {
  id: string
  clientBookingNumber?: Reference
  clientReference?: Reference
  forwarderReference?: Reference
  shipmentReference?: Reference
  wakeoReference: Reference
  color: ColorType
  emissions: Emissions
  favorite: boolean
  summary: Summary
  // Used internally to determine which component requested the shipment
  source?: SearchSource
  parties: Parties
  createdAt: string
  organization: string
  ongoingAlert?: ShipmentAlertSummary
}

export enum OngoingAlertType {
  PARCEL_EXCEPTION = 13_000,
  CUSTOMER_MOVED,
  CUSTOMER_REFUSED_DELIVERY,
  DELAYED_CUSTOMS_CLEARANCE,
  DELAYED_EXTERNAL_FACTORS,
  HELD_FOR_PAYMENT,
  INCORRECT_ADDRESS,
  PICKUP_MISSED,
  REJECTED_BY_CARRIER,
  RETURNING_TO_SENDER,
  RETURNED_TO_SENDER,
  SHIPMENT_DAMAGE,
  SHIPMENT_LOST,
  FAILED_ATTEMPT,
  ADDRESSEE_NOT_AVAILABLE,
  BUSINESS_CLOSED,
}

export type ShipmentViewType = typeof SHIPMENT_VIEW_OPTIONS[number]

export type ShipmentListSortBy =
  | typeof SORT_BY_CREATED_AT
  | typeof ETA_DIFF_DELIVERY
  | typeof ETA_DIFF_POD
  | typeof TRANSPORTATION_DATE_POL_ETD
  | typeof TRANSPORTATION_DATE_POD_ETA
  | typeof TRANSPORTATION_DATE_DELIVERY_ETA
  | typeof SORT_BY_LAST_ETA_CHANGE

export type ShipmentColor = typeof SHIPMENT_COLORS[number]
