import React, { useEffect, useMemo, useState } from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import AlternativeRoutes from 'views/trusted_routes/components/alternative_routes'
import Page from 'components/page'
import SearchBar from 'views/trusted_routes/components/search_bar'
import {
  selectDirection,
  selectSortBy,
  selectTrustedRoutes,
  selectTrustedRoutesStatus,
  SORT_BY_DEPARTURE_AT,
  SORT_BY_ARRIVAL_AT,
  SORT_BY_TRANSIT_TIME,
  SORT_BY_RELIABILITY,
  SORT_BY_SUSTAINABILITY,
  SORT_BY_RATE,
  RATE_20_DRY,
  RATE_40_DRY,
  RATE_40_HC,
  selectDisplayRateType,
  selectShowAlternatives,
} from 'views/trusted_routes/slice'
import S from 'views/trusted_routes/style'
import Item from 'views/trusted_routes/components/item'
import Filters from 'views/trusted_routes/components/filters'
import Loader from 'views/trusted_routes/components/loader'
import Header from 'views/trusted_routes/components/header'
import { DIRECTION_ASCENDING } from 'features/shipments/constants/shipment'
import { sortBy as arraySortBy, reverse as arrayReverse } from 'services/helpers/values'
import { TrustedRoute as TrustedRouteType } from 'views/trusted_routes/types/trusted_route'
import useBreakpoints from 'services/hooks/use_breakpoints'
import FiltersContext from 'views/trusted_routes/contexts/filters'
import useOnce from 'services/hooks/use_once'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { trustedRouteProfile } from 'views/iam/slices/iamSlice'
import useCurrentUser from 'views/iam/hooks/use_current_user'
import LoadingView from 'views/loading'
import ConvertToBooking from 'views/trusted_routes/components/convert_to_booking'
import useModal from 'components/modal/hooks/use_modal'
import { SUBSCRIPTION_REJECTED, UNSTARTED } from 'views/trusted_routes/types/status'
import {
  isLoopCompleted,
  isProcessing,
  isLoopCompletedSuccessfully,
} from 'views/trusted_routes/helpers'
import ErrorNotification from 'views/errors/error_notification'
import DateHelper from 'services/helpers/date_helper'

const TrustedRoute: React.FC = () => {
  const { t } = useTranslation()
  const status = useSelector(selectTrustedRoutesStatus)
  const allTrustedRoutes = useSelector(selectTrustedRoutes)
  const direction = useSelector(selectDirection)
  const sortBy = useSelector(selectSortBy)
  const displayRateType = useSelector(selectDisplayRateType)
  const showAlternatives = useSelector(selectShowAlternatives)
  const dispatch = useAppDispatch()
  const user = useCurrentUser()
  const { opened, setOpen } = useModal('trustedRoutesConvertToBooking')
  const [trustedRouteToBeConverted, setTrustedRouteToBeConverted] = useState<
    undefined | TrustedRouteType
  >()

  const sort = {
    [SORT_BY_DEPARTURE_AT]: (routes: TrustedRouteType[]): TrustedRouteType[] =>
      arraySortBy(routes, [(route) => route.legs[0].departure.date]),
    [SORT_BY_ARRIVAL_AT]: (routes: TrustedRouteType[]): TrustedRouteType[] =>
      arraySortBy(routes, [(route) => route.legs.slice(-1)[0].departure.date]),
    [SORT_BY_TRANSIT_TIME]: (routes: TrustedRouteType[]): TrustedRouteType[] =>
      arraySortBy(routes, [(route) => route.transitTime]),
    [SORT_BY_RELIABILITY]: (routes: TrustedRouteType[]): TrustedRouteType[] =>
      arraySortBy(routes, [(route) => route.reliability?.notation || 'F']),
    [SORT_BY_SUSTAINABILITY]: (routes: TrustedRouteType[]): TrustedRouteType[] =>
      arraySortBy(routes, [(route) => route.emission?.co2E || Infinity]),
    [SORT_BY_RATE]: (routes: TrustedRouteType[]): TrustedRouteType[] =>
      arraySortBy(routes, [
        (route) => {
          let rate
          switch (displayRateType) {
            case RATE_20_DRY:
              rate = route.bestRate20Dry?.rate20Dry
              break
            case RATE_40_DRY:
              rate = route.bestRate40Dry?.rate40Dry
              break
            case RATE_40_HC:
              rate = route.bestRate40HC?.rate40Hc
              break
            default:
              rate = null
              break
          }
          return rate || Infinity
        },
      ]),
  }[sortBy]

  const visibleTrustedRoutes = allTrustedRoutes.filter((trustedRoute) => trustedRoute.visible)
  const trustedRoutes =
    direction === DIRECTION_ASCENDING
      ? sort(visibleTrustedRoutes)
      : arrayReverse(sort(visibleTrustedRoutes))

  const { isDesktopLarge } = useBreakpoints()
  const [isFiltersVisible, setIsFiltersVisible] = useState<boolean>(false)

  useEffect(() => {
    setIsFiltersVisible(isDesktopLarge)
  }, [isDesktopLarge, setIsFiltersVisible])

  useOnce(() => {
    if (!user.trustedRouteSettings) {
      dispatch(trustedRouteProfile())
    }
  })

  const displayAllianceWarning = useMemo(() => {
    if (trustedRoutes.length === 0) {
      return false
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const tr of trustedRoutes) {
      if (
        ['HLCU', 'MAEU', 'HDMU', 'ONEY', 'YMLU'].includes(tr.scac) &&
        new DateHelper(tr.legs[0].departure.date).isAfter(new Date(2025, 1, 1))
      ) {
        return true
      }
    }
    return false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trustedRoutes.length])

  const convertToBooking = (trustedRoute: TrustedRouteType) => {
    setTrustedRouteToBeConverted(trustedRoute)
    setOpen(true)
  }

  return (
    <Page plain>
      <FiltersContext.Provider
        value={{ visible: isFiltersVisible, setVisible: setIsFiltersVisible }}
      >
        {!user.trustedRouteSettings ? (
          <LoadingView />
        ) : (
          <S.TrustedRoutePageContainer>
            <SearchBar />
            {showAlternatives &&
              (isProcessing(status) ||
                (isLoopCompletedSuccessfully(status) && <AlternativeRoutes />))}
            <S.Header>
              {isProcessing(status) && <Loader />}
              {isLoopCompleted(status) && allTrustedRoutes.length === 0 && (
                <S.NoResult>{t('trustedRoutes.noResult')}</S.NoResult>
              )}
              {status !== UNSTARTED && allTrustedRoutes.length > 0 && (
                <Header totalCount={visibleTrustedRoutes.length} />
              )}
            </S.Header>
            {status === SUBSCRIPTION_REJECTED && <ErrorNotification />}
            {status !== UNSTARTED && allTrustedRoutes.length > 0 && (
              <S.Content>
                <Filters trustedRoutes={allTrustedRoutes} />
                <S.List>
                  {/* To be deleted as soon as the alliance change is complete (around April 2025) */}
                  {displayAllianceWarning && (
                    <S.AllianceWarning>
                      <div>{t('trustedRoutes.alliance_warning.intro')}</div>
                      <ul>
                        <li>{t('trustedRoutes.alliance_warning.premierAlliance')}</li>
                        <li>{t('trustedRoutes.alliance_warning.geminiCorporation')}</li>
                      </ul>
                      <div>{t('trustedRoutes.alliance_warning.outro')}</div>
                    </S.AllianceWarning>
                  )}

                  {trustedRoutes.map((trustedRoute, index) => (
                    <Item
                      key={trustedRoute.token}
                      trustedRoute={trustedRoute}
                      convertToBooking={convertToBooking}
                      index={index}
                    />
                  ))}
                </S.List>
              </S.Content>
            )}
          </S.TrustedRoutePageContainer>
        )}
      </FiltersContext.Provider>
      {opened && trustedRouteToBeConverted && (
        <ConvertToBooking
          trustedRoute={trustedRouteToBeConverted}
          onCancel={() => setTrustedRouteToBeConverted(undefined)}
        />
      )}
    </Page>
  )
}

export default TrustedRoute
