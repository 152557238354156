import { SortByValue } from 'views/trusted_routes/slice'

import { isPresent } from 'services/helpers/values'

export const SORTBY_KEY = 'sortBy'

export interface SortingQueryParams {
  [SORTBY_KEY]?: SortByValue
}

export const forceSortingBy = (queryParams: SortingQueryParams): boolean =>
  isPresent(queryParams.sortBy)

export const forceSortKey = (): string => SORTBY_KEY
