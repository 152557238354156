import React from 'react'
import PropTypes from 'prop-types'

import { validateChildrenOfTypes } from 'services/helpers/prop_types'

import Icon from 'components/icon'
import { StyledShipmentFiltersTag } from 'features/shipments/components/shipment_filters/style'

import {
  StyledSavedFiltersItem,
  StyledSavedFiltersSection,
  StyledSavedFiltersTitle,
  StyledSavedFiltersArrow,
  StyledSavedFiltersTrash,
} from 'components/shipment_filters_saved_item/style'

const BookingFiltersSavedItem = ({
  label,
  editMode = false,
  children,
  onClickItem,
  onClickDelete,
  testId,
}: {
  label?: string
  editMode?: boolean
  children?: React.ReactElement[]
  onClickItem: () => void
  onClickDelete: () => void
  testId: string
}) => (
  <StyledSavedFiltersItem
    as={editMode ? 'div' : 'button'}
    onClick={onClickItem}
    data-testid={testId}
  >
    <StyledSavedFiltersSection>
      <StyledSavedFiltersTitle>{label}</StyledSavedFiltersTitle>
      <div>{children}</div>
      {!editMode && (
        <StyledSavedFiltersArrow>
          <Icon name='arrow_bottom_outline' />
        </StyledSavedFiltersArrow>
      )}
      {editMode && (
        <StyledSavedFiltersTrash onClick={onClickDelete}>
          <Icon name='trash' />
        </StyledSavedFiltersTrash>
      )}
    </StyledSavedFiltersSection>
  </StyledSavedFiltersItem>
)

BookingFiltersSavedItem.propTypes = {
  /** Children */
  children: validateChildrenOfTypes([StyledShipmentFiltersTag]),
  /** Label */
  label: PropTypes.string,
  /** Edit mode */
  editMode: PropTypes.bool,
  /** On click delete */
  onClickDelete: PropTypes.func,
  /** On click delete */
  onClickItem: PropTypes.func,
  testId: PropTypes.string,
}

BookingFiltersSavedItem.defaultProps = {
  children: undefined,
  label: undefined,
  editMode: false,
  onClickDelete: undefined,
  onClickItem: undefined,
  testId: undefined,
}

export default BookingFiltersSavedItem
