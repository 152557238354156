import { useSelector } from 'react-redux'

import useStatus, { UseStatus } from 'services/api/hooks/use_status'

import { selectAnalyticsStatus } from 'features/analytics/store/slice'
import { RootState } from 'services/store/store'

const useAnalytics = (): UseStatus =>
  useStatus(useSelector((state: RootState) => selectAnalyticsStatus(state)))

export default useAnalytics
