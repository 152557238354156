import { FieldNamesMarkedBoolean } from 'react-hook-form'

import {
  MERCHANDISE_FCL,
  MERCHANDISE_LCL,
  MERCHANDISE_TYPE_CONTAINER,
  MERCHANDISE_TYPE_PACKAGE,
  MERCHANDISE_TYPE_PACKAGE_TOTAL,
  MERCHANDISE_TYPE_TOTAL,
} from 'constants/bookings'
import { TRANSPORT_TYPE_SEA, TRANSPORT_TYPE_AIR } from 'constants/shipments'
import { isFieldDirty, isPresent } from 'services/helpers/values'
import {
  AirShipmentAttributeSchema,
  AirTransportDetailsSchema,
  AnyTimelineStepSchema,
  BookingFormData,
  BookingFormInput,
  BookingMerchandiseContainerSchema,
  BookingMerchandisePackageSchema,
  BookingMerchandisePackageTotalSchema,
  BookingMerchandiseTotalSchema,
  CommonMerchandiseDetailsSchema,
  OptionalTimelineStepSchema,
  SeaShipmentAttributeSchema,
  SeaTransportDetailsSchema,
} from 'views/booking/components/form/types'
import {
  AnyBookingMerchandise,
  BookingMerchandiseContainer,
  BookingMerchandisePackage,
  BookingMerchandiseTotal,
  BookingMerchandiseTotalPackage,
  CommonMerchandiseDetails,
  CreateBookingData,
  EditBookingData,
} from 'views/booking/slices/types'
import { UNKNOWN_CARRIER_ID } from 'views/booking/components/form/transport_details'

const buildCommonMerchandiseDetails = (
  merchandise: CommonMerchandiseDetailsSchema
): CommonMerchandiseDetails => ({
  ...merchandise,
  productDescription: merchandise.productDescription || '',
  controlledTemperatures: {
    min: merchandise.controlledTemperatures?.min ?? undefined,
    max: merchandise.controlledTemperatures?.max ?? undefined,
  },
  commercialValue:
    merchandise.commercialValue?.amount && merchandise.commercialValue.currencyCode
      ? {
          amount: merchandise.commercialValue.amount,
          currencyCode: merchandise.commercialValue.currencyCode.label,
        }
      : undefined,
  hazardousGoods: {
    ...merchandise.hazardousGoods,
    hazardousClass: merchandise.hazardousGoods?.hazardousClass?.value,
    unNumber: merchandise.hazardousGoods?.unNumber || '',
    packingGroup: merchandise.hazardousGoods?.packingGroup?.value,
    weight: merchandise.hazardousGoods?.weight
      ? {
          value: merchandise.hazardousGoods.weight,
          unit: 'kg',
        }
      : undefined,
  },
})

const buildMerchandiseTotalPackage = (
  bookingFormData: BookingFormData
): BookingMerchandiseTotalPackage => {
  const bookingMerchandisePackageTotal = bookingFormData.merchandise
    .content as BookingMerchandisePackageTotalSchema

  return {
    content:
      bookingMerchandisePackageTotal.packageTotal.merchandiseDetails?.map((m) =>
        buildCommonMerchandiseDetails(m)
      ) || [],
    volume: {
      value: bookingMerchandisePackageTotal.packageTotal.volume,
      unit: 'cbm',
    },
    weight: {
      value: bookingMerchandisePackageTotal.packageTotal.weight,
      unit: 'kg',
    },
    packageNumber: bookingMerchandisePackageTotal.packageTotal.packageNumber,
    shippingMode: MERCHANDISE_LCL,
    merchandiseType: MERCHANDISE_TYPE_PACKAGE_TOTAL,
  }
}

const buildMerchandiseTotal = (bookingFormData: BookingFormData): BookingMerchandiseTotal => {
  const bookingMerchandiseTotal = bookingFormData.merchandise
    .content as BookingMerchandiseTotalSchema

  return {
    content: {
      containers: bookingMerchandiseTotal.total.containers.map((container) => ({
        ...container,
        containerType: container.containerType.value,
      })),
      products:
        bookingMerchandiseTotal.total.products?.map((product) => ({
          ...buildCommonMerchandiseDetails(product),
          volume: product.volume ? { value: product.volume, unit: 'cbm' } : undefined,
          weight: product.weight ? { value: product.weight, unit: 'kg' } : undefined,
          packageNumber: product.packageNumber!,
        })) || [],
    },
    shippingMode: MERCHANDISE_FCL,
    merchandiseType: MERCHANDISE_TYPE_TOTAL,
  }
}

const buildMerchandisePackage = (bookingFormData: BookingFormData): BookingMerchandisePackage => {
  const bookingMerchandisePackage = bookingFormData.merchandise
    .content as BookingMerchandisePackageSchema

  return {
    content: bookingMerchandisePackage.package.packages.map((p) => ({
      ...buildCommonMerchandiseDetails(p),
      quantity: p.quantity,
      packageType: p.packageType.value,
      weight: {
        value: p.weight,
        unit: 'kg',
      },
      length: {
        value: p.length,
        unit: 'cm',
      },
      width: {
        value: p.width,
        unit: 'cm',
      },
      height: {
        value: p.height,
        unit: 'cm',
      },
    })),
    shippingMode: MERCHANDISE_LCL,
    merchandiseType: MERCHANDISE_TYPE_PACKAGE,
  }
}

const buildMerchandiseContainer = (
  bookingFormData: BookingFormData
): BookingMerchandiseContainer => {
  const bookingMerchandiseContainer = bookingFormData.merchandise
    .content as BookingMerchandiseContainerSchema

  return {
    content:
      bookingMerchandiseContainer.container?.map((container) => ({
        containerType: container.containerType.value,
        quantity: container.quantity,
        products:
          container.products?.map((product) => ({
            ...buildCommonMerchandiseDetails(product),
            packageNumber: product.packageNumber!,
            volume: product.volume
              ? {
                  value: product.volume,
                  unit: 'cbm',
                }
              : undefined,
            weight: product.weight
              ? {
                  value: product.weight,
                  unit: 'kg',
                }
              : undefined,
          })) || [],
      })) || [],
    shippingMode: MERCHANDISE_FCL,
    merchandiseType: MERCHANDISE_TYPE_CONTAINER,
  }
}

const buildMerchandise = (bookingFormData: BookingFormData): AnyBookingMerchandise => {
  if (bookingFormData.merchandise.merchandiseType === MERCHANDISE_TYPE_PACKAGE_TOTAL) {
    return buildMerchandiseTotalPackage(bookingFormData)
  }

  if (bookingFormData.merchandise.merchandiseType === MERCHANDISE_TYPE_TOTAL) {
    return buildMerchandiseTotal(bookingFormData)
  }

  if (bookingFormData.merchandise.merchandiseType === MERCHANDISE_TYPE_PACKAGE) {
    return buildMerchandisePackage(bookingFormData)
  }

  return buildMerchandiseContainer(bookingFormData)
}

const isMerchandiseDirty = (
  bookingFormData: BookingFormData,
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<BookingFormInput>>>
): boolean => {
  if (
    bookingFormData.merchandise.merchandiseType === MERCHANDISE_TYPE_CONTAINER &&
    isFieldDirty(dirtyFields.merchandise?.content?.container)
  )
    return true

  if (
    bookingFormData.merchandise.merchandiseType === MERCHANDISE_TYPE_TOTAL &&
    isFieldDirty(dirtyFields.merchandise?.content?.total)
  )
    return true

  if (
    bookingFormData.merchandise.merchandiseType === MERCHANDISE_TYPE_PACKAGE &&
    isFieldDirty(dirtyFields.merchandise?.content?.package)
  )
    return true

  if (
    bookingFormData.merchandise.merchandiseType === MERCHANDISE_TYPE_PACKAGE_TOTAL &&
    isFieldDirty(dirtyFields.merchandise?.content?.packageTotal)
  )
    return true

  return false
}

export const generateEditPayload = (
  bookingFormData: BookingFormData,
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<BookingFormInput>>>
): EditBookingData => {
  const payload: EditBookingData = {
    transportPlan: {
      withPreCarriage: bookingFormData.routing.withPreCarriage,
      withPol: isPresent((bookingFormData.routing.pol as AnyTimelineStepSchema)?.location),
      withPod: isPresent((bookingFormData.routing.pod as AnyTimelineStepSchema)?.location),
      withOnCarriage: bookingFormData.routing.withPostCarriage,
    },
  }

  const transportDetails =
    bookingFormData.transportType === TRANSPORT_TYPE_SEA
      ? (bookingFormData.transportDetails as SeaTransportDetailsSchema)
      : (bookingFormData.transportDetails as AirTransportDetailsSchema)

  if (isFieldDirty(dirtyFields.references?.clientReference))
    payload.clientReference = bookingFormData.references.clientReference

  if (isFieldDirty(dirtyFields.references?.clientBookingNumber))
    payload.clientBookingNumber = bookingFormData.references.clientBookingNumber

  if (isFieldDirty(dirtyFields.references?.forwarderReference))
    payload.forwarderReference = bookingFormData.references.forwarderReference

  if (isFieldDirty(dirtyFields.references?.customReferences))
    payload.customReferences = bookingFormData.references.customReferences

  if (isFieldDirty(dirtyFields.parties?.shipper))
    payload.shipperId = bookingFormData.parties.shipper.value

  if (isFieldDirty(dirtyFields.parties?.forwarder))
    payload.forwarderId = bookingFormData.parties.forwarder.value

  if (isFieldDirty(dirtyFields.parties?.consignee))
    payload.consigneeId = bookingFormData.parties.consignee.value

  if (isFieldDirty(dirtyFields.parties?.consignor))
    payload.consignorId = bookingFormData.parties.consignor.value

  if (isFieldDirty(dirtyFields.incoterms?.incoterms))
    payload.incoterms = bookingFormData.incoterms.incoterms.value

  if (isFieldDirty(dirtyFields.incoterms?.incotermsLocation))
    payload.incotermsLocation = bookingFormData.incoterms.incotermsLocation

  if (isFieldDirty(dirtyFields.transportType)) payload.transportType = bookingFormData.transportType

  if (isFieldDirty(dirtyFields.transportDetails?.carrier))
    payload.carrier = transportDetails.carrier
      ? {
          id: transportDetails.carrier.value,
          info: transportDetails.carrier.createValue,
        }
      : {
          id: UNKNOWN_CARRIER_ID,
        }

  if (
    bookingFormData.transportType === TRANSPORT_TYPE_SEA &&
    isFieldDirty(dirtyFields.transportDetails)
  ) {
    const seaTransportDetails = transportDetails as SeaTransportDetailsSchema
    const seaTransportDetailsDirtyFields = dirtyFields.transportDetails as Partial<
      Readonly<FieldNamesMarkedBoolean<SeaTransportDetailsSchema>>
    >

    if (isFieldDirty(seaTransportDetailsDirtyFields.bookingNumber))
      payload.bookingNumber = seaTransportDetails.bookingNumber

    if (isFieldDirty(seaTransportDetailsDirtyFields.masterBl))
      payload.masterBl = seaTransportDetails.masterBl

    if (isFieldDirty(seaTransportDetailsDirtyFields.voyageNumbers))
      payload.voyageNumbers = seaTransportDetails.voyageNumbers

    if (isFieldDirty(seaTransportDetailsDirtyFields.vessels)) {
      payload.vesselImos =
        seaTransportDetails.vessels?.filter((v) => !isPresent(v.createValue)).map((v) => v.value) ||
        []

      payload.vesselInfo =
        seaTransportDetails.vessels
          ?.filter((v) => isPresent(v.createValue))
          .map((v) => v.createValue!) || []
    }

    if (isFieldDirty(seaTransportDetailsDirtyFields.shipmentAttributes))
      payload.shipmentAttributes = seaTransportDetails.shipmentAttributes?.map(
        (shipmentAttribute) => ({
          ...shipmentAttribute,
          shipmentReference: shipmentAttribute.shipmentReference || undefined,
          weight: shipmentAttribute.weight
            ? {
                value: shipmentAttribute.weight,
                unit: 'kg',
              }
            : undefined,
          containerType: shipmentAttribute.containerType?.label,
        })
      )
  } else if (
    bookingFormData.transportType === TRANSPORT_TYPE_AIR &&
    isFieldDirty(dirtyFields.transportDetails)
  ) {
    const airTransportDetails = transportDetails as AirTransportDetailsSchema
    const airTransportDetailsDirtyFields = dirtyFields.transportDetails as Partial<
      Readonly<FieldNamesMarkedBoolean<AirTransportDetailsSchema>>
    >

    if (isFieldDirty(airTransportDetailsDirtyFields.flightNumbers))
      payload.flightNumbers = airTransportDetails.flightNumbers

    if (isFieldDirty(airTransportDetailsDirtyFields.shipmentAttributes))
      payload.shipmentAttributes = airTransportDetails.shipmentAttributes?.map(
        (shipmentAttribute) => ({
          ...shipmentAttribute,
          shipmentReference: shipmentAttribute.shipmentReference || undefined,
          weight: shipmentAttribute.weight
            ? {
                value: shipmentAttribute.weight,
                unit: 'kg',
              }
            : undefined,
        })
      )
  }

  if (isFieldDirty(dirtyFields.miscellaneous?.vgmCutOffDate))
    payload.vgmCutOffDate = bookingFormData.miscellaneous.vgmCutOffDate

  if (isFieldDirty(dirtyFields.miscellaneous?.cutOffDate))
    payload.vesselCutOffDate = bookingFormData.miscellaneous.cutOffDate

  if (isFieldDirty(dirtyFields.miscellaneous?.customFields))
    payload.customFields = bookingFormData.miscellaneous.customFields || []

  const isPreCarriageFullyUpdated = dirtyFields.routing?.postCarriage === true

  if (
    isPreCarriageFullyUpdated ||
    (typeof dirtyFields.routing?.preCarriage !== 'boolean' &&
      isFieldDirty(dirtyFields.routing?.preCarriage?.location))
  )
    payload.preCarriageAddressId = bookingFormData.routing.preCarriage?.location?.value

  if (
    isPreCarriageFullyUpdated ||
    (typeof dirtyFields.routing?.preCarriage !== 'boolean' &&
      isFieldDirty(dirtyFields.routing?.preCarriage?.ptd))
  )
    payload.ptd = bookingFormData.routing.preCarriage?.ptd

  if (isFieldDirty(dirtyFields.routing?.pol)) {
    const pol = bookingFormData.routing.pol as OptionalTimelineStepSchema
    const isPolFullyUpdated = dirtyFields.routing?.pol === true

    if (
      isPolFullyUpdated ||
      (typeof dirtyFields.routing?.pol !== 'boolean' &&
        isFieldDirty(dirtyFields.routing?.pol?.location))
    )
      payload.polId = pol?.location?.value

    if (
      isPolFullyUpdated ||
      (typeof dirtyFields.routing?.pol !== 'boolean' && isFieldDirty(dirtyFields.routing?.pol?.pta))
    )
      payload.polPta = pol?.pta

    if (
      isPolFullyUpdated ||
      (typeof dirtyFields.routing?.pol !== 'boolean' && isFieldDirty(dirtyFields.routing?.pol?.ptd))
    )
      payload.polPtd = pol?.ptd
  }

  if (isFieldDirty(dirtyFields.routing?.transshipments))
    payload.transshipments =
      bookingFormData.routing.transshipments?.map((ts) => ({
        addressId: ts.location.value,
        pta: ts.pta,
        ptd: ts.ptd,
      })) || []

  if (isFieldDirty(dirtyFields.routing?.pod)) {
    const pod = bookingFormData.routing.pod as OptionalTimelineStepSchema
    const isPodFullyUpdated = dirtyFields.routing?.pod === true

    if (
      isPodFullyUpdated ||
      (typeof dirtyFields.routing?.pod !== 'boolean' &&
        isFieldDirty(dirtyFields.routing?.pod?.location))
    )
      payload.podId = pod?.location?.value

    if (
      isPodFullyUpdated ||
      (typeof dirtyFields.routing?.pod !== 'boolean' && dirtyFields.routing?.pod?.pta)
    )
      payload.podPta = pod?.pta

    if (
      isPodFullyUpdated ||
      (typeof dirtyFields.routing?.pod !== 'boolean' && dirtyFields.routing?.pod?.ptd)
    )
      payload.podPtd = pod?.ptd
  }

  const isPostCarriageFullyUpdated = dirtyFields.routing?.postCarriage === true

  if (
    isPostCarriageFullyUpdated ||
    (typeof dirtyFields.routing?.postCarriage !== 'boolean' &&
      isFieldDirty(dirtyFields.routing?.postCarriage?.location))
  )
    payload.onCarriageAddressId = bookingFormData.routing.postCarriage?.location?.value

  if (
    isPostCarriageFullyUpdated ||
    (typeof dirtyFields.routing?.postCarriage !== 'boolean' &&
      isFieldDirty(dirtyFields.routing?.postCarriage?.pta))
  )
    payload.pta = bookingFormData.routing.postCarriage?.pta

  if (isFieldDirty(dirtyFields.rate?.withRateConfirmation))
    payload.rateConfirmation = bookingFormData.rate.withRateConfirmation

  if (
    isFieldDirty(dirtyFields.rate?.rateconfirmation) &&
    bookingFormData.rate.rateconfirmation?.amount &&
    bookingFormData.rate.rateconfirmation?.currencyCode
  )
    payload.rate = {
      amount: bookingFormData.rate.rateconfirmation.amount,
      currencyCode: bookingFormData.rate.rateconfirmation.currencyCode.label,
    }

  if (isMerchandiseDirty(bookingFormData, dirtyFields))
    payload.merchandise = buildMerchandise(bookingFormData)

  if (isFieldDirty(dirtyFields.keyContacts))
    payload.assignedUsers = bookingFormData.keyContacts.map((kc) => ({ email: kc }))

  if (isFieldDirty(dirtyFields.comments?.shipperComment))
    payload.comments = bookingFormData.comments?.shipperComment

  if (isFieldDirty(dirtyFields.comments?.forwarderComment))
    payload.forwarderComment = bookingFormData.comments?.forwarderComment

  return payload
}

export const generateCreatePayload = (bookingFormData: BookingFormData): CreateBookingData => {
  const transportDetails =
    bookingFormData.transportType === TRANSPORT_TYPE_SEA
      ? (bookingFormData.transportDetails as SeaTransportDetailsSchema)
      : (bookingFormData.transportDetails as AirTransportDetailsSchema)

  return {
    shipperId: bookingFormData.parties.shipper.value,
    forwarderId: bookingFormData.parties.forwarder.value,
    consignorId: bookingFormData.parties.consignor.value,
    consigneeId: bookingFormData.parties.consignee.value,
    clientReference: bookingFormData.references.clientReference,
    clientBookingNumber: bookingFormData.references.clientBookingNumber,
    comments: bookingFormData.comments?.shipperComment,
    incoterms: bookingFormData.incoterms.incoterms.value,
    incotermsLocation: bookingFormData.incoterms.incotermsLocation,
    merchandise: buildMerchandise(bookingFormData),
    carrier: transportDetails.carrier
      ? {
          id: transportDetails.carrier.value,
          info: transportDetails.carrier.createValue,
        }
      : undefined,
    bookingNumber:
      bookingFormData.transportType === TRANSPORT_TYPE_SEA
        ? (transportDetails as SeaTransportDetailsSchema).bookingNumber
        : undefined,
    masterBl:
      bookingFormData.transportType === TRANSPORT_TYPE_SEA
        ? (transportDetails as SeaTransportDetailsSchema).masterBl
        : undefined,
    vesselImos:
      bookingFormData.transportType === TRANSPORT_TYPE_SEA
        ? (transportDetails as SeaTransportDetailsSchema).vessels
            ?.filter((v) => !isPresent(v.createValue))
            .map((v) => v.value) || []
        : [],
    vesselInfo:
      bookingFormData.transportType === TRANSPORT_TYPE_SEA
        ? (transportDetails as SeaTransportDetailsSchema).vessels
            ?.filter((v) => isPresent(v.createValue))
            .map((v) => v.createValue as string) || []
        : [],
    voyageNumbers:
      bookingFormData.transportType === TRANSPORT_TYPE_SEA
        ? (transportDetails as SeaTransportDetailsSchema).voyageNumbers
        : undefined,
    flightNumbers:
      bookingFormData.transportType === TRANSPORT_TYPE_AIR
        ? (transportDetails as AirTransportDetailsSchema).flightNumbers
        : undefined,
    vgmCutOffDate: bookingFormData.miscellaneous.vgmCutOffDate,
    vesselCutOffDate: bookingFormData.miscellaneous.cutOffDate,
    shipmentAttributes: bookingFormData.transportDetails?.shipmentAttributes?.map(
      (shipmentAttribute) => ({
        shipmentReference: shipmentAttribute.shipmentReference || undefined,
        containerNumber:
          bookingFormData.transportType === TRANSPORT_TYPE_SEA
            ? (shipmentAttribute as SeaShipmentAttributeSchema).containerNumber
            : undefined,
        containerType:
          bookingFormData.transportType === TRANSPORT_TYPE_SEA
            ? (shipmentAttribute as SeaShipmentAttributeSchema).containerType?.label
            : undefined,
        weight: shipmentAttribute.weight
          ? {
              value: shipmentAttribute.weight,
              unit: 'kg',
            }
          : undefined,
        masterAwb:
          bookingFormData.transportType === TRANSPORT_TYPE_AIR
            ? (shipmentAttribute as AirShipmentAttributeSchema).masterAwb
            : undefined,
      })
    ),
    transportType: bookingFormData.transportType,
    ptd: bookingFormData.routing.preCarriage?.ptd,
    preCarriageAddressId: bookingFormData.routing.preCarriage?.location?.value,
    polId: bookingFormData.routing.pol
      ? (bookingFormData.routing.pol as OptionalTimelineStepSchema)?.location?.value
      : undefined,
    polPta: bookingFormData.routing.pol
      ? (bookingFormData.routing.pol as OptionalTimelineStepSchema)?.pta
      : undefined,
    polPtd: bookingFormData.routing.pol
      ? (bookingFormData.routing.pol as OptionalTimelineStepSchema)?.ptd
      : undefined,
    transshipments:
      bookingFormData.routing.transshipments?.map((ts) => ({
        addressId: ts.location.value,
        pta: ts.pta,
        ptd: ts.ptd,
      })) || [],
    podId: bookingFormData.routing.pod
      ? (bookingFormData.routing.pod as OptionalTimelineStepSchema)?.location?.value
      : undefined,
    podPta: bookingFormData.routing.pod
      ? (bookingFormData.routing.pod as OptionalTimelineStepSchema)?.pta
      : undefined,
    podPtd: bookingFormData.routing.pod
      ? (bookingFormData.routing.pod as OptionalTimelineStepSchema)?.ptd
      : undefined,
    onCarriageAddressId: bookingFormData.routing.postCarriage?.location?.value,
    pta: bookingFormData.routing.postCarriage?.pta,
    rateConfirmation: bookingFormData.rate.withRateConfirmation,
    transportPlan: {
      withPreCarriage: bookingFormData.routing.withPreCarriage,
      withPol: isPresent(bookingFormData.routing.pol),
      withPod: isPresent(bookingFormData.routing.pod),
      withOnCarriage: bookingFormData.routing.withPostCarriage,
    },
    assignedUsers: bookingFormData.keyContacts.map((kc) => ({ email: kc })),
    customReferences: bookingFormData.references.customReferences || [],
  }
}
