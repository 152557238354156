import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import { STATUS_PENDING, STATUS_FULFILLED, STATUS_REJECTED } from 'constants/api'

import { InternalClient } from 'services/api/clients'
import onError from 'services/api/error'
import useUrlParams from 'services/api/hooks/use_url_params'
import useThunkTeamsParam from 'services/api/hooks/use_thunk_teams_param'

import { ActiveValue } from 'core/types/filters'

import type { RootState } from 'services/store/store'
import type { Status } from 'constants/api'
import type { Booking } from 'views/booking/slices/types'
import type { Value } from 'components/booking_filters_custom/hooks/use_select'

// TODO: type filters
export const fetchBookings = createAsyncThunk(
  'bookings/fetchBookings',
  async (
    { filters, page, per }: { filters: Record<string, string>; page: number; per: number },
    thunkAPI
  ) => {
    const teams = useThunkTeamsParam(thunkAPI, { legacyNaming: true })
    const url = useUrlParams(
      '/bookings',
      {
        page,
        per,
        ...teams,
      },
      { escapedParams: filters }
    )

    return InternalClient.get(url)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

export const fetchShippers = createAsyncThunk(
  'bookings/fetchShippers',
  async ({ searchValue }: { searchValue: Value }, thunkAPI) => {
    const url = useUrlParams('/selects/bookings/shippers', { search: searchValue })
    return InternalClient.get(url)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

export const fetchForwarders = createAsyncThunk(
  'bookings/fetchForwarders',
  async ({ searchValue }: { searchValue: Value }, thunkAPI) => {
    const url = useUrlParams('/selects/bookings/forwarders', { search: searchValue })
    return InternalClient.get(url)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

export const fetchConsignors = createAsyncThunk(
  'bookings/fetchConsignors',
  async ({ searchValue }: { searchValue: Value }, thunkAPI) => {
    const url = useUrlParams('/selects/bookings/consignors', { search: searchValue })
    return InternalClient.get(url)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

export const fetchConsignees = createAsyncThunk(
  'bookings/fetchConsignees',
  async ({ searchValue }: { searchValue: Value }, thunkAPI) => {
    const url = useUrlParams('/selects/bookings/consignees', { search: searchValue })
    return InternalClient.get(url)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

export const fetchIncoterms = createAsyncThunk(
  'bookings/fetchIncoterms',
  async ({ searchValue }: { searchValue: Value }, thunkAPI) => {
    const url = useUrlParams('/selects/bookings/incoterms', { search: searchValue })
    return InternalClient.get(url)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

export const fetchAddresses = (type: 'origin' | 'destination') =>
  createAsyncThunk(
    'bookings/fetchAddresses',
    async ({ searchValue }: { searchValue: Value }, thunkAPI) => {
      const url = useUrlParams('/selects/bookings/addresses', { search: searchValue, type })
      return InternalClient.get(url)
        .then((r) => r.data)
        .catch(onError(thunkAPI))
    }
  )

const bookingsAdapter = createEntityAdapter<Booking>({
  selectId: ({ token }) => token,
})
interface BookingInitialState {
  status: Status
  totalCount: number
  preloadFilters: {
    shipmentReference?: string
  }
  activeFilters: Record<string, any>[]
  activeValues: ActiveValue[]
}
const initialState = bookingsAdapter.getInitialState<BookingInitialState>({
  status: STATUS_PENDING,
  totalCount: 0,
  preloadFilters: {},
  activeFilters: [],
  activeValues: [],
})

const bookingsSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    saveActiveFilters: (state, action) => {
      state.activeFilters = action.payload.activeFilters
    },
    saveActiveValues: (state, action) => {
      state.activeValues = action.payload.activeValues
    },
    addPreloadFilters: (state, action) => {
      state.preloadFilters = { ...state.preloadFilters, ...action.payload }
    },
    resetPreloadFilters: (state) => {
      state.preloadFilters = {}
    },
    removeAll: bookingsAdapter.removeAll,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBookings.fulfilled, (state, action) => {
      const { bookings, totalCount } = action.payload
      if (action.meta.arg.page <= 1) {
        bookingsAdapter.setAll(state, bookings)
      } else {
        bookingsAdapter.setMany(state, bookings)
      }
      state.totalCount = totalCount
      state.status = STATUS_FULFILLED
    })
    builder.addCase(fetchBookings.pending, (state) => {
      state.status = STATUS_PENDING
    })
    builder.addCase(fetchBookings.rejected, (state) => {
      state.status = STATUS_REJECTED
    })
  },
})

export const {
  addPreloadFilters: addBookingsPreloadFilters,
  resetPreloadFilters: resetBookingsPreloadFilters,
  saveActiveFilters: saveBookingsActiveFilters,
  saveActiveValues: saveBookingsActiveValues,
  removeAll,
} = bookingsSlice.actions

export const { selectAll: selectBookings } = bookingsAdapter.getSelectors(
  (state: RootState) => state.bookings
)

export const selectBookingsStatus = (state: RootState) => state.bookings.status
export const selectBookingsTotalCount = (state: RootState) => state.bookings.totalCount
export const selectBookingsPreloadFilters = (state: RootState) => state.bookings.preloadFilters
export const selectBookingsActiveFilters = (state: RootState) => state.bookings.activeFilters
export const selectBookingsActiveValues = (state: RootState) => state.bookings.activeValues

export default bookingsSlice.reducer
