import React, { ChangeEvent } from 'react'

import Icon from 'components/icon'
import {
  StyledInputCheckbox,
  StyledInputCheckboxIcon,
  StyledInputCheckboxInput,
  StyledInputCheckboxLabel,
} from 'components/input_checkbox/style'
import Tag from 'components/tag'

interface InputCheckboxProps {
  id?: string
  name?: string
  text: string
  // Warning:
  // Some views components consider that the checked props can be null.
  // However, null is not an acceptable value for a checkbox input element.
  // While we fix these components, we temporarily authorise this type, which will be transformed into false in the usage of InputCheckbox.
  // Do not use null as a possible value for new integrations
  //
  // TODO: remove null as allowed value
  checked: boolean | null
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  asTag?: boolean
  color?: 'white' | 'primary' | 'secondary' | 'success' | 'warn' | 'error'
  disabled?: boolean
  testId?: string
}

const InputCheckbox: React.FC<InputCheckboxProps> = ({
  id,
  name,
  text,
  checked,
  onChange,
  asTag = false,
  color,
  disabled = false,
  testId,
}) => (
  <StyledInputCheckbox>
    <StyledInputCheckboxInput
      type='checkbox'
      id={id}
      name={name}
      onChange={onChange}
      checked={checked || false}
      disabled={disabled}
      data-testid={testId}
      $asTag={asTag}
    />
    {asTag && <Tag text={text} highlighted={checked || false} color={color} />}
    {!asTag && (
      <>
        <StyledInputCheckboxIcon as={Icon} name='checkmark' />
        <StyledInputCheckboxLabel htmlFor={id}>{text}</StyledInputCheckboxLabel>
      </>
    )}
  </StyledInputCheckbox>
)

export default InputCheckbox
