import React, { FC } from 'react'

import useComputedUserReferences from 'features/shipments/components/shipment_list_item/hooks/use_computed_user_references'
import useReferences from 'features/shipments/components/shipment_list_item/hooks/use_references'

const References: FC = () => {
  const references = useComputedUserReferences()

  const { References: RefsComponent } = useReferences({ references })
  return <RefsComponent />
}

export default References
