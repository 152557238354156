import { useTheme } from 'styled-components'

import {
  SHIPMENT_COLOR_BLUE,
  SHIPMENT_COLOR_GREEN,
  SHIPMENT_COLOR_ORANGE,
  SHIPMENT_COLOR_RED,
} from 'constants/shipments'

import { ShipmentColor } from 'features/shipments/types/shipment'

const useShipmentColor = () => {
  const theme = useTheme()

  const markerColorMap = {
    [SHIPMENT_COLOR_BLUE]: theme.primary,
    [SHIPMENT_COLOR_GREEN]: theme.success,
    [SHIPMENT_COLOR_ORANGE]: theme.warn,
    [SHIPMENT_COLOR_RED]: theme.error,
  }

  const getColorFromShipmentColor = (shipmentColor: ShipmentColor | undefined) =>
    markerColorMap[shipmentColor || SHIPMENT_COLOR_BLUE]

  const pointerColorMap = {
    [SHIPMENT_COLOR_BLUE]: theme.secondary,
    [SHIPMENT_COLOR_GREEN]: theme.success,
    [SHIPMENT_COLOR_ORANGE]: theme.warn,
    [SHIPMENT_COLOR_RED]: theme.error,
  }

  const getPointerColorFromShipmentColor = (shipmentColor: ShipmentColor | undefined) =>
    pointerColorMap[shipmentColor || SHIPMENT_COLOR_BLUE]

  return { getColorFromShipmentColor, getPointerColorFromShipmentColor }
}

export default useShipmentColor
