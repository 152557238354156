import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import {
  isAnyArray,
  isAnyObject,
  isInputPresent,
  isObjEq,
  isPresent,
  isEqual,
} from 'services/helpers/values'
import useFilterEvents from 'services/hooks/use_filter_events'

import { SelectValue } from 'components/select'

export type TDateRange = { start: string | null; end: string | null }
export type TTags = string[]
type TFilterValueMap = {
  checkbox: boolean | null
  radio: string | null
  dateRange: TDateRange | null
  date: string | null
  select: SelectValue | null
  multiselect: SelectValue[] | null
  text: string | number | null
  number: number | null
  tags: TTags | null
  custom: any
  range: [number, number] | undefined
}
type TFilterErrorMap = {
  checkbox: string
  radio: string
  dateRange: { start?: string; end?: string }
  date: string
  select: string
  multiselect: string[]
  text: string
  number: string
  tags: string[]
  custom: any
  range: string
}
export type TAnyFilter = TFilterValueMap[keyof TFilterValueMap]
export type TAnyFilters = TAnyFilter[]
export type TFilterType = keyof TFilterValueMap
export type TFilterValue<T extends TFilterType> = TFilterValueMap[T]
export type TFilterValues = TFilterValueMap[TFilterType]
export type TFilterError<T extends TFilterType> = TFilterErrorMap[T]

export interface IFilter<T extends TFilterType> {
  type:
    | 'checkbox'
    | 'select'
    | 'multiselect'
    | 'dateRange'
    | 'number'
    | 'text'
    | 'radio'
    | 'tags'
    | 'custom'
    | 'date'
    | 'range'
  name: string
  value: TFilterValue<T>
  onChange: (value: any) => void
  reset: () => void
  isPresent: boolean
  isEdited: boolean
  isValid: boolean
  isDisabled: boolean
  error?: TFilterError<T>
  isRequired: boolean
  setValue: (value: TFilterValue<T>) => void
  setCategoryName: Dispatch<SetStateAction<string | undefined>>
  // TODO: refactor asNull return type, use of `this` or `IFilter<T>` throws an error with the AnyBookingFormFilter interface
  asNull: () => any
}
export type AnyFilter = IFilter<TFilterType>
export interface IFilterProps<T extends TFilterType> {
  name: string
  type: T
  defaultValue?: TFilterValue<T>
  onChange?: ({ name, categoryName }: { name: string; categoryName: string | undefined }) => void
  required?: boolean
  disabled?: boolean
  customValidation?: (value: TFilterValue<T>, setError: (error: TFilterError<T>) => void) => boolean
}

const useFilter = <T extends TFilterType>({
  name,
  type,
  defaultValue: providedDefaultValue = null,
  onChange: onChangeCallback = () => {},
  disabled = false,
  required = false,
  customValidation,
}: IFilterProps<T>): IFilter<T> => {
  const defaultValue: TFilterValue<T> = isInputPresent(providedDefaultValue)
    ? providedDefaultValue
    : {
        checkbox: null,
        radio: null,
        text: '',
        select: null,
        multiselect: [],
        number: null,
        dateRange: { start: null, end: null },
        date: null,
        tags: [],
        custom: {},
        range: undefined,
      }[type]
  const presenceCheck = {
    checkbox: (checked: boolean) => isPresent(checked),
    radio: isInputPresent,
    text: isInputPresent,
    select: (value: SelectValue | null) =>
      isPresent(value) && [value?.label, value?.value].every(isInputPresent),
    multiselect: (value: SelectValue[] | null) =>
      isPresent(value) &&
      isAnyArray(value) &&
      value.length > 0 &&
      value.every((v) => [v?.label, v?.value].every(isInputPresent)),
    number: isInputPresent,
    dateRange: ({ start, end }: TDateRange) => isInputPresent(start) && isInputPresent(end),
    date: isInputPresent,
    tags: isAnyArray,
    custom: isAnyObject,
    range: isAnyArray,
  }[type]
  const [value, setValue] = useState<TFilterValue<T>>(defaultValue)
  const onDefaultValueUpdate = () => {
    if (
      !isFilterPresent &&
      presenceCheck(defaultValue) &&
      isAnyObject(value) &&
      !isObjEq(value, defaultValue)
    ) {
      reset()
    }
  }
  useEffect(() => {
    onDefaultValueUpdate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])

  const { onTypeChange } = useFilterEvents({ type, value })
  const [isEdited, setIsEdited] = useState(false)

  const onChange = (v: TFilterValues) => {
    const newValue = onTypeChange(v)
    internalSetValue(newValue as TFilterValue<T>)
    onChangeCallback({ name, categoryName })
  }
  const reset = () => setValue(defaultValue)

  const isFilterPresent = presenceCheck(value)
  const [categoryName, setCategoryName] = useState<string | undefined>()
  const internalSetValue = (v: TFilterValue<T>) => {
    setValue(v)
    setIsEdited(!isEqual(v, defaultValue))
  }
  let isValid = true
  let error: TFilterError<T> | undefined

  const setError = (newError: TFilterError<T>) => {
    if (error) return

    error = newError
  }

  if (required) isValid = isFilterPresent
  if (customValidation) isValid = customValidation(value, setError)

  const asNull = () => ({
    name,
    type,
    value: null,
    onChange: () => {},
    reset: () => {},
    isPresent: false,
    isEdited: false,
    isValid: false,
    isDisabled: false,
    error: undefined,
    setValue: () => {},
    setCategoryName: () => {},
    asNull: () => asNull(),
  })

  return {
    type,
    name,
    value,
    onChange,
    reset,
    isPresent: isFilterPresent,
    isEdited,
    isValid,
    isDisabled: disabled,
    error,
    isRequired: required,
    setValue: internalSetValue,
    setCategoryName,
    asNull,
  }
}

export default useFilter
