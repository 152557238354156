import styled, { css } from 'styled-components'

import { StyledSelectLabel } from 'components/select/style'
import { desktop, tablet } from 'styles/utils/breakpoints'
import { text14Regular } from 'styles/utils/texts'
import StyledIcon from 'components/icon/style'
import { easeQuartOut } from 'styles/utils/ease'
import { StyledSwitchInput, StyledSwitchInputOption } from 'components/switch_input/style'

export const StyledShipmentsSelect = styled.div`
  display: flex;
  align-items: center;
  z-index: 20;
  position: relative;
  white-space: nowrap;

  ${StyledSelectLabel} {
    ${text14Regular}
    margin: 0;
  }

  @media ${tablet.down} {
    ${StyledSelectLabel} {
      display: none;
    }

    .Select__control {
      padding: 0;
    }
  }
`

export const StyledOverride = styled(StyledShipmentsSelect)`
  display: block;
`

export const StyledShipmentsBarIcons = styled.div`
  display: flex;
  align-items: center;
`

export const StyledShipmentsBarTotalCount = styled.div`
  display: flex;
  align-items: center;
  ${text14Regular};
`

export const StyledShipmentsExport = styled.button`
  @media ${tablet.down} {
    padding: 0;
  }
`

export const StyledShipmentsBarSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  &:first-child {
    z-index: 2;
  }
  &:last-child {
    z-index: 1;
    justify-content: space-between;
  }

  @media ${tablet.down} {
    flex-wrap: wrap;
  }
`

export const StyledShipmentsSortButton = styled.button`
  margin-right: 12px;

  ${StyledIcon} {
    height: 23px;
    width: 25px;
    fill: ${({ theme }) => theme.mediumGray};
    transition: fill 0.5s ${easeQuartOut};
  }

  &:hover {
    ${StyledIcon} {
      fill: ${({ theme }) => theme.primary};
    }
  }
`

export const StyledShipmentsViewButton = styled.button<{ $active: boolean }>`
  width: 40px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${StyledIcon} {
    flex: none;
    height: 20px;
    width: 20px;
    fill: ${({ theme }) => theme.mediumGray};
    transition: fill 0.5s ${easeQuartOut};
  }

  &:hover {
    ${StyledIcon} {
      fill: ${({ theme }) => theme.primary};
    }
  }

  ${(props) =>
    props.$active &&
    css`
      ${StyledIcon} {
        fill: ${({ theme }) => theme.primary};
      }
    `}
`

export const StyledShipmentsView = styled.div`
  display: flex;

  ${StyledShipmentsViewButton} {
    &:first-child {
      border-right: 1px solid ${({ theme }) => theme.mediumGray50};
    }
  }
`

export const StyledShipmentsSwitch = styled(StyledSwitchInput)`
  margin: 0 15px;

  ${StyledSwitchInputOption} {
    ${text14Regular}
  }

  @media ${desktop.up} {
    margin: 0 20px;
  }
`
