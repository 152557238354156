import { useCallback } from 'react'

import useFilter, { IFilter } from 'services/hooks/use_filter'
import useTimeout from 'services/hooks/use_timeout'
import useTracker from 'services/analytics/hooks/use_tracker'

export interface ShipmentFilters {
  organizationFilter: IFilter<'multiselect'>
  reportedReasonFilter: IFilter<'multiselect'>
  referenceFilter: IFilter<'text'>
  etaDiffMinFilter: IFilter<'number'>
  etaDiffMaxFilter: IFilter<'number'>
  arrivalForecastEarlyFilter: IFilter<'checkbox'>
  arrivalForecastOnTimeFilter: IFilter<'checkbox'>
  arrivalForecastDelayedFilter: IFilter<'checkbox'>
  statusActiveFilter: IFilter<'checkbox'>
  statusPastFilter: IFilter<'checkbox'>
  statusExpiredFilter: IFilter<'checkbox'>
  transportModeSeaFilter: IFilter<'checkbox'>
  transportModeAirFilter: IFilter<'checkbox'>
  transportModeRailFilter: IFilter<'checkbox'>
  transportModeRoadFilter: IFilter<'checkbox'>
  transportModeParcelFilter: IFilter<'checkbox'>
  routingPickupFilter: IFilter<'multiselect'>
  routingPolFilter: IFilter<'multiselect'>
  routingPodFilter: IFilter<'multiselect'>
  routingDeliveryFilter: IFilter<'multiselect'>
  partiesForwarderFilter: IFilter<'multiselect'>
  partiesShipperFilter: IFilter<'multiselect'>
  partiesConsignorFilter: IFilter<'multiselect'>
  partiesConsigneeFilter: IFilter<'multiselect'>
  partiesShippingLineFilter: IFilter<'multiselect'>
  partiesAirlineFilter: IFilter<'multiselect'>
  partiesCarrierFilter: IFilter<'multiselect'>
  transportationDatePickupFilter: IFilter<'dateRange'>
  transportationDatePolFilter: IFilter<'dateRange'>
  transportationDatePodFilter: IFilter<'dateRange'>
  transportationDateDeliveryFilter: IFilter<'dateRange'>
  bookingFilter: IFilter<'select'>
  vesselFilter: IFilter<'multiselect'>
  ongoingAlertFilter: IFilter<'multiselect'>
  alertHistoryFilter: IFilter<'multiselect'>
  creationDateFilter: IFilter<'dateRange'>
  userVisibilityFilter: IFilter<'select'>
}

const useFilters = (): ShipmentFilters => {
  const { onTimeout } = useTimeout()
  const { track } = useTracker()
  const onChange = useCallback(
    ({ name, categoryName }) =>
      onTimeout(() => track('Shipment list / pre-filter', { name, category: categoryName })),
    [onTimeout, track]
  )

  const organizationFilter = useFilter({
    name: 'organization',
    type: 'multiselect',
    onChange,
  })

  const reportedReasonFilter = useFilter({
    name: 'reportedReason',
    type: 'multiselect',
    onChange,
  })

  const referenceFilter = useFilter({
    name: 'reference',
    type: 'text',
    onChange,
  })

  const etaDiffMinFilter = useFilter({
    name: 'etaDiffMin',
    type: 'number',
    onChange,
  })
  const etaDiffMaxFilter = useFilter({
    name: 'etaDiffMax',
    type: 'number',
    onChange,
  })
  const arrivalForecastEarlyFilter = useFilter({
    name: 'arrivalForecastEarly',
    type: 'checkbox',
    onChange,
  })
  const arrivalForecastOnTimeFilter = useFilter({
    name: 'arrivalForecastOnTime',
    type: 'checkbox',
    onChange,
  })
  const arrivalForecastDelayedFilter = useFilter({
    name: 'arrivalForecastDelayed',
    type: 'checkbox',
    onChange,
  })
  const statusActiveFilter = useFilter({ name: 'statusActive', type: 'checkbox', onChange })
  const statusPastFilter = useFilter({ name: 'statusPast', type: 'checkbox', onChange })
  const statusExpiredFilter = useFilter({ name: 'statusExpired', type: 'checkbox', onChange })
  const transportModeSeaFilter = useFilter({ name: 'transportModeSea', type: 'checkbox', onChange })
  const transportModeAirFilter = useFilter({ name: 'transportModeAir', type: 'checkbox', onChange })
  const transportModeRailFilter = useFilter({
    name: 'transportModeRail',
    type: 'checkbox',
    onChange,
  })
  const transportModeRoadFilter = useFilter({
    name: 'transportModeRoad',
    type: 'checkbox',
    onChange,
  })
  const transportModeParcelFilter = useFilter({
    name: 'transportModeParcel',
    type: 'checkbox',
    onChange,
  })
  const routingPickupFilter = useFilter({ name: 'routingPickup', type: 'multiselect', onChange })
  const routingPolFilter = useFilter({ name: 'routingPol', type: 'multiselect', onChange })
  const routingPodFilter = useFilter({ name: 'routingPod', type: 'multiselect', onChange })
  const routingDeliveryFilter = useFilter({
    name: 'routingDelivery',
    type: 'multiselect',
    onChange,
  })
  const partiesForwarderFilter = useFilter({
    name: 'partiesForwarder',
    type: 'multiselect',
    onChange,
  })
  const partiesShipperFilter = useFilter({ name: 'partiesShipper', type: 'multiselect', onChange })
  const partiesConsignorFilter = useFilter({
    name: 'partiesConsignor',
    type: 'multiselect',
    onChange,
  })
  const partiesConsigneeFilter = useFilter({
    name: 'partiesConsignee',
    type: 'multiselect',
    onChange,
  })
  const partiesShippingLineFilter = useFilter({
    name: 'partiesShippingLine',
    type: 'multiselect',
    onChange,
  })
  const partiesAirlineFilter = useFilter({ name: 'partiesAirline', type: 'multiselect', onChange })
  const partiesCarrierFilter = useFilter({ name: 'partiesCarrier', type: 'multiselect', onChange })
  const transportationDatePickupFilter = useFilter({
    name: 'transportationDatePickup',
    type: 'dateRange',
    onChange,
  })
  const transportationDatePolFilter = useFilter({
    name: 'transportationDatePol',
    type: 'dateRange',
    onChange,
  })
  const transportationDatePodFilter = useFilter({
    name: 'transportationDatePod',
    type: 'dateRange',
    onChange,
  })
  const transportationDateDeliveryFilter = useFilter({
    name: 'transportationDateDelivery',
    type: 'dateRange',
    onChange,
  })
  const creationDateFilter = useFilter({
    name: 'createdAt',
    type: 'dateRange',
    onChange,
  })
  const userVisibilityFilter = useFilter({
    name: 'userVisibility',
    type: 'select',
    onChange,
  })

  const vesselFilter = useFilter({ name: 'vessel', type: 'multiselect', onChange })

  const ongoingAlertFilter = useFilter({ name: 'ongoingAlert', type: 'multiselect', onChange })

  const alertHistoryFilter = useFilter({ name: 'alertHistory', type: 'multiselect', onChange })

  const bookingFilter = useFilter({ name: 'booking', type: 'select', onChange })

  return {
    organizationFilter,
    reportedReasonFilter,
    referenceFilter,
    etaDiffMinFilter,
    etaDiffMaxFilter,
    arrivalForecastEarlyFilter,
    arrivalForecastOnTimeFilter,
    arrivalForecastDelayedFilter,
    statusActiveFilter,
    statusPastFilter,
    statusExpiredFilter,
    transportModeSeaFilter,
    transportModeAirFilter,
    transportModeRailFilter,
    transportModeRoadFilter,
    transportModeParcelFilter,
    routingPickupFilter,
    routingPolFilter,
    routingPodFilter,
    routingDeliveryFilter,
    partiesForwarderFilter,
    partiesShipperFilter,
    partiesConsignorFilter,
    partiesConsigneeFilter,
    partiesShippingLineFilter,
    partiesAirlineFilter,
    partiesCarrierFilter,
    transportationDatePickupFilter,
    transportationDatePolFilter,
    transportationDatePodFilter,
    transportationDateDeliveryFilter,
    bookingFilter,
    vesselFilter,
    ongoingAlertFilter,
    alertHistoryFilter,
    creationDateFilter,
    userVisibilityFilter,
  }
}

export default useFilters
