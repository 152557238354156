import { arraySize, isAnyValuePresent, isPresent } from 'services/helpers/values'
import {
  AirTransportDetails,
  BookingFormContext,
  BookingFormInput,
  BookingFormReferenceData,
  CommentFormData,
  IncotermsFormData,
  MiscellaneousFormData,
  PartiesFormData,
  RateFormData,
  RoutingFormData,
  SeaTransportDetails,
  TransportDetails,
} from 'views/booking/components/form/types'
import { TRANSPORT_TYPE_AIR } from 'constants/shipments'
import { formatBookingMerchandiseToMerchandiseFormData } from 'views/booking/components/form/helpers/booking_to_form'
import { BookingTemplate } from 'views/booking/components/templates/types'

const mergeTemplateReferences = (
  bookingFormInput: BookingFormInput,
  template: BookingTemplate
): BookingFormReferenceData => ({
  clientReference: template.clientReference || bookingFormInput.references.clientReference,
  clientBookingNumber:
    template.clientBookingNumber || bookingFormInput.references.clientBookingNumber,
  customReferences: template.customReferences?.some((cr) => isPresent(cr.value))
    ? template.customReferences
    : bookingFormInput.references.customReferences,
  forwarderReference: template.forwarderReference || bookingFormInput.references.forwarderReference,
})

const mergeTemplateParties = (
  bookingFormInput: BookingFormInput,
  template: BookingTemplate
): PartiesFormData => ({
  shipper: template.shipper?.id
    ? {
        label: template.shipper.name,
        value: template.shipper.id,
      }
    : bookingFormInput.parties.shipper,
  forwarder: template.forwarder?.id
    ? {
        label: template.forwarder.name,
        value: template.forwarder.id,
      }
    : bookingFormInput.parties.forwarder,
  consignee: template.consignee?.id
    ? {
        label: template.consignee.name,
        value: template.consignee.id,
      }
    : bookingFormInput.parties.consignee,
  consignor: template.consignor?.id
    ? {
        label: template.consignor.name,
        value: template.consignor.id,
      }
    : bookingFormInput.parties.consignor,
})

const mergeTemplateIncoterms = (
  bookingFormInput: BookingFormInput,
  template: BookingTemplate
): IncotermsFormData => ({
  incoterms: isPresent(template.incoterms)
    ? {
        label: template.incoterms,
        value: template.incoterms,
      }
    : bookingFormInput.incoterms.incoterms,
  incotermsLocation: template.incotermsLocation || bookingFormInput.incoterms.incotermsLocation,
})

const shouldMergeTemplateRouting = (
  bookingFormInput: BookingFormInput,
  template: BookingTemplate
): boolean =>
  [
    template.preCarriageAddress,
    template.pol,
    template.pod,
    template.onCarriageAddress,
    ...(template.transshipments.map((ts) => ts.address) || []),
  ].some((address) => isPresent(address?.name)) ||
  bookingFormInput.transportType !== template.transportType

/** Template dates are not applied by design */
const mergeTemplateRouting = (
  bookingFormInput: BookingFormInput,
  template: BookingTemplate
): RoutingFormData => ({
  withPreCarriage: isPresent(template.preCarriageAddress?.id),
  withPostCarriage: isPresent(template.onCarriageAddress?.id),
  preCarriage: {
    ...bookingFormInput.routing.preCarriage,
    location: template.preCarriageAddress
      ? {
          value: template.preCarriageAddress.id,
          label: template.preCarriageAddress.name,
        }
      : bookingFormInput.routing.preCarriage?.location,
  },
  pol: {
    ...bookingFormInput.routing.pol,
    location: template.pol
      ? {
          value: template.pol.id,
          label: template.pol.name,
        }
      : bookingFormInput.routing.pol?.location,
  },
  transshipments: template.transshipments.map((ts) => ({
    ...ts,
    location: ts.address?.name
      ? {
          value: ts.address.id,
          label: ts.address.name,
        }
      : undefined,
  })),
  pod: {
    ...bookingFormInput.routing.pod,
    location: template.pod
      ? {
          value: template.pod.id,
          label: template.pod.name,
        }
      : bookingFormInput.routing.pod?.location,
  },
  postCarriage: {
    ...bookingFormInput.routing.postCarriage,
    location: template.onCarriageAddress
      ? {
          value: template.onCarriageAddress.id,
          label: template.onCarriageAddress.name,
        }
      : bookingFormInput.routing.postCarriage?.location,
  },
})

const mergeTemplateTransportDetails = (
  bookingFormInput: BookingFormInput,
  template: BookingTemplate
): TransportDetails => {
  const defaultCarrierValue = bookingFormInput.transportDetails.carrier?.value
    ? bookingFormInput.transportDetails.carrier
    : undefined

  const carrier = template.carrier?.name
    ? {
        label: template.carrier.name,
        value: template.carrier.id,
      }
    : defaultCarrierValue

  if (template.transportType === TRANSPORT_TYPE_AIR) {
    const transportDetails = bookingFormInput.transportDetails as AirTransportDetails

    return {
      ...transportDetails,
      carrier,
      flightNumbers:
        arraySize(template.flightNumbers) > 0
          ? template.flightNumbers
          : transportDetails.flightNumbers,
    }
  }

  const transportDetails = bookingFormInput.transportDetails as SeaTransportDetails

  return {
    ...transportDetails,
    carrier,
    vessels: template.vessels?.some((v) => isPresent(v.name))
      ? template.vessels?.map((v) => ({
          label: v.name,
          value: v.id,
        }))
      : transportDetails.vessels,
    bookingNumber: template.bookingNumber || transportDetails.bookingNumber,
    masterBl: template.masterBl || transportDetails.masterBl,
    voyageNumbers:
      arraySize(template.voyageNumbers) > 0
        ? template.voyageNumbers
        : transportDetails.voyageNumbers,
  }
}

const shouldMergeTemplateMerchandise = (template: BookingTemplate): boolean => {
  if (!isPresent(template.merchandise)) return false

  // eslint-disable-next-line no-unused-vars
  const { shippingMode, merchandiseType, ...relevantMerchandiseAttributes } = template.merchandise

  return (
    isPresent(relevantMerchandiseAttributes?.content) &&
    isAnyValuePresent(relevantMerchandiseAttributes)
  )
}

/** Template dates are not applied by design */
const mergeTemplateMiscellaneous = (
  bookingFormInput: BookingFormInput,
  template: BookingTemplate
): MiscellaneousFormData => ({
  cutOffDate: bookingFormInput.miscellaneous.cutOffDate,
  vgmCutOffDate: bookingFormInput.miscellaneous.vgmCutOffDate,
  customFields: template.customFields?.some((cf) => isPresent(cf.value))
    ? template.customFields
    : bookingFormInput.miscellaneous.customFields,
})

const mergeTemplateRate = (
  bookingFormInput: BookingFormInput,
  template: BookingTemplate
): RateFormData => ({
  withRateConfirmation: template.rateConfirmation || bookingFormInput.rate.withRateConfirmation,
  rateconfirmation: bookingFormInput.rate.rateconfirmation,
})

const mergeTemplateComments = (
  bookingFormInput: BookingFormInput,
  template: BookingTemplate
): CommentFormData => ({
  shipperComment: template.comments || bookingFormInput.comments.shipperComment,
  forwarderComment: template.forwarderComment || bookingFormInput.comments.forwarderComment,
})

const mergeTemplateIntoBooking = (
  bookingFormInput: BookingFormInput,
  template: BookingTemplate,
  context: BookingFormContext
): BookingFormInput => {
  const result = { ...bookingFormInput }

  result.references = mergeTemplateReferences(result, template)
  result.parties = mergeTemplateParties(result, template)
  result.incoterms = mergeTemplateIncoterms(result, template)

  if (shouldMergeTemplateRouting(result, template)) {
    if (template.transportType) result.transportType = template.transportType

    result.routing = mergeTemplateRouting(result, template)
  }

  result.transportDetails = mergeTemplateTransportDetails(result, template)

  if (shouldMergeTemplateMerchandise(template))
    result.merchandise = formatBookingMerchandiseToMerchandiseFormData(context, template)

  result.miscellaneous = mergeTemplateMiscellaneous(result, template)
  result.rate = mergeTemplateRate(result, template)
  result.comments = mergeTemplateComments(result, template)

  if (template.assignedUsers && template.assignedUsers?.length !== 0)
    result.keyContacts = template.assignedUsers.map((user) => user.email)

  return result
}

export default mergeTemplateIntoBooking
