import React, { useState, useCallback, useMemo, FC } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useFormContext } from 'react-hook-form'

import useOnce from 'services/hooks/use_once'
import useAppDispatch from 'services/hooks/use_app_dispatch'

import Icon from 'components/icon'
import SavedTemplatesItem from 'components/booking_templates_saved_item'
import Input from 'components/input'
import Placeholder from 'components/placeholder'
import BookingFiltersSavedSkeleton from 'components/booking_templates_saved_item/skeleton'

import { StyledBookingCreateList } from 'views/booking/components/create/style'

import {
  createBookingTemplate,
  deleteBookingTemplate,
  fetchBookingTemplates,
  selectBookingTemplates,
  selectBookingTemplatesStatus,
  updateBookingTemplate,
} from 'views/booking/slices/booking_templates_slice'
import useStaticLocales from 'views/locales/hooks/use_static_locales'
import { addNotification } from 'views/notifications/slice'

import ValidateTemplateModal from 'views/booking/components/templates/template_modal'
import ValidateEditTemplateModal from 'views/booking/components/templates/validate_edit_template_modal'

import { STATUS_FULFILLED } from 'constants/api'
import Sidebar from 'components/sidebar'
import S from 'components/filters_sidebar/style'
import { StyledSavedTemplatesEdit } from 'components/booking_templates_saved_item/style'
import {
  getTestIdForBookingTemplateItem,
  TEST_ID_BOOKING_TOGGLE_TEMPLATES_EDITION,
  TEST_ID_BOOKING_TEMPLATE_INPUT,
  TEST_ID_BOOKING_TEMPLATE_SAVE,
} from 'tests/e2e/test_ids'
import useModal from 'components/modal/hooks/use_modal'
import { BookingFormContext, BookingFormInput } from 'views/booking/components/form/types'
import mergeTemplateIntoBooking from 'views/booking/components/templates/helpers/template_application'
import generateCreateTemplatePayload from 'views/booking/components/form/helpers/form_to_template'
import { BookingTemplate, EditBookingTemplateData } from 'views/booking/components/templates/types'
import { buildSelectValuesFromStaticTranslations } from 'services/helpers/values'

const BookingTemplates: FC = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const templates = useSelector(selectBookingTemplates)
  const { setOpen: setOpenValidateApply } = useModal('bookingTemplateValidateApply')
  const { setOpen: setOpenValidateEdit } = useModal('bookingTemplateValidateEdit')
  const status = useSelector(selectBookingTemplatesStatus)
  const { s } = useStaticLocales()
  const [templateName, setTemplateName] = useState<string>('')
  const [editMode, setEditMode] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState<BookingTemplate | undefined>(undefined)

  const { getValues, reset } = useFormContext<BookingFormInput>()

  const onTemplateSubmit = () => {
    const bookingTemplateData = generateCreateTemplatePayload(getValues(), templateName)

    dispatch(createBookingTemplate(bookingTemplateData))
      .unwrap()
      .then(() => {
        setTemplateName('')
        dispatch(
          addNotification({
            type: 'success',
            title: t('bookings.templates.notifications.title'),
            text: t('bookings.templates.notifications.success'),
          })
        )
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('bookings.templates.notifications.title'),
            text: t('errors.notification.content'),
          })
        )
      })
  }

  const onTemplateEdit = () => {
    const editBookingTemplateData: EditBookingTemplateData = generateCreateTemplatePayload(
      getValues(),
      selectedTemplate!.templateName
    )

    dispatch(
      updateBookingTemplate({
        id: selectedTemplate!.id,
        changes: editBookingTemplateData,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(
          addNotification({
            type: 'success',
            title: t('bookings.templates.notifications.title'),
            text: t('bookings.templates.notifications.edit'),
          })
        )
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('bookings.templates.notifications.title'),
            text: t('errors.notification.content'),
          })
        )
      })
  }

  const applyTemplate = useCallback(
    (template: BookingTemplate) => {
      const currentBookingFormInput = getValues()
      const context: BookingFormContext = {
        isForwarder: false,
        isStatusPastProposalExchange: false,
        withBookingOldWorkflow: false,
        containerTypes: buildSelectValuesFromStaticTranslations(s('containerTypes')),
        packageTypes: buildSelectValuesFromStaticTranslations(s('packageTypes')),
        hazardousClasses: buildSelectValuesFromStaticTranslations(s('hazardousGoods')),
        packingGroups: buildSelectValuesFromStaticTranslations(s('packingGroups')),
      }

      const bookingFormInput = mergeTemplateIntoBooking(currentBookingFormInput, template, context)
      reset(bookingFormInput)

      dispatch(
        addNotification({
          type: 'success',
          title: t('bookings.templates.notifications.title'),
          text: t('bookings.templates.notifications.apply'),
        })
      )
    },
    [getValues, reset, dispatch, t, s]
  )

  const deleteTemplate = useCallback(
    (template: BookingTemplate) => {
      dispatch(deleteBookingTemplate(template))
        .unwrap()
        .then(() =>
          dispatch(
            addNotification({
              type: 'success',
              title: t('bookings.templates.notifications.title'),
              text: t('bookings.templates.notifications.delete'),
            })
          )
        )
        .catch(() =>
          dispatch(
            addNotification({
              type: 'alert',
              title: t('bookings.templates.notifications.title'),
              text: t('errors.notification.content'),
            })
          )
        )
    },
    [dispatch, t]
  )

  const items = useMemo(
    () =>
      templates.map((template, index) => (
        <SavedTemplatesItem
          key={template.id}
          label={template.templateName}
          editMode={editMode}
          onClickDelete={() => deleteTemplate(template)}
          onClickItem={() => {
            setSelectedTemplate(template)
            setOpenValidateApply(true)
          }}
          onClickEdit={() => {
            setSelectedTemplate(template)
            setOpenValidateEdit(true)
          }}
          testId={getTestIdForBookingTemplateItem(index)}
        />
      )),
    [templates, editMode, deleteTemplate, setOpenValidateApply, setOpenValidateEdit]
  )

  useOnce(() => {
    dispatch(fetchBookingTemplates())
  })

  return (
    <Sidebar>
      <ValidateTemplateModal
        onValidate={() => {
          if (selectedTemplate !== undefined) {
            applyTemplate(selectedTemplate)
          }
        }}
        onClose={() => {
          setSelectedTemplate(undefined)
        }}
      />

      <ValidateEditTemplateModal
        onValidate={() => {
          if (selectedTemplate !== undefined) {
            onTemplateEdit()
          }
        }}
        onClose={() => {
          setSelectedTemplate(undefined)
        }}
        templateName={selectedTemplate?.templateName}
      />

      <Sidebar.Header>
        <S.Header>
          <S.HeaderIcon as={Icon} name='settings' />
          <Sidebar.Title>{t('bookings.templates.title')}</Sidebar.Title>
        </S.Header>
      </Sidebar.Header>

      <Sidebar.Content>
        <S.Section>
          <Input
            type='text'
            placeholder={t('bookings.templates.placeholder')}
            name='template_name'
            button={{
              text: t('actions.save'),
              icon: 'save_outline',
              onClick: onTemplateSubmit,
              disabled: !templateName,
              testId: TEST_ID_BOOKING_TEMPLATE_SAVE,
            }}
            value={templateName}
            onChange={({ target: { value } }) => setTemplateName(value)}
            testId={TEST_ID_BOOKING_TEMPLATE_INPUT}
          />
        </S.Section>
        <StyledSavedTemplatesEdit
          data-testid={TEST_ID_BOOKING_TOGGLE_TEMPLATES_EDITION}
          onClick={() => setEditMode(!editMode)}
        >
          {!editMode && <span>{t('bookings.templates.edit')}</span>}
          {editMode && <span>{t('actions.cancel')}</span>}
        </StyledSavedTemplatesEdit>
        <Placeholder
          ready={status === STATUS_FULFILLED}
          customPlaceholder={<BookingFiltersSavedSkeleton count={8} />}
        >
          <StyledBookingCreateList>{items}</StyledBookingCreateList>
        </Placeholder>
      </Sidebar.Content>
    </Sidebar>
  )
}

export default BookingTemplates
