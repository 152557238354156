import React, { FC } from 'react'

import Page from 'components/page'
import EmbeddedReport from 'features/analytics/components/embedded_report'

const AnalyticsPage: FC = () => (
  <Page plain>
    <EmbeddedReport />
  </Page>
)

export default AnalyticsPage
