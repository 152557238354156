import React from 'react'
import { useParams } from 'react-router-dom'

import UpdatePasswordForm from 'components/update_password_form'
import ForgotPasswordForm from 'components/forgot_password_form'

import { isNull, isPresent } from 'services/helpers/values'

import IAm from 'views/iam'

interface UrlParams {
  token?: string
  invitationToken?: string
}

const PasswordRecovery: React.FC = () => {
  const { token, invitationToken } = useParams<keyof UrlParams>()

  return (
    <IAm>
      {isPresent(invitationToken) ? (
        <UpdatePasswordForm passwordAction='invitation' token={invitationToken} />
      ) : (
        <>
          {isPresent(token) && <UpdatePasswordForm passwordAction='recovery' token={token} />}
          {isNull(token) && <ForgotPasswordForm />}
        </>
      )}
    </IAm>
  )
}

export default PasswordRecovery
