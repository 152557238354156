import React, { useContext, useEffect, useState } from 'react'

import { Route, Routes } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ProductFruits } from 'react-product-fruits'

import UsersPage from 'pages/users'

import {
  ANALYTICS_ACCESS,
  USER_ORGANIZATION_READ,
  AUDIT_TRAIL_READ,
  TRUSTED_ROUTES_ACCESS,
} from 'constants/permissions'
import { WITH_EMBED_ACCESS_TO_SHIPMENT_INDEX } from 'constants/organization_features'

import DashboardPage from 'pages/dashboard'
import SignIn from 'views/iam/sign_in'
import PasswordRecovery from 'views/iam/password_recovery'
import Shipment from 'views/shipment'
import useCurrentUser from 'views/iam/hooks/use_current_user'
import useUserCan from 'views/iam/hooks/use_user_can'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import BookingsPage from 'views/bookings'
import BookingDetailsPage from 'views/booking'
import BookingCreate from 'views/booking/components/create'
import useBookingRole from 'views/booking/hooks/use_booking_role'
import BookingEdit from 'views/booking/components/edit'
import { refreshAccessToken, ssoSignIn } from 'views/iam/slices/iamSlice'
import { addNotification } from 'views/notifications/slice'
import TrustedRoutes from 'views/trusted_routes'
import Atlas from 'views/atlas'

import useOnce from 'services/hooks/use_once'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { isProd } from 'services/helpers/environment'

import Page from 'components/page'
import NotifMessage from 'components/notif_message'
import {
  routeDashboard,
  routePasswordRecovery,
  routeSignIn,
  routeSSO,
  routeShipments,
  routeShipment,
  routeShipmentAuditTrail,
  routeAnalytics,
  routeBookings,
  routeBooking,
  routeBookingCreate,
  routeBookingEdit,
  routeUsers,
  routeTrustedRoutes,
  routeInvitation,
  routeAtlas,
} from 'services/helpers/routes'
import { isPresent } from 'services/helpers/values'

import AppContext from 'app/contexts/app_context'
import ShipmentAuditTrail from 'views/audit_trail'
import { Category } from 'features/analytics/components/embedded_report/types'
import LoadingView from 'views/loading'
import { TEST_ID_ROOT_LOADER_WAKEO } from 'tests/e2e/test_ids'
import useCanAccessAtlas from 'views/atlas/hooks/use_can_access_atlas'

import { hasAValidSsoLogoutConfiguration, isSSOUser } from 'views/iam/slices/ssoSlice'

import SSORedirect from 'views/iam/ssoRedirect'
import SSOSignOut from 'views/iam/ssoSIgnOut'
import SSOErrorPage from 'views/sso/ssoError'
import AnalyticsPage from 'pages/analytics'
import RedirectWithParams from 'app/redirect_with_params'
import ShipmentsPage from 'pages/shipments'

const StandaloneApp = () => {
  const user = useCurrentUser()
  const { t, i18n } = useTranslation()
  const userCan = useUserCan()
  const canAccessAtlas = useCanAccessAtlas()
  const dispatch = useAppDispatch()
  const { hasAnyBookingRole, isShipper } = useBookingRole()
  const fromSSO = window.location.href.indexOf('/sso?') > -1
  const { features } = useOrganizationCan()
  const { isEmbedded } = useContext(AppContext)
  const [applicationReady, setApplicationReady] = useState(false)
  const isAnSSOUser = useSelector(isSSOUser)
  const isSsoConfigurationValid = useSelector(hasAValidSsoLogoutConfiguration)

  const canAccessShipments =
    !isEmbedded || (isEmbedded && features(WITH_EMBED_ACCESS_TO_SHIPMENT_INDEX))
  useEffect(() => {
    if (user.isSignedIn) {
      i18n.changeLanguage(user.profile.settings.locale)
    }
  }, [user, i18n])

  const handleSSOlocation = () => {
    if (fromSSO) {
      const params = new URLSearchParams(window.location.search)
      const ssoToken = params.get('sso_token')
      const redirectUrl = params.get('redirect')

      if (ssoToken) {
        dispatch(ssoSignIn({ ssoToken }))
          .unwrap()
          .then(() => {
            if (isPresent(redirectUrl)) {
              window.location.replace(redirectUrl)
            } else {
              window.location.replace(routeDashboard())
            }
          })
          .catch(() => {
            dispatch(
              addNotification({
                type: 'alert',
                title: t('errors.notification.title'),
                text: t('errors.notification.content'),
              })
            )
          })
      }
    }
  }

  useOnce(() => {
    handleSSOlocation()
  })

  useOnce(() => {
    dispatch(refreshAccessToken()).finally(() => setApplicationReady(true))
  })

  // Before returning the router, we perform the refreshAccessToken request.
  // If the request is successful, the user information is stored in the redux store and the user can access the requested page,
  // otherwise he's redirected to the signIn.
  // We don't use automatic signOut in the case of 498 to prevent users from being disconnected during the useRefreshTokenInterval call.
  return (
    <>
      {applicationReady && !isEmbedded && user.isSignedIn && isProd && (
        <ProductFruits
          workspaceCode={process.env.REACT_APP_PRODUCT_FRUIT_WORKSPACE}
          language='en'
          user={{
            username: user.profile.token,
            email: user.profile.email,
            firstname: user.profile.firstName,
            lastname: user.profile.lastName,
            signUpAt: user.profile.createdAt,
            props: {
              displayMarketIntelTour: user.availableAnalyticsCategories.includes('market_intel'),
              organization: user.organization.name,
              company: user.company?.name || '',
            },
          }}
        />
      )}
      <>
        {!applicationReady && <LoadingView data-testid={TEST_ID_ROOT_LOADER_WAKEO} />}

        {applicationReady && fromSSO && (
          <Routes>
            <Route
              path={routeSSO()}
              element={
                <Page>
                  <NotifMessage
                    type='info'
                    title='SSO connexion'
                    text='Waiting for SSO connexion'
                  />
                </Page>
              }
            />
          </Routes>
        )}

        {applicationReady && !fromSSO && !user.isSignedIn && (
          <Routes>
            <Route
              path='/signin/:token'
              element={<RedirectWithParams to={routePasswordRecovery(':token')} />}
            />
            <Route path='/signin' element={<RedirectWithParams to={routeSignIn()} />} />
            <Route
              path={routeSignIn()}
              element={isAnSSOUser && isSsoConfigurationValid ? <SSORedirect /> : <SignIn />}
            />
            <Route path={routePasswordRecovery(':token?')} element={<PasswordRecovery />} />
            <Route path={routeInvitation(':invitationToken?')} element={<PasswordRecovery />} />
            <Route path='/sso/logout' element={<SSOSignOut />} />
            <Route path='/sso-error' element={<SSOErrorPage />} />
            <Route path='*' element={<RedirectWithParams to={routeSignIn()} />} />
          </Routes>
        )}

        {applicationReady && !fromSSO && user.isSignedIn && (
          <Routes>
            <Route
              path='/order/:id'
              element={<RedirectWithParams to={routeShipment(':id')} />}
              replace
            />
            <Route path='/orders' element={<RedirectWithParams to={routeShipments()} />} />
            <Route path='/settings' element={<RedirectWithParams to='/#alerts' />} />

            <Route path={routeDashboard()} element={<DashboardPage />} />

            {userCan(ANALYTICS_ACCESS) && (
              <>
                <Route path={routeAnalytics(':category')} element={<AnalyticsPage />} />
                <Route
                  path='/analytics'
                  element={<RedirectWithParams to={routeAnalytics(Category.PRIMARY)} />}
                />
              </>
            )}

            {userCan(TRUSTED_ROUTES_ACCESS) && (
              <Route path={routeTrustedRoutes()} element={<TrustedRoutes />} />
            )}

            {canAccessAtlas && <Route path={routeAtlas(':category')} element={<Atlas />} />}

            {hasAnyBookingRole && (
              <>
                <Route path={routeBookings()} element={<BookingsPage />} />
                {isShipper && <Route path={routeBookingCreate()} element={<BookingCreate />} />}
                <Route path={routeBookingEdit(':id')} element={<BookingEdit />} />
                <Route path={routeBooking(':id')} element={<BookingDetailsPage />} />
              </>
            )}

            {userCan(AUDIT_TRAIL_READ) && (
              <Route path={routeShipmentAuditTrail(':id')} element={<ShipmentAuditTrail />} />
            )}

            <Route path='/shipment/:id' element={<Shipment />} />

            {canAccessShipments && <Route path={routeShipments()} element={<ShipmentsPage />} />}

            {userCan(USER_ORGANIZATION_READ) && (
              <Route path={routeUsers()} element={<UsersPage />} />
            )}

            <Route path='*' element={<RedirectWithParams to={routeDashboard()} />} />
          </Routes>
        )}
      </>
    </>
  )
}

export default StandaloneApp
