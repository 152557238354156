import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import useModal from 'components/modal/hooks/use_modal'
import ActionModalContent from 'views/booking/components/action_modal_content'
import Modal from 'components/modal'

import { createBooking } from 'views/booking/slices/booking_slice'
import { addNotification } from 'views/notifications/slice'

import Button from 'components/button'
import { uploadDocument } from 'views/booking/slices/documents_slice'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { CreateBookingData } from 'views/booking/slices/types'
import { CustomFile } from 'components/modal_upload_document/hooks/use_upload_files'

interface ValidateCreateModalProps {
  createBookingData: CreateBookingData
  uploadedFiles?: CustomFile[]
  onClose: () => void
}

const ValidateCreateModal: FC<ValidateCreateModalProps> = ({
  createBookingData,
  uploadedFiles = [],
  onClose,
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { setOpen } = useModal('bookingValidateCreate')
  const navigate = useNavigate()
  const [isSendDisabled, setIsSendDisabled] = useState<boolean>(false)

  const onCloseModal = () => {
    onClose()
    setOpen(false)
  }

  const onClickSend = () => {
    setIsSendDisabled(true)

    dispatch(createBooking(createBookingData))
      .unwrap()
      .then(async ({ token }) => {
        dispatch(
          addNotification({
            type: 'success',
            title: t('bookings.alerts.createBooking.title'),
            text: t('bookings.alerts.createBooking.successMessage'),
          })
        )

        // eslint-disable-next-line no-restricted-syntax
        for (const { id, nativeFile, fileType } of uploadedFiles) {
          // eslint-disable-next-line no-await-in-loop
          await dispatch(
            uploadDocument({
              token,
              documentId: id,
              document: nativeFile,
              documentType: fileType!.value,
              withEmailNotification: false,
            })
          )
            .unwrap()
            .catch(() =>
              dispatch(
                addNotification({
                  type: 'alert',
                  title: t('documents.uploadError.title'),
                  text: t('documents.uploadError.errorMessage'),
                })
              )
            )
        }
        navigate('/bookings')
      })
      .catch(() =>
        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      )
      .finally(() => {
        setIsSendDisabled(false)
      })
  }

  return (
    <Modal position='center' onClose={onCloseModal} modalName='bookingValidateCreate'>
      <Modal.Header>{t('bookings.exchangeModal.createBooking.title')}</Modal.Header>
      <Modal.Content>
        <ActionModalContent actionKey='createBooking' />
      </Modal.Content>
      <Modal.Footer>
        <Button text={t('actions.cancel')} onClick={onCloseModal} rounded variant='clear' />
        <Button
          text={t('actions.send')}
          isLoading={isSendDisabled}
          onClick={onClickSend}
          rounded
          variant='highlight'
        />
      </Modal.Footer>
    </Modal>
  )
}

export default ValidateCreateModal
