import { isPresent } from 'services/helpers/values'
import { Category as AnalyticsCategory } from 'features/analytics/components/embedded_report/types'
import { Category as AtlasCategory } from 'views/atlas/types/category'

export const routeDashboard = () => '/' as const

export const routeSignIn = () => '/sign-in' as const
export const routePasswordRecovery = (token?: string) => {
  const basePath = '/password-recovery'
  return isPresent(token) ? `${basePath}/${token}` : basePath
}

export const routeInvitation = (invitationToken: string) => `/invitation/${invitationToken}`

export const routeSSO = () => '/sso' as const

export const routeShipments = () => '/shipments' as const
export const ROUTE_SHARED = '/shared' as const

interface ShipmentRouteOptions {
  isLegacy?: boolean
}
const shipmentPath = (isLegacy?: boolean) => (isLegacy ? '/order' : '/shipment')

export const routeShipment = (
  token: string,
  { isLegacy }: ShipmentRouteOptions = { isLegacy: false }
) => `${shipmentPath(isLegacy)}/${token}` as const

export const routeSharedShipment = (
  token: string,
  { isLegacy }: ShipmentRouteOptions = { isLegacy: false }
) => `${ROUTE_SHARED}${shipmentPath(isLegacy)}/${token}` as const

export const routeShipmentAuditTrail = (token: string) =>
  `${routeShipment(token)}/audit-trail` as const

export const routeAnalytics = (category?: AnalyticsCategory) =>
  `/analytics/${category ?? ''}` as const
export const routeTrustedRoutes = () => '/trusted-routes' as const
export const routeAtlas = (category?: AtlasCategory) => `/atlas/${category ?? ''}` as const
export const routeBookings = () => '/bookings' as const
export const routeBooking = (token: string) => `/booking/${token}` as const
export const routeBookingCreate = () => '/booking/create' as const
export const routeBookingEdit = (token: string) => `/booking/${token}/edit` as const

export const routeUsers = () => '/users' as const
