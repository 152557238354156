import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import type { ReferenceDisplay } from 'views/iam/types'
import type { Reference } from 'features/shipments/types/shipment'

interface UseReferenceDisplayParams {
  referenceDisplayIndex: ReferenceDisplay
}
const useReferenceDisplay = ({ referenceDisplayIndex }: UseReferenceDisplayParams) => {
  const { t } = useTranslation()
  const types = [
    'clientReference',
    'forwarderReference',
    'shipmentReference',
    'wakeoReference',
    'clientBookingNumber',
  ] as const
  const translations = {
    clientReference: t('settings.content.references.clientReference'),
    forwarderReference: t('settings.content.references.forwarderReference'),
    shipmentReference: t('settings.content.references.shipmentReference'),
    wakeoReference: t('settings.content.references.wakeoReference'),
    clientBookingNumber: t('settings.content.references.clientBookingNumber'),
  }
  const findRef =
    (userRef: ReferenceDisplay) =>
    ({
      clientReference,
      forwarderReference,
      shipmentReference,
      wakeoReference,
      clientBookingNumber,
    }: {
      clientReference?: Reference
      forwarderReference?: Reference
      shipmentReference?: Reference
      wakeoReference: Reference
      clientBookingNumber?: Reference
    }) =>
      [clientReference, forwarderReference, shipmentReference, wakeoReference, clientBookingNumber][
        userRef
      ]

  const getReference = useMemo(() => findRef(referenceDisplayIndex), [referenceDisplayIndex])
  const type = types[referenceDisplayIndex]
  const translation = translations[type]
  return {
    getReference,
    translation,
  }
}

export default useReferenceDisplay
