import React, { FC } from 'react'

import StyledShipmentListItemTime from 'features/shipments/components/shipment_list_item/components/eta/style'
import useArrivalTimes from 'features/shipments/components/shipment_list_item/hooks/use_arrival_times'

import DateHelper from 'services/helpers/date_helper'

const Eta: FC = () => {
  const { actualTime, estimatedTime } = useArrivalTimes()
  return (
    <StyledShipmentListItemTime>
      {actualTime && new DateHelper(actualTime).toLocale()}
      {!actualTime && estimatedTime && new DateHelper(estimatedTime).toLocale()}
    </StyledShipmentListItemTime>
  )
}

export default Eta
