import { createAsyncThunk } from '@reduxjs/toolkit'

import { InternalClient } from 'services/api/clients'
import onError from 'services/api/error'

import useThunkTeamsParam from 'services/api/hooks/use_thunk_teams_param'
import useUrlParams from 'services/api/hooks/use_url_params'

export const fetchFilters = createAsyncThunk(
  'shipments/fetchFilters',
  async (
    { name, value, filters: q }: { name: string; value: any; filters: Record<string, any> },
    thunkAPI
  ) => {
    const teams = useThunkTeamsParam(thunkAPI)
    const url = useUrlParams('/shipments/filters', { name, value, q, ...teams })
    return InternalClient.get(url)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

export const fetchRoutingFilters = createAsyncThunk(
  'shipments/fetchRoutingFilters',
  async (
    { suffix, value, filters: q }: { suffix: string; value: any; filters: Record<string, any> },
    thunkAPI
  ) => {
    const teams = useThunkTeamsParam(thunkAPI)
    const urlRouting = useUrlParams('/shipments/filters', {
      name: `routing_${suffix}`,
      value,
      q,
      ...teams,
    })
    const urlCountry = useUrlParams('/shipments/filters', {
      name: `routing_country_${suffix}`,
      value,
      q,
      ...teams,
    })

    return Promise.all([InternalClient.get(urlCountry), InternalClient.get(urlRouting)])
      .then(([resultCountry, resultRouting]) =>
        [
          ...resultCountry.data.map((name: string) => ({
            value: name,
            label: name,
            type: 'country',
          })),
          ...resultRouting.data.map((name: string) => ({
            value: name,
            label: name,
            type: 'location',
          })),
        ].slice(0, 50)
      )
      .catch(onError(thunkAPI))
  }
)
