import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Bugsnag from '@bugsnag/js'

import { PowerBIEmbed } from 'powerbi-client-react'

import { Embed, models } from 'powerbi-client'

import { Category, CategoryParams } from 'features/analytics/components/embedded_report/types'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import useBreakpoints from 'services/hooks/use_breakpoints'
import useAnalytics from 'features/analytics/hooks/use_analytics'

import { onExternalError } from 'services/api/error'

import useInterval from 'services/hooks/use_interval'

import {
  StyledNotifMessageWrapper,
  StyledPowerBIWrapper,
} from 'features/analytics/components/embedded_report/style'
import ErrorNotification from 'views/errors/error_notification'
import NotifMessage from 'components/notif_message'
import { fetchTokenAndReportId } from 'features/analytics/store/slice'

// https://learn.microsoft.com/en-us/javascript/api/overview/powerbi/troubleshoot-and-debug#find-what-error-occurred
interface IError {
  message: string
  detailedMessage?: string
  errorCode?: string
}

const REFRESH_TOKEN_INTERVAL = 30 * 60 * 1000

const EmbeddedReport: FC = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { category } = useParams<keyof CategoryParams>() as CategoryParams
  const [accessToken, setAccessToken] = useState<null | string>(null)
  const [reportId, setReportId] = useState<null | string>(null)
  const [errorCode, setErrorCode] = useState<null | number | undefined>(null)
  const { isMobile } = useBreakpoints()
  const status = useAnalytics()
  const [EmbeddedPBIComponent, setEmbeddedPBIComponent] = useState<null | Embed>(null)

  // Initialize PowerBI with a token and a report ID
  useEffect(() => {
    setAccessToken(null)
    dispatch(fetchTokenAndReportId(category))
      .unwrap()
      .then(({ analyticsToken, reportId: id }) => {
        setReportId(id)
        setAccessToken(analyticsToken)
      })
      .catch((error) => {
        const { code } = onExternalError({ axiosError: error, dispatch })
        setErrorCode(code)
      })
  }, [dispatch, category])

  // Refresh token every 30 mins
  useInterval(() => {
    if (EmbeddedPBIComponent) {
      dispatch(fetchTokenAndReportId(category))
        .unwrap()
        .then(({ analyticsToken }) => EmbeddedPBIComponent.setAccessToken(analyticsToken))
        .catch((error) => {
          const { code } = onExternalError({ axiosError: error, dispatch })
          setErrorCode(code)
        })
    }
  }, REFRESH_TOKEN_INTERVAL)

  return (
    <StyledPowerBIWrapper isMobile={isMobile}>
      {status.rejected && errorCode !== 404 && <ErrorNotification />}
      {status.rejected && errorCode === 404 && (
        <StyledNotifMessageWrapper>
          <NotifMessage
            padded
            type='info'
            title={t('analytics.alerts.noReportFound.title')}
            text={t('analytics.alerts.noReportFound.content')}
          />
        </StyledNotifMessageWrapper>
      )}
      {accessToken && reportId && !status.rejected && (
        <PowerBIEmbed
          embedConfig={{
            type: 'report',
            id: reportId,
            accessToken,
            embedUrl: 'https://app.powerbi.com/reportEmbed',
            tokenType: models.TokenType.Embed,
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
                pageNavigation: { visible: category === Category.PRIMARY },
              },
              background: models.BackgroundType.Transparent,
              ...(isMobile ? { layoutType: models.LayoutType.MobilePortrait } : {}),
            },
          }}
          cssClassName='report-style-class'
          getEmbeddedComponent={(report) => {
            setEmbeddedPBIComponent(report)
          }}
          eventHandlers={
            new Map([
              [
                'error',
                (event) => {
                  if (!event) return

                  const error = event.detail as IError

                  Bugsnag.notify(`${error.message}: ${error.detailedMessage} - ${error.errorCode}`)
                },
              ],
            ])
          }
        />
      )}
    </StyledPowerBIWrapper>
  )
}

export default EmbeddedReport
