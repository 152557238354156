import React, { useRef } from 'react'

import Avatar from 'components/avatar'
import Select from 'components/select'
import StyledAvatarList from 'components/avatar_list/style'

// TODO: improve behavior by not redefining <Avatar/> children but simply slicing list
// May be achieved by using React.Children internal API

interface AvatarListProps {
  children: JSX.Element[]
  limit?: number
}

const AvatarList = ({ children, limit = 3 }: AvatarListProps) => {
  const selectRef = useRef<HTMLSelectElement>(null)
  const toggleOpenSelect = () => selectRef.current?.focus()

  const visibleChildren = children
    .slice(0, limit)
    .map((child) => (
      <Avatar
        firstName={child.props.firstName}
        lastName={child.props.lastName}
        color={child.props.color}
        onClick={toggleOpenSelect}
        outline
        key={`avatar-${child.props.firstName}-${child.props.lastName}-${child.props.color}`}
      />
    ))
  let hiddenChildren = null

  hiddenChildren = children.map((child, index) => ({
    label: `${child.props.firstName} ${child.props.lastName}`,
    value: index,
    slot: {
      beforeLabel: (
        <Avatar
          size='small'
          firstName={child.props.firstName}
          lastName={child.props.lastName}
          color={child.props.color}
          outline={false}
        />
      ),
    },
  }))

  return (
    <StyledAvatarList>
      {visibleChildren}
      {children.length > limit && (
        <Avatar
          label={`${children.length - limit}+`}
          outline
          color='#DCF1FA'
          onClick={toggleOpenSelect}
        />
      )}
      <Select
        portal={false}
        options={hiddenChildren}
        hideControls
        ref={selectRef}
        openMenuOnFocus
      />
    </StyledAvatarList>
  )
}

export default AvatarList
