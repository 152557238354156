import styled from 'styled-components'

import StyledIcon from 'components/icon/style'
import { borderRadiusSmall } from 'styles/utils/border_radius'
import { tablet, desktopLarge } from 'styles/utils/breakpoints'

import {
  TrustedRoutesPagePadding,
  TrustedRoutesMaxWidth,
  TrustedRoutesGap,
} from 'views/trusted_routes/style'

const OuterContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 0;
  align-items: center;
  justify-content: center;
  max-width: ${TrustedRoutesMaxWidth}px;
  padding-left: 50px;
  padding-right: 50px;
  @media ${tablet.up} {
    margin-right: ${TrustedRoutesPagePadding}px;
  }
  @media ${tablet.down} {
    margin-right: ${TrustedRoutesGap}px;
  }
`

const AlternativesFrame = styled.div`
  display: flex;
  justify-content: center;
  flex: 1 1 0;
  padding: 30px;
  border-style: solid;
  ${borderRadiusSmall};
  background-color: ${({ theme }) => theme.white};
  border-color: ${({ theme }) => theme.primary};
  border-width: 1px;
`

const Container = styled.div`
  display: flex;
  flex: 1 1 0;
  align-items: center;
  justify-content: center;
  gap: 20px 50px;

  @media ${desktopLarge.down} {
    flex-direction: column;
    align-items: left;
    gap: 20px;
  }
`

const InfoFields = styled.div`
  display: flex;
  gap: 25px;

  @media ${desktopLarge.down} {
    flex-direction: column;
    gap: 15px;
  }
`

const NearByNotice = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: right;
  gap: 5px;
`

const NoticeIcon = styled(StyledIcon)`
  width: 12px;
  height: 12px;
  position: relative;
  margin-bottom: 4px;
  svg {
    path {
      fill: ${({ theme }) => theme.primary};
    }
  }
`

const NearByLabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: right;
  gap: 5px;
`

const NearByDescription = styled.p`
  color: grey;
`

const CrossContainer = styled.div`
  display: flex;
  align-items: right;
  justify-content: right;
  margin: -20px;
`

const CrossIcon = styled(StyledIcon)`
  width: 16px;
  height: 16px;
  position: relative;
  svg {
    path {
      fill: ${({ theme }) => theme.primary};
    }
    &:hover {
      cursor: pointer;
      path {
        fill: ${({ theme }) => theme.mediumGray};
      }
    }
  }
`

const S = {
  OuterContainer,
  AlternativesFrame,
  NearByNotice,
  NoticeIcon,
  NearByLabel,
  NearByDescription,
  InfoFields,
  Container,
  CrossContainer,
  CrossIcon,
}

export default S
