import React, { MouseEvent } from 'react'

import { StyledAvatar, StyledSpan } from 'components/avatar/style'
import { isPresent } from 'services/helpers/values'

interface AvatarProps {
  firstName?: string
  lastName?: string
  label?: string
  size?: 'small' | 'medium'
  outline?: boolean
  color?: string
  onClick?: (event: MouseEvent<HTMLDivElement>) => void
  hover?: boolean
  testId?: string
}

const Avatar = ({
  label,
  onClick,
  testId,
  firstName = '?',
  lastName = '?',
  size = 'medium',
  outline = false,
  color = '#5E75ED',
  hover = true,
}: AvatarProps) => (
  <StyledAvatar
    $outline={outline}
    $color={color}
    $size={size}
    $withLabel={!!label}
    onClick={onClick}
    $hover={hover}
    data-testid={testId}
  >
    <StyledSpan>
      {label ||
        `${isPresent(firstName) ? firstName[0].toUpperCase() : ''}${
          isPresent(lastName) ? lastName[0].toUpperCase() : ''
        }`}
    </StyledSpan>
  </StyledAvatar>
)

export default Avatar
