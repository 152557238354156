import React from 'react'
import { useParams, Navigate } from 'react-router-dom'

const RedirectWithParams = ({ to }: { to: string }) => {
  const params = useParams()
  const path = Object.keys(params).reduce((_path, key) => {
    const value = params[key] ?? ''
    return _path.replace(`:${key}`, value)
  }, to)

  return <Navigate to={path} replace />
}

export default RedirectWithParams
