import styled from 'styled-components'

const StyledCondensedCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  gap: 15px;
  &:not(:last-child) {
    border-bottom: 1px solid #efefef;
  }

  &:hover&::before {
    opacity: 0.03;
  }
`

export default StyledCondensedCard
