import React, { FC, useContext } from 'react'

import { isPresent } from 'services/helpers/values'

import {
  STEP_TYPE_PICKUP,
  STEP_TYPE_POL,
  STEP_TYPE_POD,
  STEP_TYPE_DELIVERY,
} from 'constants/shipments'
import SimpleTimeline from 'components/simple_transport_plan'
import ShipmentContext from 'features/shipments/contexts/shipment_context'

const FromTo: FC = () => {
  const { shipment } = useContext(ShipmentContext)
  const { summary } = shipment
  let locationFrom = ''
  const from = (summary[STEP_TYPE_PICKUP] || summary[STEP_TYPE_POL])?.location
  if (isPresent(from)) locationFrom = `${from.name}, ${from.countryCode}`
  let locationTo = ''
  const to = (summary[STEP_TYPE_DELIVERY] || summary[STEP_TYPE_POD])?.location
  if (isPresent(to)) locationTo = `${to.name}, ${to.countryCode}`

  return <SimpleTimeline locationFrom={locationFrom} locationTo={locationTo} />
}

export default FromTo
