import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  combineReducers,
  EntityId,
  EntityState,
} from '@reduxjs/toolkit'

import { InternalClient } from 'services/api/clients'
import onError from 'services/api/error'
import reduceReducers from 'services/helpers/reduce_reducers'
import useUrlParams from 'services/api/hooks/use_url_params'
import useThunkTeamsParam from 'services/api/hooks/use_thunk_teams_param'

import { STATUS_PENDING, STATUS_FULFILLED, STATUS_REJECTED } from 'constants/api'

import bookingDocumentsReducer from 'views/booking/slices/documents_slice'
import bookingLastEventsReducer from 'views/booking/slices/last_events_slice'
import { Booking, CreateBookingData, EditBookingData } from 'views/booking/slices/types'

import { Tvalue } from 'components/select'

import type { RootState } from 'services/store/store'
import type { Status } from 'constants/api'

export const fetchBooking = createAsyncThunk(
  'bookings/fetchBooking',
  async (token: string, thunkAPI) =>
    InternalClient.get(`/bookings/${token}`)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
)

export const createBooking = createAsyncThunk(
  'bookings/createBooking',
  async (data: CreateBookingData, thunkAPI) => {
    const teams = useThunkTeamsParam(thunkAPI, { legacyNaming: true })
    return InternalClient.post('/bookings', { ...data, ...teams })
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

export const editBooking = createAsyncThunk(
  'bookings/editBooking',
  async ({ token, changes }: { token: string; changes: EditBookingData }, thunkAPI) =>
    InternalClient.patch(`/bookings/${token}`, changes)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
)

export const fetchAssignableUsers = createAsyncThunk(
  'bookings/fetchAssignableUsers',
  async ({ forwarderId }: { forwarderId?: Tvalue }, thunkAPI) => {
    const url = useUrlParams('/bookings/available_users', { forwarderId })
    return InternalClient.get(url)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

export const validateProposal = createAsyncThunk(
  'bookings/validateProposal',
  async (token, thunkAPI) =>
    InternalClient.post(`/bookings/${token}/validate_proposal`)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
)

export const acceptBooking = createAsyncThunk('bookings/acceptBooking', async (token, thunkAPI) =>
  InternalClient.post(`/bookings/${token}/accept`)
    .then((r) => r.data)
    .catch(onError(thunkAPI))
)

export const confirmBooking = createAsyncThunk('bookings/confirmBooking', async (token, thunkAPI) =>
  InternalClient.post(`/bookings/${token}/confirm`)
    .then((r) => r.data)
    .catch(onError(thunkAPI))
)

export const cancelBooking = createAsyncThunk('bookings/cancelBooking', async (token, thunkAPI) =>
  InternalClient.post(`/bookings/${token}/cancel`)
    .then((r) => r.data)
    .catch(onError(thunkAPI))
)

export const declineBooking = createAsyncThunk('bookings/declineBooking', async (token, thunkAPI) =>
  InternalClient.post(`/bookings/${token}/decline`)
    .then((r) => r.data)
    .catch(onError(thunkAPI))
)

const bookingAdapter = createEntityAdapter({
  selectId: ({ token }: Booking) => token,
})
interface BookingInitialState {
  status: Status
  filesStatus: Status
  // TODO: type files
  files: Record<string, never>
}
const initialState = bookingAdapter.getInitialState<BookingInitialState>({
  status: STATUS_PENDING,
  filesStatus: STATUS_PENDING,
  files: {},
})

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBooking.fulfilled, (state, action) => {
      bookingAdapter.upsertOne(state, action.payload)
      state.status = STATUS_FULFILLED
    })
    builder.addCase(fetchBooking.pending, (state) => {
      state.status = STATUS_PENDING
    })
    builder.addCase(fetchBooking.rejected, (state) => {
      state.status = STATUS_REJECTED
    })
  },
})

export const { selectById: selectBooking } = bookingAdapter.getSelectors(
  (state: RootState) => state.booking
)

export const selectBookingFiles = (state: EntityState<Booking>, id: EntityId) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  selectBooking(state, id)?.files

export const selectBookingStatus = (state: RootState) => state.booking.status
export const selectBookingFileStatus = (state: RootState, id: EntityId) =>
  state.booking.fileStatuses[id]

const nestedEntitiesReducer = combineReducers({
  documents: bookingDocumentsReducer,
  lastEvents: bookingLastEventsReducer,
})

const reducer = reduceReducers(bookingSlice.reducer, nestedEntitiesReducer)

export default reducer
