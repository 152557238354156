import React, { useContext, useMemo } from 'react'
import ContentLoader from 'react-content-loader'

import { SHIPMENT_VIEW_CONDENSED } from 'constants/shipments'

import ShipmentViewContext from 'features/shipments/contexts/shipment_view_context'

import useBreakpoints from 'services/hooks/use_breakpoints'
import {
  StyledShipmentListItem,
  StyledShipmentListItemHead,
} from 'features/shipments/components/shipment_list_item/style'

const ShipmentListItemSkeleton = () => {
  const { view } = useContext(ShipmentViewContext)
  const isCondensed = useMemo(() => view === SHIPMENT_VIEW_CONDENSED, [view])
  const { isMobile } = useBreakpoints()

  return (
    <StyledShipmentListItem>
      {isCondensed && !isMobile && (
        <ContentLoader viewBox='0 0 400 20' style={{ width: '100%' }}>
          <rect x='18' y='4' rx='3' ry='3' width='158' height='3' />
          <rect x='18' y='14' rx='3' ry='3' width='128' height='3' />
          <circle cx='7' cy='11' r='7' />
          <rect x='325' y='8' rx='3' ry='3' width='75' height='3' />
        </ContentLoader>
      )}
      {isCondensed && isMobile && (
        <ContentLoader viewBox='0 0 200 65' style={{ width: '100%' }}>
          <rect x='18' y='4' rx='3' ry='3' width='120' height='4' />
          <rect x='18' y='20' rx='5' ry='3' width='100' height='4' />
          <circle cx='7' cy='14' r='7' />
          <rect x='8' y='42' rx='3' ry='3' width='185' height='17' />
        </ContentLoader>
      )}
      {!isCondensed && !isMobile && (
        <div>
          <StyledShipmentListItemHead>
            <ContentLoader viewBox='0 0 400 15' style={{ width: '100%' }}>
              <rect x='18' y='6' rx='3' ry='3' width='158' height='3' />
              <circle cx='7' cy='8' r='7' />
              <rect x='325' y='6' rx='3' ry='3' width='75' height='3' />
            </ContentLoader>
          </StyledShipmentListItemHead>
          <ContentLoader viewBox='0 0 400 35' style={{ width: '100%' }}>
            <rect x='0' y='-5' rx='5' ry='5' width='400' height='40' />
          </ContentLoader>
        </div>
      )}

      {!isCondensed && isMobile && (
        <div>
          <StyledShipmentListItemHead>
            <ContentLoader viewBox='0 0 200 27' style={{ width: '100%' }}>
              <rect x='18' y='6' rx='3' ry='3' width='158' height='4' />
              <rect x='18' y='18' rx='3' ry='3' width='128' height='4' />
              <circle cx='7' cy='14' r='7' />
              <rect x='325' y='6' rx='3' ry='3' width='75' height='4' />
            </ContentLoader>
          </StyledShipmentListItemHead>
          <ContentLoader viewBox='0 0 200 120' style={{ width: '100%' }}>
            <rect x='0' y='-5' rx='10' ry='10' width='200' height='120' />
          </ContentLoader>
        </div>
      )}
    </StyledShipmentListItem>
  )
}

ShipmentListItemSkeleton.propTypes = {}

ShipmentListItemSkeleton.defaultProps = {}

export default ShipmentListItemSkeleton
