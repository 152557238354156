export const SORT_BY_DEPARTURE_AT = 'departure_date'
export const SORT_BY_CREATED_AT = 'created_at'
export const SORT_BY_ARRIVED_AT = 'transportation_date'
export const SORT_BY_ETA_DIFFERENCE = 'eta_diff'
export const SORT_BY_ETA_DIFF_POD = 'eta_diff_pod'
export const SORT_BY_LAST_ETA_CHANGE = 'last_eta_change'
export const SORT_BY_OPTIONS = [
  SORT_BY_CREATED_AT,
  SORT_BY_ARRIVED_AT,
  SORT_BY_ETA_DIFFERENCE,
  SORT_BY_LAST_ETA_CHANGE,
  SORT_BY_DEPARTURE_AT,
] as const
export type SortByOption = typeof SORT_BY_OPTIONS[number]

export const FILTER_BY_FAVORITE = 'favorite_user_ids_eq'

export const DIRECTION_ASCENDING = 'asc'
export const DIRECTION_DESCENDING = 'desc'
export const DIRECTIONS = [DIRECTION_ASCENDING, DIRECTION_DESCENDING] as const
export type Directions = typeof DIRECTIONS[number]

export const ETA_DIFF_DELIVERY = 'eta_diff_delivery'
export const ETA_DIFF_POD = 'eta_diff_pod'
export const TRANSPORTATION_DATE_POL_ETD = 'transportation_date_pol_etd'
export const TRANSPORTATION_DATE_POD_ETA = 'transportation_date_pod_eta'
export const TRANSPORTATION_DATE_DELIVERY_ETA = 'transportation_date_delivery_eta'
