import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import useModal from 'components/modal/hooks/use_modal'
import ActionModalContent from 'views/booking/components/action_modal_content'
import Modal from 'components/modal'

import { editBooking } from 'views/booking/slices/booking_slice'
import { addNotification } from 'views/notifications/slice'

import { BOOKING_EXCHANGE_ACTION_KEYS } from 'constants/bookings'

import Button from 'components/button'
import useBookingRole from 'views/booking/hooks/use_booking_role'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { EditBookingData } from 'views/booking/slices/types'

interface ValidateEditModalProps {
  token: string
  editBookingData: EditBookingData
  wontTriggerReview: boolean
  onClose: () => void
}

const ValidateEditModal: FC<ValidateEditModalProps> = ({
  token,
  editBookingData,
  wontTriggerReview,
  onClose,
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { setOpen } = useModal('bookingValidateEdit')
  const navigate = useNavigate()
  const { isShipper, isForwarder } = useBookingRole()
  const [isSendDisabled, setIsSendDisabled] = useState<boolean>(false)

  const onCloseModal = () => {
    onClose()
    setOpen(false)
  }

  const modalActionKey =
    wontTriggerReview || isForwarder
      ? BOOKING_EXCHANGE_ACTION_KEYS.EDIT_BOOKING
      : BOOKING_EXCHANGE_ACTION_KEYS.CREATE_BOOKING

  const onClickSend = () => {
    setIsSendDisabled(true)

    dispatch(editBooking({ token, changes: editBookingData }))
      .unwrap()
      .then(() => {
        dispatch(
          addNotification({
            type: 'success',
            title: t(`bookings.alerts.${BOOKING_EXCHANGE_ACTION_KEYS.EDIT_BOOKING}.title`),
            text: t(`bookings.alerts.${BOOKING_EXCHANGE_ACTION_KEYS.EDIT_BOOKING}.successMessage`),
          })
        )
        navigate(`/booking/${token}`)
      })
      .catch((error) => {
        const errorDetails =
          error.message?.errorType === 'validation' && error.message?.details.length > 0
            ? error.message?.details[0].message
            : null
        dispatch(
          addNotification({
            type: 'alert',
            title: t(`bookings.alerts.${BOOKING_EXCHANGE_ACTION_KEYS.EDIT_BOOKING}.title`),
            text:
              errorDetails ||
              t(`bookings.alerts.${BOOKING_EXCHANGE_ACTION_KEYS.EDIT_BOOKING}.errorMessage`),
          })
        )
      })
      .finally(() => setIsSendDisabled(false))
  }

  return (
    <Modal position='center' onClose={onCloseModal} modalName='bookingValidateEdit'>
      <Modal.Header>{t(`bookings.exchangeModal.${modalActionKey}.title`)}</Modal.Header>
      <Modal.Content>
        <ActionModalContent actionKey={modalActionKey} role={isShipper ? 'forwarder' : 'shipper'} />
      </Modal.Content>
      <Modal.Footer>
        <Button text={t('actions.cancel')} onClick={onCloseModal} rounded variant='clear' />
        <Button
          text={t('actions.send')}
          onClick={onClickSend}
          disabled={!editBookingData}
          isLoading={isSendDisabled}
          rounded
          variant='highlight'
        />
      </Modal.Footer>
    </Modal>
  )
}

export default ValidateEditModal
