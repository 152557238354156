import { array, bool, boolean, InferType, mixed, number, object, setLocale, string } from 'yup'

import { TRANSPORT_TYPE_AIR, TRANSPORT_TYPE_SEA } from 'constants/shipments'
import {
  MERCHANDISE_FCL,
  MERCHANDISE_LCL,
  MERCHANDISE_TYPE_CONTAINER,
  MERCHANDISE_TYPE_PACKAGE,
  MERCHANDISE_TYPE_PACKAGE_TOTAL,
  MERCHANDISE_TYPE_TOTAL,
} from 'constants/bookings'
import { SelectValue } from 'components/select'
import { BookingFormMerchandise } from 'views/booking/components/form/merchandise/types'
import i18n from 'views/locales/i18n'

interface BookingFormCustomReference {
  key?: string
  value?: string
}

export interface BookingFormContext {
  isForwarder: boolean
  defaultForwarder?: SelectValue
  defaultShipper?: SelectValue
  isStatusPastProposalExchange: boolean
  withBookingOldWorkflow: boolean
  containerTypes: SelectValue[]
  packageTypes: SelectValue[]
  hazardousClasses: SelectValue[]
  packingGroups: SelectValue[]
  defaultKeyContacts?: string[]
}

export interface BookingFormReferenceData {
  clientReference?: string
  clientBookingNumber?: string
  forwarderReference?: string
  customReferences?: BookingFormCustomReference[]
}

export interface PartiesFormData {
  shipper?: SelectValue
  forwarder?: SelectValue
  consignor?: SelectValue
  consignee?: SelectValue
}

export interface IncotermsFormData {
  incoterms?: SelectValue
  incotermsLocation?: string
}

export interface TimelineStep {
  location?: SelectValue | null
  pta?: string
  ptd?: string
}

export interface RoutingFormData {
  withPreCarriage: boolean
  preCarriage?: Omit<TimelineStep, 'pta'> | null
  pol?: TimelineStep | null
  transshipments?: TimelineStep[]
  pod?: TimelineStep | null
  withPostCarriage: boolean
  postCarriage?: Omit<TimelineStep, 'ptd'> | null
}

interface ShipmentAttributeMainData {
  shipmentReference?: string
  weight?: number
}

export interface SeaShipmentAttribute extends ShipmentAttributeMainData {
  containerNumber?: string
  containerType?: SelectValue
}

export interface AirShipmentAttribute extends ShipmentAttributeMainData {
  masterAwb?: string
}

export interface AirTransportDetails extends TransportDetailsMainData {
  flightNumbers: string[]
  shipmentAttributes?: AirShipmentAttribute[]
}

export interface SeaTransportDetails extends TransportDetailsMainData {
  bookingNumber?: string
  masterBl?: string
  vessels?: SelectValue[]
  voyageNumbers?: string[]
  shipmentAttributes?: SeaShipmentAttribute[]
}

export type TransportDetails = SeaTransportDetails | AirTransportDetails

interface TransportDetailsMainData {
  carrier?: SelectValue | null
}

export interface MiscellaneousFormData {
  cutOffDate?: string
  vgmCutOffDate?: string
  customFields?: BookingFormCustomReference[]
}

export interface RateFormData {
  withRateConfirmation: boolean
  rateconfirmation?: {
    amount?: number
    currencyCode?: SelectValue
  }
}

export interface CommentFormData {
  shipperComment?: string
  forwarderComment?: string
}

export interface BookingFormInput {
  transportType: typeof TRANSPORT_TYPE_SEA | typeof TRANSPORT_TYPE_AIR
  references: BookingFormReferenceData
  parties: PartiesFormData
  incoterms: IncotermsFormData
  routing: RoutingFormData
  transportDetails: TransportDetails
  miscellaneous: MiscellaneousFormData
  merchandise: BookingFormMerchandise
  rate: RateFormData
  keyContacts: string[]
  comments: CommentFormData
}

setLocale({
  mixed: {
    required: i18n.t('errors.validation.required'),
    notNull: i18n.t('errors.validation.notNull'),
  },
})

const positiveNumberSchema = number().min(0, i18n.t('errors.validation.greaterThan', { value: 0 }))

const selectValueNumberSchema = object({
  value: number().required(),
  label: string().required(),
}).default(undefined)

const selectValueNumberWithCreationSchema = selectValueNumberSchema.shape({
  createValue: string().optional(),
})

const selectValueStringSchema = object({
  value: string().required(),
  label: string().required(),
}).default(undefined)

const selectValueStringWithCreationSchema = selectValueStringSchema.shape({
  createValue: string().optional(),
})

const optionalTimelineStepSchema = object({
  location: selectValueNumberSchema.nullable().optional(),
  ptd: string().nullable().optional(),
  pta: string().nullable().optional(),
})
  .optional()
  .nullable()

export type OptionalTimelineStepSchema = InferType<typeof optionalTimelineStepSchema>

const timelineStepSchema = object({
  location: selectValueNumberSchema.required(),
  ptd: string().nullable().optional(),
  pta: string().nullable().optional(),
})

type TimelineStepSchema = InferType<typeof timelineStepSchema>
export type AnyTimelineStepSchema = OptionalTimelineStepSchema | TimelineStepSchema

const customReferencesSchema = array().of(
  object({ key: string().required(), value: string().required() })
)

const shipmentAttributeSchema = object({
  shipmentReference: string().optional().nullable().default(undefined),
  weight: positiveNumberSchema.optional().default(undefined),
})

const seaShipmentAttributeSchema = shipmentAttributeSchema.shape({
  containerNumber: string()
    .required()
    .matches(/^[a-zA-Z]{4}[0-9]{7}$/, i18n.t('errors.validation.containerNumber')),
  containerType: selectValueNumberSchema.optional().nullable().default(undefined),
})

export type SeaShipmentAttributeSchema = InferType<typeof seaShipmentAttributeSchema>

const airShipmentAttributeSchema = shipmentAttributeSchema.shape({
  masterAwb: string()
    .required()
    .matches(/^[0-9]{3}-[0-9]{8}$/, i18n.t('errors.validation.awb')),
})

export type AirShipmentAttributeSchema = InferType<typeof airShipmentAttributeSchema>

const transportDetailsSchema = object({
  carrier: selectValueNumberWithCreationSchema.optional().nullable().default(undefined),
})

const seaTransportDetailsSchema = transportDetailsSchema.shape({
  bookingNumber: string().optional(),
  masterBl: string().optional(),
  vessels: array().of(selectValueStringWithCreationSchema).optional().default([]),
  voyageNumbers: array().of(string().defined()).optional().default([]),
  shipmentAttributes: array().of(seaShipmentAttributeSchema).optional(),
})

export type SeaTransportDetailsSchema = InferType<typeof seaTransportDetailsSchema>

const airTransportDetailsSchema = transportDetailsSchema.shape({
  flightNumbers: array().of(string().defined()).optional(),
  shipmentAttributes: array().of(airShipmentAttributeSchema).optional(),
})

export type AirTransportDetailsSchema = InferType<typeof airTransportDetailsSchema>

const timelineArrivalStepSchema = timelineStepSchema.omit(['ptd'])
const timelineDepartureStepSchema = timelineStepSchema.omit(['pta'])

const containerSchema = object({
  containerType: selectValueNumberSchema.required(),
  quantity: positiveNumberSchema.required(),
})

const commonMerchandiseSchema = object({
  productDescription: string().nullable(),
  commercialValue: object({
    amount: positiveNumberSchema.nullable(),
    currencyCode: selectValueStringSchema.nullable(),
  }).optional(),
  controlledTemperatures: object({
    min: number().nullable(),
    max: number().nullable(),
  }).optional(),
  hazardousGoods: object({
    hazardousClass: selectValueNumberSchema.nullable(),
    packingGroup: selectValueNumberSchema.nullable(),
    weight: positiveNumberSchema.nullable(),
    unNumber: string().nullable(),
  }).optional(),
})

export type CommonMerchandiseDetailsSchema = InferType<typeof commonMerchandiseSchema>

const productMerchandiseSchema = commonMerchandiseSchema.shape({
  volume: positiveNumberSchema.nullable(),
  weight: positiveNumberSchema.nullable(),
  packageNumber: positiveNumberSchema.nullable(),
})

const packageSchema = commonMerchandiseSchema.shape({
  packageType: selectValueNumberSchema.required(),
  quantity: positiveNumberSchema.required(),
  weight: positiveNumberSchema.required(),
  length: positiveNumberSchema.required(),
  width: positiveNumberSchema.required(),
  height: positiveNumberSchema.required(),
})

const bookingMerchandiseTotalSchema = object({
  containers: array().of(containerSchema).required(),
  products: array().of(productMerchandiseSchema).optional(),
})

export type BookingMerchandiseTotalSchema = {
  total: InferType<typeof bookingMerchandiseTotalSchema>
}

const containerWithProductSchema = containerSchema.shape({
  products: array().of(productMerchandiseSchema).optional(),
})

const bookingMerchandisePackageSchema = object({
  totalWeight: positiveNumberSchema.nullable(),
  totalVolume: positiveNumberSchema.nullable(),
  packages: array().of(packageSchema).required(),
})

export type BookingMerchandisePackageSchema = {
  package: InferType<typeof bookingMerchandisePackageSchema>
}

const bookingMerchandiseContainerSchema = array().of(containerWithProductSchema)

export type BookingMerchandiseContainerSchema = {
  container: InferType<typeof bookingMerchandiseContainerSchema>
}

const bookingMerchandisePackageTotalSchema = object({
  weight: positiveNumberSchema.required(),
  volume: positiveNumberSchema.required(),
  packageNumber: positiveNumberSchema.optional().default(undefined),
  merchandiseDetails: array().of(commonMerchandiseSchema).optional(),
})

export type BookingMerchandisePackageTotalSchema = {
  packageTotal: InferType<typeof bookingMerchandisePackageTotalSchema>
}

export const bookingFormSchema = object({
  references: object({
    clientReference: string().required(),
    clientBookingNumber: string().optional(),
    forwarderReference: string().optional().default(undefined),
    customReferences: customReferencesSchema.optional(),
  }).required(),
  parties: object({
    shipper: selectValueNumberSchema.required().default(undefined),
    forwarder: selectValueNumberSchema.required().default(undefined),
    consignee: selectValueNumberSchema.required().default(undefined),
    consignor: selectValueNumberSchema.required().default(undefined),
  }).required(),
  incoterms: object({
    incotermsLocation: string().optional(),
    incoterms: selectValueStringSchema.required(),
  }).required(),
  transportType: string().oneOf([TRANSPORT_TYPE_SEA, TRANSPORT_TYPE_AIR]).required(),
  routing: object({
    withPreCarriage: bool().default(false).required(),
    preCarriage: optionalTimelineStepSchema.when('withPreCarriage', {
      is: true,
      then: () => timelineDepartureStepSchema.required(),
      otherwise: () => optionalTimelineStepSchema,
    }),
    pol: mixed<typeof optionalTimelineStepSchema | typeof timelineStepSchema>().when(
      'withPreCarriage',
      {
        is: true,
        then: () => optionalTimelineStepSchema,
        otherwise: () => timelineStepSchema.required(),
      }
    ),
    transshipments: array().of(timelineStepSchema).optional(),
    pod: mixed<typeof optionalTimelineStepSchema | typeof timelineStepSchema>().when(
      'withPostCarriage',
      {
        is: true,
        then: () => optionalTimelineStepSchema,
        otherwise: () => timelineStepSchema.required(),
      }
    ),
    withPostCarriage: bool().default(false).required(),
    postCarriage: optionalTimelineStepSchema.when('withPostCarriage', {
      is: true,
      then: () => timelineArrivalStepSchema.required(),
      otherwise: () => optionalTimelineStepSchema,
    }),
  }).required(),
  transportDetails: mixed<AirTransportDetailsSchema | SeaTransportDetailsSchema>()
    .when('transportType', {
      is: TRANSPORT_TYPE_SEA,
      then: () => seaTransportDetailsSchema,
    })
    .when('transportType', {
      is: TRANSPORT_TYPE_AIR,
      then: () => airTransportDetailsSchema,
    }),
  miscellaneous: object({
    cutOffDate: string().nullable().optional(),
    vgmCutOffDate: string().nullable().optional(),
    customFields: customReferencesSchema.optional(),
  }),
  merchandise: object({
    merchandiseType: string()
      .oneOf([
        MERCHANDISE_TYPE_TOTAL,
        MERCHANDISE_TYPE_PACKAGE,
        MERCHANDISE_TYPE_CONTAINER,
        MERCHANDISE_TYPE_PACKAGE_TOTAL,
      ])
      .required(),
    shippingMode: string().oneOf([MERCHANDISE_FCL, MERCHANDISE_LCL]).required(),
    content: mixed()
      .when('merchandiseType', ([merchandiseType]) => {
        if (merchandiseType === MERCHANDISE_TYPE_CONTAINER) {
          return object({
            container: bookingMerchandiseContainerSchema.required(),
          })
        }

        if (merchandiseType === MERCHANDISE_TYPE_PACKAGE) {
          return object({
            package: bookingMerchandisePackageSchema.required(),
          })
        }

        if (merchandiseType === MERCHANDISE_TYPE_TOTAL) {
          return object({
            total: bookingMerchandiseTotalSchema.required(),
          })
        }

        return object({
          packageTotal: bookingMerchandisePackageTotalSchema.required(),
        })
      })
      .required(),
  }).required(),
  rate: object({
    withRateConfirmation: boolean().required(),
    rateconfirmation: object({
      amount: positiveNumberSchema.optional(),
      currencyCode: selectValueStringSchema.optional().default(undefined),
    }).optional(),
  }).required(),
  keyContacts: array().of(string().defined()).optional().default([]),
  comments: object({
    shipperComment: string().optional(),
    forwarderComment: string().optional(),
  }).optional(),
})

export type BookingFormData = InferType<typeof bookingFormSchema>
