import React, { FC, useContext } from 'react'

import FavoriteComponent from 'components/favorite'
import ShipmentContext from 'features/shipments/contexts/shipment_context'
import useToggleFavorite from 'features/shipments/components/shipment_list_item/hooks/use_toggle_favorite'

const Favorite: FC = () => {
  const { shipment } = useContext(ShipmentContext)
  const { id } = shipment
  const isActive = shipment.favorite
  const toggleFavorite = useToggleFavorite({ id, isActive })

  return <FavoriteComponent active={isActive} onClick={toggleFavorite} />
}

export default Favorite
