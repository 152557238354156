import React from 'react'

import * as IconList from 'assets/icons'
import Icon from 'components/icon'
import {
  StyledCheckboxPicto,
  StyledText,
  StyledInput,
  StyledLabel,
  StyledPicto,
} from 'components/checkbox_picto/style'

// TODO: REFACTOR
// All props are manually passed to checkboxes
// This may cause bugs or overhead maintaining the two components

interface CheckboxPictoProps {
  icon: keyof typeof IconList
  text: string
  name: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  checked?: boolean
  id?: string
  testId?: string
  type?: 'checkbox' | 'radio'
  condensed?: boolean
  disabled?: boolean
  value?: string
}

const CheckboxPicto = ({
  icon,
  text,
  name,
  onChange,
  checked,
  id,
  testId,
  type = 'checkbox',
  condensed = false,
  disabled = false,
  value = '',
}: CheckboxPictoProps) => (
  <StyledCheckboxPicto $condensed={condensed}>
    <StyledInput
      type={type}
      name={name}
      id={id}
      onChange={onChange}
      checked={checked}
      value={value}
      disabled={disabled}
      data-testid={testId}
    />
    <StyledLabel htmlFor={id}>
      {icon && <StyledPicto as={Icon} name={icon} />}
      <StyledText>{text}</StyledText>
    </StyledLabel>
  </StyledCheckboxPicto>
)

export default CheckboxPicto
