import React from 'react'

import { useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'

import {
  saveShowAlternatives,
  selectAlternatives,
  selectShowAlternatives,
} from 'views/trusted_routes/slice'

import Icon from 'components/icon'

import S from 'views/trusted_routes/components/alternative_routes/style'

import AlternativesFetcher from 'views/trusted_routes/components/alternative_routes/alternatives_fetcher'

import useAppDispatch from 'services/hooks/use_app_dispatch'

import useSearchAlternativeTrustedRoutes from 'views/trusted_routes/hooks/use_search_alternatives'
import BlockAlternativesSkeleton from 'views/trusted_routes/components/alternative_routes/alternatives_loader'

const useOnClick = () => {
  const dispatch = useAppDispatch()
  return () => dispatch(saveShowAlternatives(false))
}

const AlternativeRoutes: React.FC = () => {
  const { t } = useTranslation()
  const showAlternatives = useSelector(selectShowAlternatives)
  const alternatives = useSelector(selectAlternatives)
  const onClick = useOnClick()
  useSearchAlternativeTrustedRoutes()
  if (
    showAlternatives &&
    (alternatives === null ||
      alternatives.bestHistoricalCo2Alternative ||
      alternatives.bestHistoricalEstimatedTimeAlternative)
  )
    return (
      <S.OuterContainer>
        <S.AlternativesFrame>
          <S.Container>
            <S.InfoFields>
              <S.NearByNotice>
                <S.NoticeIcon as={Icon} name='light_bulb' />
                <S.NearByLabel>
                  <b>{t('trustedRoutes.alternatives.title')}</b>
                  <S.NearByDescription>
                    {t('trustedRoutes.alternatives.description')}
                  </S.NearByDescription>
                </S.NearByLabel>
              </S.NearByNotice>
            </S.InfoFields>
          </S.Container>
          {alternatives != null && <AlternativesFetcher />}
          {alternatives === null && <BlockAlternativesSkeleton />}
          <S.CrossContainer onClick={onClick}>
            <S.CrossIcon as={Icon} name='cross_plain' />
          </S.CrossContainer>
        </S.AlternativesFrame>
      </S.OuterContainer>
    )
  return <div />
}

export default AlternativeRoutes
