import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import {
  fetchAlternativeTrustedRoutes,
  selectSubscriptionID,
  selectShowAlternatives,
} from 'views/trusted_routes/slice'
import useAppDispatch from 'services/hooks/use_app_dispatch'

const useSearchAlternativeTrustedRoutes = () => {
  const dispatch = useAppDispatch()
  const shouldShowAlternatives = useSelector(selectShowAlternatives)
  const subscriptionID = useSelector(selectSubscriptionID)

  // when the subscriptionID is ready, go fetch the alternative nearby ports
  useEffect(() => {
    if (!shouldShowAlternatives || !subscriptionID) return

    dispatch(fetchAlternativeTrustedRoutes({ subscriptionID })).unwrap()
  }, [subscriptionID, shouldShowAlternatives, dispatch])
}

export default useSearchAlternativeTrustedRoutes
