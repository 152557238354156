/* eslint-disable import/no-cycle */

import UsersReducer from 'features/users/store/slice'

import BookingTemplatesReducer from 'views/booking/slices/booking_templates_slice'

import AnalyticsReducer from 'features/analytics/store/slice'
import BookingReducer from 'views/booking/slices/booking_slice'
import BookingsReducer from 'views/bookings/slice'
import DashboardReducer from 'features/shipments/store/vehicle_slice'
import IAmReducer from 'views/iam/slices/iamSlice'
import NotificationsReducer from 'views/notifications/slice'
import NetworkCompaniesReducer from 'views/network/companies/slice'
import ShipmentsReducer from 'features/shipments/store/shipment_slice'
import ShipmentFilterTemplatesReducer from 'features/shipments/store/filter_templates_slice'
import BookingFilterTemplatesReducer from 'views/bookings/slices/filter_templaces_slice'
import ShipmentReducer from 'views/shipment/slice'
import ShipmentVersionsReducer from 'views/audit_trail/slice'
import CustomKpisReducer from 'features/custom_kpis/store/slice'
import ApplicationReducer from 'app/slice'
import TrustedRoutesReducer from 'views/trusted_routes/slice'
import ClusterReducer from 'views/atlas/slices/cluster'
import HubReducer from 'views/atlas/slices/hub'
import AddressReducer from 'views/atlas/slices/address'
import SSOReducer from 'views/iam/slices/ssoSlice'

const rootReducer = () => ({
  iam: IAmReducer,
  shipments: ShipmentsReducer,
  dashboard: DashboardReducer,
  shipmentFilterTemplates: ShipmentFilterTemplatesReducer,
  shipment: ShipmentReducer,
  versions: ShipmentVersionsReducer,
  notifications: NotificationsReducer,
  networkCompanies: NetworkCompaniesReducer,
  analytics: AnalyticsReducer,
  booking: BookingReducer,
  bookings: BookingsReducer,
  bookingFilterTemplates: BookingFilterTemplatesReducer,
  bookingTemplates: BookingTemplatesReducer,
  customKpis: CustomKpisReducer,
  application: ApplicationReducer,
  users: UsersReducer,
  trustedRoutes: TrustedRoutesReducer,
  clusters: ClusterReducer,
  hubs: HubReducer,
  addresses: AddressReducer,
  sso: SSOReducer,
})

export default rootReducer
