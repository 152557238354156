import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { FieldErrors, FormProvider, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import {
  StyledFormFooter,
  StyledPageBookingDetailBoxShadow,
  StyledPageBookingDetailInner,
} from 'views/booking/style'
import { StyledFooterButton } from 'views/booking/components/show/style'
import ValidateEditModal from 'views/booking/components/edit/validate_edit_modal'
import useBookingWorkflow from 'views/booking/hooks/use_booking_workflow'

import useModal from 'components/modal/hooks/use_modal'
import Button from 'components/button'
import { TEST_ID_BOOKING_CANCEL_BUTTON, TEST_ID_BOOKING_EDIT_BUTTON } from 'tests/e2e/test_ids'
import BookingForm from 'views/booking/components/form'
import formatBookingToFormInput from 'views/booking/components/form/helpers/booking_to_form'
import useBookingStatus from 'views/booking/hooks/use_booking_status'
import useBookingRole from 'views/booking/hooks/use_booking_role'
import { WITH_BOOKING_OLD_WORKFLOW } from 'constants/organization_features'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import {
  BookingFormContext,
  BookingFormData,
  BookingFormInput,
  bookingFormSchema,
} from 'views/booking/components/form/types'
import useDisabledField from 'views/booking/components/form/hooks/use_disabled_field'
import useStaticLocales from 'views/locales/hooks/use_static_locales'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { addNotification } from 'views/notifications/slice'
import { generateEditPayload } from 'views/booking/components/form/helpers/form_to_booking_payload'
import { Booking, EditBookingData } from 'views/booking/slices/types'
import { INFORMATIVE_FIELD_NAMES } from 'constants/bookings'
import { buildSelectValuesFromStaticTranslations } from 'services/helpers/values'

interface BookingEditFormProps {
  booking: Booking
}

const BookingEditForm: FC<BookingEditFormProps> = ({ booking }) => {
  const navigate = useNavigate()
  const { token } = booking
  const { t } = useTranslation()
  const { setOpen } = useModal('bookingValidateEdit')
  const { features } = useOrganizationCan()
  const { isStatusPastProposalExchange } = useBookingStatus(booking?.status)
  const { isForwarder } = useBookingRole()
  const { s } = useStaticLocales()
  const dispatch = useAppDispatch()
  const [editBookingData, setEditBookingData] = useState<EditBookingData | undefined>(undefined)
  const [isInformativeFieldsChange, setIsInformativeFieldsChange] = useState<boolean>(true)

  const withBookingOldWorkflow = features(WITH_BOOKING_OLD_WORKFLOW)

  const context: BookingFormContext = {
    isForwarder,
    isStatusPastProposalExchange,
    withBookingOldWorkflow,
    containerTypes: buildSelectValuesFromStaticTranslations(s('containerTypes')),
    packageTypes: buildSelectValuesFromStaticTranslations(s('packageTypes')),
    hazardousClasses: buildSelectValuesFromStaticTranslations(s('hazardousGoods')),
    packingGroups: buildSelectValuesFromStaticTranslations(s('packingGroups')),
  }

  const formInput = formatBookingToFormInput(context, booking)

  const { isFieldDisabled } = useDisabledField({
    booking,
    isEdit: true,
    routingWithoutPreCarriage: !booking?.transportPlan.withPreCarriage,
    routingWithoutOnCarriage: !booking?.transportPlan.withOnCarriage,
    rateConfirmation: booking?.rateConfirmation || false,
    defaultForwarderId: context.defaultForwarder?.value as number,
  })

  const { canEditBooking } = useBookingWorkflow(token)

  useEffect(() => {
    if (!canEditBooking) {
      navigate('/')
    }
  }, [canEditBooking, navigate])

  const methods = useForm<BookingFormInput, undefined, BookingFormData>({
    defaultValues: formInput,
    resolver: yupResolver(bookingFormSchema),
  })

  const {
    formState: { isDirty, dirtyFields },
    handleSubmit,
  } = methods

  const onSubmit = (data: BookingFormData) => {
    const editPayload = generateEditPayload(data, dirtyFields)

    const isInformativeChanges = Object.keys(editPayload).every((modifiedKey) =>
      INFORMATIVE_FIELD_NAMES.some((info) => info === modifiedKey)
    )

    setEditBookingData(editPayload)
    setIsInformativeFieldsChange(isInformativeChanges)
    setOpen(true)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onError = (_formErrors: FieldErrors<BookingFormInput>) => {
    dispatch(
      addNotification({
        type: 'info',
        title: t('errors.notification.title'),
        text: t('errors.validation.invalidFormData'),
      })
    )
  }

  return (
    <FormProvider {...methods}>
      {editBookingData && (
        <ValidateEditModal
          token={token}
          editBookingData={editBookingData}
          wontTriggerReview={isInformativeFieldsChange}
          onClose={() => setEditBookingData(undefined)}
        />
      )}
      <StyledPageBookingDetailBoxShadow>
        <StyledPageBookingDetailInner>
          <BookingForm isCreate={false} isFieldDisabled={isFieldDisabled} />
        </StyledPageBookingDetailInner>
        <StyledFormFooter>
          <StyledFooterButton
            as={Button}
            text={t('actions.cancel')}
            onClick={() => navigate(`/booking/${token}`)}
            rounded
            variant='clear'
            testId={TEST_ID_BOOKING_CANCEL_BUTTON}
          />
          <StyledFooterButton
            as={Button}
            text={t('bookings.actions.saveModifications')}
            onClick={handleSubmit(
              (data) => onSubmit(data),
              (formErrors) => onError(formErrors)
            )}
            rounded
            icon='send'
            variant='highlight'
            disabled={!isDirty}
            testId={TEST_ID_BOOKING_EDIT_BUTTON}
          />
        </StyledFormFooter>
      </StyledPageBookingDetailBoxShadow>
    </FormProvider>
  )
}

export default BookingEditForm
