import { InferType, object, string, number } from 'yup'

export const numericalValueAlternativeSchema = object({
  departure: string().required(),
  departureName: string().required(),
  arrival: string().required(),
  arrivalName: string().required(),
  value: number().required(),
})

export const alternativesSchema = object({
  bestHistoricalEstimatedTimeAlternative: numericalValueAlternativeSchema.optional(),
  bestHistoricalCo2Alternative: numericalValueAlternativeSchema.optional(),
  bestHistoricalDisruptionAlertsAlternative: numericalValueAlternativeSchema.optional(),
})

export const ESTIMATED_TT = 'ESTIMATED_TT'
export const CO2 = 'CO2'
export const DISRUPTIONS = 'DISRUPTIONS'
export type AlternativeType = typeof ESTIMATED_TT | typeof CO2 | typeof DISRUPTIONS
export type AlternativeNumericalValue = InferType<typeof numericalValueAlternativeSchema>
export type Alternatives = InferType<typeof alternativesSchema>
