import React from 'react'

import { useTranslation } from 'react-i18next'
import { Controller, FieldErrors, useFieldArray, useFormContext } from 'react-hook-form'

import { v4 as uuid } from 'uuid'

import { useDispatch } from 'react-redux'

import { addNotification } from 'views/notifications/slice'

import {
  StyledGridReferences,
  StyledInput,
  StyledShipmentAttributesWrapper,
} from 'views/booking/components/form/style'
import {
  fetchAirlinesOptions,
  fetchShippingLinesOptions,
  fetchVesselsOptions,
} from 'views/select_options/slice'
import useStaticLocales from 'views/locales/hooks/use_static_locales'

import { TRANSPORT_TYPE_AIR, TRANSPORT_TYPE_SEA } from 'constants/shipments'

import Grid from 'components/grid'
import Form from 'components/form'
import Input from 'components/input'
import InputTags from 'components/input_tags'
import Select from 'components/select'
import SearchSelect from 'components/search_select'
import SearchMultiSelect from 'components/search_multi_select'

import {
  getTestIdForShipment,
  TEST_ID_BOOKING_FORM_ADD_SHIPMENT,
  TEST_ID_BOOKING_FORM_RATE_BOOKING_NUMBER,
  TEST_ID_BOOKING_FORM_SHIPMENTS_BLOCK,
} from 'tests/e2e/test_ids'
import {
  BOOKING_FIELD_NAMES,
  BOOKING_FIELD_VALIDATIONS,
  trackingRefFormatExampleMap,
} from 'constants/bookings'

import { BookingFieldNames } from 'views/booking/slices/types'

import useBookingRole from 'views/booking/hooks/use_booking_role'
import {
  AirShipmentAttribute,
  BookingFormInput,
  SeaShipmentAttribute,
} from 'views/booking/components/form/types'
import { stringToInt } from 'services/helpers/values'
import BlockContainerItem from 'components/block_container/block_container_item'
import BlockContainerAdd from 'components/block_container/block_container_add'

export const UNKNOWN_CARRIER_ID = -1

const TransportDetails = ({
  isFieldDisabled,
  isCreate,
}: {
  isFieldDisabled: (fieldName: BookingFieldNames) => boolean
  isCreate: boolean
}) => {
  const dispatch = useDispatch()
  const { isShipper } = useBookingRole()
  const { isForwarder } = useBookingRole()
  const { s } = useStaticLocales()
  const { t } = useTranslation()
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<BookingFormInput>()

  const currentTransportType = watch('transportType')

  const {
    fields: shipmentAttributes,
    append: appendShipmentAttribute,
    remove: removeShipmentAttribute,
  } = useFieldArray({
    control,
    name: 'transportDetails.shipmentAttributes',
  })

  const numberLabelMap = {
    [TRANSPORT_TYPE_SEA]: 'Container number',
    [TRANSPORT_TYPE_AIR]: 'AWB number (M)',
  }

  const canHaveShipmentsAttributes = (
    transportType: string | undefined
  ): transportType is typeof TRANSPORT_TYPE_SEA | typeof TRANSPORT_TYPE_AIR =>
    transportType === TRANSPORT_TYPE_SEA || transportType === TRANSPORT_TYPE_AIR

  const carrierInput = (
    <Controller
      name='transportDetails.carrier'
      control={control}
      render={({ field }) => (
        <StyledInput
          as={SearchSelect}
          label={t('bookings.transportDetails.carrier')}
          action={
            currentTransportType === TRANSPORT_TYPE_SEA
              ? fetchShippingLinesOptions
              : fetchAirlinesOptions
          }
          name={field.name}
          creatable
          formatCreateLabel={(inputValue: string) => {
            const label =
              currentTransportType === TRANSPORT_TYPE_SEA
                ? t('bookings.select.options.createUnknownScac')
                : t('bookings.select.options.createUnknownAwbPrefix')
            return `${label} "${inputValue}"`
          }}
          value={field.value}
          onCreateOption={(newOption: string) =>
            field.onChange({
              value: UNKNOWN_CARRIER_ID,
              label: `${t('bookings.select.options.unknownCarrier')} (${newOption})`,
              createValue: newOption,
            })
          }
          error={errors.transportDetails?.carrier?.value?.message}
          onChange={({ value }) => field.onChange(value)}
          isDisabled={isFieldDisabled(BOOKING_FIELD_NAMES.CARRIER)}
        />
      )}
    />
  )

  const vesselsInput = (
    <Controller
      name='transportDetails.vessels'
      control={control}
      render={({ field }) => (
        <StyledInput
          as={SearchMultiSelect}
          label={t('bookings.transportDetails.vessels')}
          value={field.value}
          name={field.name}
          formatCreateLabel={(inputValue: string) =>
            `${t('bookings.select.options.createUnknownImoNumber')} "${inputValue}"`
          }
          onCreateOption={(newOption: string) => {
            if (!Number.isInteger(parseInt(newOption, 10))) {
              dispatch(
                addNotification({
                  type: 'alert',
                  title: t('bookings.alerts.invalidImoNumber.title'),
                  text: t('bookings.alerts.invalidImoNumber.message'),
                })
              )
              return
            }

            field.onChange(
              (field.value ?? []).concat({
                value: uuid(),
                createValue: newOption,
                label: `${t('bookings.select.options.unknownVessel')} (${newOption})`,
              })
            )
          }}
          onChange={({ value }) => field.onChange(value)}
          action={fetchVesselsOptions}
          isMulti
          creatable
          isDisabled={isFieldDisabled(BOOKING_FIELD_NAMES.VESSELS)}
        />
      )}
    />
  )

  const voyageNumbersInput = (
    <Controller
      name='transportDetails.voyageNumbers'
      control={control}
      render={({ field }) => (
        <StyledInput
          as={InputTags}
          label={t('bookings.transportDetails.voyageNumbers')}
          value={field.value}
          name={field.name}
          onChange={({ tags }) => {
            field.onChange(tags)
          }}
          disabled={isFieldDisabled(BOOKING_FIELD_NAMES.VOYAGE_NUMBERS)}
        />
      )}
    />
  )

  return (
    <Form.Group title={t('bookings.transportDetails.title')}>
      {isShipper && isCreate ? (
        <StyledGridReferences as={Grid} columns={3}>
          <Grid.Row>
            <Grid.Column>{carrierInput}</Grid.Column>
            {currentTransportType === TRANSPORT_TYPE_SEA && (
              <Grid.Column>{vesselsInput}</Grid.Column>
            )}
            {currentTransportType === TRANSPORT_TYPE_SEA && (
              <Grid.Column>{voyageNumbersInput}</Grid.Column>
            )}
          </Grid.Row>
        </StyledGridReferences>
      ) : (
        <StyledGridReferences as={Grid} columns={3}>
          <Grid.Row>
            <Grid.Column>{carrierInput}</Grid.Column>
            {currentTransportType === TRANSPORT_TYPE_SEA && (
              <Grid.Column>
                <Controller
                  name='transportDetails.bookingNumber'
                  control={control}
                  render={({ field }) => (
                    <StyledInput
                      as={Input}
                      label={t('bookings.transportDetails.bookingNumber')}
                      placeholder={t('bookings.reference')}
                      name={field.name}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      disabled={isFieldDisabled(BOOKING_FIELD_NAMES.BOOKING_NUMBER)}
                      testId={TEST_ID_BOOKING_FORM_RATE_BOOKING_NUMBER}
                    />
                  )}
                />
              </Grid.Column>
            )}
            {currentTransportType === TRANSPORT_TYPE_AIR && (
              <Grid.Column>
                <Controller
                  name='transportDetails.flightNumbers'
                  control={control}
                  render={({ field }) => (
                    <StyledInput
                      as={InputTags}
                      disabled={isFieldDisabled(BOOKING_FIELD_NAMES.FLIGHT_NUMBERS)}
                      label={t('bookings.transportDetails.flightNumbers')}
                      value={field.value}
                      onChange={({ tags }) => {
                        field.onChange(tags)
                      }}
                    />
                  )}
                />
              </Grid.Column>
            )}
          </Grid.Row>
          {currentTransportType === TRANSPORT_TYPE_SEA && (
            <Grid.Row>
              <Grid.Column>
                <Controller
                  name='transportDetails.masterBl'
                  control={control}
                  render={({ field }) => (
                    <StyledInput
                      as={Input}
                      label={t('bookings.transportDetails.blNumber')}
                      placeholder={t('bookings.reference')}
                      value={field.value}
                      name={field.name}
                      onChange={(value) => field.onChange(value)}
                      disabled={isFieldDisabled(BOOKING_FIELD_NAMES.MASTER_BL)}
                    />
                  )}
                />
              </Grid.Column>
              <Grid.Column>{vesselsInput}</Grid.Column>
              <Grid.Column>{voyageNumbersInput}</Grid.Column>
            </Grid.Row>
          )}
          {isForwarder && canHaveShipmentsAttributes(currentTransportType) && (
            <StyledShipmentAttributesWrapper data-testid={TEST_ID_BOOKING_FORM_SHIPMENTS_BLOCK}>
              {shipmentAttributes.map((shipmentAttribute, index) => (
                <BlockContainerItem
                  title={t('bookings.transportDetails.shipment')}
                  key={shipmentAttribute.id}
                  index={index}
                  columns={2}
                  size={1}
                  minLength={0}
                  testId={getTestIdForShipment(index)}
                  length={shipmentAttributes.length}
                  disabled={isFieldDisabled(BOOKING_FIELD_NAMES.SHIPMENT_ATTRIBUTES)}
                  removeBlock={() => removeShipmentAttribute(index)}
                >
                  <Grid columns={2} columnsTablet={2}>
                    <Grid.Row>
                      <Grid.Column>
                        {currentTransportType === TRANSPORT_TYPE_SEA ? (
                          <Controller
                            name={`transportDetails.shipmentAttributes.${index}.containerNumber`}
                            control={control}
                            render={({ field }) => (
                              <Input
                                label={numberLabelMap[currentTransportType]}
                                name={`shipment-${index}-trackingRef`}
                                placeholder={trackingRefFormatExampleMap[currentTransportType]}
                                error={
                                  (
                                    errors.transportDetails?.shipmentAttributes?.[
                                      index
                                    ] as FieldErrors<SeaShipmentAttribute>
                                  )?.containerNumber?.message
                                }
                                value={field.value}
                                onChange={({ target: { value } }) => field.onChange(value)}
                                disabled={isFieldDisabled(BOOKING_FIELD_NAMES.SHIPMENT_ATTRIBUTES)}
                              />
                            )}
                          />
                        ) : (
                          <Controller
                            name={`transportDetails.shipmentAttributes.${index}.masterAwb`}
                            control={control}
                            render={({ field }) => (
                              <Input
                                label={numberLabelMap[currentTransportType]}
                                name={`shipment-${index}-trackingRef`}
                                placeholder={trackingRefFormatExampleMap[currentTransportType]}
                                error={
                                  (
                                    errors.transportDetails?.shipmentAttributes?.[
                                      index
                                    ] as FieldErrors<AirShipmentAttribute>
                                  )?.masterAwb?.message
                                }
                                value={field.value}
                                onChange={({ target: { value } }) => field.onChange(value)}
                                disabled={isFieldDisabled(BOOKING_FIELD_NAMES.SHIPMENT_ATTRIBUTES)}
                              />
                            )}
                          />
                        )}
                      </Grid.Column>
                      <Grid.Column>
                        <Controller
                          name={`transportDetails.shipmentAttributes.${index}.shipmentReference`}
                          control={control}
                          render={({ field }) => (
                            <Input
                              label='Shipment reference'
                              name={`shipment-${index}-reference`}
                              placeholder='Reference'
                              value={field.value}
                              error={
                                errors.transportDetails?.shipmentAttributes?.[index]
                                  ?.shipmentReference?.message
                              }
                              onChange={({ target: { value } }) => field.onChange(value)}
                              disabled={isFieldDisabled(BOOKING_FIELD_NAMES.SHIPMENT_ATTRIBUTES)}
                            />
                          )}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Controller
                          name={`transportDetails.shipmentAttributes.${index}.weight`}
                          control={control}
                          render={({ field }) => (
                            <Input
                              label='Weight'
                              name={`shipment-${index}-weight`}
                              placeholder='kg'
                              error={
                                errors.transportDetails?.shipmentAttributes?.[index]?.weight
                                  ?.message
                              }
                              value={field.value}
                              onChange={({ target: { value } }) =>
                                field.onChange(stringToInt(value))
                              }
                              step={0.01}
                              type='number'
                              min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.SHIPMENT_WEIGHT}
                              max={BOOKING_FIELD_VALIDATIONS.MAXIMAL_VALUES.SHIPMENT_WEIGHT}
                              disabled={isFieldDisabled(BOOKING_FIELD_NAMES.SHIPMENT_ATTRIBUTES)}
                            />
                          )}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        {currentTransportType === TRANSPORT_TYPE_SEA && (
                          <Controller
                            name={`transportDetails.shipmentAttributes.${index}.containerType`}
                            control={control}
                            render={({ field }) => (
                              <Select
                                label='Container type'
                                name={`shipment-${index}-containerType`}
                                options={Object.entries(s('containerTypes')).map(
                                  ([key, value]) => ({
                                    value: key,
                                    label: value,
                                  })
                                )}
                                error={
                                  (
                                    errors.transportDetails?.shipmentAttributes?.[
                                      index
                                    ] as FieldErrors<SeaShipmentAttribute>
                                  )?.containerType?.message
                                }
                                defaultValue={field.value}
                                onChange={({ value }) => field.onChange(value)}
                                isDisabled={isFieldDisabled(
                                  BOOKING_FIELD_NAMES.SHIPMENT_ATTRIBUTES
                                )}
                              />
                            )}
                          />
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </BlockContainerItem>
              ))}
              {!isFieldDisabled(BOOKING_FIELD_NAMES.SHIPMENT_ATTRIBUTES) && (
                <BlockContainerAdd
                  testId={TEST_ID_BOOKING_FORM_ADD_SHIPMENT}
                  title={t('bookings.transportDetails.shipment')}
                  addBlock={() => appendShipmentAttribute({})}
                  btnSize={1}
                  columns={2}
                />
              )}
            </StyledShipmentAttributesWrapper>
          )}
        </StyledGridReferences>
      )}
    </Form.Group>
  )
}

export default TransportDetails
