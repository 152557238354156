import React from 'react'

import { useTranslation } from 'react-i18next'
import { useFormContext, Controller } from 'react-hook-form'

import { StyledInput } from 'views/booking/components/form/style'
import {
  fetchConsigneeOptions,
  fetchConsignorOptions,
  fetchForwarderOptions,
  fetchShipperOptions,
  ISearch,
} from 'views/select_options/slice'
import Grid from 'components/grid'
import Form from 'components/form'
import SearchSelect from 'components/search_select'

import { TEST_ID_BOOKING_FORM_SHIPPER_FIELD } from 'tests/e2e/test_ids'

import { BookingFieldNames } from 'views/booking/slices/types'
import { BookingFormInput } from 'views/booking/components/form/types'
import { BOOKING_FIELD_NAMES } from 'constants/bookings'

const Parties = ({
  isFieldDisabled,
}: {
  isFieldDisabled: (fieldName: BookingFieldNames) => boolean
}) => {
  const { t } = useTranslation()

  const {
    control,
    formState: { errors },
  } = useFormContext<BookingFormInput>()

  const fetchShipperOptionsWithTooltip = (params: ISearch<any>) =>
    fetchShipperOptions({ withTooltip: true, ...params })
  const fetchConsignorOptionsWithTooltip = (params: ISearch<any>) =>
    fetchConsignorOptions({ withTooltip: true, ...params })
  const fetchConsigneeOptionsWithTooltip = (params: ISearch<any>) =>
    fetchConsigneeOptions({ withTooltip: true, ...params })

  return (
    <Form.Group title={t('bookings.parties.title')}>
      <Grid columns={3}>
        <Grid.Row>
          <Grid.Column>
            <Controller
              name='parties.shipper'
              control={control}
              render={({ field }) => (
                <StyledInput
                  as={SearchSelect}
                  label={t('bookings.parties.shipper')}
                  action={fetchShipperOptionsWithTooltip}
                  name={field.name}
                  onChange={({ value }) => field.onChange(value)}
                  value={field.value}
                  isClearable
                  error={errors.parties?.shipper?.message}
                  required
                  isDisabled={isFieldDisabled(BOOKING_FIELD_NAMES.SHIPPER)}
                  testId={TEST_ID_BOOKING_FORM_SHIPPER_FIELD}
                />
              )}
            />
          </Grid.Column>
          <Grid.Column>
            <Controller
              name='parties.forwarder'
              control={control}
              render={({ field }) => (
                <StyledInput
                  as={SearchSelect}
                  label={t('bookings.parties.forwarder')}
                  action={fetchForwarderOptions}
                  name={field.name}
                  error={errors.parties?.forwarder?.message}
                  onChange={({ value }) => field.onChange(value)}
                  value={field.value}
                  required
                  isDisabled={isFieldDisabled(BOOKING_FIELD_NAMES.FORWARDER)}
                />
              )}
            />
          </Grid.Column>
          <Grid.Column>
            <Controller
              name='parties.consignor'
              control={control}
              render={({ field }) => (
                <StyledInput
                  as={SearchSelect}
                  label={t('bookings.parties.consignor')}
                  action={fetchConsignorOptionsWithTooltip}
                  name={field.name}
                  error={errors.parties?.consignor?.message}
                  onChange={({ value }) => field.onChange(value)}
                  value={field.value}
                  required
                  isDisabled={isFieldDisabled(BOOKING_FIELD_NAMES.CONSIGNOR)}
                />
              )}
            />
          </Grid.Column>
          <Grid.Column>
            <Controller
              name='parties.consignee'
              control={control}
              render={({ field }) => (
                <StyledInput
                  as={SearchSelect}
                  label={t('bookings.parties.consignee')}
                  action={fetchConsigneeOptionsWithTooltip}
                  name={field.name}
                  error={errors.parties?.consignee?.message}
                  onChange={({ value }) => field.onChange(value)}
                  value={field.value}
                  required
                  isDisabled={isFieldDisabled(BOOKING_FIELD_NAMES.CONSIGNEE)}
                />
              )}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form.Group>
  )
}

export default Parties
