import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { object, string, ref, InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import useTimeout from 'services/hooks/use_timeout'
import {
  StyledForm,
  StyledLoginFormInput,
  StyledLoginFormButton,
  StyledLoginFormFormTitle,
  StyledLoginFormError,
  StyledLoginFormActions,
  StyledLoginFormLink,
} from 'components/update_password_form/style'
import { changePasswordFromInvitation, changePasswordFromRecovery } from 'views/iam/slices/iamSlice'
import { addNotification } from 'views/notifications/slice'
import { routeSignIn } from 'services/helpers/routes'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { patternPassword } from 'services/helpers/form_validation'
import PasswordComplexity from 'components/password_complexity'

const updatePasswordSchema = object({
  password: string()
    .required('iam.signIn.form.validation.required')
    .min(12, 'iam.signIn.form.validation.passwordMinLength')
    .matches(patternPassword, {
      message: 'iam.signIn.form.validation.passwordComplexity',
    }),
  passwordConfirmation: string()
    .required('iam.signIn.form.validation.required')
    .oneOf([ref('password')], 'iam.signIn.form.validation.passwordsDontMatch'),
}).required()

type UpdatePasswordData = InferType<typeof updatePasswordSchema>

type PasswordAction = 'recovery' | 'invitation'

interface UpdatePasswordFormProps {
  passwordAction: PasswordAction
  token: string
}

const UpdatePasswordForm: React.FC<UpdatePasswordFormProps> = ({ token, passwordAction }) => {
  const [apiError, setApiError] = useState(null)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { onTimeout } = useTimeout({ timeout: 3000 })
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<UpdatePasswordData>({
    resolver: yupResolver(updatePasswordSchema),
    reValidateMode: 'onChange',
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
  })
  const onSubmit = (data: UpdatePasswordData): void => {
    const { password, passwordConfirmation } = data

    let asyncThunk
    switch (passwordAction) {
      case 'invitation':
        asyncThunk = changePasswordFromInvitation
        break
      case 'recovery':
        asyncThunk = changePasswordFromRecovery
        break
      default:
        asyncThunk = changePasswordFromRecovery
    }
    dispatch(
      asyncThunk({
        password,
        passwordConfirmation,
        token,
      })
    )
      .unwrap()
      .then(() => {
        onTimeout(() => {
          navigate(routeSignIn())
        })
        dispatch(
          addNotification({
            type: 'success',
            title: t('iam.signIn.form.updatePassword'),
            text: t('iam.signIn.form.passwordUpdated'),
          })
        )
      })
      .catch(({ message }) => setApiError(message))
  }

  const passwordValue = watch('password')

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} noValidate>
      <StyledLoginFormFormTitle>{t('iam.signIn.recoveryTitle')}</StyledLoginFormFormTitle>
      <PasswordComplexity password={passwordValue} />
      <StyledLoginFormInput
        label={t('iam.signIn.form.password')}
        type='password'
        id='password'
        {...register('password')}
        error={errors.password?.message ? t(errors.password?.message) : undefined}
      />
      <StyledLoginFormInput
        label={t('iam.signIn.form.passwordConfirmation')}
        type='password'
        id='passwordConfirmation'
        {...register('passwordConfirmation')}
        error={
          !errors.password?.message && errors.passwordConfirmation?.message
            ? t(errors.passwordConfirmation?.message)
            : undefined
        }
      />

      <StyledLoginFormError>{apiError}</StyledLoginFormError>
      <StyledLoginFormButton
        text={t('iam.signIn.form.updatePassword')}
        variant='highlight'
        type='submit'
      />
      <StyledLoginFormActions>
        <StyledLoginFormLink as={Link} to={routeSignIn()}>
          {t('iam.signIn.form.footer.loginLink')}
        </StyledLoginFormLink>
      </StyledLoginFormActions>
    </StyledForm>
  )
}
UpdatePasswordForm.propTypes = {}

UpdatePasswordForm.defaultProps = {}

export default UpdatePasswordForm
