import { TRANSPORT_TYPE_AIR, TRANSPORT_TYPE_SEA } from 'constants/shipments'

const STATUS_IN_REVIEW = 'in_review'
const STATUS_RATE_CONFIRMATION_REQUESTED = 'rate_confirmation_requested'
const STATUS_PROPOSAL_SENT = 'proposal_sent'
const STATUS_PROPOSAL_VALIDATED = 'proposal_validated'
const STATUS_ACCEPTED = 'accepted'
const STATUS_CONFIRMED = 'confirmed'
const STATUS_COMPLETED = 'completed'
const STATUS_CANCELLED = 'cancelled'
const STATUS_DECLINED = 'declined'

export const MERCHANDISE_TYPE_CONTAINER = 'container'
export const MERCHANDISE_TYPE_PACKAGE = 'package'
export const MERCHANDISE_TYPE_TOTAL = 'total'
export const MERCHANDISE_TYPE_PACKAGE_TOTAL = 'package_total'

export const MERCHANDISE_FCL = 'FCL'
export const MERCHANDISE_LCL = 'LCL'

export const CURRENCY_USD = 'USD'
export const CURRENCY_EUR = 'EUR'
export const CURRENCY_USD_SYMBOL = '$'
export const CURRENCY_EUR_SYMBOL = '€'

export const BOOKING_REVIEW_STATUSES = [
  STATUS_IN_REVIEW,
  STATUS_RATE_CONFIRMATION_REQUESTED,
  STATUS_PROPOSAL_SENT,
  STATUS_PROPOSAL_VALIDATED,
] as const

export const BOOKING_SUCCESS_STATUSES = [STATUS_CONFIRMED, STATUS_ACCEPTED] as const

export const BOOKING_STATUSES = {
  IN_REVIEW: STATUS_IN_REVIEW,
  RATE_CONFIRMATION_REQUESTED: STATUS_RATE_CONFIRMATION_REQUESTED,
  PROPOSAL_SENT: STATUS_PROPOSAL_SENT,
  PROPOSAL_VALIDATED: STATUS_PROPOSAL_VALIDATED,
  ACCEPTED: STATUS_ACCEPTED,
  CONFIRMED: STATUS_CONFIRMED,
  COMPLETED: STATUS_COMPLETED,
  CANCELLED: STATUS_CANCELLED,
  DECLINED: STATUS_DECLINED,
} as const

export const BOOKING_ACTUAL_STATUSES = [
  STATUS_IN_REVIEW,
  STATUS_ACCEPTED,
  STATUS_CONFIRMED,
  STATUS_COMPLETED,
  STATUS_CANCELLED,
  STATUS_DECLINED,
] as const

export const BOOKING_FORWARDER_ACTION_REQUIRED_STATUSES = [
  STATUS_RATE_CONFIRMATION_REQUESTED,
  STATUS_IN_REVIEW,
  STATUS_ACCEPTED,
  STATUS_PROPOSAL_VALIDATED,
] as const
export const BOOKING_SHIPPER_ACTION_REQUIRED_STATUSES = [STATUS_PROPOSAL_SENT] as const

export const BOOKING_NO_ACTION_REQUIRED_STATUSES = [
  STATUS_COMPLETED,
  STATUS_CANCELLED,
  STATUS_DECLINED,
] as const

export const BOOKING_PAST_PROPOSAL_EXCHANGE_STATUSES = [
  STATUS_PROPOSAL_VALIDATED,
  STATUS_ACCEPTED,
  STATUS_CONFIRMED,
  STATUS_COMPLETED,
  STATUS_CANCELLED,
  STATUS_DECLINED,
] as const

export const BOOKING_EDITABLE_CANCELABLE_STATUSES = [
  STATUS_IN_REVIEW,
  STATUS_RATE_CONFIRMATION_REQUESTED,
  STATUS_PROPOSAL_SENT,
  STATUS_PROPOSAL_VALIDATED,
  STATUS_ACCEPTED,
] as const

export const BOOKING_DECLINABLE_STATUSES = [STATUS_IN_REVIEW, STATUS_ACCEPTED] as const

export const TRANSPORT_MODES = {
  SEA: 'sea',
  AIR: 'air',
} as const

export type BookingTransportType = typeof TRANSPORT_TYPE_SEA | typeof TRANSPORT_TYPE_AIR

export const FORWARDER = 'Forwarder'
export const SHIPPER = 'Shipper'

const ACTION_CREATION = 'create'
const ACTION_EDIT = 'edit'
const ACTION_CANCEL = 'cancel'
const ACTION_DECLINE = 'decline'
const ACTION_ACCEPT = 'accept'
const ACTION_CONFIRM = 'confirm'
const ACTION_SEND_PROPOSAL = 'send_proposal'
const ACTION_REQUEST_RATE_CONFIRMATION = 'request_rate_confirmation'
const ACTION_VALIDATE_BOOKING_PROPOSAL = 'validate_booking_proposal'
const DOCUMENT_CREATE = 'document_create'
const DOCUMENT_DELETE = 'document_delete'

export const BOOKING_ACTIONS = {
  CREATION: ACTION_CREATION,
  EDIT: ACTION_EDIT,
  CANCEL: ACTION_CANCEL,
  DECLINE: ACTION_DECLINE,
  ACCEPT: ACTION_ACCEPT,
  CONFIRM: ACTION_CONFIRM,
  SEND_PROPOSAL: ACTION_SEND_PROPOSAL,
  REQUEST_RATE_CONFIRMATION: ACTION_REQUEST_RATE_CONFIRMATION,
  VALIDATE_BOOKING_PROPOSAL: ACTION_VALIDATE_BOOKING_PROPOSAL,
  DOCUMENT_CREATE,
  DOCUMENT_DELETE,
}

export const BOOKING_EXCHANGE_ACTION_KEYS = {
  VALIDATE_PROPOSAL: 'validateProposal',
  ACCEPT_BOOKING: 'acceptBooking',
  CONFIRM_BOOKING: 'confirmBooking',
  CANCEL_BOOKING: 'cancelBooking',
  DECLINE_BOOKING: 'declineBooking',
  EDIT_BOOKING: 'editBooking',
  CREATE_BOOKING: 'createBooking',
} as const

export const BOOKING_FIELD_NAMES = {
  CLIENT_REFERENCE: 'clientReference',
  CLIENT_BOOKING_NUMBER: 'clientBookingNumber',
  FORWARDER_REFERENCE: 'forwarderReference',
  CUSTOM_REFERENCES: 'customReferences',
  SHIPPER: 'shipper',
  FORWARDER: 'forwarder',
  CONSIGNOR: 'consignor',
  CONSIGNEE: 'consignee',
  INCOTERMS: 'incoterms',
  INCOTERMS_LOCATION: 'incotermsLocation',
  TRANSPORT_TYPE: 'transportType',
  PRE_CARRIAGE: 'preCarriage',
  PTD: 'ptd',
  POL: 'pol',
  POL_PTD: 'polPtd',
  POL_PTA: 'polPta',
  TRANSSHIPMENTS: 'transshipments',
  POD: 'pod',
  POD_PTD: 'podPtd',
  POD_PTA: 'podPta',
  ON_CARRIAGE: 'onCarriage',
  PTA: 'pta',
  CARRIER: 'carrier',
  BOOKING_NUMBER: 'bookingNumber',
  MASTER_BL: 'masterBl',
  VESSELS: 'vessels',
  VOYAGE_NUMBERS: 'voyageNumbers',
  FLIGHT_NUMBERS: 'flightNumbers',
  SHIPMENT_ATTRIBUTES: 'shipmentAttributes',
  CUSTOM_FIELDS: 'customFields',
  VGM_CUT_OFF_DATE: 'vgmCutOffDate',
  VESSEL_CUT_OFF_DATE: 'vesselCutOffDate',
  MERCHANDISE_CONTENT: 'merchandiseContent',
  MERCHANDISE_SIGNIFICANT_CONTENT: 'merchandiseSignificantContent',
  MERCHANDISE_TYPE: 'merchandiseType',
  SHIPPING_MODE: 'shippingMode',
  RATE_CONFIRMATION: 'rateConfirmation',
  KEY_CONTACTS: 'keyContacts',
  COMMENTS: 'comments',
  FORWARDER_COMMENT: 'forwarderComment',
  RATE_AMOUNT: 'rateAmount',
  RATE_CURRENCY: 'rateCurrency',
} as const

export const BOOKING_FIELD_VALIDATIONS = {
  MINIMAL_VALUES: {
    VOLUME: 0.01,
    WEIGHT: 0.01,
    NUMBER_OF_PACKAGES: 1,
    LENGTH: 0.01,
    QUANTITY: 1,
    WIDTH: 0.01,
    HEIGHT: 0.01,
    AMOUNT: 0,
    SHIPMENT_WEIGHT: 0.01,
  },
  MAXIMAL_VALUES: {
    SHIPMENT_WEIGHT: 99999999,
  },
}

export const INFORMATIVE_FIELD_NAMES = [
  BOOKING_FIELD_NAMES.CLIENT_REFERENCE,
  BOOKING_FIELD_NAMES.CLIENT_BOOKING_NUMBER,
  BOOKING_FIELD_NAMES.FORWARDER_REFERENCE,
  BOOKING_FIELD_NAMES.CUSTOM_REFERENCES,
  BOOKING_FIELD_NAMES.CONSIGNOR,
  BOOKING_FIELD_NAMES.CONSIGNEE,
  BOOKING_FIELD_NAMES.POL,
  BOOKING_FIELD_NAMES.POL_PTD,
  BOOKING_FIELD_NAMES.POD,
  BOOKING_FIELD_NAMES.POD_PTA,
  BOOKING_FIELD_NAMES.POL_PTA,
  BOOKING_FIELD_NAMES.POD_PTD,
  BOOKING_FIELD_NAMES.TRANSSHIPMENTS,
  BOOKING_FIELD_NAMES.CARRIER,
  BOOKING_FIELD_NAMES.BOOKING_NUMBER,
  BOOKING_FIELD_NAMES.MASTER_BL,
  BOOKING_FIELD_NAMES.VESSELS,
  BOOKING_FIELD_NAMES.VOYAGE_NUMBERS,
  BOOKING_FIELD_NAMES.FLIGHT_NUMBERS,
  BOOKING_FIELD_NAMES.SHIPMENT_ATTRIBUTES,
  BOOKING_FIELD_NAMES.CUSTOM_FIELDS,
  BOOKING_FIELD_NAMES.VGM_CUT_OFF_DATE,
  BOOKING_FIELD_NAMES.VESSEL_CUT_OFF_DATE,
  BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT,
  BOOKING_FIELD_NAMES.COMMENTS,
  BOOKING_FIELD_NAMES.KEY_CONTACTS,
] as const

export const EDITABLE_BY_SHIPPER_FIELD_NAMES = [
  BOOKING_FIELD_NAMES.CLIENT_REFERENCE,
  BOOKING_FIELD_NAMES.CLIENT_BOOKING_NUMBER,
  BOOKING_FIELD_NAMES.FORWARDER_REFERENCE,
  BOOKING_FIELD_NAMES.CUSTOM_REFERENCES,
  BOOKING_FIELD_NAMES.SHIPPER,
  BOOKING_FIELD_NAMES.FORWARDER,
  BOOKING_FIELD_NAMES.CONSIGNOR,
  BOOKING_FIELD_NAMES.CONSIGNEE,
  BOOKING_FIELD_NAMES.INCOTERMS,
  BOOKING_FIELD_NAMES.INCOTERMS_LOCATION,
  BOOKING_FIELD_NAMES.TRANSPORT_TYPE,
  BOOKING_FIELD_NAMES.PRE_CARRIAGE,
  BOOKING_FIELD_NAMES.PTD,
  BOOKING_FIELD_NAMES.POL,
  BOOKING_FIELD_NAMES.POL_PTA,
  BOOKING_FIELD_NAMES.POL_PTD,
  BOOKING_FIELD_NAMES.TRANSSHIPMENTS,
  BOOKING_FIELD_NAMES.POD,
  BOOKING_FIELD_NAMES.POD_PTA,
  BOOKING_FIELD_NAMES.POD_PTD,
  BOOKING_FIELD_NAMES.ON_CARRIAGE,
  BOOKING_FIELD_NAMES.PTA,
  BOOKING_FIELD_NAMES.CARRIER,
  BOOKING_FIELD_NAMES.BOOKING_NUMBER,
  BOOKING_FIELD_NAMES.MASTER_BL,
  BOOKING_FIELD_NAMES.VESSELS,
  BOOKING_FIELD_NAMES.VOYAGE_NUMBERS,
  BOOKING_FIELD_NAMES.FLIGHT_NUMBERS,
  BOOKING_FIELD_NAMES.VGM_CUT_OFF_DATE,
  BOOKING_FIELD_NAMES.VESSEL_CUT_OFF_DATE,
  BOOKING_FIELD_NAMES.MERCHANDISE_CONTENT,
  BOOKING_FIELD_NAMES.MERCHANDISE_TYPE,
  BOOKING_FIELD_NAMES.SHIPPING_MODE,
  BOOKING_FIELD_NAMES.MERCHANDISE_SIGNIFICANT_CONTENT,
  BOOKING_FIELD_NAMES.RATE_CONFIRMATION,
  BOOKING_FIELD_NAMES.KEY_CONTACTS,
  BOOKING_FIELD_NAMES.COMMENTS,
] as const

export const EDITABLE_BY_FORWARDER_FIELD_NAMES = [
  BOOKING_FIELD_NAMES.FORWARDER_REFERENCE,
  BOOKING_FIELD_NAMES.PRE_CARRIAGE,
  BOOKING_FIELD_NAMES.PTD,
  BOOKING_FIELD_NAMES.POL,
  BOOKING_FIELD_NAMES.POL_PTA,
  BOOKING_FIELD_NAMES.POL_PTD,
  BOOKING_FIELD_NAMES.TRANSSHIPMENTS,
  BOOKING_FIELD_NAMES.POD,
  BOOKING_FIELD_NAMES.POD_PTA,
  BOOKING_FIELD_NAMES.POD_PTD,
  BOOKING_FIELD_NAMES.ON_CARRIAGE,
  BOOKING_FIELD_NAMES.PTA,
  BOOKING_FIELD_NAMES.TRANSSHIPMENTS,
  BOOKING_FIELD_NAMES.CARRIER,
  BOOKING_FIELD_NAMES.BOOKING_NUMBER,
  BOOKING_FIELD_NAMES.MASTER_BL,
  BOOKING_FIELD_NAMES.VESSELS,
  BOOKING_FIELD_NAMES.VOYAGE_NUMBERS,
  BOOKING_FIELD_NAMES.FLIGHT_NUMBERS,
  BOOKING_FIELD_NAMES.SHIPMENT_ATTRIBUTES,
  BOOKING_FIELD_NAMES.CUSTOM_FIELDS,
  BOOKING_FIELD_NAMES.VGM_CUT_OFF_DATE,
  BOOKING_FIELD_NAMES.VESSEL_CUT_OFF_DATE,
  BOOKING_FIELD_NAMES.FORWARDER_COMMENT,
  BOOKING_FIELD_NAMES.RATE_AMOUNT,
  BOOKING_FIELD_NAMES.RATE_CURRENCY,
] as const

export const ADDRESS_PORT_TYPE = 'port'
export const ADDRESS_AIRPORT_TYPE = 'airport'
export const ADDRESS_COMPANY_TYPE = 'company'

export const BOOKING_STEP_TYPES = {
  POL: 'POL',
  POD: 'POD',
  TRANSSHIPMENT: 'TRANSSHIPMENT',
} as const

export const trackingRefFormatExampleMap = {
  [TRANSPORT_TYPE_SEA]: 'ABCD1234567',
  [TRANSPORT_TYPE_AIR]: '123-12345678',
}

export const CATEGORY_REFERENCES = 'references' as const
export const CATEGORY_INCOTERMS = 'incoterms' as const
export const CATEGORY_PARTIES = 'parties' as const
export const CATEGORY_ROUTING = 'routing' as const
export const CATEGORY_MERCHANDISE = 'merchandise' as const
export const CATEGORY_TRANSPORT_DETAILS = 'transport_details' as const
export const CATEGORY_COMMENTS = 'comments' as const

export const BOOKING_CATEGORIES = [
  CATEGORY_REFERENCES,
  CATEGORY_INCOTERMS,
  CATEGORY_PARTIES,
  CATEGORY_ROUTING,
  CATEGORY_MERCHANDISE,
  CATEGORY_TRANSPORT_DETAILS,
  CATEGORY_COMMENTS,
] as const
