import React, { createContext } from 'react'

import type { TemplateFilter } from 'features/shipments/components/shipment_filters'

interface FilterTabContextValues {
  currentTab?: string
  switchToCustomTab: () => void
  switchToTemplatesTab: () => void
}

export const FilterTabContext = createContext<FilterTabContextValues>({
  switchToCustomTab: () => {},
  switchToTemplatesTab: () => {},
})

interface TemplateFiltersContextValues {
  templateFilters: TemplateFilter[] | null
  setTemplateFilters: React.Dispatch<React.SetStateAction<TemplateFilter[] | null>>
}

export const TemplateFiltersContext = createContext<TemplateFiltersContextValues>({
  templateFilters: [],
  setTemplateFilters: () => {},
})
