import React, { useContext, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { useFormContext, Controller, useFieldArray } from 'react-hook-form'

import { TRANSPORT_TYPE_SEA } from 'constants/shipments'

import InputDatepicker from 'components/input_datepicker'
import Select, { OnChangeFnProps } from 'components/select'
import Form from 'components/form'
import Grid from 'components/grid'

import { WITH_FRONT_BOOKING_CUT_OFF_DATES } from 'constants/organization_features'

import BookingContext from 'views/booking/contexts/booking_context'
import useBooking from 'views/booking/hooks/use_booking'
import useBookingRole from 'views/booking/hooks/use_booking_role'
import {
  StyledCustomRefWrapper,
  StyledSelectCustomRef,
  StyledInputCustomRef,
} from 'views/booking/components/form/style'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'

import {
  getTestIdForCustomFieldName,
  getTestIdForCustomFieldReference,
  TEST_ID_BOOKING_FORM_VESSEL_CUT_OFF,
  TEST_ID_BOOKING_FORM_VGM_CUT_OFF,
} from 'tests/e2e/test_ids'

import { isAnyArray } from 'services/helpers/values'
import { BookingFieldNames } from 'views/booking/slices/types'
import { BookingFormInput } from 'views/booking/components/form/types'
import { BOOKING_FIELD_NAMES } from 'constants/bookings'
import BlockContainerItem from 'components/block_container/block_container_item'
import BlockContainerAdd from 'components/block_container/block_container_add'

const Miscellaneous = ({
  isFieldDisabled,
  isCreate,
}: {
  isFieldDisabled: (fieldName: BookingFieldNames) => boolean
  isCreate: boolean
}) => {
  const { token } = useContext(BookingContext)
  const [booking] = useBooking(token)
  const { t } = useTranslation()
  const { isForwarder } = useBookingRole()
  const { features } = useOrganizationCan()
  const { availableCustomFieldKeys } = booking || {}

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<BookingFormInput>()
  const {
    fields: customFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'miscellaneous.customFields',
  })

  const transportType = watch('transportType')

  const isCurrentTransportTypeSea = useMemo(
    () => transportType === TRANSPORT_TYPE_SEA,
    [transportType]
  )

  const displayCustomFields = useMemo(
    () =>
      !isCreate &&
      ((isForwarder && isAnyArray(availableCustomFieldKeys)) || isAnyArray(customFields)),
    [isCreate, isForwarder, availableCustomFieldKeys, customFields]
  )

  const displayCutOffDates = useMemo(
    () => features(WITH_FRONT_BOOKING_CUT_OFF_DATES) && isCurrentTransportTypeSea,
    [features, isCurrentTransportTypeSea]
  )

  const displayMiscellaneous = useMemo(
    () => displayCustomFields || displayCutOffDates,
    [displayCustomFields, displayCutOffDates]
  )

  return (
    <>
      {displayMiscellaneous && (
        <Form.Group title={t('bookings.miscellaneous.title')}>
          <Grid columns={3}>
            {displayCutOffDates && (
              <Grid.Row>
                <Grid.Column>
                  <Controller
                    control={control}
                    name='miscellaneous.vgmCutOffDate'
                    render={({ field }) => (
                      <InputDatepicker
                        label={t('bookings.miscellaneous.vgmCutOffDate')}
                        withPortal
                        name={field.name}
                        onChange={({ value }) => field.onChange(value[0] || null)}
                        startDate={field.value}
                        disabled={isFieldDisabled(BOOKING_FIELD_NAMES.VGM_CUT_OFF_DATE)}
                        testId={TEST_ID_BOOKING_FORM_VGM_CUT_OFF}
                      />
                    )}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Controller
                    control={control}
                    name='miscellaneous.cutOffDate'
                    render={({ field }) => (
                      <InputDatepicker
                        label={t('bookings.miscellaneous.vesselCutOffDate')}
                        withPortal
                        name={field.name}
                        onChange={({ value }) => field.onChange(value[0] || null)}
                        startDate={field.value}
                        disabled={isFieldDisabled(BOOKING_FIELD_NAMES.VESSEL_CUT_OFF_DATE)}
                        testId={TEST_ID_BOOKING_FORM_VESSEL_CUT_OFF}
                      />
                    )}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            {displayCustomFields && (
              <StyledCustomRefWrapper>
                {customFields.map((customField, index) => (
                  <BlockContainerItem
                    title={t('bookings.miscellaneous.customField')}
                    key={customField.key}
                    index={index}
                    columns={3}
                    size={1}
                    length={customFields.length}
                    minLength={0}
                    disabled={isFieldDisabled(BOOKING_FIELD_NAMES.CUSTOM_FIELDS)}
                    removeBlock={() => remove(index)}
                  >
                    <Controller
                      name={`miscellaneous.customFields.${index}.key`}
                      control={control}
                      render={({ field }) => (
                        <StyledSelectCustomRef
                          as={Select}
                          options={availableCustomFieldKeys
                            ?.filter((r: string) => !customFields.map((cf) => cf.key).includes(r))
                            .map((c: string) => ({
                              value: c,
                              label: c,
                            }))}
                          name={field.name}
                          value={{
                            label: field.value || '',
                            value: field.value,
                          }}
                          error={errors.miscellaneous?.customFields?.[index]?.value?.message}
                          onChange={({ value }: OnChangeFnProps) => field.onChange(value.label)}
                          isDisabled={isFieldDisabled(BOOKING_FIELD_NAMES.CUSTOM_FIELDS)}
                          testId={getTestIdForCustomFieldName(index)}
                        />
                      )}
                    />
                    <Controller
                      name={`miscellaneous.customFields.${index}.value`}
                      control={control}
                      render={({ field }) => (
                        <StyledInputCustomRef
                          onChange={({ target: { value } }) => field.onChange(value)}
                          name={field.name}
                          value={field.value}
                          error={errors.miscellaneous?.customFields?.[index]?.value?.message}
                          disabled={isFieldDisabled(BOOKING_FIELD_NAMES.CUSTOM_FIELDS)}
                          testId={getTestIdForCustomFieldReference(index)}
                        />
                      )}
                    />
                  </BlockContainerItem>
                ))}
                {customFields.length < (availableCustomFieldKeys?.length || 0) &&
                  !isFieldDisabled(BOOKING_FIELD_NAMES.CUSTOM_FIELDS) && (
                    <BlockContainerAdd
                      title={t('bookings.miscellaneous.customField')}
                      addBlock={() => append({})}
                      btnSize={1}
                      columns={3}
                    />
                  )}
              </StyledCustomRefWrapper>
            )}
          </Grid>
        </Form.Group>
      )}
    </>
  )
}

export default Miscellaneous
