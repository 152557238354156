import styled, { css } from 'styled-components'

import { PageContentMaxWidth, PagePadding } from 'components/page/style'
import { borderRadiusMedium } from 'styles/utils/border_radius'
import { desktopLarge, tablet } from 'styles/utils/breakpoints'

export const StyledShipmentsListContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  padding-bottom: 40px;
  padding-right: ${PagePadding};
`

export const StyledShipmentsList = styled.div<{ $condensed?: boolean }>`
  position: relative;
  z-index: 1;
  ${(props) =>
    props.$condensed &&
    css`
      background: ${props.theme.white};
      padding: 10px 15px;
      ${borderRadiusMedium};

      @media ${tablet.up} {
        padding: 10px 18px;
      }

      @media ${desktopLarge.up} {
        max-width: ${PageContentMaxWidth};
      }

      margin-bottom: 30px;
    `}
`
