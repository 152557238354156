import styled from 'styled-components'

import { PageContentMaxWidth, PagePadding } from 'components/page/style'

import { desktopLarge } from 'styles/utils/breakpoints'

export const StyledShipments = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
`

export const StyledShipmentsMainHeader = styled.div`
  @media ${desktopLarge.up} {
    max-width: ${PageContentMaxWidth};
  }
  padding-right: ${PagePadding};
`

export const StyledShipmentsBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  margin-top: 15px;
  margin-bottom: 15px;

  // Fix dropdown going below shipment cards
  position: relative;
  z-index: 2;
`
