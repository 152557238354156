import React from 'react'

import DateHelper from 'services/helpers/date_helper'

import TagDiffTime from 'components/tag_diff_time'
import Flag, { FlagType } from 'components/flag'
import Icon from 'components/icon'
import {
  MILESTONE_TIME_TYPE_ACTUAL,
  MilestoneTimeType,
  MILESTONE_TIME_TYPE_PLANNED,
} from 'constants/shipments'

import {
  StyledQuickInfoItem,
  StyledQuickInfoItemName,
  StyledQuickInfoItemTime,
  StyledQuickInfoItemDiffTime,
  StyledQuickInfoItemLocation,
  StyledQuickInfoItemLocationIcon,
  StyledQuickInfoWrap,
} from 'components/quick_info/style'
import { Location } from 'features/shipments/types/shipment'

interface QuickInfoItemProps {
  location: Location
  time?: string
  diffTime?: string | null
  type?: MilestoneTimeType
}

const QuickInfoItem: React.FC<QuickInfoItemProps> = ({
  location,
  time,
  diffTime,
  type = MILESTONE_TIME_TYPE_PLANNED,
}) => (
  <StyledQuickInfoItem>
    <StyledQuickInfoWrap>
      <StyledQuickInfoItemLocation>
        <StyledQuickInfoItemLocationIcon
          as={Icon}
          width={11}
          height={11}
          name={type === MILESTONE_TIME_TYPE_ACTUAL ? 'checkmark' : 'arrow'}
          $type={type}
        />
        <StyledQuickInfoItemName>{location.name}</StyledQuickInfoItemName>
        {location.countryCode && <Flag countryCode={location.countryCode as FlagType} />}
      </StyledQuickInfoItemLocation>
      {time && (
        <StyledQuickInfoItemTime>
          {new DateHelper(time).toLocale({ hours: true })}
        </StyledQuickInfoItemTime>
      )}
    </StyledQuickInfoWrap>
    {diffTime && time && (
      <StyledQuickInfoItemDiffTime as={TagDiffTime} time={time} timeTo={diffTime} highlighted />
    )}
  </StyledQuickInfoItem>
)

export default QuickInfoItem
