// TODO: REFACTOR
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  camelCase,
  isArray as lodashIsArray,
  isObject as lodashIsObject,
  isEqual as lodashIsEqual,
  isBoolean as lodashIsBoolean,
  snakeCase,
  transform,
  pickBy,
  identity,
  omitBy,
  escapeRegExp,
  uniq,
  mapValues as lodashMapValues,
  pick,
  Many,
  omit,
  min,
  max,
  isString,
  sample,
  sortBy as lodashSortBy,
  reverse as lodashReverse,
  startCase,
  isUndefined as lodashIsUndefined,
  round as lodashRound,
  size,
} from 'lodash'

import type { AnyValue } from 'types/common'

type TValue = any
export const isNull = (value: TValue): value is null | undefined =>
  value === undefined || value === null
type NotNull<T> = T extends null | undefined ? never : T
// TODO: Add `is not null | undefined` to the function signatures (isPresent, isInputPresent)
// when negated types will become available https://github.com/Microsoft/TypeScript/pull/29317.
// Complete if needed the list of possibles value types
export const isPresent = (value: AnyValue): value is NotNull<typeof value> => !isNull(value)
export const isUndefined = (value: any) => lodashIsUndefined(value)

export const isInputEmpty = (value: TValue): value is NotNull<typeof value> =>
  isNull(value) || value === ''
export const isInputPresent = (value: TValue): boolean => !isInputEmpty(value)
export class Converter {
  value: TValue

  constructor(value: TValue) {
    this.value = value
  }

  toInt = (): number | null => (this.value ? parseInt(this.value, 10) : null)

  toFloat = (): number | null => (this.value ? parseFloat(this.value) : null)
}
export const capitalize = (string: TValue) => string?.charAt(0).toUpperCase() + string?.slice(1)
export const toCamelCaseKeys = (obj: TValue) =>
  transform(obj, (acc: TValue, value, key: TValue, target) => {
    const camelKey = isArray(target) ? key : camelCase(key)
    acc[camelKey] = lodashIsObject(value) ? toCamelCaseKeys(value) : value
  })
export const toSnakeCase = (value: TValue) => snakeCase(value)
export const toCamelCase = (value: TValue) => camelCase(value)
export const toStartCase = (value: TValue) => startCase(value)

export const stringToFloat = (value: string | null | undefined): number | null =>
  value ? parseFloat(value) : null

export const stringToInt = (value: string | null | undefined): number | null =>
  value ? parseInt(value, 10) : null

const DISABLE_SNAKIFY_KEY_PREFIX = 'DISABLE_SNAKIFY__'
export const disabledSnakeKey = (key: string): string => `${DISABLE_SNAKIFY_KEY_PREFIX}${key}`
export const disableSnakeKeyForObject = (object: { [key: string]: string }) => {
  const pairKeyValues = Object.entries(object)
  return pairKeyValues.reduce((acc, currentPair) => {
    const [key, value] = currentPair
    acc[disabledSnakeKey(key)] = value
    return acc
  }, {} as Record<string, string>)
}
export const toSnakeCaseKeys = (obj: TValue) =>
  transform(obj, (acc: TValue, value, key: TValue, target) => {
    let newKey = snakeCase(key)
    if (typeof key === 'string' && key.startsWith(DISABLE_SNAKIFY_KEY_PREFIX)) {
      newKey = key.replace(DISABLE_SNAKIFY_KEY_PREFIX, '')
    }
    if (isArray(target)) {
      newKey = key
    }
    acc[newKey] = lodashIsObject(value) ? toSnakeCaseKeys(value) : value
  })

// Shallow comparison
export const isObjEq = (obj: TValue, otherObj: TValue): boolean =>
  Object.entries(obj).toString() === Object.entries(otherObj).toString()
export const isObject = (object: TValue): object is Record<string, unknown> =>
  isPresent(object) && lodashIsObject(object) && !isArray(object) && !isString(object)
export const isAnyObject = (object: TValue): object is Record<string, unknown> =>
  isObject(object) && Object.keys(object).length > 0
export const isEmptyObject = (object: TValue): boolean => !isAnyObject(object)
export const isArray = (object: TValue): object is any[] => lodashIsArray(object)
export const isBoolean = (object: TValue): object is boolean => lodashIsBoolean(object)
export const isAnyArray = (array: TValue): boolean => isArray(array) && array.length > 0
export const isEmptyArray = (array: TValue): boolean => !isAnyArray(array)

// Can take any type of variable. Deep compares objects
export const isEqual = lodashIsEqual
export const round = lodashRound
export const filterObject = (
  object: TValue,
  predicate: (value: TValue, key: string) => boolean = identity
): TValue => pickBy(object, predicate)
export const pickAttributes = (
  object: TValue,
  attributes: Many<string | number | symbol>
): TValue => pick(object, attributes)
export const compactObject = (object: TValue) => omitBy(object, isNull)
export const omitKeys = (object: TValue, keys: string[]) => omit(object, keys)
export const compactArray = <T>(array: (T | null | undefined)[]): T[] =>
  array.filter((v): v is T => isPresent(v as any))
export const escapeRegexp = (str: string): string => escapeRegExp(str)
export const uniqArray = <T>(array: T[]): T[] => uniq(array)
export const mapValues = (
  object: Record<string, unknown> | null | undefined,
  iterator: () => void
) => lodashMapValues(object, iterator)
export const datesMax = (...dates: Date[]): Date | undefined => max(dates)
export const datesMin = (...dates: Date[]): Date | undefined => min(dates)
export const sampleValue = <T>(list: [T, ...T[]]): T | undefined => sample(list)
export const compareDateISO8601 = (a: string, b: string) => {
  if (a > b) return 1
  if (a < b) return -1
  return 0
}
export const sortBy = <T>(collection: T[], iteratees: ((value: T) => any)[]): T[] =>
  lodashSortBy(collection, iteratees)
export const reverse = <T>(array: T[]): T[] => lodashReverse(array)
export const extractProperties = <T, U extends T>({
  properties,
  value,
}: {
  properties: (keyof T)[]
  value: U
}) =>
  Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(value as Record<keyof U, any>).filter(([key, _value]) =>
      properties.includes(key as keyof T)
    ) as [keyof U, any]
  ) as T

export const isAValidLatitude = (latitude: number) => latitude <= 90 && latitude >= -90
export const isAValidLongitude = (longitude: number) => longitude <= 180 && longitude >= -180

/*
 * Checks if an element is included in a readonly array while respecting typing.
 *
 * @example
 * const array = ['a', 'b', 'c'] as const
 * array.includes('a') // Error: Argument of type string is not assignable to parameter of type '"a" | "b" | "c"'
 * readonlyArrayIncludes(array, 'a') // true
 * readonlyArrayIncludes(array, 'd') // false
 */
export const readonlyArrayIncludes = <T extends U, U>(coll: ReadonlyArray<T>, el: U): el is T =>
  coll.includes(el as T)

export const buildSelectValuesFromStaticTranslations = (
  staticTranslations: Record<string, string>
): { value: string; label: string }[] =>
  Object.entries(staticTranslations).map(([key, value]) => ({
    value: key,
    label: value,
  }))

const checkValue = (value: unknown | undefined): boolean => {
  if (typeof value === 'boolean' && value) {
    return true
  }

  if (typeof value === 'object' && value) {
    if (Array.isArray(value)) {
      return value.some(checkValue)
    }

    return Object.values(value).some(checkValue)
  }

  return false
}

/** To be used for fields marked as boolean. Check if any values of the complex object is true */
export const isFieldDirty = (data: unknown | undefined): boolean => {
  if (!data) return false

  if (typeof data !== 'object') return data === true

  return Object.values(data).some(checkValue)
}

const checkValuePresence = (value: unknown | undefined): boolean => {
  if (typeof value === 'object' && value) {
    if (Array.isArray(value)) {
      return value.some(checkValuePresence)
    }

    return Object.values(value).some(checkValuePresence)
  }

  return value !== undefined && value !== null
}

export const isAnyValuePresent = (data: unknown | undefined): boolean => {
  if (!data) return false

  return checkValuePresence(data)
}

export const arraySize = (collection?: any[] | null) => size(collection)
