import React, { FC } from 'react'

import IconTooltip from 'components/icon_tooltip'
import { OngoingAlertType, ShipmentAlertSummary } from 'features/shipments/types/shipment'

import { IconType } from 'components/icon'

interface OngoingAlertProps {
  ongoingAlert: ShipmentAlertSummary
}

const variantMap: Record<OngoingAlertType, IconType> = {
  [OngoingAlertType.PARCEL_EXCEPTION]: 'warning',
  [OngoingAlertType.CUSTOMER_MOVED]: 'house_swap',
  [OngoingAlertType.CUSTOMER_REFUSED_DELIVERY]: 'hand_stop',
  [OngoingAlertType.DELAYED_CUSTOMS_CLEARANCE]: 'customs_clearance',
  [OngoingAlertType.DELAYED_EXTERNAL_FACTORS]: 'delayed',
  [OngoingAlertType.HELD_FOR_PAYMENT]: 'debit_card_failed',
  [OngoingAlertType.INCORRECT_ADDRESS]: 'cross_plain',
  [OngoingAlertType.PICKUP_MISSED]: 'hand_failed',
  [OngoingAlertType.REJECTED_BY_CARRIER]: 'hand_stop',
  [OngoingAlertType.RETURNED_TO_SENDER]: 'arrow_go_back',
  [OngoingAlertType.RETURNING_TO_SENDER]: 'arrow_go_back',
  [OngoingAlertType.SHIPMENT_DAMAGE]: 'damaged',
  [OngoingAlertType.SHIPMENT_LOST]: 'interrogation_mark',
  [OngoingAlertType.FAILED_ATTEMPT]: 'cross_plain',
  [OngoingAlertType.ADDRESSEE_NOT_AVAILABLE]: 'cross_plain',
  [OngoingAlertType.BUSINESS_CLOSED]: 'cross_plain',
}

const OngoingAlertIcon: FC<OngoingAlertProps> = ({ ongoingAlert }) => {
  const variant = variantMap[ongoingAlert.alertType]

  return (
    <IconTooltip
      content={ongoingAlert?.details}
      width={28}
      height={28}
      size='nowrap'
      color='warn'
      placement='top-left'
      variant={variant}
    />
  )
}

export default OngoingAlertIcon
