import {
  LOCATION_TYPE_ADDRESS,
  LOCATION_TYPE_AIRPORT,
  LOCATION_TYPE_COMPANY,
  LOCATION_TYPE_INLAND,
  LOCATION_TYPE_PORT,
  TRANSPORT_TYPE_AIR,
  TRANSPORT_TYPE_PARCEL,
  TRANSPORT_TYPE_RAIL,
  TRANSPORT_TYPE_ROAD,
  TRANSPORT_TYPE_SEA,
} from 'constants/shipments'

export const MILESTONE_TYPE_SEA_DEPARTURE = 3
export const MILESTONE_TYPE_SEA_ARRIVAL = 4
export const MILESTONE_TYPE_VESSEL_MOORED_IN_THE_ARRIVAL_PORT = 1006
export const MILESTONE_TYPE_CONTAINER_PICKED_UP = 2000
export const MILESTONE_TYPE_CONTAINER_GATE_IN_AT_DEPARTURE_PORT = 2001
export const MILESTONE_TYPE_CONTAINER_LOADED_ON_VESSEL = 2002
export const MILESTONE_TYPE_CONTAINER_UNLOADED_FROM_VESSEL = 2003
export const MILESTONE_TYPE_CONTAINER_GATE_OUT_AT_ARRIVAL_PORT = 2004
export const MILESTONE_TYPE_EMPTY_CONTAINER_PICKED_UP = 2005
export const MILESTONE_TYPE_EMPTY_CONTAINER_RETURNED = 2006
export const MILESTONE_TYPE_CONTAINER_DELIVERED = 2007
export const MILESTONE_TYPE_SEA_GOODS_DEPARTURE = 2008
export const MILESTONE_TYPE_SEA_GOODS_ARRIVAL = 2009
export const MILESTONE_TYPE_CONTAINER_STUFFED = 2010
export const MILESTONE_TYPE_CONTAINER_STRIPPED = 2011

export const MILESTONE_TYPE_CONTAINER_UNLOADED_FROM_RAIL = 2012
export const MILESTONE_TYPE_CONTAINER_GATE_OUT = 2013
export const MILESTONE_TYPE_CONTAINER_GATE_IN = 2014
export const MILESTONE_TYPE_CONTAINER_LOADED_ON_RAIL = 2015
export const MILESTONE_TYPE_CONTAINER_LOADED_ON_BARGE = 2016
export const MILESTONE_TYPE_BARGE_DEPARTURE = 2017
export const MILESTONE_TYPE_BARGE_ARRIVAL = 2018
export const MILESTONE_TYPE_CONTAINER_UNLOADED_FROM_BARGE = 2019

export const MILESTONE_TYPE_LOADED_ON_TRUCK = 5004
export const MILESTONE_TYPE_TRUCK_APPROACHING_ARRIVAL = 5005
export const MILESTONE_TYPE_ROAD_DEPARTURE = 5006
export const MILESTONE_TYPE_ROAD_ARRIVAL = 5009
export const MILESTONE_TYPE_UNLOADED_FROM_TRUCK = 5010

export const MILESTONE_TYPE_AIR_BOOKED = 3001
export const MILESTONE_TYPE_CONSIGNMENT_RECEIVED_FROM_SHIPPER = 3002
export const MILESTONE_TYPE_CONSIGNMENT_DOCUMENTATION_RECEIVED_FROM_FLIGHT = 3003
export const MILESTONE_TYPE_CONSIGNMENT_PREPARED_FOR_LOADING = 3004
export const MILESTONE_TYPE_CONSIGNMENT_MANIFESTED_ON_FLIGHT = 3005
export const MILESTONE_TYPE_AIR_DEPARTURE = 3006
export const MILESTONE_TYPE_CONSIGNMENT_TRANSFERRED_TO_OTHER_AIRLINE = 3007
export const MILESTONE_TYPE_CONSIGNMENT_RECEIVED_FROM_OTHER_FLIGHT = 3008
export const MILESTONE_TYPE_CONSIGNMENT_RECEIVED_FROM_FLIGHT = 3009
export const MILESTONE_TYPE_CONSIGNMENT_TO_BE_TRANSFERRED_TO_OTHER_AIRLINE = 3010
export const MILESTONE_TYPE_CONSIGNEE_OR_AGENT_INFORMED_OF_ARRIVAL = 3011
export const MILESTONE_TYPE_CONSIGNMENT_ARRIVAL_DOCUMENTS_DELIVERED_TO_CONSIGNEE_OR_AGENT = 3012
export const MILESTONE_TYPE_CONSIGNMENT_INFORMATION_REPORTED_TO_CUSTOMS = 3013
export const MILESTONE_TYPE_CONSIGNMENT_CLEARED_BY_CUSTOMS = 3014
export const MILESTONE_TYPE_CONSIGNMENT_DELIVERED_TO_CONSIGNEE_OR_AGENT = 3015
export const MILESTONE_TYPE_CONSIGNMENT_DELIVERED_TO_CONSIGNEE_DOOR = 3016
export const MILESTONE_TYPE_CONSIGNMENT_WITH_A_DISCREPANCY = 3017
export const MILESTONE_TYPE_AIR_ARRIVAL = 3018
export const MILESTONE_TYPE_CONSIGNMENT_TRANSFERRED_TO_CUSTOMS_GOVERNMENT_CONTROL = 3019

export const MILESTONE_TYPE_LOADED_ON_RAIL = 10000
export const MILESTONE_TYPE_RAIL_DEPARTURE = 10001
export const MILESTONE_TYPE_RAIL_APPROACHING_ARRIVAL = 10002
export const MILESTONE_TYPE_RAIL_ARRIVAL = 10003
export const MILESTONE_TYPE_UNLOADED_FROM_RAIL = 10004

export const MILESTONE_TYPE_PARCEL_PICKUP = 11000
export const MILESTONE_TYPE_PARCEL_DELIVERED = 11001
export const MILESTONE_TYPE_PARCEL_SORTING_CENTER_ARRIVAL = 11002
export const MILESTONE_TYPE_PARCEL_SORTING_CENTER_PROCESSING = 11003
export const MILESTONE_TYPE_PARCEL_SORTING_CENTER_DEPARTURE = 11004
export const MILESTONE_TYPE_PARCEL_OUT_FOR_DELIVERY = 11005
export const MILESTONE_TYPE_PARCEL_CUSTOM_CLEARANCE_STARTED = 11006
export const MILESTONE_TYPE_PARCEL_CUSTOM_CLEARANCE_COMPLETED = 11007

export const MILESTONE_TYPE_READY_FOR_PICKUP_ORIGIN = 8
export const MILESTONE_TYPE_READY_FOR_PICKUP_DESTINATION = 9008
export const MILESTONE_TYPE_GOODS_AT_ORIGIN_CUSTOMS = 9000
export const MILESTONE_TYPE_GOODS_AT_DESTINATION_CUSTOMS = 9001
export const MILESTONE_TYPE_GOODS_UNDER_INSPECTION_AT_ORIGIN = 9002
export const MILESTONE_TYPE_GOODS_UNDER_INSPECTION_AT_DESTINATION = 9003
export const MILESTONE_TYPE_GOODS_BLOCKED_AT_ORIGIN = 9004
export const MILESTONE_TYPE_GOODS_BLOCKED_AT_DESTINATION = 9005
export const MILESTONE_TYPE_GOODS_CLEARED_AT_ORIGIN_CUSTOMS = 9006
export const MILESTONE_TYPE_GOODS_CLEARED_AT_DESTINATION_CUSTOMS = 9007

export const EMPTY_VALUE = 'empty'

export const TIMELINE_MAIN_MILESTONES = [
  MILESTONE_TYPE_SEA_DEPARTURE,
  MILESTONE_TYPE_SEA_ARRIVAL,
  MILESTONE_TYPE_ROAD_DEPARTURE,
  MILESTONE_TYPE_ROAD_ARRIVAL,
  MILESTONE_TYPE_AIR_DEPARTURE,
  MILESTONE_TYPE_AIR_ARRIVAL,
  MILESTONE_TYPE_RAIL_DEPARTURE,
  MILESTONE_TYPE_RAIL_ARRIVAL,
  MILESTONE_TYPE_CONTAINER_PICKED_UP,
  MILESTONE_TYPE_CONTAINER_DELIVERED,
  MILESTONE_TYPE_SEA_GOODS_DEPARTURE,
  MILESTONE_TYPE_SEA_GOODS_ARRIVAL,
  MILESTONE_TYPE_PARCEL_PICKUP,
  MILESTONE_TYPE_PARCEL_DELIVERED,
] as const

export const MILESTONES_DEPARTURE_TYPES = [
  MILESTONE_TYPE_SEA_DEPARTURE,
  MILESTONE_TYPE_ROAD_DEPARTURE,
  MILESTONE_TYPE_AIR_DEPARTURE,
  MILESTONE_TYPE_RAIL_DEPARTURE,
  MILESTONE_TYPE_BARGE_DEPARTURE,
] as const

export const MILESTONES_ORIGIN_LOCATION_TYPE_MAP = {
  [LOCATION_TYPE_PORT]: MILESTONE_TYPE_SEA_DEPARTURE,
  [LOCATION_TYPE_COMPANY]: MILESTONE_TYPE_ROAD_DEPARTURE,
  [LOCATION_TYPE_INLAND]: MILESTONE_TYPE_ROAD_DEPARTURE,
  [LOCATION_TYPE_ADDRESS]: MILESTONE_TYPE_ROAD_DEPARTURE,
  [LOCATION_TYPE_AIRPORT]: MILESTONE_TYPE_AIR_DEPARTURE,
} as const

export const MILESTONES_DESTINATION_LOCATION_TYPE_MAP = {
  [LOCATION_TYPE_PORT]: MILESTONE_TYPE_SEA_ARRIVAL,
  [LOCATION_TYPE_COMPANY]: MILESTONE_TYPE_ROAD_ARRIVAL,
  [LOCATION_TYPE_INLAND]: MILESTONE_TYPE_ROAD_ARRIVAL,
  [LOCATION_TYPE_ADDRESS]: MILESTONE_TYPE_ROAD_ARRIVAL,
  [LOCATION_TYPE_AIRPORT]: MILESTONE_TYPE_AIR_ARRIVAL,
} as const

export const MILESTONES_ARRIVAL_TYPES = [
  MILESTONE_TYPE_SEA_ARRIVAL,
  MILESTONE_TYPE_ROAD_ARRIVAL,
  MILESTONE_TYPE_AIR_ARRIVAL,
  MILESTONE_TYPE_RAIL_ARRIVAL,
  MILESTONE_TYPE_BARGE_ARRIVAL,
] as const

export const TIMELINE_CARRIER_HAULAGE_MILESTONES = [
  MILESTONE_TYPE_CONTAINER_GATE_IN,
  MILESTONE_TYPE_CONTAINER_LOADED_ON_BARGE,
  MILESTONE_TYPE_CONTAINER_LOADED_ON_RAIL,
  MILESTONE_TYPE_BARGE_DEPARTURE,
  MILESTONE_TYPE_RAIL_DEPARTURE,
  MILESTONE_TYPE_ROAD_DEPARTURE,
  MILESTONE_TYPE_BARGE_ARRIVAL,
  MILESTONE_TYPE_RAIL_ARRIVAL,
  MILESTONE_TYPE_ROAD_ARRIVAL,
  MILESTONE_TYPE_CONTAINER_UNLOADED_FROM_BARGE,
  MILESTONE_TYPE_CONTAINER_UNLOADED_FROM_RAIL,
  MILESTONE_TYPE_CONTAINER_GATE_OUT,
]

export const TIMELINE_SEA_MILESTONES = [
  MILESTONE_TYPE_SEA_DEPARTURE,
  MILESTONE_TYPE_SEA_ARRIVAL,
  MILESTONE_TYPE_VESSEL_MOORED_IN_THE_ARRIVAL_PORT,
  MILESTONE_TYPE_CONTAINER_PICKED_UP,
  MILESTONE_TYPE_CONTAINER_GATE_IN_AT_DEPARTURE_PORT,
  MILESTONE_TYPE_CONTAINER_LOADED_ON_VESSEL,
  MILESTONE_TYPE_CONTAINER_UNLOADED_FROM_VESSEL,
  MILESTONE_TYPE_CONTAINER_GATE_OUT_AT_ARRIVAL_PORT,
  MILESTONE_TYPE_EMPTY_CONTAINER_PICKED_UP,
  MILESTONE_TYPE_EMPTY_CONTAINER_RETURNED,
  MILESTONE_TYPE_CONTAINER_DELIVERED,
  MILESTONE_TYPE_SEA_GOODS_DEPARTURE,
  MILESTONE_TYPE_SEA_GOODS_ARRIVAL,
  MILESTONE_TYPE_CONTAINER_STUFFED,
  MILESTONE_TYPE_CONTAINER_STRIPPED,
]

export const TIMELINE_ROAD_MILESTONES = [
  MILESTONE_TYPE_LOADED_ON_TRUCK,
  MILESTONE_TYPE_TRUCK_APPROACHING_ARRIVAL,
  MILESTONE_TYPE_ROAD_DEPARTURE,
  MILESTONE_TYPE_ROAD_ARRIVAL,
  MILESTONE_TYPE_UNLOADED_FROM_TRUCK,
]

export const TIMELINE_AIR_MILESTONES = [
  MILESTONE_TYPE_AIR_BOOKED,
  MILESTONE_TYPE_CONSIGNMENT_RECEIVED_FROM_SHIPPER,
  MILESTONE_TYPE_CONSIGNMENT_DOCUMENTATION_RECEIVED_FROM_FLIGHT,
  MILESTONE_TYPE_CONSIGNMENT_PREPARED_FOR_LOADING,
  MILESTONE_TYPE_CONSIGNMENT_MANIFESTED_ON_FLIGHT,
  MILESTONE_TYPE_AIR_DEPARTURE,
  MILESTONE_TYPE_CONSIGNMENT_TRANSFERRED_TO_OTHER_AIRLINE,
  MILESTONE_TYPE_CONSIGNMENT_RECEIVED_FROM_OTHER_FLIGHT,
  MILESTONE_TYPE_CONSIGNMENT_RECEIVED_FROM_FLIGHT,
  MILESTONE_TYPE_CONSIGNMENT_TO_BE_TRANSFERRED_TO_OTHER_AIRLINE,
  MILESTONE_TYPE_CONSIGNEE_OR_AGENT_INFORMED_OF_ARRIVAL,
  MILESTONE_TYPE_CONSIGNMENT_ARRIVAL_DOCUMENTS_DELIVERED_TO_CONSIGNEE_OR_AGENT,
  MILESTONE_TYPE_CONSIGNMENT_INFORMATION_REPORTED_TO_CUSTOMS,
  MILESTONE_TYPE_CONSIGNMENT_CLEARED_BY_CUSTOMS,
  MILESTONE_TYPE_CONSIGNMENT_DELIVERED_TO_CONSIGNEE_OR_AGENT,
  MILESTONE_TYPE_CONSIGNMENT_DELIVERED_TO_CONSIGNEE_DOOR,
  MILESTONE_TYPE_CONSIGNMENT_WITH_A_DISCREPANCY,
  MILESTONE_TYPE_AIR_ARRIVAL,
  MILESTONE_TYPE_CONSIGNMENT_TRANSFERRED_TO_CUSTOMS_GOVERNMENT_CONTROL,
]

export const TIMELINE_AIR_MILESTONES_WITHOUT_FLIGHT_NUMBER = [
  MILESTONE_TYPE_CONSIGNMENT_RECEIVED_FROM_SHIPPER,
]

export const TIMELINE_MILESTONES_WITH_METADATA = [
  MILESTONE_TYPE_AIR_BOOKED,
  MILESTONE_TYPE_CONSIGNMENT_RECEIVED_FROM_SHIPPER,
  MILESTONE_TYPE_CONSIGNMENT_DOCUMENTATION_RECEIVED_FROM_FLIGHT,
  MILESTONE_TYPE_CONSIGNMENT_PREPARED_FOR_LOADING,
  MILESTONE_TYPE_CONSIGNMENT_MANIFESTED_ON_FLIGHT,
  MILESTONE_TYPE_AIR_DEPARTURE,
  MILESTONE_TYPE_CONSIGNMENT_TRANSFERRED_TO_OTHER_AIRLINE,
  MILESTONE_TYPE_CONSIGNMENT_RECEIVED_FROM_OTHER_FLIGHT,
  MILESTONE_TYPE_CONSIGNMENT_RECEIVED_FROM_FLIGHT,
  MILESTONE_TYPE_CONSIGNMENT_TO_BE_TRANSFERRED_TO_OTHER_AIRLINE,
  MILESTONE_TYPE_CONSIGNEE_OR_AGENT_INFORMED_OF_ARRIVAL,
  MILESTONE_TYPE_CONSIGNMENT_ARRIVAL_DOCUMENTS_DELIVERED_TO_CONSIGNEE_OR_AGENT,
  MILESTONE_TYPE_CONSIGNMENT_INFORMATION_REPORTED_TO_CUSTOMS,
  MILESTONE_TYPE_CONSIGNMENT_CLEARED_BY_CUSTOMS,
  MILESTONE_TYPE_CONSIGNMENT_DELIVERED_TO_CONSIGNEE_OR_AGENT,
  MILESTONE_TYPE_CONSIGNMENT_DELIVERED_TO_CONSIGNEE_DOOR,
  MILESTONE_TYPE_CONSIGNMENT_WITH_A_DISCREPANCY,
  MILESTONE_TYPE_AIR_ARRIVAL,
  MILESTONE_TYPE_CONSIGNMENT_TRANSFERRED_TO_CUSTOMS_GOVERNMENT_CONTROL,
]

export const TIMELINE_RAIL_MILESTONES = [
  MILESTONE_TYPE_LOADED_ON_RAIL,
  MILESTONE_TYPE_RAIL_DEPARTURE,
  MILESTONE_TYPE_RAIL_APPROACHING_ARRIVAL,
  MILESTONE_TYPE_RAIL_ARRIVAL,
  MILESTONE_TYPE_UNLOADED_FROM_RAIL,
]

export const TIMELINE_PARCEL_MILESTONES = [
  MILESTONE_TYPE_PARCEL_PICKUP,
  MILESTONE_TYPE_PARCEL_SORTING_CENTER_ARRIVAL,
  MILESTONE_TYPE_PARCEL_SORTING_CENTER_PROCESSING,
  MILESTONE_TYPE_PARCEL_SORTING_CENTER_DEPARTURE,
  MILESTONE_TYPE_PARCEL_OUT_FOR_DELIVERY,
  MILESTONE_TYPE_PARCEL_DELIVERED,
]

export const TIMELINE_TYPE_MILESTONES = {
  [TRANSPORT_TYPE_SEA]: TIMELINE_SEA_MILESTONES,
  [TRANSPORT_TYPE_ROAD]: TIMELINE_ROAD_MILESTONES,
  [TRANSPORT_TYPE_AIR]: TIMELINE_AIR_MILESTONES,
  [TRANSPORT_TYPE_RAIL]: TIMELINE_RAIL_MILESTONES,
  [TRANSPORT_TYPE_PARCEL]: TIMELINE_PARCEL_MILESTONES,
} as const

export const TIMELINE_GENERAL_MILESTONES = [
  MILESTONE_TYPE_READY_FOR_PICKUP_ORIGIN,
  MILESTONE_TYPE_READY_FOR_PICKUP_DESTINATION,
  MILESTONE_TYPE_GOODS_AT_ORIGIN_CUSTOMS,
  MILESTONE_TYPE_GOODS_AT_DESTINATION_CUSTOMS,
  MILESTONE_TYPE_GOODS_UNDER_INSPECTION_AT_ORIGIN,
  MILESTONE_TYPE_GOODS_UNDER_INSPECTION_AT_DESTINATION,
  MILESTONE_TYPE_GOODS_BLOCKED_AT_ORIGIN,
  MILESTONE_TYPE_GOODS_BLOCKED_AT_DESTINATION,
  MILESTONE_TYPE_GOODS_CLEARED_AT_ORIGIN_CUSTOMS,
  MILESTONE_TYPE_GOODS_CLEARED_AT_DESTINATION_CUSTOMS,
] as const
