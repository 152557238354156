import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from 'services/store/store'

interface ISsoState {
  loginUrl?: string
  logoutRedirectUrl?: string
  logoutHint?: string
  isSSOUser?: boolean
  customDomain?: string
  clientId?: string
  logoutInitiatedByUser?: boolean
  bypassLogoutPage?: boolean
}

const initialState: ISsoState = {
  loginUrl: '',
  logoutRedirectUrl: '',
  logoutHint: '',
  isSSOUser: false,
  customDomain: '',
  clientId: '',
  logoutInitiatedByUser: false,
  bypassLogoutPage: false,
}

const ssoSlice = createSlice({
  name: 'sso',
  initialState,
  reducers: {
    setSsoConfiguration: (state, action: PayloadAction<ISsoState>) => {
      const {
        loginUrl,
        logoutRedirectUrl,
        logoutHint,
        isSSOUser,
        clientId,
        customDomain,
        bypassLogoutPage,
      } = action.payload
      state.loginUrl = loginUrl
      state.logoutRedirectUrl = logoutRedirectUrl
      state.logoutHint = logoutHint
      state.isSSOUser = isSSOUser
      state.clientId = clientId
      state.customDomain = customDomain
      state.bypassLogoutPage = bypassLogoutPage
    },
    setLogoutInitiatedByUser: (state, action) => {
      state.logoutInitiatedByUser = action.payload
    },
  },
})

export const isSSOUser = (state: RootState) => state.sso.isSSOUser

export const hasAValidSsoLogoutConfiguration = (state: RootState) => {
  const { logoutRedirectUrl, loginUrl } = state.sso
  return !!(logoutRedirectUrl && loginUrl)
}

export const getSsoState = (state: RootState) => state.sso

export const { setSsoConfiguration, setLogoutInitiatedByUser } = ssoSlice.actions

export default ssoSlice.reducer
